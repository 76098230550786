var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import Fuse from 'fuse.js';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
export function useSearch() {
    const dispatch = useDispatch();
    const region = useSelector((state) => {
        var _a;
        return ((_a = state.region.regions.find((region) => region.id === state.region.id)) === null || _a === void 0 ? void 0 : _a.externalRegion) ||
            '';
    });
    const selectProducts = useSelector((state) => state.products.products);
    const allProducts = useMemo(() => new Map(selectProducts.map((product) => [product.id, product])), [selectProducts]);
    const allProducers = useSelector((state) => state.producers.producers);
    return (query) => {
        return Promise.all([
            productSearch(query, region, allProducts),
            Promise.resolve(producerSearch(query.replace(' ', '+'), allProducers)),
        ]).then(([products, producers]) => {
            dispatch({
                type: 'PRODUCT_AUTOCOMPLETE_SEARCH_SUCCESS',
                payload: { products, producers, query },
            });
            return {
                products,
                producers,
            };
        });
    };
}
function producerSearch(query, producers) {
    const fuse = new Fuse(producers, fuseOptions);
    const result = fuse.search(query);
    return result
        .map((item) => ('item' in item ? item.item : null))
        .filter((item) => item !== null);
}
function productSearch(query, region, products) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve) => {
            axios
                .get(`${process.env.SEARCH_API}/${region}?q=${query}`, {})
                .then((response) => {
                const results = response.data
                    .map((productId) => products.get(productId))
                    .filter((product) => product !== undefined);
                resolve(results);
            })
                .catch(() => {
                resolve([]);
            });
        });
    });
}
const fuseOptions = {
    shouldSort: true,
    includeScore: true,
    threshold: 0.2,
    location: 0,
    tokenize: true,
    distance: 100,
    includeMatches: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name', 'description'],
};

import { Button, ButtonRow } from 'common';
import React from 'react';
import './CheckoutSubPageButtonContainer.scss';
function CheckoutSubPageButtonRow({ saveButtonText, backButtonText, disableSaveButton = false, handleSave, handleAbort, }) {
    return (React.createElement("div", { className: "checkout-sub-page__button-container" },
        React.createElement(ButtonRow, { display: "grow-right", stickyOnMobile: true },
            React.createElement(Button, { type: "action", style: "ghost", onClick: handleAbort }, backButtonText),
            handleSave ? (React.createElement(Button, { type: "action", style: "primary", onClick: handleSave, disabled: disableSaveButton }, saveButtonText)) : (React.createElement(Button, { type: "submit", style: "primary", disabled: disableSaveButton }, saveButtonText)))));
}
export default CheckoutSubPageButtonRow;

import AppPortal from 'components/UI/AppPortal'
import Notification from 'components/UI/Notification'
import Overlay from 'components/UI/Overlay'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDateAndMonth, getDateAsText } from 'utilities/date'

class DeliveryDateChangedNotification extends Component {
    hide() {
        this.props.dispatch({ type: 'CLOSE_DELIVERY_DATE_CHANGED_NOTIFICATION' })
    }

    render() {
        const { show, deliveryDate } = this.props

        if (!show) return null

        return (
            <AppPortal>
                <Overlay onClick={() => this.hide()}>
                    <Notification title="Leveringsdag endret" onClose={() => this.hide()}>
                        <p>
                            Første mulige leveringsdag for adressen du har valgt er{' '}
                            <strong>
                                {getDateAsText(deliveryDate, true, false)},{' '}
                                {getDateAndMonth(deliveryDate, false)}
                            </strong>
                            .
                        </p>
                    </Notification>
                </Overlay>
            </AppPortal>
        )
    }
}

DeliveryDateChangedNotification.propTypes = {
    show: PropTypes.bool,
    deliveryDate: PropTypes.string,
    dispatch: PropTypes.func,
}

function mapStateToProps({ checkout, ui }) {
    return {
        show: ui.showDeliveryDateChangeNotification,
        deliveryDate: checkout.deliveryDate,
    }
}

export default connect(mapStateToProps)(DeliveryDateChangedNotification)

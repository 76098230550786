import { setDeliverySms } from 'actions/PersonalInfo';
import { Card, LinkBox } from 'common';
import CheckboxWithLabel from 'common/components/CheckboxWithLabel/CheckboxWithLabel';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValidContactInfo } from 'utilities/validators';
import FieldLinkBox from '../FieldLinkBox/FieldLinkBox';
import './SenderCard.scss';
function SenderCard() {
    const deliverySms = useSelector((state) => state.personalInfo.deliverySms);
    const dispatch = useDispatch();
    const setSms = (newValue) => dispatch(setDeliverySms(newValue));
    const personalInfo = useSelector((state) => state.personalInfo);
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const { firstName, lastName, email, mobile } = personalInfo;
    const cardTitle = orderType === 'single'
        ? 'Kontaktinfo'
        : 'Avsender';
    return (React.createElement(Card, { title: cardTitle }, isValidContactInfo(personalInfo) ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "sender-card__name" },
            firstName,
            " ",
            lastName),
        React.createElement("div", { className: "sender-card__email" }, email),
        React.createElement("div", { className: "sender-card__mobile" }, mobile),
        React.createElement("div", { className: "sender-card__delivery-sms" },
            React.createElement(CheckboxWithLabel, { isChecked: deliverySms, setIsChecked: setSms, labelText: "Send SMS ved levering" })),
        React.createElement(LinkBox, { colorStyle: "gray", text: "Endre avsender", onClick: () => dispatch(push('/checkout/contactinfo')) }))) : (React.createElement(FieldLinkBox, { isValid: false, text: "Legg til avsender", onClick: () => dispatch(push('/checkout/contactinfo')) }))));
}
export default SenderCard;

import { generateToken } from 'actions/NewPaymentMethod';
import { addPaymentMethod } from 'actions/PaymentMethod';
import { CheckoutCardSelector, CheckoutCvc } from 'checkout';
import { FEATURES, isFeatureEnabled, LinkBox } from 'common';
import Radio from 'common/components/Radio';
import Icon from 'components/UI/Icon';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethodType } from 'types/paymentMethodType';
import { formatExpirationDate } from 'utilities/paymentMethod';
import { cardFormatMasked, cardFormatShort, cardIssuerFromNumber } from 'utilities/text';
import './CheckoutPaymentMethodTypeSelector.scss';
const vipps = require('img/Vipps.svg');
function CheckoutPaymentMethodTypeSelector() {
    const dispatch = useDispatch();
    const { paymentMethodType, savePaymentMethodDirectly } = useSelector(({ checkout }) => ({
        paymentMethodType: checkout.paymentMethodType,
        savePaymentMethodDirectly: checkout.checkoutMode == 'SUBSCRIPTION_ORDER',
    }));
    const { token: newCardToken, tokenError: newCardTokenHasErrored, active: useNewPaymentCard, cardNo: newCardNumber, expiry: newCardExpiryDate, } = useSelector(({ newPaymentMethod }) => newPaymentMethod);
    const { preferred: preferredPaymentCard } = useSelector(({ paymentMethod }) => paymentMethod);
    const { isLoggedIn, isLoggedInSpid } = useSelector(({ user }) => user);
    const isVippsEnabled = useSelector(({ checkout, features, ui }) => checkout.checkoutMode === 'SINGLE_ORDER' &&
        isFeatureEnabled(features.enabled, FEATURES.VIPPS) &&
        !ui.isApp);
    // Local state to handle displaying correct credit card component
    const [hasPreferredPaymentCard, setHasPreferredPaymentCard] = useState(Object.keys(preferredPaymentCard).length > 0);
    const [showSelectedCard, setShowSelectedCard] = useState(hasPreferredPaymentCard || (useNewPaymentCard && newCardToken != null));
    const [showForm, setShowForm] = useState(paymentMethodType == PaymentMethodType.CREDIT_CARD && !showSelectedCard);
    const [cardIssuer, setCardIssuer] = useState();
    const [maskedCardNumber, setMaskedCardNumber] = useState();
    const [expiryDateString, setExpiryDateString] = useState();
    const determineEditButtonVisibility = () => {
        return (isLoggedIn == null ||
            !isLoggedIn ||
            isLoggedInSpid == null ||
            !isLoggedInSpid ||
            useNewPaymentCard);
    };
    const [showEditButton, setShowEditButton] = useState(determineEditButtonVisibility());
    // useEffects to update the card display state
    useEffect(() => {
        setShowSelectedCard(!newCardTokenHasErrored && newCardToken != null);
    }, [newCardToken, newCardTokenHasErrored]);
    useEffect(() => {
        setShowForm(paymentMethodType == PaymentMethodType.CREDIT_CARD && !showSelectedCard);
    }, [paymentMethodType, showSelectedCard]);
    useEffect(() => {
        if (Object.keys(preferredPaymentCard).length > 0) {
            setHasPreferredPaymentCard(true);
            setShowSelectedCard(true);
            setShowForm(false);
        }
    }, [preferredPaymentCard]);
    useEffect(() => {
        setShowEditButton(determineEditButtonVisibility);
    }, [isLoggedIn, isLoggedInSpid, useNewPaymentCard]);
    // find which card to display
    useEffect(() => {
        if (!showSelectedCard)
            return;
        if (useNewPaymentCard) {
            setCardIssuer(cardIssuerFromNumber(newCardNumber) || undefined);
            setMaskedCardNumber(cardFormatMasked(newCardNumber));
            setExpiryDateString(newCardExpiryDate);
        }
        else if (hasPreferredPaymentCard) {
            setCardIssuer(preferredPaymentCard.type);
            setMaskedCardNumber(cardFormatShort(preferredPaymentCard.cardNo));
            setExpiryDateString(formatExpirationDate(preferredPaymentCard.expiry));
        }
        else {
            throw new Error('Trying to select card without a valid card');
        }
    }, [showSelectedCard]);
    // Event handlers
    const handleOptionClick = (type) => {
        dispatch({
            type: 'SET_PAYMENT_METHOD_TYPE',
            payload: type,
        });
    };
    const handleNewCardSumbit = () => {
        dispatch({ type: 'SET_NEW_CARD' });
        const treeDSecureRedirectUrl = window.location.origin + '/3dsecure/paymentmethod?context=checkout';
        const onGenerateTokenSuccess = (cardNo, generatedToken) => {
            if (savePaymentMethodDirectly) {
                dispatch(addPaymentMethod(cardNo, generatedToken, true, treeDSecureRedirectUrl));
            }
            setShowForm(false);
            setShowSelectedCard(true);
        };
        dispatch(generateToken(onGenerateTokenSuccess));
    };
    const handleEditCardButtonClick = (e) => {
        e.preventDefault();
        setShowForm(true);
        setShowSelectedCard(false);
    };
    const getClassName = (type) => {
        let className = 'payment-method-type__option';
        if (type == paymentMethodType) {
            className += ' payment-method-type__option--clicked';
        }
        return className;
    };
    return (React.createElement("div", { className: "payment-method-type" },
        isVippsEnabled && (React.createElement(Radio, { className: getClassName(PaymentMethodType.VIPPS), onChange: () => handleOptionClick(PaymentMethodType.VIPPS), checked: paymentMethodType == PaymentMethodType.VIPPS },
            React.createElement("div", { className: "payment-method-type__content" },
                React.createElement("div", { className: "payment-method-type__content__title" }, "Vipps"),
                React.createElement("div", { className: "payment-method-type__content__logo" },
                    React.createElement(Icon, { svg: vipps }))))),
        React.createElement(Radio, { className: getClassName(PaymentMethodType.CREDIT_CARD), onChange: () => handleOptionClick(PaymentMethodType.CREDIT_CARD), checked: paymentMethodType == PaymentMethodType.CREDIT_CARD },
            React.createElement("div", { className: "payment-method-type__option__credit-card_wrapper" },
                React.createElement("div", { className: 'payment-method-type__option__credit-card' },
                    React.createElement(CheckoutCardSelector, { showSelectedCard: showSelectedCard, showForm: showForm, maskedCardNumber: maskedCardNumber || '', cardIssuer: cardIssuer || '', expiryDateString: expiryDateString || '', handleNewCardSubmit: handleNewCardSumbit, handleEditCardButtonClick: handleEditCardButtonClick, showEditButton: showEditButton })),
                showSelectedCard && paymentMethodType == PaymentMethodType.CREDIT_CARD && (React.createElement("div", { className: "payment-method-type__option__cvc" },
                    React.createElement(CheckoutCvc, null))))),
        hasPreferredPaymentCard && isLoggedIn && isLoggedInSpid && (React.createElement(LinkBox, { colorStyle: "gray", text: "Bruk et annet kort", onClick: () => dispatch(push('/checkout/payment')) }))));
}
export default CheckoutPaymentMethodTypeSelector;

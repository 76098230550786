import { setSubscriptionMode } from 'actions/Subscription';
import HideScroll from 'common/components/HideScroll/HideScroll';
import { Pill, PillType } from 'common/components/Pill/Pill';
import { PillList, PillListType } from 'common/components/Pill/PillList';
import Switch from 'components/UI/Switch';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isPaused } from 'utilities/subscription';
import { filterProducts } from '../../util';
import ProductGrid from '../ProductGrid/ProductGrid';
import { SortOption, sortProducts } from '../SortDropdown/productSort';
import SortDropdown from '../SortDropdown/SortDropdown';
import { sortByReducer } from './FilterableCategoryProductGridUtils';
function URLControlledFilterableCategoryProductGrid({ products, categories = [], categoryFilterHeading, allowSort = false, showSubscriptionSwitch = false, showCount = false, pillListHeading = '', }) {
    var _a, _b;
    const { category: categorySlug, subcategory: subCategorySlug } = useParams();
    const initialSortByState = {
        sortBy: SortOption.recommended,
        previouslyUserSelectedSortBy: null,
    };
    const [sortByState, sortByDispatch] = useReducer(sortByReducer, initialSortByState);
    const setSortBy = (sortOption) => sortByDispatch(['userInitialized', sortOption]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const dispatch = useDispatch();
    const categoryPillListRef = useRef(null);
    const subCategoryPillListRef = useRef(null);
    const activeCategoryRef = useRef(null);
    const activeSubCategoryRef = useRef(null);
    const showSubsSwitch = useSelector((state) => state.user.isLoggedIn &&
        !state.subscription.stopped &&
        !isPaused(state.subscription.stopDates)) && showSubscriptionSwitch;
    const isSubscriptionMode = useSelector((state) => state.subscription.isSubscriptionMode);
    const isMobile = useSelector((state) => state.ui.isMobile);
    const activeCategory = categories.find((c) => c.slug === categorySlug);
    const activeSubCategory = (_a = activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.children) === null || _a === void 0 ? void 0 : _a.find((c) => c.slug === subCategorySlug);
    useEffect(() => {
        setFilteredProducts(filterProducts(products, isSubscriptionMode, activeCategory, activeSubCategory));
    }, [products, activeCategory, activeSubCategory, isSubscriptionMode]);
    useEffect(() => {
        if (products) {
            sortProducts(products, sortByState.sortBy);
            // Update filtered products immediately after sorting
            setFilteredProducts(filterProducts(products, isSubscriptionMode, activeCategory, activeSubCategory));
        }
    }, [products, sortByState.sortBy, activeCategory, activeSubCategory, isSubscriptionMode]);
    // Scroll active category pill into view on first render
    useEffect(() => {
        if (categoryPillListRef.current && activeCategoryRef.current) {
            categoryPillListRef.current.scrollBy({
                top: 0,
                left: activeCategoryRef.current.offsetLeft - categoryPillListRef.current.scrollLeft,
                behavior: 'smooth',
            });
        }
        if (subCategoryPillListRef.current && activeSubCategoryRef.current) {
            subCategoryPillListRef.current.scrollBy({
                top: 0,
                left: activeSubCategoryRef.current.offsetLeft -
                    subCategoryPillListRef.current.scrollLeft,
                behavior: 'smooth',
            });
        }
    }, [categorySlug]);
    useEffect(() => {
        sortByDispatch(['automatic', SortOption.recommended]);
    }, [categorySlug, sortByState.previouslyUserSelectedSortBy]);
    /*
     * element.scrollBy is used in place of setting
     * scrollLeft = offsetValue as only the former works
     * with the smoothscroll polyfill which enables smooth
     * scrolling in Safari
     */
    function handleClick(e, ref) {
        if (ref.current == null) {
            return;
        }
        const eventTarget = e.target;
        const leftOffset = eventTarget.offsetLeft;
        ref.current.scrollBy({
            top: 0,
            left: leftOffset - ref.current.scrollLeft,
            behavior: 'smooth',
        });
    }
    function handleCategoryClick(e) {
        var _a;
        handleClick(e, categoryPillListRef);
        // Scroll subcategory list all the way to the left
        (_a = subCategoryPillListRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy({
            top: 0,
            left: -subCategoryPillListRef.current.scrollLeft,
            behavior: 'smooth',
        });
    }
    function handleSubCategoryClick(e) {
        handleClick(e, subCategoryPillListRef);
    }
    let categoryFilter = (React.createElement("div", { className: "filterable-category-product-grid__category-filters" },
        categoryFilterHeading && React.createElement("h3", null, categoryFilterHeading),
        React.createElement(PillList, { listType: PillListType.Horizontal, ref: categoryPillListRef, heading: pillListHeading }, [
            React.createElement(Pill, { key: 'allCategories', pillType: PillType.SelectableLink, navigationPath: "/products/", active: !categorySlug, onClick: handleCategoryClick, ref: !categorySlug ? activeCategoryRef : null }, "Alle"),
            ...categories.map((category) => (React.createElement(Pill, { key: category.id, pillType: PillType.SelectableLink, navigationPath: `/products/${category.slug}`, active: category.slug === categorySlug, onClick: handleCategoryClick, ref: category.slug === categorySlug ? activeCategoryRef : undefined }, category.name))),
        ]),
        (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.children) && activeCategory.children.length > 0 && (React.createElement(PillList, { listType: PillListType.Horizontal, ref: subCategoryPillListRef }, [
            React.createElement(Pill, { key: 'allSubCategories', pillType: PillType.SelectableLink, navigationPath: (_b = '/products/' + (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.slug)) !== null && _b !== void 0 ? _b : '', active: !activeSubCategory, ref: !activeSubCategory ? activeSubCategoryRef : null, onClick: handleSubCategoryClick, type: "secondary" }, "Alle"),
            ...activeCategory.children.map((subCategory) => {
                var _a;
                return (React.createElement(Pill, { key: subCategory.id, pillType: PillType.SelectableLink, navigationPath: `/products/${(_a = activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.slug) !== null && _a !== void 0 ? _a : ''}/${subCategory.slug}`, active: subCategory.id === (activeSubCategory === null || activeSubCategory === void 0 ? void 0 : activeSubCategory.id), type: "secondary", onClick: handleSubCategoryClick, ref: subCategory.id === (activeSubCategory === null || activeSubCategory === void 0 ? void 0 : activeSubCategory.id)
                        ? activeSubCategoryRef
                        : null }, subCategory.name));
            }),
        ]))));
    if (isMobile) {
        categoryFilter = React.createElement(HideScroll, { top: 80 }, categoryFilter);
    }
    return (React.createElement("div", { className: "filterable-category-product-grid" },
        categories.length > 1 && categoryFilter,
        React.createElement("div", { className: "filterable-category-product-grid__pre-content" },
            React.createElement("div", { className: "filterable-category-product-grid__content" },
                showSubsSwitch && (React.createElement("div", { className: "filterable-category-product-grid__subscription-switch" },
                    React.createElement("span", null, "Fast levering"),
                    React.createElement(Switch, { on: isSubscriptionMode, onChange: (val) => dispatch(setSubscriptionMode(val)) }))),
                showCount && (React.createElement("p", { className: "filterable-category-product-grid__count-text" }, filteredProducts.length < products.length
                    ? `Viser ${filteredProducts.length} av ${products.length} produkter`
                    : `Viser ${products.length} produkter`))),
            React.createElement("div", { className: "filterable-category-product-grid__content" }, allowSort && (React.createElement(SortDropdown, { defaultSortBy: sortByState.sortBy, onChangeSort: setSortBy, 
                // The key is used to force a re-render when the sortBy option changes
                key: sortByState.sortBy })))),
        React.createElement(ProductGrid, { products: filteredProducts })));
}
export default URLControlledFilterableCategoryProductGrid;

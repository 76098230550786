import React, { useMemo } from 'react';
import { sanityImageBuilder } from 'utilities/sanityClient';
import { motion } from 'framer-motion';
import './ArticleImageQuote.scss';
import { useSelector } from 'react-redux';
function ArticleImageQuote({ value }) {
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const { image, quote } = value;
    const imgSrc = useMemo(function getImageSrc() {
        if (isMobile) {
            return sanityImageBuilder
                .image(image)
                .auto('format')
                .height(window.innerWidth)
                .width(window.innerWidth)
                .fit('min')
                .dpr(window.devicePixelRatio)
                .url();
        }
        return sanityImageBuilder
            .image(image)
            .height(350)
            .width(350)
            .fit('min')
            .auto('format')
            .dpr(window.devicePixelRatio)
            .url();
    }, [image, isMobile]);
    return (React.createElement("div", { className: "article-image-quote" },
        React.createElement(motion.img, { className: "article-image-quote__image", src: imgSrc, initial: { opacity: 0, y: 50 }, whileInView: { opacity: 1, y: 0 }, transition: { duration: 1 }, viewport: { once: true, amount: 0.4 } }),
        React.createElement(motion.blockquote, { className: "article-image-quote__quote", initial: { opacity: 0, y: 50 }, whileInView: { opacity: 1, y: 0 }, transition: { duration: 1 }, viewport: { once: true, amount: 0.4 } }, quote)));
}
export { ArticleImageQuote as default };

import classNames from 'classnames';
import React from 'react';
import './NutritionTable.scss';
function NutritionTable({ nutrition }) {
    const rowClasses = (isItemTitle) => (classNames({
        'nutrition-accordion__nutrition-table__body__row__left-data': true,
        'nutrition-accordion__nutrition-table__body__row__left-data--title': isItemTitle,
    }));
    return (React.createElement("table", { className: "nutrition-accordion__nutrition-table" },
        React.createElement("tbody", { className: "nutrition-accordion__nutrition-table__body" }, nutrition.map((item, idx) => {
            const isItemTitle = item.value === '' && item.unit === '' && idx !== 0;
            return (React.createElement("tr", { key: idx, className: "nutrition-accordion__nutrition-table__body__row" },
                React.createElement("td", { className: rowClasses(isItemTitle) }, item.type),
                React.createElement("td", { className: "nutrition-accordion__nutrition-table__body__row__right-data" },
                    item.value,
                    item.unit)));
        }))));
}
export default NutritionTable;

import React from 'react';
import { useSanityDocument } from 'common';
import { useSelector } from 'react-redux';
import { DynamicPageBuilder } from '../../../pagebuilder/DynamicPageBuilder';
export default function RegionVisibility({ documentId }) {
    const regionId = useSelector(({ region }) => region.id);
    const document = useSanityDocument({
        documentId,
        queryTemplate: `
            title,
            'componentsForRegion': region_${regionId}[]-> {
              'type': _type,
              'elementId': _id
            }
        `,
    });
    if (!document || !document.componentsForRegion) {
        return null;
    }
    const elements = document.componentsForRegion.filter((element) => element.type !== 'regionVisibility' && // Prevent infinite loop
        element.type !== 'ctaButton' && // Requires extra data from query
        element.type !== 'abTest'); // Requires extra data from query
    return (React.createElement(DynamicPageBuilder, { elements: elements }));
}

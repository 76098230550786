import { createAction, createReducer } from '@reduxjs/toolkit';
const baseState = {
    producers: [],
};
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["FETCH_PRODUCERS_SUCCESS"] = "FETCH_PRODUCERS_SUCCESS";
    ActionTypes["FETCH_PRODUCERS_FAILED"] = "FETCH_PRODUCERS_FAILED";
})(ActionTypes || (ActionTypes = {}));
const FetchProducers = createAction(ActionTypes.FETCH_PRODUCERS_SUCCESS);
const FetchProducersFailed = createAction(ActionTypes.FETCH_PRODUCERS_FAILED);
const producersReducer = createReducer(baseState, (builder) => {
    builder
        .addCase(FetchProducers, (state, action) => {
        state.producers = action.payload;
    })
        .addCase(FetchProducersFailed, (state, _) => {
        state.producers = [];
    });
});
export default producersReducer;

import { setDeliveryDate } from 'actions/Checkout';
import { handleNetworkError } from 'actions/Error';
import { setRegionByRegionId } from 'actions/Regions';
import axios from 'axios';
import { checkCart } from 'cart';
import { getDefaultDeliveryDates } from 'common';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { showTimedNotification } from 'notification';
import { formatAddress } from 'utilities/address';
import { getDateAsText } from 'utilities/date';
import { canDeliver, getEarliestDeliveryDate } from 'utilities/distribution';
function addTransportProductFromRegionToAddress(addressInfo, regionId) {
    return (dispatch, getState) => {
        var _a;
        const transportProduct = (_a = getState().region.regions.find((region) => region.id === regionId)) === null || _a === void 0 ? void 0 : _a.transportProduct;
        dispatch({
            type: 'ADD_TRANSPORT_PRODUCT_TO_ADDRESS',
            payload: { addressInfo, transportProduct },
        });
    };
}
export function getDistributionForRegion(transportProduct) {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_DISTRIBUTION_FOR_REGION',
            payload: { transportProduct },
        });
        const fromDate = moment().format('YYYY-MM-DD');
        const toDate = moment().add(10, 'days').format('YYYY-MM-DD');
        axios
            .get(`${process.env.AH_URL}/${process.env.AH_COUNTRYCODE}/distributionDates/${process.env.AH_CUSTOMER_SYSTEM}-${transportProduct}/${fromDate}/${toDate}`, {
            params: {
                apiKey: process.env.AH_API_KEY,
            },
        })
            .then((response) => {
            const dates = formatAndSaturateDistributionDates(response.data.distributions);
            dispatch(updateDeliveryDate(dates));
            dispatch(toggleCanDeliver(dates));
            dispatch({
                type: 'FETCH_DISTRIBUTION_FOR_REGION_SUCCESS',
                payload: { dates },
            });
            dispatch(checkCart());
        })
            .catch((err) => {
            dispatch({
                type: 'FETCH_DISTRIBUTION_FOR_REGION_FAILED',
                payload: err,
            });
        });
    };
}
export function getDistributionForAddress(addressInfo, isNewAddress) {
    return (dispatch) => {
        dispatch({ type: 'FETCH_DISTRIBUTION_FOR_ADDRESS' });
        const address = addressInfo.deliveryAddress;
        axios
            .post(`${process.env.CORE_API}/distribution/check`, {
            address: {
                countryCode: address.countryCode,
                postalName: address.city,
                zipCode: address.zip,
                streetAddress: formatAddress(address),
                recipient: '-',
            },
        })
            .then((response) => {
            let dates = response.data.dates.map((d) => (Object.assign({ canDeliver: d.delivery }, d)));
            dates = orderBy(dates, 'date', 'asc');
            dispatch(updateDeliveryDate(dates));
            dispatch(toggleCanDeliver(dates, isNewAddress));
            dispatch({
                type: 'FETCH_DISTRIBUTION_FOR_ADDRESS_SUCCESS',
                payload: Object.assign(Object.assign({}, response.data), { dates }),
            });
            dispatch(setRegionByRegionId(response.data.regionId));
            if (!address.transportProduct && response.data.regionId) {
                dispatch(addTransportProductFromRegionToAddress(addressInfo, response.data.regionId));
            }
            dispatch(checkCart());
        })
            .catch((err) => {
            dispatch({
                type: 'FETCH_DISTRIBUTION_FOR_ADDRESS_FAILED',
                payload: err,
            });
            console.error('Address check failed', err);
            dispatch(handleNetworkError(err));
        });
    };
}
export function fetchDeadline(regionId) {
    return (dispatch) => {
        dispatch({ type: 'FETCH_DEADLINE' });
        axios.get(`${process.env.CORE_API}/region/${regionId}/deadlines`).then((response) => {
            dispatch({ type: 'FETCH_DEADLINE_SUCCESS', payload: response.data });
        });
    };
}
export function fetchSubscriptionDeadline(regionId) {
    return (dispatch) => {
        dispatch({ type: 'FETCH_SUBSCRIPTION_DEADLINE' });
        axios
            .get(`${process.env.CORE_API}/region/${regionId}/subscription-deadline`, {})
            .then((response) => {
            dispatch({
                type: 'FETCH_FETCH_SUBSCRIPTION_DEADLINE_DEADLINE_SUCCESS',
                payload: response.data.changeTomorrowDeadline.deadline,
            });
        });
    };
}
function toggleCanDeliver(dates, isNewAddress) {
    return (dispatch) => {
        const canDeliver = dates.filter((d) => d.canDeliver).length > 0;
        dispatch({ type: 'TOGGLE_CAN_DELIVER', payload: canDeliver });
        if (isNewAddress) {
            dispatch({ type: 'CAN_DELIVER_TO_NEW_ADDRESS', payload: { canDeliver } });
        }
    };
}
function updateDeliveryDate(dates) {
    return (dispatch, getState) => {
        const currentDeliveryDate = getState().checkout.deliveryDate;
        const deadline = getState().distribution.deadline;
        const currentDate = dates.find((d) => d.date === currentDeliveryDate);
        const canDeliverOnCurrentDate = currentDate && canDeliver(currentDate, deadline);
        if (!canDeliverOnCurrentDate) {
            const earliestDeliveryDate = getEarliestDeliveryDate(dates, deadline);
            if (!earliestDeliveryDate) {
            }
            else {
                dispatch(setDeliveryDate(earliestDeliveryDate, false));
                if (currentDeliveryDate) {
                    dispatch({ type: 'SHOW_DELIVERY_DATE_CHANGED_NOTIFICATION' });
                }
            }
        }
        else if (currentDate.capacityStatus === 'NEARLY_FULL') {
            dispatch(showTimedNotification({
                style: 'INFO',
                text: `Det nærmer seg fullbooket ${getDateAsText(currentDate.date, true)}, vær rask å bestille :)`
            }));
        }
    };
}
function formatAndSaturateDistributionDates(dates) {
    const distributionDates = getDefaultDeliveryDates();
    return distributionDates.map((d) => {
        return {
            date: d,
            canDeliver: dates.includes(d),
            message: 'from region',
        };
    });
}

import classNames from 'classnames';
import { DesktopNotification } from 'notification';
import React from 'react';
import './SubHeader.scss';
const SubHeader = () => {
    const classes = classNames({
        'sub-header': true,
    });
    return (React.createElement("div", { className: classes },
        React.createElement(DesktopNotification, { isInSubHeader: true })));
};
export default SubHeader;

import axios from 'axios';
import apiConfig from 'utilities/apiConfig';
export function fetchProducerBySlug(producerSlug, regionSlug) {
    const sessionStorageKey = `${regionSlug}/producer/${producerSlug}`;
    const producerFromSessionStorage = window.sessionStorage.getItem(sessionStorageKey);
    if (producerFromSessionStorage) {
        return new Promise((res, _) => res({ type: 'SUCCESS', data: JSON.parse(producerFromSessionStorage) }));
    }
    // Not found in session storage, fetch from Craft and persist
    return axios
        .get(`${process.env.API_URL}/${regionSlug}/api/producer/${producerSlug}`, apiConfig)
        .then((response) => {
        if (response.data) {
            window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(response.data));
            return { type: 'SUCCESS', data: response.data };
        }
        return { type: 'ERROR' };
    }, (error) => {
        var _a;
        return { type: 'ERROR', message: (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.statusText };
    });
}
export function fetchProducers(region) {
    return (dispatch) => {
        dispatch({ type: 'FETCH_PRODUCERS' });
        axios
            .get(`${process.env.API_URL}/${region}/api/producers`, apiConfig)
            .then((response) => {
            dispatch({
                type: 'FETCH_PRODUCERS_SUCCESS',
                payload: response.data.data,
            });
        })
            .catch((err) => {
            if (process.env.CONFIG !== 'production') {
                console.log(err);
            }
            dispatch({ type: 'FETCH_PRODUCERS_FAILED' });
        });
    };
}

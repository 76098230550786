import { CartItem } from 'cart';
import React from 'react';
import { useSelector } from 'react-redux';
import './CartContent.scss';
export function CartContent() {
    const products = useSelector((state) => state.cart.products);
    const deliveryDate = useSelector((state) => state.checkout.deliveryDate);
    const deadline = useSelector((state) => state.distribution.deadline);
    if (products.length < 1) {
        return React.createElement("div", { className: "cart-content cart-content--empty" }, "Handlekurven er tom");
    }
    return (React.createElement("div", { className: "cart-content" }, products.map((product) => {
        return (React.createElement(CartItem, { key: product.id, item: product, deadline: deadline, deliveryDate: deliveryDate }));
    })));
}

import { toggleEditAddress } from 'actions/Address';
import { Button, ButtonRow } from 'common';
import AddressList from 'components/Address/AddressList';
import Spinner from 'components/UI/Spinner';
import { GenericAddressForm } from 'delivery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ExpandableAddressForm.scss';
function ExpandableAddressForm({ isSubscriptionAddress, onSelected, onStore, onAbort, }) {
    const dispatch = useDispatch();
    const [isCheckingAddress, setIsCheckingAddress] = useState(false);
    const [addressCompleteHandler, setAddressCompleteHandler] = useState();
    const editAddress = useSelector((state) => state.address.edit);
    const distributionLoading = useSelector((state) => state.distribution.distributionLoading);
    const handleStore = (address) => {
        if (address) {
            setIsCheckingAddress(true);
            onStore && onStore(address);
            onSelected && onSelected(address);
        }
    };
    const handleAddressComplete = () => {
        if (!isCheckingAddress && addressCompleteHandler) {
            addressCompleteHandler.handleAddressComplete();
        }
    };
    useEffect(() => {
        if (isCheckingAddress && !distributionLoading) {
            setIsCheckingAddress(false);
            editAddress && dispatch(toggleEditAddress(false));
        }
    }, [distributionLoading]);
    if (isCheckingAddress) {
        return (React.createElement("div", { className: "expandable-address-form" },
            React.createElement(Spinner, { color: '0,0,0', size: "medium" })));
    }
    return (React.createElement("div", { className: "expandable-address-form" },
        !editAddress && (React.createElement("div", { className: "address-list-container" },
            React.createElement(AddressList, { onSelected: onSelected, isSubscriptionAddress: isSubscriptionAddress }))),
        editAddress && (React.createElement("div", null,
            React.createElement(GenericAddressForm, { onComplete: handleStore, hideButton: true, setAddressCompleteHandler: setAddressCompleteHandler }),
            React.createElement(ButtonRow, { display: "space-between" },
                React.createElement(Button, { type: "action", style: "secondary", onClick: onAbort }, "Tilbake"),
                React.createElement(Button, { type: "action", style: "primary", onClick: () => handleAddressComplete() }, "Legg til"))))));
}
export default ExpandableAddressForm;

import cardValidator from 'card-validator';
export function isNotEmpty(val) {
    return !!val;
}
export function isNumber(val) {
    return !isNaN(parseInt(val));
}
export function isValidCardNumber(val) {
    return cardValidator.number(val).isValid;
}
export function isValidCardExpiration(val) {
    return cardValidator.expirationDate(val).isValid;
}
export function isValidCvc(val) {
    return cardValidator.cvv(val).isValid;
}
export function isValidEmail(val) {
    // The pattern is copied from Shopping_OrderModel.php
    // TODO should handle more unicode characters than æøå
    return /^[\u00E6\u00F8\u00E5a-z0-9._%+-]+@[\u00E6\u00F8\u00E5a-z0-9.-]+\.[A-Z]{2,}$/iu.test(val);
}
export function isValidMobileOrEmpty(val) {
    if (!val || val == '')
        return true;
    else
        return isValidMobile(val);
}
export function isValidAndNotEmpty(val) {
    return !!val && isValidMobile(val) && isNotEmpty(val);
}
export function isValidMobile(val) {
    // The pattern is copied from Shopping_OrderModel.php
    if (process.env.AH_COUNTRYCODE === 'NO') {
        return /^((\+47|0047)?\s?([49]( *\d *){7}))$/.test(val);
    }
    else if (process.env.AH_COUNTRYCODE === 'SE') {
        return /^(\+46|0046|0)\s*(7[02369])(( *\d *){7})$/.test(val);
    }
    else {
        return true;
    }
}
export function isValidContactInfo(contactInfo) {
    const { firstName, lastName, email, mobile } = contactInfo;
    return (!!firstName &&
        firstName.length > 0 &&
        !!lastName &&
        lastName.length > 0 &&
        !!email &&
        isValidEmail(email) &&
        !!mobile &&
        isValidMobile(mobile));
}
export function isValidAddress(address) {
    return (!!address &&
        !!address.deliveryPointId &&
        !!address.firstName &&
        address.firstName.length > 0 &&
        address.firstName.length <= 40 &&
        !!address.lastName &&
        address.lastName.length > 0 &&
        address.lastName.length <= 40 &&
        !!address.mobile &&
        isValidMobile(address.mobile));
}

import { Card } from 'common';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { concatGiftMessageData } from '../../store/checkoutSlice';
import FieldLinkBox from '../FieldLinkBox/FieldLinkBox';
import './GiftMessageCard.scss';
function GiftMessageCard() {
    const giftMessage = useSelector((state) => {
        return concatGiftMessageData(state.newCheckout.giftMessage);
    });
    const giftMessageValid = !!giftMessage && giftMessage.trim().length > 0;
    const dispatch = useDispatch();
    const onClick = () => dispatch(push('/checkout/gift'));
    const giftMessageText = giftMessageValid ? giftMessage : 'Ingen gavemelding enda.';
    return (React.createElement(Card, { title: "Gavemelding" },
        React.createElement("div", { className: "gift-message-card__message" }, giftMessageText),
        React.createElement(FieldLinkBox, { text: giftMessageValid ? 'Endre' : 'Legg til gavemelding (påkrevd)', isValid: giftMessageValid, onClick: onClick })));
}
export default GiftMessageCard;

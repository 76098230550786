import classNames from 'classnames';
import { ProductTile } from 'product';
import React from 'react';
import './FeaturedProducts.scss';
function FeaturedProducts({ featured, className }) {
    const featuredProductsClasses = classNames({
        [`${className}`]: className,
        'featured-products-showcase': true,
        'featured-products-showcase--zero': featured.length === 0,
        'featured-products-showcase--one': featured.length === 1,
        'featured-products-showcase--two': featured.length === 2,
    });
    const styleVariables = {
        '--number-of-products': featured.length,
    };
    return (React.createElement("div", { className: featuredProductsClasses, style: styleVariables }, featured.map((product) => (React.createElement("div", { key: product.id, className: "featured-products-showcase__product-tile" },
        React.createElement(ProductTile, { product: product, shrinkOnMobile: true, isSubscriptionMode: false }))))));
}
export default FeaturedProducts;

import Loading from 'components/UI/LoadingScreen';
import { replace } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { corePurchaseComplete } from '../store/checkoutSlice';
function CheckoutCallback() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [orderStatus, setOrderStatus] = useState();
    const [sessionId, setSessionId] = useState();
    useEffect(() => {
        var _a;
        const orderFailed = /status=FAILED/.test(location.search);
        const orderIsActive = /status=ACTIVE/.test(location.search);
        const orderIsCreated = /status=CREATED/.test(location.search);
        const sessionIdMatch = new URLSearchParams(location.search).get('orderSession');
        setSessionId(sessionIdMatch);
        if (orderFailed) {
            const orderFailedRegex = /reason=(?<failureReason>\w+)&?/;
            const orderFailureReasonMatch = orderFailedRegex.exec(location.search);
            switch ((_a = orderFailureReasonMatch === null || orderFailureReasonMatch === void 0 ? void 0 : orderFailureReasonMatch.groups) === null || _a === void 0 ? void 0 : _a.failureReason) {
                case 'exportFailed':
                    setOrderStatus({ type: 'FAILURE', failureReasion: 'exportFailed' });
                    break;
                case 'refundFailed':
                    setOrderStatus({ type: 'FAILURE', failureReasion: 'refundFailed' });
                    break;
                default:
                    setOrderStatus({ type: 'FAILURE', failureReasion: 'chargeError' });
            }
        }
        else if (orderIsActive) {
            setOrderStatus({ type: 'ACTIVE' });
        }
        else if (orderIsCreated) {
            setOrderStatus({ type: 'CREATED' });
        }
    }, [location]);
    useEffect(() => {
        if (orderStatus && orderStatus.type == 'FAILURE') {
            dispatch({
                type: 'PLACE_ORDER_ERROR',
                payload: [
                    {
                        code: orderStatus.failureReasion,
                        message: '5003',
                    },
                ],
            });
            dispatch(replace('/checkout'));
        }
        if (orderStatus && (orderStatus.type == 'ACTIVE' || orderStatus.type == 'CREATED')) {
            dispatch({ type: '2_PHASE_COMPLETE', payload: sessionId });
            if (sessionId) {
                dispatch(corePurchaseComplete(sessionId));
            }
            dispatch(replace('/checkout/complete'));
        }
    }, [orderStatus]);
    return React.createElement(Loading, null);
}
export default CheckoutCallback;

const baseState = {
    code: '',
    codeIsValid: false,
    codeChecked: false,
    discount: {},
    errorCode: undefined,
}

export default function reducer(state = baseState, action) {
    switch (action.type) {
        case 'SET_COUPON_CODE':
            return {
                ...state,
                code: action.payload,
                codeChecked: false,
                errorCode: undefined,
            }
        case 'VALIDATE_COUPON_CODE':
            return { ...state, discount: {} }
        case 'VALIDATE_COUPON_CODE_FAILED':
            return {
                ...state,
                discount: {},
                codeIsValid: false,
                codeChecked: true,
                errorCode: action.payload.response.data.errorCode,
            }
        case 'CHECK_CART_SUCCESS':
            const coupon = action.payload.promotions.find((p) => p.type === 'COUPON')
            const codeIsValid = !!coupon && action.payload.couponErrors.length === 0
            return {
                ...state,
                discount: { ...action.payload, code: coupon?.id },
                codeIsValid: codeIsValid,
                codeChecked: codeIsValid,
                errorCode:
                    action.payload.couponErrors.length === 0
                        ? undefined
                        : action.payload.couponErrors[0],
            }
        case 'REMOVE_COUPON_CODE':
            return {
                ...state,
                discount: {},
                code: '',
                codeIsValid: false,
                codeChecked: false,
                errorCode: undefined,
            }
        case 'PLACE_ORDER_ERROR_COUPON_LIMIT_EXCEEDED':
            return {
                ...state,
                codeIsValid: false,
                codeChecked: false,
                discount: {},
            }
        case 'USER_LOGOUT':
            return { ...baseState }
        case 'PLACE_ORDER_SUCCESS':
        case '2_PHASE_COMPLETE':
            return {
                ...state,
                code: '',
                codeChecked: false,
                codeIsValid: false,
                discount: {},
                errorCode: undefined,
            }
        case 'SET_EMAIL':
            return {
                ...state,
                codeChecked: false,
            }
        default:
            return state
    }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './style.scss'

export default class Spinner extends Component {
    render() {
        const { transparent } = this.props

        let style
        if (this.props.color) {
            style = {
                borderColor: `rgba(${this.props.color}, 0.25)`,
                borderTopColor: `rgb(${this.props.color})`,
            }
        }
        const classes = classNames({
            'legacy-spinner': !transparent && true,
            'legacy-spinner--circle': this.props.type !== 'logo',
            'legacy-spinner--logo': this.props.type === 'logo',
            [`legacy-spinner--${this.props.size}`]: this.props.size,
            'legacy-spinner--transparent': transparent,
        })

        return <div className={classes} style={style} />
    }
}

Spinner.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.oneOf(['logo']),
    transparent: PropTypes.bool,
}

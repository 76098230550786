import { SelectedCard } from 'checkout';
import { Button, ButtonRow } from 'common';
import NewPaymentMethodForm from 'components/Payment/NewPaymentMethodForm';
import Form from 'components/UI/Form';
import React from 'react';
import './CheckoutCardSelector.scss';
function CheckoutCardSelector({ showSelectedCard, showForm, cardIssuer, maskedCardNumber, expiryDateString, showEditButton, handleNewCardSubmit, handleEditCardButtonClick, }) {
    return (React.createElement(React.Fragment, null,
        !showSelectedCard && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "checkout-card-selector__title" }, "Bankkort"),
            showForm && (React.createElement(Form, { onSubmit: handleNewCardSubmit },
                React.createElement("div", { className: "checkout-card-selector__form" },
                    React.createElement(NewPaymentMethodForm, null)),
                React.createElement(ButtonRow, { display: "stretch" },
                    React.createElement(Button, { type: "submit", style: "secondary", className: "checkout-card-selector__form__submit" }, "Bruk")))))),
        showSelectedCard && (React.createElement(SelectedCard, { maskedCardNumber: maskedCardNumber, cardIssuer: cardIssuer, expiryDate: expiryDateString, showEditButton: showEditButton, handleEdit: handleEditCardButtonClick }))));
}
export default CheckoutCardSelector;

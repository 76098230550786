import { checkCart, checkCartCore } from 'cart/actions/cartActions';
import { Price } from 'common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CartTotal.scss';
export function CartTotal() {
    const { discount, freight, total } = useSelector((state) => state.cart);
    const { recipients, orderType } = useSelector((state) => state.newCheckout);
    const pendingCheckCart = useSelector((state) => state.cart.pendingCheckCart);
    const dispatch = useDispatch();
    const isGroup = orderType === 'group';
    const numberOfRecipients = !isGroup || recipients.length < 2 ? 1 : recipients.length;
    useEffect(() => {
        if (pendingCheckCart) {
            dispatch(checkCartCore());
            dispatch(checkCart());
        }
    }, []);
    return (React.createElement("div", { className: "cart-total" },
        React.createElement("div", { className: "cart-total--shipping" },
            React.createElement("span", null, isGroup ? 'Frakt per person' : 'Frakt'),
            ' ',
            React.createElement(Price, { amount: freight.amount })),
        discount > 0 && (React.createElement("div", { className: "cart-total--discount" },
            React.createElement("span", null, "Rabatt"),
            " ",
            React.createElement(Price, { amount: discount }))),
        isGroup && (React.createElement("div", { className: "cart-total--sumPerPerson" },
            React.createElement("span", null, "Pris per person (send til flere)"),
            " ",
            React.createElement(Price, { amount: total }))),
        React.createElement("div", { className: "cart-total--sum" },
            React.createElement("span", null, "Totalsum"),
            " ",
            React.createElement(Price, { amount: total * numberOfRecipients })),
        isGroup && (React.createElement("div", { className: "cart-total--helpText" },
            React.createElement("span", null,
                "(",
                numberOfRecipients,
                " mottakere)")))));
}

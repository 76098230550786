import React from 'react';
import { useSelector } from 'react-redux';
import './BannerStrips.scss';
import { PortableText } from '@portabletext/react';
export function BannerStrips({ page }) {
    const bannerStrips = useSelector((state) => state.ui.bannerStrips);
    const { bannerStrip1, bannerStrip2 } = bannerStrips[page] || {};
    return (React.createElement(React.Fragment, null,
        bannerStrip1 && (bannerStrip1 === null || bannerStrip1 === void 0 ? void 0 : bannerStrip1.length) > 0 && (React.createElement("div", { className: "banner-strip" },
            React.createElement(PortableText, { value: bannerStrip1 }))),
        bannerStrip2 && bannerStrip2.length > 0 && (React.createElement("div", { className: "banner-strip" },
            React.createElement(PortableText, { value: bannerStrip2 })))));
}

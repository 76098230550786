import Navigation from 'components/UI/Navigation';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './MainNavigation.scss';
function MainNavigation() {
    return (React.createElement(Navigation, { type: "row" },
        React.createElement(MainNavLink, { to: "/products" }, "Alle produkter")));
}
function MainNavLink({ to, children }) {
    return (React.createElement(NavLink, { to: to, activeClassName: "font-semibold", exact: true }, children));
}
export default MainNavigation;

import { addEditProduct, addProduct, aggregateAmounts, changeAmountForDay, commitEditProduct, removeEditProduct, removeProduct, verifyProduct, } from 'utilities/subscription.js';
const baseState = {
    isSubscriptionMode: false,
    hasSubscription: false,
    selectedTransportProduct: undefined,
    subscriptionTransportProduct: undefined,
    transportProductStatus: undefined,
    availableDays: [1, 2, 3, 4, 5, 6],
    validFrom: '',
    stopped: null,
    netAmount: 0,
    vatAmount: 0,
    products: [],
    editProducts: [],
    stopDates: [],
    loading: false,
    isSaving: false,
    editSuccess: false,
};
function isNonMatchingRegionSelected(subscriptionState) {
    return (subscriptionState.hasSubscription &&
        subscriptionState.subscriptionTransportProduct &&
        subscriptionState.subscriptionTransportProduct !==
            subscriptionState.selectedTransportProduct);
}
function determineStatusFromSelectedTransportProduct(state, newSelectedTransportProduct) {
    const { hasSubscription, selectedTransportProduct, subscriptionTransportProduct, products } = state;
    const anyProds = products.length > 0;
    if (!hasSubscription &&
        selectedTransportProduct &&
        newSelectedTransportProduct !== selectedTransportProduct &&
        anyProds &&
        !subscriptionTransportProduct)
        return 'NEW_CURRENT_REGION';
    if (!hasSubscription &&
        selectedTransportProduct &&
        newSelectedTransportProduct !== selectedTransportProduct &&
        anyProds &&
        subscriptionTransportProduct &&
        newSelectedTransportProduct !== subscriptionTransportProduct)
        return 'NEW_CURRENT_REGION'; // TODO: Should this be different from above status?
    if (hasSubscription &&
        // (selectedTransportProduct && selectedTransportProduct !== selectedTransportProduct) &&
        subscriptionTransportProduct &&
        newSelectedTransportProduct !== subscriptionTransportProduct)
        return 'OTHER_REGION';
    return 'OK';
}
function determineStatusFromSubscriptionTransportProduct(state, newSubscriptionTransportProduct) {
    const { hasSubscription, selectedTransportProduct, products } = state;
    const anyProds = products.length > 0;
    if (!hasSubscription &&
        selectedTransportProduct &&
        newSubscriptionTransportProduct !== selectedTransportProduct &&
        anyProds)
        return 'NEW_SUBSCRIPTION_REGION';
    return 'OK';
}
function getProductsWithProductsInDraft(previousProducts, newProducts) {
    const newproductIds = newProducts.map((p) => p.id);
    const productsInDrafts = previousProducts.filter((p) => p.isDraft && !newproductIds.includes(p.id));
    return newProducts.concat(productsInDrafts);
}
export default function reducer(state = Object.assign({}, baseState), action) {
    switch (action.type) {
        case 'SET_SUBSCRIPTION_MODE':
            return Object.assign(Object.assign({}, state), { isSubscriptionMode: action.payload });
        case 'GET_AUTO_REGION_SUCCESS':
        case 'SET_REGION': {
            const { transportProduct } = action.payload;
            const transportProductStatus = determineStatusFromSelectedTransportProduct(state, transportProduct);
            switch (transportProductStatus) {
                case 'NEW_CURRENT_REGION':
                    return Object.assign(Object.assign({}, state), { selectedTransportProduct: transportProduct, transportProductStatus, validFrom: '', stopped: null, netAmount: 0, vatAmount: 0, products: [], editProducts: [] });
                case 'OTHER_REGION':
                case 'OK':
                default:
                    return Object.assign(Object.assign({}, state), { selectedTransportProduct: transportProduct, transportProductStatus });
            }
        }
        case 'STORE_SUBSCRIPTION_ADDRESS_SUCCESS':
        case 'SET_SUBSCRIPTION_ADDRESS': {
            const { transportProduct } = action.payload;
            const transportProductStatus = determineStatusFromSubscriptionTransportProduct(state, transportProduct);
            return Object.assign(Object.assign({}, state), { subscriptionTransportProduct: transportProduct, transportProductStatus });
        }
        case 'FETCH_SUBSCRIPTION':
            return Object.assign({}, state);
        case 'FETCH_SUBSCRIPTION_FAILED':
            return Object.assign({}, state);
        case 'FETCH_SUBSCRIPTION_NO_SUBSCRIPTION':
            return Object.assign(Object.assign({}, state), { hasSubscription: false });
        case 'FETCH_SUBSCRIPTION_SUCCESS':
            return Object.assign(Object.assign({}, state), { hasSubscription: true, validFrom: action.payload.validFrom, stopped: action.payload.stopped, netAmount: action.payload.netAmount, vatAmount: action.payload.vatAmount, products: getProductsWithProductsInDraft(state.products, action.payload.products) });
        case 'ADD_PRODUCT_TO_SUBSCRIPTION': {
            if (isNonMatchingRegionSelected(state)) {
                return Object.assign({}, state);
            }
            const { product, replace } = action.payload;
            if (!product.subscriptionProduct)
                return Object.assign({}, state);
            const products = addProduct(replace ? [] : state.products, product);
            const editProducts = addEditProduct(replace ? [] : state.editProducts, products, product.id);
            const { netAmount, vatAmount } = aggregateAmounts(products, editProducts);
            return Object.assign(Object.assign({}, state), { netAmount,
                vatAmount,
                products,
                editProducts });
        }
        case 'INCREASE_SUBSCRIPTION_WEEKDAY': {
            const editProducts = changeAmountForDay(state.editProducts, action.payload.product, action.payload.weekday, 1);
            const { netAmount, vatAmount } = aggregateAmounts(state.products, editProducts);
            return Object.assign(Object.assign({}, state), { netAmount,
                vatAmount,
                editProducts });
        }
        case 'DECREASE_SUBSCRIPTION_WEEKDAY': {
            const editProducts = changeAmountForDay(state.editProducts, action.payload.product, action.payload.weekday, -1);
            const { netAmount, vatAmount } = aggregateAmounts(state.products, editProducts);
            return Object.assign(Object.assign({}, state), { netAmount,
                vatAmount,
                editProducts });
        }
        case 'BEGIN_EDIT_SUBSCRIPTION': {
            return Object.assign(Object.assign({}, state), { editProducts: addEditProduct(state.editProducts, state.products, action.payload.productId) });
        }
        case 'COMMIT_EDITED_SUBSCRIPTION': {
            const products = commitEditProduct(state.editProducts, state.products, action.payload);
            const editProducts = removeEditProduct(state.editProducts, action.payload.id);
            const { netAmount, vatAmount } = aggregateAmounts(products, editProducts);
            return Object.assign(Object.assign({}, state), { products,
                editProducts,
                netAmount,
                vatAmount });
        }
        case 'COMMIT_EDITED_SUBSCRIPTION_SUCCESS': {
            return Object.assign(Object.assign({}, state), { editSuccess: true });
        }
        case '@@router/LOCATION_CHANGE': {
            return Object.assign(Object.assign({}, state), { editSuccess: false });
        }
        case 'ROLLBACK_EDITED_SUBSCRIPTION': {
            const editProducts = removeEditProduct(state.editProducts, action.payload.id);
            const products = verifyProduct(state.products, action.payload);
            const { netAmount, vatAmount } = aggregateAmounts(state.products, editProducts);
            return Object.assign(Object.assign({}, state), { editProducts,
                products,
                netAmount,
                vatAmount });
        }
        case 'UPDATE_SUBSCRIPTION_FAILED': {
            return Object.assign({}, state);
        }
        case 'REMOVE_PRODUCT_FROM_SUBSCRIPTION': {
            const products = removeProduct(action.payload.data.products, action.payload.product.id);
            const editProducts = removeEditProduct(state.editProducts, action.payload.product.id);
            const { netAmount, vatAmount } = aggregateAmounts(products, editProducts);
            return Object.assign(Object.assign({}, state), { products,
                editProducts, validFrom: action.payload.data.validFrom, stopped: action.payload.data.stopped, netAmount,
                vatAmount });
        }
        case 'REMOVE_PRODUCT_FROM_SUBSCRIPTION_FAILED': {
            return Object.assign({}, state);
        }
        case 'TOGGLE_SUBSCRIPTION_SUCCESS': {
            return Object.assign(Object.assign({}, state), { editSuccess: false, editProducts: [] });
        }
        case 'TOGGLE_SUBSCRIPTION_FAILED': {
            return Object.assign({}, state);
        }
        case 'PAUSE_SUBSCRIPTION_SUCCESS': {
            return Object.assign(Object.assign({}, state), { isSubscriptionMode: false, editProducts: [] });
        }
        case 'UNPAUSE_SUBSCRIPTION': {
            return Object.assign(Object.assign({}, state), { isSaving: true });
        }
        case 'UNPAUSE_SUBSCRIPTION_SUCCESS': {
            return Object.assign(Object.assign({}, state), { isSaving: false, stopDates: [] });
        }
        case 'GET_STOP_DATES': {
            return Object.assign(Object.assign({}, state), { loading: true });
        }
        case 'GET_STOP_DATES_SUCCESS': {
            return Object.assign(Object.assign({}, state), { stopDates: action.payload, loading: false });
        }
        case 'GET_STOP_DATES_FAILED': {
            return Object.assign(Object.assign({}, state), { loading: false });
        }
        case 'USER_LOGOUT':
            return Object.assign({}, baseState);
        default:
            return state;
    }
}

import './style.scss'

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

class InputRow extends Component {
    render() {
        const { className } = this.props
        const classes = classNames('input-row', className)
        return <div className={classes}>{this.props.children}</div>
    }
}

InputRow.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

export default InputRow

import classNames from 'classnames';
import { SafeHtml } from 'common';
import { motion } from 'framer-motion';
import React from 'react';
import './ReadMoreButton.scss';
const readMoreArrow = require('img/expandArrow.svg');
function ReadMoreButton({ className, handleClick }) {
    const classes = classNames({
        'read-more-button': true,
        [`${className}`]: className,
    });
    return (React.createElement("button", { className: classes, onClick: handleClick },
        React.createElement(SafeHtml, { type: motion.div, animate: {
                y: [0, 8, 0, 8, 0],
            }, transition: {
                duration: 1.1,
                repeat: Infinity,
                repeatDelay: 1,
            }, html: readMoreArrow })));
}
export default ReadMoreButton;

import { PillLink } from 'common';
import React from 'react';
import './ItemCarouselHeading.scss';
const ItemCarouselHeading = (props) => {
    return (React.createElement("div", { className: "item-carousel-heading__wrapper" },
        React.createElement("div", null,
            props.title && React.createElement("h2", null, props.title),
            props.subtitle && (React.createElement("p", { className: "item-carousel-heading__wrapper__subtitle" }, props.subtitle))),
        React.createElement("div", null, props.target && props.targetText && (React.createElement(PillLink, { target: props.target }, props.targetText)))));
};
export default ItemCarouselHeading;

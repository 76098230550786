const baseState = {
    isFeatureEnabled: undefined,
    hasAskedForAuthorization: false,
    authorization: 'notDetermined',
    token: null,
    options: [
        {
            key: 'Push, Campaigns',
            title: 'notificationCampaigns',
            isOn: true,
        },
        {
            key: 'Push, Reminders',
            title: 'notificationReminders',
            isOn: true,
        },
    ],
}

// TODO: on rehydrate, merge basestate

function updateOptions(options, changedOption, value) {
    let newOptions = options ? [...options] : [...baseState.options]
    const index = newOptions.findIndex((o) => o.key === changedOption.key)
    if (index >= 0) {
        newOptions[index] = {
            ...newOptions[index],
            isOn: value,
        }
    }

    return newOptions
}

export default function reducer(state = baseState, action) {
    switch (action.type) {
        case '@@redux/INIT':
            if (!state.options || state.options.length) {
                return {
                    ...state,
                    options: baseState.options,
                }
            }
            return state
        case 'PUSH_IS_FEATURE_ENABLED_CHANGED':
            return {
                ...state,
                isFeatureEnabled: action.payload,
            }
        case 'PUSH_HAS_ASKED_FOR_AUTHORIZATION':
            return {
                ...state,
                hasAskedForAuthorization: true,
            }
        case 'PUSH_AUTHORIZATION_CHANGED':
            return {
                ...state,
                authorization: action.payload,
            }
        case 'PUSH_TOKEN_CHANGED':
            return {
                ...state,
                token: action.payload,
            }
        case 'PUSH_NOTIFICATION_CHANGE_OPTION': {
            const { option, value } = action.payload
            return {
                ...state,
                options: updateOptions(state.options, option, value),
            }
        }
        default:
            return state
    }
}

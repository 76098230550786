import React from 'react';
import { useId } from '@reach/auto-id';
import { AnimatePresence, motion } from 'framer-motion';
import SafeHtml from '../SafeHtml/SafeHtml';
import { postfixClassnames } from './util';
const chevron = require('img/down-button-icon.svg');
function AccordionChild({ heading, children, expanded, onClick, className }) {
    const id = useId();
    const animationDuration = 0.3;
    const animationVariants = {
        hidden: {
            height: 0,
            opacity: 0,
            marginTop: 0,
            marginBottom: 0,
        },
        expanded: {
            height: 'auto',
            opacity: 1,
            marginTop: 18 - 36,
            marginBottom: 42,
        },
    };
    return (React.createElement("li", { className: className, "data-expanded": expanded },
        React.createElement("button", { className: postfixClassnames(className, 'button'), "aria-expanded": expanded, "aria-controls": id, onClick: onClick },
            React.createElement("h3", { className: postfixClassnames(className, 'button__heading') }, heading),
            React.createElement(SafeHtml, { className: postfixClassnames(className, 'button__chevron'), type: motion.div, initial: false, animate: { rotate: expanded ? 180 : 0 }, transition: { duration: animationDuration }, html: chevron })),
        React.createElement(AnimatePresence, null, expanded && (React.createElement(motion.div, { className: postfixClassnames(className, 'content'), variants: animationVariants, initial: "hidden", animate: "expanded", exit: "hidden", transition: { duration: animationDuration }, id: id }, children)))));
}
export default AccordionChild;

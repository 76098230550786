import {
    fetchDeadline,
    fetchSubscriptionDeadline,
    getDistributionForAddress,
} from 'actions/Distribution'
import {
    findCurrentPrimaryAddress,
    findNextPrimaryAddress,
    formatAddress,
    addressIsEqual,
} from 'utilities/address'
import { removeRecipientIfPresent } from 'checkout'

import axios from 'axios'
import find from 'lodash/find'
import { handleNetworkError } from 'actions/Error'
import { setPersonalInfo } from 'actions/PersonalInfo'
import { showTimedNotification } from 'notification'

export function getAddresses(activeAddress, shouldActivate = true) {
    return (dispatch, getState) => {
        dispatch({ type: 'GET_ADDRESSES' })
        axios
            // TODO: if groupSimilar=true backend will only set isPrimary on the current primary
            // address, so we have to fetch all addresses to get the next primary address.
            .all([
                axios.get(`${process.env.CORE_API}/address?groupSimilar=true`),
                axios.get(`${process.env.CORE_API}/address`),
            ])
            .then(([responseGrouped, responseAll]) => {
                const groupedAddresses = responseGrouped.data || []
                const allAddresses = responseAll.data || []

                dispatch({
                    type: 'GET_ADDRESSES_SUCCESS',
                    payload: { grouped: groupedAddresses, ungrouped: allAddresses },
                })

                if (allAddresses.length) {
                    const nextPrimaryAddress = findNextPrimaryAddress(allAddresses)
                    const currentPrimaryAddress =
                        findCurrentPrimaryAddress(groupedAddresses) || nextPrimaryAddress

                    activeAddress =
                        activeAddress ||
                        (getState().address.active !== null && getState().address.active) ||
                        nextPrimaryAddress ||
                        groupedAddresses[0] ||
                        allAddresses[0]

                    shouldActivate && dispatch(setActiveAddress(activeAddress))

                    if (nextPrimaryAddress) {
                        dispatch({
                            type: 'SET_NEXT_SUBSCRIPTION_ADDRESS',
                            payload: nextPrimaryAddress,
                        })
                    }

                    if (currentPrimaryAddress) {
                        dispatch({
                            type: 'SET_SUBSCRIPTION_ADDRESS',
                            payload: currentPrimaryAddress,
                        })
                        const { firstName, lastName, mobile } = currentPrimaryAddress
                        dispatch(setPersonalInfo(firstName, lastName, mobile))
                    }

                    dispatch(
                        fetchDeadline(
                            getState().region.id,
                            activeAddress.deliveryPointId,
                            activeAddress.zip
                        )
                    )

                    dispatch(fetchSubscriptionDeadline(getState().region.id))
                }
            })
            .catch((err) => {
                console.error(err)
                dispatch({ type: 'GET_ADDRESSES_FAILED', payload: err })
                dispatch(handleNetworkError(err))
            })
    }
}

export function getAllAddresses() {
    return (dispatch) => {
        dispatch({ type: 'GET_ALL_ADDRESSES' })
        axios
            .get(`${process.env.CORE_API}/address`)
            .then((response) =>
                dispatch({
                    type: 'GET_ALL_ADDRESSES_SUCCESS',
                    payload: response.data || [],
                })
            )
            .catch((err) => {
                console.error(err)
                dispatch({ type: 'GET_ALL_ADDRESSES_FAILED', payload: err })
                dispatch(handleNetworkError(err))
            })
    }
}

export function setActiveAddress(address, force = false) {
    return (dispatch, getState) => {
        if (address === getState().address.active && !force) return

        dispatch({
            type: 'SET_ACTIVE_ADDRESS',
            payload: address,
        })
        if (address != null) {
            dispatch(getDistributionForAddress(address, false))
        }
    }
}

export function saveAddress(address, primary, shouldActivate = true) {
    return (dispatch, getState) => {
        dispatch({
            type: 'SAVE_ADDRESS',
            payload: { ...address },
        })

        axios
            .post(`${process.env.CORE_API}/distribution/check`, {
                address: {
                    countryCode: address.countryCode,
                    postalName: address.city,
                    zipCode: address.zip,
                    streetAddress: formatAddress(address),
                    recipient: '-',
                },
            })
            .then((response) => {
                const canDeliver = find(response.data.dates, 'delivery')

                dispatch({
                    type: 'CAN_DELIVER_TO_NEW_ADDRESS',
                    payload: { canDeliver },
                })

                if (canDeliver) {
                    const transportProduct = getState().region.regions.filter(
                        (r) => r.id === response.data.regionId
                    )[0].transportProduct
                    return Promise.resolve(transportProduct)
                } else {
                    return Promise.reject('error_saveAddress_cannotDeliver')
                }
            })
            .then((transportProduct) =>
                axios.post(`${process.env.CORE_API}/address/createAddress`, {
                    ...address,
                    transportProduct,
                })
            )
            .then((response) => {
                const addressId = response.data.addressId

                if (!primary) return Promise.resolve(response)

                return axios
                    .put(`${process.env.CORE_API}/address/subscription?addressId=${addressId}`)
                    .then(() => Promise.resolve(response))
                    .catch(() => Promise.reject('error_saveAddress_primary'))
            })
            .then((response) => {
                const address = {
                    ...response.data,
                    isPrimary: primary,
                }
                dispatch({ type: 'ADD_SHIPPING_INFO', payload: getState().cart })
                dispatch({
                    type: 'SAVE_ADDRESS_SUCCESS',
                    payload: address,
                })
                dispatch(getAddresses(address, shouldActivate))
            })
            .catch((e) =>
                dispatch({
                    type: 'SAVE_ADDRESS_FAILED',
                    payload: typeof e === 'string' ? e : 'error_saveAddress_internal',
                })
            )
    }
}

export function editAddress(address, addressId) {
    const errorMessage = (errorCode) => {
        switch (errorCode) {
            case 'ADDRESS_NOT_EXISTS':
                return 'Adressen du prøver å endre finnes ikke i vårt system. Fjern heller adressen og opprett en ny.'
            case 'ADDRESS_NO_CARRIER_SUPPORT':
                return 'Vi kan dessverre ikke levere til denne adressen 😞'
            case 'INTERNAL_SERVER_ERROR':
            case 'UPSTREAM_FAILURE':
                return 'Ai, noe skurrer i maskineriet. Prøv en gang til.'
        }
    }

    return (dispatch) => {
        dispatch({ type: 'EDIT_ADDRESS' })
        axios
            .put(`${process.env.CORE_API}/address/${addressId}`, address)
            .then((response) => {
                dispatch({ type: 'EDIT_ADDRESS_SUCCESS' })
                dispatch(getAddresses(response.data))
            })
            .catch((e) => {
                dispatch({ type: 'EDIT_ADDRESS_FAILED' })
                dispatch(
                    showTimedNotification({
                        style: 'ERROR',
                        text: errorMessage(e.response.data.errorCode),
                    })
                )
            })
    }
}

export function editAddressAndSetAsPrimary(address, addressId) {
    return (dispatch) => {
        dispatch({ type: 'SET_NEW_MAIN_ADDRESS' })
        axios
            .put(`${process.env.CORE_API}/address/subscription?addressId=${addressId}`)
            .then(() => {
                dispatch(editAddress(address, addressId))
                dispatch({ type: 'SET_NEW_MAIN_ADDRESS_SUCCESS' })
            })
            .catch(() => dispatch({ type: 'SET_NEW_MAIN_ADDRESS_FAILED' }))
    }
}

export function setNewMainAddress(address) {
    return (dispatch) => {
        dispatch({ type: 'SET_NEW_MAIN_ADDRESS' })
        axios
            .put(`${process.env.CORE_API}/address/subscription?addressId=${address.addressId}`)
            .then(() => {
                dispatch({ type: 'SET_NEW_MAIN_ADDRESS_SUCCESS' })
                dispatch(
                    getAddresses({
                        ...address,
                        isPrimary: true,
                    })
                )
            })
            .catch(() => dispatch({ type: 'SET_NEW_MAIN_ADDRESS_FAILED' }))
    }
}

function toggleActivateAddress(addressId, method) {
    return (dispatch) => {
        axios
            .put(`${process.env.CORE_API}/address/${method}?addressId=${addressId}`)
            .then(() => dispatch(getAddresses()))
            .catch((e) => console.error(e))
    }
}

export function activateAddress(addressId) {
    return toggleActivateAddress(addressId, 'activate')
}

export function deactivateAddress(address, addressId) {
    return (dispatch) => {
        dispatch(deleteActiveAddressIfEqual(address))
        dispatch(toggleActivateAddress(addressId, 'deactivate'))
    }
}

export function deactivatePrimaryAddress() {
    return (dispatch, getState) => {
        const primaryAddress =
            getState().address.nextSubscription || getState().address.subscription
        if (primaryAddress) {
            dispatch(deactivateAddress(primaryAddress.deliveryAddress, primaryAddress.addressId))
            dispatch(removeRecipientIfPresent(primaryAddress.deliveryAddress))
        }
    }
}

export function storeAddress(address) {
    return (dispatch, getState) => {
        dispatch({ type: 'STORE_ADDRESS', payload: address })
        dispatch({ type: 'ADD_SHIPPING_INFO', payload: getState().cart })
        dispatch(getDistributionForAddress(address, false))
    }
}

export function deleteStoredAddress(address) {
    return (dispatch) => {
        dispatch(deleteActiveAddressIfEqual(address))
        dispatch({ type: 'DELETE_STORED_ADDRESS', payload: address })
    }
}

export function storeSubscriptionAddress(address) {
    return (dispatch, getState) => {
        dispatch({ type: 'STORE_SUBSCRIPTION_ADDRESS' })
        axios
            .post(`${process.env.CORE_API}/distribution/check`, {
                address: {
                    countryCode: address.deliveryAddress.countryCode,
                    postalName: address.deliveryAddress.city,
                    zipCode: address.deliveryAddress.zip,
                    streetAddress: formatAddress(address.deliveryAddress),
                    recipient: '-',
                },
            })
            .then((response) => {
                const canDeliver = find(response.data.dates, 'delivery')

                if (canDeliver) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject('error_storeSubscriptionAddress_cannotDeliver')
                }
            })
            .then((response) => {
                const transportProduct = getState().region.regions.filter(
                    (r) => r.id === response.data.regionId
                )[0].transportProduct
                dispatch({
                    type: 'STORE_SUBSCRIPTION_ADDRESS_SUCCESS',
                    payload: {
                        ...address,
                        transportProduct,
                    },
                })
                dispatch(getDistributionForAddress(address, false))
            })
            .catch((err) => {
                dispatch({
                    type: 'STORE_SUBSCRIPTION_ADDRESS_FAILED',
                    payload: err,
                })
            })
    }
}

export function updateSubscriptionAddress(deliveryAddress, validFrom, onSuccess, onFailure) {
    const deliveryAddressUrl = `${process.env.CORE_API}/address/subscription`

    return (dispatch) => {
        const payload = {
            deliveryAddress,
            validFrom,
        }
        dispatch({
            type: 'UPDATE_SUBSCRIPTION_ADDRESS',
            payload,
        })
        axios
            .post(deliveryAddressUrl, payload, {
                validateStatus: function (status) {
                    return (status >= 200 && status < 300) || status === 304 // 304: Unchanged
                },
            })
            .then((response) => {
                dispatch({
                    type: 'UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS',
                    payload: response,
                })

                // Refresh subscription from server before dispatching SUCCESS to update state before any navigation, e.g. back to subscription page
                dispatch(getAddresses())
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch({
                    type: 'UPDATE_SUBSCRIPTION_ADDRESS_FAILED',
                    payload: err,
                })
                onFailure && onFailure(err)
            })
    }
}

export function toggleEditAddress(force) {
    return function (dispatch) {
        dispatch({ type: 'TOGGLE_EDIT_ADDRESS', payload: force })
    }
}

function deleteActiveAddressIfEqual(address) {
    return (dispatch, getState) => {
        const activeAddressInfo = getState().address.active
        if (addressIsEqual(address.deliveryAddress, activeAddressInfo.deliveryAddress)) {
            dispatch({ type: 'DELETE_ACTIVE_ADDRESS', payload: activeAddressInfo.isPrimary })
        }
    }
}

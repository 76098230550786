import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './CartBadge.scss';
function CartBadge({ count, hasUnavailableProducts }) {
    const isMobile = useSelector((state) => state.ui.isMobile);
    const cartBadgeClasses = classNames({
        'cart-badge': true,
        'cart-badge--unavailable-products': hasUnavailableProducts,
    });
    if (isMobile) {
        return (React.createElement("span", { className: cartBadgeClasses },
            React.createElement("span", { className: "cart-badge-count" }, count)));
    }
    else {
        return (React.createElement(TransitionGroup, { component: null },
            React.createElement(CSSTransition, { key: count, classNames: "update-cart", timeout: 500 },
                React.createElement("span", { className: cartBadgeClasses },
                    React.createElement("span", { className: "cart-badge-badge" }),
                    React.createElement("span", { className: "cart-badge-count" }, count)))));
    }
}
export default CartBadge;

import { getFullAddressFromAddressHelper } from 'address';
import { isEmpty } from 'lodash';
import { PaymentMethodType } from 'types/paymentMethodType';
import { isValidCardNumber } from 'utilities/validators';
export const getSelectedPaymentOption = (checkoutState, newPaymentMethodState, paymentMethodState, cartState) => {
    const paymentMethodType = checkoutState.paymentMethodType;
    const newCardActive = newPaymentMethodState.active;
    const adyenToken = newPaymentMethodState.token;
    const newCardNo = newPaymentMethodState.cardNo;
    const preferredPaymentMethod = paymentMethodState.preferred;
    if (cartState.total === 0) {
        return { type: 'NO_PAYMENT' };
    }
    else if (paymentMethodType === PaymentMethodType.VIPPS) {
        return { type: 'VIPPS', paymentMethodType };
    }
    else if (paymentMethodType == PaymentMethodType.CREDIT_CARD &&
        newCardActive &&
        adyenToken &&
        isValidCardNumber(newCardNo)) {
        return {
            type: 'NEW_CREDIT_CARD',
            paymentMethodType: PaymentMethodType.CREDIT_CARD,
        };
    }
    else if (paymentMethodType === PaymentMethodType.CREDIT_CARD &&
        !!preferredPaymentMethod.paymentMethodId) {
        return {
            type: 'CREDIT_CARD',
            paymentMethodType: PaymentMethodType.CREDIT_CARD,
            paymentMethodId: preferredPaymentMethod.paymentMethodId,
        };
    }
    else {
        return {
            type: 'NONE',
        };
    }
};
export const getOrderContactInfo = (addressState, personalInfoState, newCheckoutState, addressHelperState) => {
    const singleOrderAddress = getSingleOrderAddress(addressState, addressHelperState);
    const orderType = newCheckoutState.orderType;
    if (!isEmpty(singleOrderAddress) && orderType === 'single') {
        const deliveryAddress = singleOrderAddress.deliveryAddress;
        return {
            firstName: deliveryAddress.firstName,
            lastName: deliveryAddress.lastName,
            email: personalInfoState.email,
            mobile: deliveryAddress.mobile,
        };
    }
    return {
        firstName: personalInfoState.firstName,
        lastName: personalInfoState.lastName,
        email: personalInfoState.email,
        mobile: personalInfoState.mobile,
    };
};
export const getSingleOrderAddress = (addressState, addressHelperState) => {
    const activeAddress = addressState.active;
    const fullAddressFromAddressHelper = getFullAddressFromAddressHelper(addressHelperState, false);
    return activeAddress && !isEmpty(activeAddress) ? activeAddress : fullAddressFromAddressHelper;
};

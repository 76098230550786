import './style.scss'

import { CSSTransition, TransitionGroup } from 'react-transition-group'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { calculateBadgeCount } from 'utilities/subscription'

class SubscriptionBadge extends Component {
    render() {
        const { count } = this.props
        const classes = classNames({
            'subscription-badge': true,
            'subscription-badge-empty': !count,
        })

        return (
            <TransitionGroup component={null}>
                <CSSTransition key={count} classNames="update-subscription" timeout={500}>
                    <span className={classes}>
                        <span className="subscription-badge-badge" />
                        <span className="subscription-badge-count">{count}</span>
                    </span>
                </CSSTransition>
            </TransitionGroup>
        )
    }
}

SubscriptionBadge.propTypes = {
    count: PropTypes.number,
}

function mapStateToProps({ subscription }) {
    return {
        count: calculateBadgeCount(subscription),
    }
}

export default connect(mapStateToProps)(SubscriptionBadge)

import Icon from 'components/UI/Icon';
import NavItem from 'components/UI/NavItem';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { webLogin } from 'user';
import CategoryList from './CategoryList';
import './NavigationModal.scss';
import SubscriptionBadge from 'components/Subscription/SubscriptionBadge';
import { gotoSubscriptionInfoOrOverview } from 'actions/Subscription';
import { Pill, PillList, PillListType, PillType } from 'common';
const UserIcon = require('img/navigationIcons/user.svg');
const Products = require('img/navigationIcons/products.svg');
const CloseButton = require('img/navigationIcons/closebutton.svg');
const Subscription = require('img/navigationIcons/subscription.svg');
function NavigationModal({ isShown, hide }) {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const [showCategories, setShowCategories] = useState(false);
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.products.categories);
    const slugMap = useSelector(({ products }) => products.categories.reduce((map, category) => {
        if (category.slug != null) {
            map.set(category.id, category.slug);
        }
        return map;
    }, new Map()));
    function hideModalAndSetShowCategoriesToFalse() {
        setShowCategories(false);
        hide();
    }
    const loggedInNav = (React.createElement(NavItem, { to: "/my-page" },
        React.createElement("div", { className: 'navigation-modal__navigation-item' },
            React.createElement(Icon, { svg: UserIcon, size: "large" }),
            React.createElement("p", { className: 'navigation-modal__navigation-item--text' }, "Min side"))));
    const notLoggedInNav = (React.createElement(NavItem, null,
        React.createElement("div", { className: 'navigation-modal__navigation-item', onClick: () => webLogin() },
            React.createElement(Icon, { svg: UserIcon, size: "large" }),
            React.createElement("p", { className: 'navigation-modal__navigation-item--text' }, "Logg inn"))));
    const navigationMenu = (React.createElement(React.Fragment, null,
        React.createElement("ul", { className: "navigation-modal__navigation-items" },
            React.createElement(NavItem, { to: "/products" },
                React.createElement("div", { className: 'navigation-modal__navigation-item' },
                    React.createElement(Icon, { svg: Products, size: "large" }),
                    React.createElement("span", { className: 'navigation-modal__navigation-item--text' }, "Alle produkter"))),
            React.createElement(NavItem, { to: "#", callback: () => dispatch(gotoSubscriptionInfoOrOverview()) },
                React.createElement("div", { className: 'navigation-modal__navigation-item' },
                    React.createElement(Icon, { svg: Subscription, size: "large" }),
                    React.createElement("span", { className: 'navigation-modal__navigation-item--text' }, "Fast levering"),
                    React.createElement(SubscriptionBadge, null))),
            isLoggedIn ? loggedInNav : notLoggedInNav,
            React.createElement("h2", null, "Utforsk kategorier"),
            React.createElement(PillList, { listType: PillListType.HorizontalWrap }, categories.map((category, idx) => {
                const categoryName = category.name;
                const slug = slugMap.get(category.id);
                return (React.createElement(Pill, { pillType: PillType.Selectable, key: idx, navigationPath: '/products/' + slug }, categoryName));
            })))));
    const categoryList = (React.createElement(CategoryList, { goBack: () => setShowCategories(false), hide: hideModalAndSetShowCategoriesToFalse }));
    const modal = (React.createElement("div", { className: 'navigation-modal__wrapper' },
        React.createElement("div", { className: 'navigation-modal__content' },
            React.createElement("div", { className: 'navigation-modal__close-icon' },
                React.createElement(Icon, { svg: CloseButton, size: 'large', onClick: () => {
                        setShowCategories(false);
                        hide();
                    } })),
            showCategories ? categoryList : navigationMenu)));
    return isShown ? ReactDOM.createPortal(modal, document.body) : null;
}
export default NavigationModal;

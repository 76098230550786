import './style.scss'

import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import Intercom, { IntercomAPI } from 'utilities/reactIntercom'

class CustomerServiceWeb extends PureComponent {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!window.Intercom) {
            return prevState
        }

        const {
            userId,
            userEmail,
            intercomUserProps,
            hasItemsInCart,
            isLoggedIn,
            isCartPage,
            userHash,
            hasNotification,
        } = nextProps

        if (
            userId &&
            userHash &&
            (userId !== prevState.userId ||
                !isEqual(intercomUserProps, prevState.intercomUserProps))
        ) {
            IntercomAPI('update', {
                user_id: userId,
                user_hash: userHash,
                email: userEmail,
                ...intercomUserProps,
            })
        }

        if (prevState.isLoggedIn && !isLoggedIn) {
            IntercomAPI('shutdown')
        }

        if (hasItemsInCart !== prevState.hasItemsInCart || isCartPage !== prevState.isCartPage) {
            toggleMobileCartView(hasItemsInCart, isCartPage)
        }

        if (hasNotification !== prevState.hasNotification) {
            toggleNotificationSpacing(hasNotification)
        }

        return { userId, intercomUserProps, hasItemsInCart, isLoggedIn }
    }

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { intercomAppId } = this.props
        if (!intercomAppId || intercomAppId == '') return null
        return (
            <div>
                <Intercom appID={intercomAppId} />
            </div>
        )
    }
}

function toggleMobileCartView(toggle, isCartPage) {
    waitForElement('#intercom-container iframe.intercom-launcher-frame', (el) => {
        if (toggle) {
            el.classList.add('intercom__cart-visible')
        } else {
            el.classList.remove('intercom__cart-visible')
        }
        if (isCartPage) {
            el.classList.add('intercom__cart-visible--cart-page')
        } else {
            el.classList.remove('intercom__cart-visible--cart-page')
        }
    })
}

function toggleNotificationSpacing(hasNotification) {
    waitForElement('.intercom-lightweight-app .intercom-lightweight-app-launcher', (el) => {
        if (hasNotification) {
            el.classList.add('intercom--has-notification')
        } else {
            el.classList.remove('intercom--has-notification')
        }
    })
    waitForElement('#intercom-container iframe.intercom-launcher-frame', (el) => {
        if (hasNotification) {
            el.classList.add('intercom--has-notification')
        } else {
            el.classList.remove('intercom--has-notification')
        }
    })
}

function waitForElement(selector, callback) {
    if (document.querySelector(selector)) {
        callback(document.querySelector(selector))
        return
    }

    const interval = setInterval(() => {
        if (document.querySelector(selector)) {
            clearInterval(interval)
            callback(document.querySelector(selector))
            return
        }
    }, 250)
}

CustomerServiceWeb.propTypes = {
    intercomAppId: PropTypes.string,
    userId: PropTypes.string,
    userHash: PropTypes.string,
    userEmail: PropTypes.string,
    hasItemsInCart: PropTypes.bool,
    isCartPage: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    hasNotification: PropTypes.bool,
}

function mapStateToProps({ user, notification }) {
    const isCartPage = RegExp(/^\/cart/).test(location.pathname)
    return {
        isCartPage: isCartPage,
        isLoggedIn: user.isLoggedIn,
        hasNotification: notification.notifications.length > 0,
    }
}

export default connect(mapStateToProps)(CustomerServiceWeb)

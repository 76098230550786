export var SortOption;
(function (SortOption) {
    SortOption["recommended"] = "recommended";
    SortOption["name"] = "name";
    SortOption["dateDesc"] = "dateDesc";
    SortOption["priceDesc"] = "priceDesc";
    SortOption["priceAsc"] = "priceAsc";
})(SortOption || (SortOption = {}));
export function sortProducts(products, sortOption) {
    if (!products || products.length === 0) {
        return [];
    }
    switch (sortOption) {
        case SortOption.recommended:
            return products
                .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
                .sort((a, b) => b.sortingPriority - a.sortingPriority);
        case SortOption.name:
            return products.sort((a, b) => a.title.localeCompare(b.title));
        case SortOption.dateDesc:
            return products.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
        case SortOption.priceDesc:
            return products.sort((a, b) => b.price - a.price);
        case SortOption.priceAsc:
            return products.sort((a, b) => a.price - b.price);
    }
}

import { gotoProduct } from 'actions/Products';
import classNames from 'classnames';
import { ButtonRow, Price } from 'common';
import ProductImage from 'components/Product/ProductImage';
import { AddToCartButton, ProductContext } from 'product';
import React, { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { AddToSubscriptionButton } from 'subscription';
import './ProductTile.scss';
import { useIsSoldOut } from '../../hooks';
function ProductTile({ product, isSubscriptionMode, shrinkOnMobile = false, shrink = false, onClick, gridPosition, className, }) {
    var _a;
    const dispatch = useDispatch();
    const productContext = useContext(ProductContext);
    const isSoldOut = useIsSoldOut(product);
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.8,
    });
    useEffect(() => {
        if (inView) {
            dispatch({ type: 'VIEW_ITEM', payload: { product, productContext } });
        }
    }, [dispatch, inView, product, productContext]);
    const handleClick = (event) => {
        onClick === null || onClick === void 0 ? void 0 : onClick(product, gridPosition);
        const target = event.target;
        const tag = target === null || target === void 0 ? void 0 : target.tagName;
        if (tag !== 'INPUT' && tag !== 'BUTTON') {
            dispatch(gotoProduct(product, productContext));
        }
    };
    const handleKeyPress = (event) => {
        const target = event.target;
        const tag = target === null || target === void 0 ? void 0 : target.tagName;
        if (event.key === 'Enter' && tag !== 'BUTTON' && tag !== 'INPUT') {
            dispatch(gotoProduct(product, productContext));
        }
    };
    const button = isSubscriptionMode && !isSoldOut ? (React.createElement(React.Fragment, null,
        React.createElement(Price, { amount: product.price }),
        React.createElement(ButtonRow, { display: "left" },
            React.createElement(AddToSubscriptionButton, { product: product })))) : (React.createElement(AddToCartButton, { product: product, shrinkOnMobile: shrinkOnMobile, shrink: shrink, soldOut: isSoldOut }));
    const tileClasses = classNames({
        'product-tile': true,
        [`${className}`]: className,
    });
    const imageClasses = classNames({
        'product-tile__image': true,
    });
    return (React.createElement("div", { ref: ref, className: tileClasses, tabIndex: 0, onClick: handleClick, onKeyUp: handleKeyPress, role: 'button' },
        React.createElement(ProductImage, { product: product, className: imageClasses }),
        React.createElement("div", { className: "product-tile__title" },
            React.createElement("h3", null, product.title),
            React.createElement("span", null, product.producer ? product.producer.name : product.vendor)),
        React.createElement("div", { className: "product-tile__badges" },
            React.createElement("div", { className: 'product-tile__badges__left' }, product.filterTags.map((tag) => tag.image && (React.createElement("img", { src: tag.image, alt: tag.name, key: tag.name + tag.image })))),
            React.createElement("div", { className: 'product-tile__badges__right' }, ((_a = product.badge) === null || _a === void 0 ? void 0 : _a.image) && (React.createElement("img", { src: product.badge.image, alt: product.badge.name })))),
        React.createElement("div", null, button)));
}
export default ProductTile;

import React from 'react';
import Icon from 'components/UI/Icon';
import './SelectedCard.scss';
const Pencil = require('img/pencil.svg');
function SelectedCard({ maskedCardNumber, cardIssuer, expiryDate, showEditButton, handleEdit = () => undefined, }) {
    return (React.createElement("div", { className: "selected-credit-card" },
        React.createElement("span", { className: "selected-credit-card__issuer" }, cardIssuer),
        React.createElement("span", { className: "selected-credit-card__number" }, maskedCardNumber),
        React.createElement("br", null),
        React.createElement("span", { className: "selected-credit-card__valid-until" },
            "Gyldig til ",
            expiryDate),
        showEditButton && (React.createElement("button", { "aria-label": "Endre kort", className: "selected-credit-card__edit-btn", onClick: handleEdit },
            React.createElement(Icon, { svg: Pencil, size: "medium" })))));
}
export default SelectedCard;

import { Card, PageWithCards } from 'common';
import CheckoutShowContactInfo from 'components/Checkout/CheckoutShowContactInfo';
import CheckoutShowSubscriptionAddress from 'components/Checkout/CheckoutShowSubscriptionAddress';
import SubscriptionContent from 'components/Subscription/SubscriptionContent';
import SubscriptionTotal from 'components/Subscription/SubscriptionTotal';
import React from 'react';
import CheckoutPaymentMethodTypeSelector from '../components/CheckoutPaymentMethodTypeSelector';
import PlaceSubscriptionOrderButton from '../components/PlaceOrderButton/PlaceSubscriptionOrderButton';
function NewCheckoutPageSubscription() {
    return (React.createElement(PageWithCards, null,
        React.createElement(Card, { title: "Din faste levering" },
            React.createElement(SubscriptionContent, { view: "condensed" }),
            React.createElement(SubscriptionTotal, null)),
        React.createElement(Card, { title: "Leveringsdetaljer" },
            React.createElement(CheckoutShowContactInfo, null),
            React.createElement(CheckoutShowSubscriptionAddress, null)),
        React.createElement(Card, null,
            React.createElement(CheckoutPaymentMethodTypeSelector, null)),
        React.createElement(PlaceSubscriptionOrderButton, null)));
}
export default NewCheckoutPageSubscription;

import { deactivatePrimaryAddress, saveAddress, setActiveAddress, storeAddress, } from 'actions/Address';
import { setRegionByRegionId } from 'actions/Regions';
import { useCoalescedAddresses } from 'address';
import { RecipientList, setRecipients } from 'checkout';
import { Card, ConfirmModal, LinkBox } from 'common';
import CheckoutSubPageButtonRow from 'components/Checkout/CheckoutSubPageButtonContainer';
import CheckoutSubPageHeader from 'components/Checkout/CheckoutSubPageHeader';
import Alert from 'components/UI/Alert';
import Spinner from 'components/UI/Spinner';
import { goBack } from 'connected-react-router';
import { GenericAddressForm } from 'delivery';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addressIsEqual } from 'utilities/address';
import './AddRecipients.scss';
import { generalError } from 'utilities/error';
function AddRecipients({ onClose }) {
    var _a;
    const dispatch = useDispatch();
    const [addRecipient, setAddRecipient] = useState(false);
    const [shouldAddRecipientAsPrimary, setShouldAddRecipientAsPrimary] = useState(false);
    const addresses = useCoalescedAddresses();
    const recipientsFromState = useSelector((state) => state.newCheckout.recipients);
    const selectedAddressInfo = useSelector((state) => state.address.active);
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const savingAddressError = useSelector((state) => state.address.savingAddressError);
    const savingAddress = useSelector((state) => state.address.savingAddress);
    const currentRegion = useSelector((state) => state.region);
    const isMultiSelect = orderType === 'group';
    const [selectedRecipients, setSelectedRecipients] = useState(() => {
        return isMultiSelect
            ? addresses.filter((x) => recipientsFromState.some((y) => addressIsEqual(x.deliveryAddress, y.deliveryAddress)))
            : selectedAddressInfo
                ? [selectedAddressInfo]
                : [];
    });
    const [showChangeRegionModal, setShowChangeRegionModal] = useState(false);
    const [addressOnRegionChange, setAddressOnRegionChange] = useState();
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const [addressCompleteHandler, setAddressCompleteHandler] = useState(undefined);
    const newAddressRef = useRef();
    const handleSelectedRecipient = useCallback((recipient) => {
        if (isMultiSelect) {
            if (!selectedRecipients.includes(recipient)) {
                if (recipient.deliveryAddress.transportProduct !==
                    currentRegion.transportProduct) {
                    setShowChangeRegionModal(true);
                    setAddressOnRegionChange(recipient);
                    return;
                }
                setSelectedRecipients([...selectedRecipients, recipient]);
            }
            else {
                setSelectedRecipients(selectedRecipients.filter((selectedRecipient) => {
                    return selectedRecipient !== recipient;
                }));
            }
        }
        else {
            setSelectedRecipients([recipient]);
        }
    }, [orderType, selectedRecipients]);
    const handleSave = () => {
        const onlyAddress = selectedRecipients.length === 1 ? selectedRecipients[0] : null;
        if (isMultiSelect) {
            dispatch(setRecipients(selectedRecipients));
        }
        else if (selectedAddressInfo &&
            onlyAddress &&
            !addressIsEqual(selectedAddressInfo.deliveryAddress, onlyAddress.deliveryAddress)) {
            dispatch(setActiveAddress(onlyAddress));
        }
        // If onClose prop is set (other context than checkout)
        if (!!onClose) {
            onClose();
        }
        else {
            dispatch(goBack());
        }
    };
    useEffect(() => {
        if (newAddressRef.current) {
            const addressesWithoutTransportProduct = addresses.map((address) => (Object.assign(Object.assign({}, address.deliveryAddress), { transportProduct: undefined })));
            const newAddressIndex = addressesWithoutTransportProduct.findIndex((address) => newAddressRef.current && addressIsEqual(address, newAddressRef.current));
            if (newAddressIndex != -1 &&
                addresses[newAddressIndex].deliveryAddress.transportProduct) {
                handleSelectedRecipient(addresses[newAddressIndex]);
                newAddressRef.current = undefined;
                setAddRecipient(false);
            }
        }
    }, [addresses]);
    useEffect(() => {
        const validSelectedRecipients = selectedRecipients.filter((x) => addresses.some((y) => addressIsEqual(x === null || x === void 0 ? void 0 : x.deliveryAddress, y.deliveryAddress)));
        if (validSelectedRecipients.length !== selectedRecipients.length) {
            setSelectedRecipients(validSelectedRecipients);
        }
        else if (isEmpty(validSelectedRecipients) && !isMultiSelect) {
            setSelectedRecipients(selectedAddressInfo ? [selectedAddressInfo] : []);
        }
    }, [addresses, selectedAddressInfo]);
    const handleSubmitNewAddress = (address) => {
        if (address) {
            const shouldSetNewAddressAsActive = shouldAddRecipientAsPrimary;
            newAddressRef.current = address.deliveryAddress;
            dispatch(isLoggedIn
                ? saveAddress(address.deliveryAddress, shouldAddRecipientAsPrimary, shouldSetNewAddressAsActive)
                : storeAddress(address));
            if (shouldAddRecipientAsPrimary) {
                dispatch(deactivatePrimaryAddress());
                setShouldAddRecipientAsPrimary(false);
            }
        }
    };
    const handleConfirmRegionChangeModal = () => {
        const newRegion = currentRegion.regions.find((region) => region.transportProduct === (addressOnRegionChange === null || addressOnRegionChange === void 0 ? void 0 : addressOnRegionChange.deliveryAddress.transportProduct));
        setShowChangeRegionModal(false);
        if (!newRegion) {
            // Error handling?
            return;
        }
        dispatch(setRegionByRegionId(newRegion.id));
        setSelectedRecipients(addressOnRegionChange ? [addressOnRegionChange] : []);
    };
    const handleAddRecipient = (newPrimaryAddress) => {
        if (newPrimaryAddress) {
            setShouldAddRecipientAsPrimary(true);
        }
        setAddRecipient(true);
    };
    const cancelNewAddress = () => {
        setAddRecipient(false);
        setShouldAddRecipientAsPrimary(false);
    };
    if (addRecipient) {
        return (React.createElement(Card, { className: "add-recipients__card" },
            React.createElement(CheckoutSubPageHeader, { title: "Ny adresse", onClose: () => (!!onClose ? onClose() : dispatch(goBack())) }),
            savingAddressError && React.createElement(Alert, { type: "danger" }, generalError(savingAddressError)),
            React.createElement("div", { className: 'add-recipients__address-form-container' },
                savingAddress && (React.createElement("div", { className: "add-recipients__spinner-container" },
                    React.createElement(Spinner, { type: "logo", size: "extra-large" }))),
                React.createElement(GenericAddressForm, { onComplete: handleSubmitNewAddress, hideButton: true, setAddressCompleteHandler: setAddressCompleteHandler })),
            React.createElement(CheckoutSubPageButtonRow, { saveButtonText: "Legg til", backButtonText: "Tilbake", handleSave: () => {
                    !savingAddress &&
                        addressCompleteHandler &&
                        addressCompleteHandler.handleAddressComplete();
                }, handleAbort: cancelNewAddress })));
    }
    else {
        return (React.createElement(React.Fragment, null,
            showChangeRegionModal && (React.createElement(ConfirmModal, { title: "Endre region?", description: `Denne adressen er i en annen region enn den du har valgt, ønsker du å endre region til ${(_a = currentRegion.regions.find((x) => x.transportProduct ===
                    (addressOnRegionChange === null || addressOnRegionChange === void 0 ? void 0 : addressOnRegionChange.deliveryAddress.transportProduct))) === null || _a === void 0 ? void 0 : _a.name}?`, onCancel: () => setShowChangeRegionModal(false), cancelText: "Avbryt", onOk: handleConfirmRegionChangeModal, okText: "Ja, bytt region" })),
            React.createElement(Card, { className: "add-recipients__card" },
                React.createElement(CheckoutSubPageHeader, { title: isMultiSelect ? 'Velg mottakere' : 'Velg mottaker', onClose: () => (!!onClose ? onClose() : dispatch(goBack())) }),
                React.createElement(LinkBox, { colorStyle: "gray", text: "Legg til ny adresse", onClick: () => {
                        setAddRecipient(true);
                    }, className: "add-recipients__linkbox" }),
                isMultiSelect && (React.createElement(Alert, { type: 'success' }, "Du har valgt \u00E5 sende til flere. Velg opptil 10 mottakere.")),
                React.createElement(RecipientList, { currentlySelectedRecipients: selectedRecipients, recipients: addresses, handleSelect: handleSelectedRecipient, handleNewPrimaryAddress: () => handleAddRecipient(true) }),
                React.createElement(CheckoutSubPageButtonRow, { saveButtonText: isMultiSelect ? 'Bruk valgte adresser' : 'Bruk valgt adresse', backButtonText: "Tilbake", disableSaveButton: isEmpty(selectedRecipients), handleSave: handleSave, handleAbort: () => (!!onClose ? onClose() : dispatch(goBack())) }))));
    }
}
export default AddRecipients;

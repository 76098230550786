import { useSearch } from 'actions/Search/searchHooks';
import { SearchResultDropdownV2 } from 'components/Search/SearchResultDropdown/SearchResultDropdown';
import { push } from 'connected-react-router';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './style.scss';
import NewTextInput from 'common/components/TextInput/NewTextInput';
function SearchInput(props) {
    const dispatch = useDispatch();
    const search = useSearch();
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState({
        producers: [],
        products: [],
    });
    const doSearch = debounce((query) => {
        search(query).then((results) => {
            setSearchResults(results);
        });
    }, 50);
    useEffect(() => {
        if (searchResults.products.length + searchResults.producers.length > 0) {
            setShowDropdown(true);
        }
    }, [searchResults.producers, searchResults.products]);
    useEffect(() => {
        if (props.onShowDropdown) {
            props.onShowDropdown(showDropdown);
        }
    }, [showDropdown]);
    const handleValueChange = (query) => {
        if (query.trim().length >= 3) {
            doSearch(query.trim());
        }
        else {
            setShowDropdown(false);
        }
        props.onValueChange && props.onValueChange(query);
        setSearchValue(query);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && searchValue) {
            dispatch(push(`/search/${searchValue}`));
            setShowDropdown(false);
        }
    };
    const handleFocus = (e) => {
        props.onFocus && props.onFocus(e);
        if (e && e.target && e.target.value && searchValue.length >= 3) {
            setShowDropdown(true);
        }
    };
    return (React.createElement("div", { className: 'search-input', onKeyDown: handleKeyDown },
        React.createElement(NewTextInput, { placeholder: "S\u00F8k i produkter", value: searchValue, onChange: handleValueChange, onFocus: handleFocus, onBlur: props.onBlur, inputState: 'incomplete', size: props.size || 'medium' }),
        showDropdown && (React.createElement(SearchResultDropdownV2, { products: searchResults.products, producers: searchResults.producers, onClose: () => setShowDropdown(false), query: searchValue, anchorRef: props.anchorRef }))));
}
export default SearchInput;

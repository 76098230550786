var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import groq from 'groq';
import sanityClient from 'utilities/sanityClient';
const initialState = {
    bannerStrip1: undefined,
    bannerStrip2: undefined,
    homePageElements: [],
};
export const fetchDynamicHomePage = createAsyncThunk('dynamicHomePage/fetchHomePage', (regionId) => __awaiter(void 0, void 0, void 0, function* () {
    if (regionId == null) {
        throw new Error('Missing regionId');
    }
    const query = groq `
            *[_type == "frontPage" && region == $regionId][0] {
                bannerStrip1,
                bannerStrip2,
                "elements": pageBuilder[] {
                    "type": _type,
                    _type == "abTest" => @-> {
                        "name": title,
                        variantA->{ "type": _type, "elementId":_id },
                        variantB->{ "type": _type, "elementId":_id }
                    },
                    _type == "reference" => @-> {
                        "type": _type,
                        _type != "ctaButton" => {
                            "elementId": _id
                        },
                        _type == "ctaButton" => {
                            title,
                            target
                        }
                    },
                    _type != "reference" && _type != "abTest" => @ {
                        "type": content,
                    }
                }
            }`.replace(/[\s\n]/, '');
    return sanityClient
        .fetch(query, { regionId })
        .then((frontPage) => {
        return frontPage;
    })
        .catch(() => {
        throw new Error('Error when fetching Sanity document');
    });
}));
const dynamicHomePageSlice = createSlice({
    name: 'dynamicHomePage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDynamicHomePage.pending, (state) => {
            state.bannerStrip1 = undefined;
            state.bannerStrip2 = undefined;
            state.homePageElements = [];
        });
        builder.addCase(fetchDynamicHomePage.fulfilled, (state, { payload }) => {
            payload.bannerStrip1;
            state.bannerStrip1 = payload.bannerStrip1;
            state.bannerStrip2 = payload.bannerStrip2;
            state.homePageElements = payload.elements;
        });
        builder.addCase(fetchDynamicHomePage.rejected, (state, { error }) => {
            state.bannerStrip1 = undefined;
            state.bannerStrip2 = undefined;
            state.homePageElements = [];
            console.error(error.message);
        });
    },
});
export default dynamicHomePageSlice.reducer;

import { setSubscriptionRegion } from 'actions/Regions'
import { Button, ButtonRow, Modal } from 'common'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { getRegionFromTransportProduct } from 'utilities/region'
import './style.scss'

class WrongRegionModal extends PureComponent {
    render() {
        const { subscriptionRegion, selectedRegion, onClose, dispatch } = this.props

        return (
            <Modal onClose={onClose} labelId="wrong-region-modal-title">
                <div className="wrong-region-modal">
                    <span id="wrong-region-modal-title">
                        Du har fast levering til {subscriptionRegion} men har valgt region{' '}
                        {selectedRegion}. Velg region {subscriptionRegion} for å se og gjøre
                        endringer på din faste levering.
                    </span>
                    <ButtonRow display="center">
                        <Button type="action" style="ghost" onClick={onClose}>
                            Avbryt
                        </Button>
                        <Button
                            type="action"
                            style="primary"
                            onClick={() => dispatch(setSubscriptionRegion())}
                        >
                            Ok, bytt region
                        </Button>
                    </ButtonRow>
                </div>
            </Modal>
        )
    }
}

WrongRegionModal.propTypes = {
    subscriptionRegion: PropTypes.string,
    selectedRegion: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
}

const mapStateToProps = ({ subscription, region }) => ({
    subscriptionRegion: getRegionFromTransportProduct(
        subscription.subscriptionTransportProduct,
        region.regions
    ),
    selectedRegion: getRegionFromTransportProduct(
        subscription.selectedTransportProduct,
        region.regions
    ),
})

export default connect(mapStateToProps)(WrongRegionModal)

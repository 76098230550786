import orderBy from 'lodash/orderBy'
import trimStart from 'lodash/trimStart'
import endsWith from 'lodash/endsWith'
import moment from 'moment'

export function getNewestMatchingPaymentMethod(paymentMethods, cardNo) {
    const matchingMethods = paymentMethods.filter((p) =>
        endsWith(cardNo, trimStart(p.details.maskedNumber, '*'))
    )
    return orderBy(matchingMethods, ['created'], ['desc'])[0]
}

// Input formats can be "MM/YY" for new payment methods or "YYYY-MM-DD" for cards fetch from the server
export function formatExpirationDate(expirationDate) {
    const outputFormat = 'MM-YY'
    if (expirationDate.indexOf('/') < 0) {
        return moment(expirationDate).format(outputFormat)
    } else {
        return moment(expirationDate, 'MM/YY').format(outputFormat)
    }
}

import { useSanityDocument } from 'common/utils/useSanityDocument';
import { FreeText } from './FreeText';
import React from 'react';
export default function DynamicFreeText(props) {
    const data = useSanityDocument({
        documentId: props.documentId,
        queryTemplate: `body`,
    });
    if (!data)
        return null;
    return React.createElement(FreeText, Object.assign({}, data));
}

import Icon from 'components/UI/Icon';
import { push } from 'connected-react-router';
import { navigateBack } from 'navigation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import './NavigationHeader.scss';
import NavigationModal from './NavigationModal';
import { useNavigationModal } from './useNavigationModal';
import { CartButton, viewCart } from 'cart';
import SearchInput from 'components/Search/SearchInput/SearchInput';
import { useHideScroll } from 'common/components/HideScroll/HideScroll';
import { webLogin } from 'user';
const User = require('img/navigationIcons/user.svg');
const Menu = require('img/navigationIcons/Burger icon.svg');
const BackButton = require('img/navigationIcons/backbutton.svg');
const LogoNoText = require('img/morgenlevering_logo_no_text.svg');
function NavigationHeader() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const scrolled = useHideScroll();
    const headerRef = React.useRef(null);
    const isCartPage = RegExp(/^\/cart/).test(location.pathname);
    const { isShown: isModalShown, toggle: toggleModal } = useNavigationModal();
    const showBackIcon = location.pathname.startsWith('/subscription') ||
        location.pathname.startsWith('/delivery') ||
        location.pathname.startsWith('/cart') ||
        location.pathname.startsWith('/product/') ||
        location.pathname.startsWith('/my-page') ||
        (location.pathname.startsWith('/checkout') &&
            !location.pathname.startsWith('/checkout/complete')) ||
        location.pathname.startsWith('/produsenter') ||
        location.pathname.startsWith('/produsent/') ||
        location.pathname.startsWith('/bedrift');
    if (showBackIcon) {
        return (React.createElement("div", { className: "navigation-header navigation-header__no-search" },
            React.createElement("div", { className: 'navigation-header__icons' },
                React.createElement(Icon, { svg: BackButton, size: "large", onClick: () => navigateBack(dispatch, history, location) }))));
    }
    return (React.createElement("div", { className: "navigation-header", "data-scrolled": !scrolled },
        React.createElement("div", { className: 'navigation-header__search', "data-scrolled": !scrolled },
            React.createElement(SearchInput, { size: 'medium', anchorRef: headerRef }),
            React.createElement("div", { className: 'navigation-header__search__anchor', ref: headerRef })),
        React.createElement("div", { className: 'navigation-header__icons' },
            React.createElement("div", { className: "navigation-header__icon" },
                React.createElement(Icon, { svg: Menu, size: "large", onClick: () => toggleModal() })),
            React.createElement("div", { className: 'navigation-header__icon--logo', "data-scrolled": !scrolled },
                React.createElement(Icon, { svg: LogoNoText, size: "xlarge", onClick: () => dispatch(push('/')) }))),
        React.createElement("div", { className: "navigation-header__icons" },
            React.createElement("div", { className: 'navigation-header__icon', "data-scrolled": !scrolled },
                React.createElement(UserIcon, null)),
            !isCartPage && (React.createElement("div", { className: "navigation-header__icon" },
                React.createElement(CartButton, { onClick: () => {
                        dispatch(viewCart());
                        dispatch(push('/cart'));
                    } })))),
        React.createElement(NavigationModal, { isShown: isModalShown, hide: toggleModal })));
}
function UserIcon() {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const dispatch = useDispatch();
    const handleClick = () => {
        if (isLoggedIn) {
            dispatch(push('/my-page'));
        }
        else {
            webLogin();
        }
    };
    return (React.createElement("a", { className: 'navigation-header__icons__login', onClick: handleClick },
        React.createElement(Icon, { svg: User, size: "large", onClick: () => 0 }),
        !isLoggedIn && React.createElement("span", null, "Logg inn")));
}
export default NavigationHeader;

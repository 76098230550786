import { searchAddress } from 'actions/AddressHelper';
import classNames from 'classnames';
import NewTextInput from 'common/components/TextInput/NewTextInput';
import kebabCase from 'lodash/kebabCase';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './AddressInput.scss';
function AddressInput({ value, className, onChange, onFocus, isActive, inputRef, items, view, findBestMatchIndex, name, label, onComplete, setSelectedValue, fillHelp, helpIcon, children, highlightInvalid, errorMessage, disabled, }) {
    const [_currentValue, setCurrentValue] = useState(null);
    const [cursor, setCursor] = useState(-1);
    const [prevCursor, setPrevCursor] = useState(-1);
    const [showDropdown, setShowDropdown] = useState(false);
    const [unmounted, setUnmounted] = useState(false);
    const [dropdown, setDropdown] = useState();
    const dispatch = useDispatch();
    const findBestMatch = (_items, _value) => {
        return findBestMatchIndex ? findBestMatchIndex(_items, _value) : 0;
    };
    useEffect(() => {
        setCursor(findBestMatch(items, _currentValue));
    }, [items]);
    useEffect(() => {
        return () => {
            setUnmounted(true);
        };
    }, []);
    const dropdownRef = useCallback((node) => setDropdown(node), []);
    const select = (index, autoNavigate, wasTabKey) => {
        if (unmounted) {
            return;
        }
        const selectedItem = items[index];
        if (selectedItem) {
            const fieldName = `${kebabCase(name)}`;
            dispatch(searchAddress({
                [fieldName]: setSelectedValue
                    ? setSelectedValue(selectedItem)
                    : view(selectedItem),
            }));
            onComplete(selectedItem, name, autoNavigate, wasTabKey);
        }
        setShowDropdown(false);
    };
    const handleChange = (newValue) => {
        setCursor(findBestMatch(items, newValue));
        setShowDropdown(true);
        dispatch(searchAddress({ [`${kebabCase(name)}`]: newValue }));
        if (onChange) {
            onChange(newValue);
        }
        setCurrentValue(newValue);
    };
    const handleKeyDown = (e) => {
        if (items.length === 0) {
            return;
        }
        if (e.key === 'Tab' && !e.shiftKey) {
            select(cursor, false, true);
        }
        if (e.key === 'Enter') {
            select(cursor, true, false);
        }
        if (e.key === 'ArrowDown') {
            const nextPos = items[cursor + 1] ? cursor + 1 : 0;
            setCursor(nextPos);
            setShowDropdown(true);
        }
        if (e.key === 'ArrowUp') {
            const prevPos = items[cursor - 1] ? cursor - 1 : items.length - 1;
            setCursor(prevPos);
            setShowDropdown(true);
        }
    };
    const setActiveElement = (index, prevIndex) => {
        if (!dropdown || index === -1)
            return;
        const element = dropdown.children[index];
        if (!element)
            return;
        const position = element.offsetTop;
        const height = element.offsetHeight;
        const scrollEnd = dropdown.offsetHeight + dropdown.scrollTop;
        if (position + height > scrollEnd || position < dropdown.scrollTop) {
            dropdown.scrollTop =
                index > prevIndex ? position + height - dropdown.offsetHeight : position;
        }
    };
    useEffect(() => {
        setActiveElement(cursor, prevCursor);
        setPrevCursor(cursor);
    }, [cursor]);
    const validate = (_value) => {
        return !isActive || (items && findBestMatch(items, _value) > -1);
    };
    const classes = classNames({
        'address-input': true,
        [`${className}`]: className,
    });
    const dropdownClasses = classNames({
        'address-input__dropdown': true,
        'address-input__dropdown--open': showDropdown,
    });
    const invalidInputState = highlightInvalid ? 'invalid' : 'incomplete';
    return (React.createElement("div", { className: classes, onKeyDown: (e) => {
            handleKeyDown(e);
        } },
        React.createElement(NewTextInput, { inputState: disabled ? 'disabled' : validate(value) ? 'valid' : invalidInputState, onChange: (e) => {
                handleChange(e);
            }, className: "address-input__text-input", onBlur: (_value, event) => {
                if (event.relatedTarget && event.relatedTarget.type === 'submit') {
                    select(cursor, true, false);
                }
                else {
                    select(cursor, false, false);
                }
            }, inputRef: inputRef, value: value, onFocus: () => {
                onFocus && onFocus();
                setShowDropdown(true);
            }, label: label, autoComplete: "off", fillHelp: fillHelp, errorMessage: errorMessage, helpIcon: helpIcon }, children),
        showDropdown && (React.createElement("div", { className: dropdownClasses, ref: dropdownRef }, items &&
            items.map((item, idx) => {
                const _classes = classNames({
                    'address-input__item': true,
                    'address-input__item--active': idx === cursor,
                });
                return (React.createElement("div", { key: idx, className: _classes, onClick: () => {
                        setCursor(idx);
                        select(idx, true, false);
                    }, onMouseDown: (e) => e.preventDefault() }, view(item)));
            })))));
}
export default AddressInput;

import combineEvents from '@redux-beacon/combine-events';
import { trackPageView } from '@redux-beacon/google-analytics';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { getCartSize, getCartValue, getPromoDescription, } from 'common/utils/analytics';
import { LOCATION_CHANGE } from 'connected-react-router';
import hash from 'hash.js';
import { createMiddleware } from 'redux-beacon';
const mapDeliveriesToSummedProducts = (deliveries) => {
    const allProducts = deliveries.flatMap((delivery) => delivery.products);
    const productIds = [...new Set(allProducts.map((product) => product.id))];
    return productIds.map((productId) => (Object.assign(Object.assign({}, allProducts.find((product) => product.id === productId)), { quantity: allProducts
            .filter((product) => product.id === productId)
            .map((product) => product.quantity)
            .reduce((a, b) => a + b) })));
};
const productMap = (product) => {
    var _a;
    return ({
        item_name: product.title,
        item_id: product.id,
        price: product.price,
        item_brand: product.vendor,
        item_category: (_a = product.category[0]) === null || _a === void 0 ? void 0 : _a.title,
        quantity: product.quantity ? product.quantity : 1,
    });
};
const amplitudeProductMap = (product) => (Object.assign({ productId: product.id, productPrice: product.price, quantity: product.quantity ? product.quantity : 1, productTitle: product.title }, categories(product.category)));
const clear = () => ({ ecommerce: null });
const orderCompleted = (action) => {
    const response = action.payload.response.data;
    const summedProducts = mapDeliveriesToSummedProducts(response.deliveries);
    const items = summedProducts.map((item) => ({
        sku: item.id,
        name: item.title,
        price: item.price,
        quantity: item.quantity,
    }));
    const shippingTotal = response.deliveries
        .map((delivery) => delivery.freightPrice)
        .reduce((a, b) => a + b);
    return {
        event: 'orderCompleted',
        transactionId: action.payload.sessionId,
        transactionTotal: response.payment.grossAmount,
        transactionCurrency: process.env.TRANSACTIONCURRENCY,
        transactionTax: response.payment.vatAmount,
        transactionShipping: shippingTotal,
        transactionProducts: items,
    };
};
const purchase = (action) => {
    const response = action.payload.response.data;
    const summedProducts = mapDeliveriesToSummedProducts(response.deliveries);
    const items = summedProducts.map((item) => ({
        item_id: item.id,
        item_name: item.title,
        price: item.price,
        quantity: item.quantity,
    }));
    const shippingTotal = response.deliveries
        .map((delivery) => delivery.freightPrice)
        .reduce((a, b) => a + b);
    return {
        event: 'purchase',
        ecommerce: {
            transaction_id: action.payload.sessionId,
            value: response.payment.grossAmount,
            tax: response.payment.vatAmount,
            shipping: shippingTotal,
            currency: process.env.TRANSACTIONCURRENCY,
            items: items,
        },
    };
};
const error = (type, err) => ({
    event: 'error',
    type: type,
    error: err,
});
const categories = (categories) => {
    var _a;
    return {
        productMainCategory: (_a = categories[0]) === null || _a === void 0 ? void 0 : _a.title,
        productSubCategories: categories.slice(1).map((category) => category.title),
    };
};
const dimensionRegion = `dimension${process.env.GA_DIMENSION_INDEX_REGION}`;
const eventsMap = {
    [LOCATION_CHANGE]: trackPageView((action, state) => ({
        hitType: 'pageview',
        page: action.payload.location.pathname,
        fieldsObject: {
            [dimensionRegion]: state.region.slug,
        },
    })),
    // EventsMap type doesn't work with TypeScript strict mode
    'newCheckout/corePurchaseComplete/fulfilled': combineEvents(purchase, orderCompleted),
    'newCheckout/corePurchaseComplete/rejected': (action) => error('checkout', action),
    PLACE_SUBSCRIPTION_ORDER_FAILED: (action) => error('subscription', action.payload),
    PLACE_SUBSCRIPTION_ORDER_ERROR: (action) => error('subscription', action.payload),
    PAUSE_SUBSCRIPTION_FAILED: (action) => error('subscription', action.payload),
    UNPAUSE_SUBSCRIPTION_FAILED: (action) => error('subscription', action.payload),
    UPDATE_SUBSCRIPTION_FAILED: (action) => error('subscription', action.payload),
    TOGGLE_SUBSCRIPTION_FAILED: (action) => error('subscription', action.payload),
    FETCH_SUBSCRIPTION_FAILED: (action) => error('subscription', action.payload),
    GET_STOP_DATES_FAILED: (action) => error('subscription', action.payload),
    PLACE_ORDER_ERROR: (action) => error('checkout', action.payload),
    FETCH_CHECK_CART_REJECTED: () => error('cart', 'check cart failed'),
    GET_FREIGHT_FAILED: (action) => error('freight', action.payload),
    GENERATE_TOKEN_FAILED: () => error('payment method', 'error generating token'),
    ORDER_VALIDATION_FAILED: (action) => error('order validation', action.payload),
    VALIDATE_COUPON_CODE_FAILED: (action) => error('coupon', action.payload),
    FETCH_PAYMENT_METHODS_FAILED: (action) => error('payment method', action.payload),
    DELETE_PAYMENT_METHOD_FAILED: (action) => error('payment method', action.payload),
    ABORT_DELETE_PAYMENT_METHOD_FAILED: (action) => error('payment method', action.payload),
    SET_PREFERRED_PAYMENT_METHOD_FAILED: (action) => error('payment method', action.payload),
    ADD_PAYMENT_METHOD_FAILED: (action) => error('payment method', action.payload),
    'dynamicHomePage/fetchHomePage/rejected': (action) => error('content', action.payload),
    GET_ADDRESSES_FAILED: (action) => error('address', action.payload),
    GET_ALL_ADDRESSES_FAILED: (action) => error('address', action.payload),
    SAVE_ADDRESS_FAILED: (action) => error('address', action.payload),
    EDIT_ADDRESS_FAILED: () => error('address', 'could not edit address'),
    FETCH_DISTRIBUTION_FOR_ADDRESS_FAILED: (action) => error('distribution', action.payload),
    SET_NEW_MAIN_ADDRESS_FAILED: () => error('address', 'could not set main address'),
    STORE_SUBSCRIPTION_ADDRESS_FAILED: () => error('address', 'could not save subscription address'),
    UPDATE_SUBSCRIPTION_ADDRESS_FAILED: () => error('address', 'could not update subscription address'),
    VALIDATE_JWT_SUCCESS: (action) => ({
        event: 'login',
        email: hash.sha256().update(action.payload.jwtData.spidEmail).digest('hex'),
        spid: action.payload.jwtData.spidUserId,
    }),
    USER_LOGOUT: () => ({ event: 'logout' }),
    AUTOCOMPLETE_SEARCH: (action) => ({ event: 'search', search_term: action.payload }),
    ADD_BUNDLE_TO_CART: (action) => {
        var _a, _b, _c, _d;
        return {
            event: 'add_bundle_to_cart',
            ecommerce: {
                products: action.payload.products.map(amplitudeProductMap),
                source: (_b = (_a = action.payload.productContext) === null || _a === void 0 ? void 0 : _a.context) !== null && _b !== void 0 ? _b : null,
                parentSource: (_d = (_c = action.payload.productContext) === null || _c === void 0 ? void 0 : _c.parentContext) !== null && _d !== void 0 ? _d : null,
            },
        };
    },
    ADD_TO_CART: (action) => {
        var _a, _b;
        const { title: productTitle, id: productId, productContext, price: productPrice, category, } = action.payload.product;
        const { productsInCart } = action.payload;
        return {
            event: 'add_to_cart',
            ecommerce: Object.assign(Object.assign({ productTitle,
                productId, parentSource: (_a = productContext === null || productContext === void 0 ? void 0 : productContext.parentContext) !== null && _a !== void 0 ? _a : null, source: (_b = productContext === null || productContext === void 0 ? void 0 : productContext.context) !== null && _b !== void 0 ? _b : null, productPrice }, categories(category)), { currency: process.env.TRANSACTIONCURRENCY, products: productsInCart.map(amplitudeProductMap) }),
        };
    },
    REMOVE_BUNDLE_FROM_CART: (action) => {
        return {
            event: 'remove_bundle_from_cart',
            ecommerce: {
                products: action.payload.map(amplitudeProductMap),
            },
        };
    },
    REMOVE_FROM_CART: (action) => {
        const { id, title, price, category } = action.payload;
        return {
            event: 'remove_from_cart',
            ecommerce: Object.assign({ productId: id, productTitle: title, productPrice: price }, categories(category)),
        };
    },
    CANCEL_ORDER_SUCCESS: () => ({ event: 'orderCancelled' }),
    CAN_DELIVER_TO_NEW_ADDRESS: (action) => {
        return {
            event: action.payload.canDeliver ? 'addressAccepted' : 'addressRejected',
        };
    },
    PLACE_SUBSCRIPTION_ORDER_SUCCESS: () => ({ event: 'subscriptionStarted' }),
    COMMIT_EDITED_SUBSCRIPTION_SUCCESS: () => ({ event: 'subscriptionUpdated' }),
    TOGGLE_SUBSCRIPTION_SUCCESS: (action) => ({
        event: action.payload.activate ? 'subscriptionRestarted' : 'subscriptionStopped',
    }),
    BEGIN_CHECKOUT: (action) => {
        const { id: cartId, totalWithoutFreight: cartValue, discount: totalDiscounts, products, } = action.payload;
        const cartSize = getCartSize(action.payload);
        return {
            event: 'checkout_started',
            ecommerce: {
                cartId,
                cartValue,
                cartSize,
                totalDiscounts,
                products: products.map(amplitudeProductMap),
            },
        };
    },
    ADD_SHIPPING_INFO: (action) => {
        const cartState = action.payload;
        return {
            event: 'add_shipping_info',
            ecommerce: {
                currency: 'NOK',
                value: cartState.total,
                items: cartState.products.map(productMap),
            },
        };
    },
    ADD_PAYMENT_INFO: (action, _, nextState) => {
        const { discount: totalDiscounts, id: cartId, totalWithoutFreight: cartValue, } = action.payload;
        const cartSize = getCartSize(action.payload);
        const { paymentMethodType: paymentMethod } = nextState.checkout;
        return {
            event: 'add_payment_info',
            ecommerce: {
                cartId,
                cartSize,
                cartValue,
                paymentMethod,
                totalDiscounts,
            },
        };
    },
    PLACE_ORDER: (_, prevState) => {
        const { cart, coupon, checkout } = prevState;
        const ecommerce = {
            cartId: cart.id,
            cartValue: getCartValue(cart),
            cartSize: getCartSize(cart),
            promoCode: coupon.codeIsValid ? coupon.code : null,
            orderTotal: cart.total,
            paymentMethod: checkout.paymentMethodType,
            totalDiscount: cart.discount,
            totalShipping: cart.freight.amount,
            products: cart.products.map(amplitudeProductMap),
        };
        return { event: 'order_submitted', ecommerce };
    },
    CHECK_CART_SUCCESS: (action, _, nextState) => {
        const { code: promoCode, discountedAmount: totalDiscounts } = action.payload;
        const { cart } = nextState;
        const ecommerce = {
            promoCode,
            totalDiscounts,
            promoDescription: getPromoDescription(action.payload),
            cartId: cart.id,
            cartValue: getCartValue(cart),
            cartSize: getCartSize(cart),
        };
        return { event: 'promo_applied', ecommerce };
    },
    PRODUCT_AUTOCOMPLETE_SEARCH_SUCCESS: (action) => {
        var _a, _b;
        return {
            event: 'search_completed',
            ecommerce: {
                numberOfSearchResults: (_b = (_a = action.payload.products) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0,
                searchQuery: action.payload.query,
            },
        };
    },
    SEARCH_RESULT_CLICKED: (action) => {
        return { event: 'search_result_clicked', ecommerce: Object.assign({}, action.payload) };
    },
    SELECT_ITEM: (action) => {
        var _a, _b;
        const { product, productContext } = action.payload;
        return {
            event: 'product_details_viewed',
            ecommerce: Object.assign({ source: (_a = productContext === null || productContext === void 0 ? void 0 : productContext.context) !== null && _a !== void 0 ? _a : null, parentSource: (_b = productContext === null || productContext === void 0 ? void 0 : productContext.parentContext) !== null && _b !== void 0 ? _b : null, productTitle: product.title, productId: product.id, productPrice: product.price }, categories(product.category)),
        };
    },
    SELECT_PROMOTION: (action) => {
        const { campaign } = action.payload;
        return {
            event: 'select_promotion',
            ecommerce: {
                promotion_id: campaign.id,
                promotion_name: campaign.title,
            },
        };
    },
    VIEW_ITEM: (action) => {
        var _a;
        const { product, productContext } = action.payload;
        return {
            event: 'view_item',
            ecommerce: {
                currency: 'NOK',
                value: product.price,
                items: [
                    {
                        item_name: product.title,
                        item_id: product.id,
                        price: product.price,
                        item_brand: product.vendor,
                        item_category: (_a = product.category[0]) === null || _a === void 0 ? void 0 : _a.title,
                        item_list_name: productContext === null || productContext === void 0 ? void 0 : productContext.context,
                        item_list_parent_name: productContext === null || productContext === void 0 ? void 0 : productContext.parentContext,
                        quantity: 1,
                    },
                ],
            },
        };
    },
    VIEW_ITEM_LIST: (action) => {
        const { products, productContext } = action.payload;
        return {
            event: 'view_item_list',
            ecommerce: {
                item_list_name: productContext === null || productContext === void 0 ? void 0 : productContext.context,
                item_list_parent_name: productContext === null || productContext === void 0 ? void 0 : productContext.parentContext,
                items: products.map(productMap),
            },
        };
    },
    VIEW_CART: (action) => {
        const cartState = action.payload;
        return {
            event: 'view_cart',
            ecommerce: {
                currency: 'NOK',
                value: cartState.total,
                items: cartState.products.map(productMap),
            },
        };
    },
    GENERATE_LEAD: (action) => {
        const nokValue = action.payload;
        return {
            event: 'generate_lead',
            ecommerce: {
                currency: 'NOK',
                value: nokValue,
            },
        };
    },
    PROMOTION_CLICKED: (action) => {
        const { promotionTitle } = action.payload;
        return {
            event: 'promotion_clicked',
            ecommerce: {
                promotionTitle,
            },
        };
    },
    AB_TEST_VIEWED: (action) => {
        return {
            event: 'ab_test_viewed',
            abTest: Object.assign({}, action.payload),
        };
    },
    LINK_LIST_CLICKED: (action) => {
        return { event: 'link_list_clicked', ecommerce: Object.assign({}, action.payload) };
    },
    ADDRESS_SEARCH_FAILED: () => {
        return { event: 'address_search_failed' };
    },
    ADDRESS_SAVED: (action) => {
        return Object.assign({ event: 'address_saved' }, action.payload);
    },
    ADDRESS_FORM_OPENED: (action) => {
        return Object.assign({ event: 'address_form_opened' }, action.payload);
    }
};
const gtmOptions = {
    dataLayerName: 'dataLayer',
};
const eventsMapWithClear = Object.fromEntries(Object.keys(eventsMap).map((x) => [
    x,
    // EventsMap type doesn't work with TypeScript strict mode
    combineEvents(clear, eventsMap[x]),
]));
const gtm = GoogleTagManager(gtmOptions);
export const googleAnalyticsMiddleware = createMiddleware(eventsMapWithClear, gtm);

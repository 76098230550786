var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { replace } from 'connected-react-router';
import { addOrderError, setShowRelatedProductsInterrupt } from '../store/checkoutSlice';
export const completeVippsOrder = createAsyncThunk('newCheckout/completeVipps', (sessionId, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const dispatch = thunkApi.dispatch;
    const url = `${process.env.CORE_API}/order/complete/vipps/${encodeURIComponent(sessionId)}`;
    return axios.post(url).then(() => {
        dispatch({ type: '2_PHASE_COMPLETE' });
        dispatch(setShowRelatedProductsInterrupt(true));
        dispatch(replace('/checkout/complete'));
    }, (error) => {
        dispatch(addOrderError(error.response.data));
        dispatch(replace('/checkout'));
    });
}));

import Icon from 'components/UI/Icon';
import React from 'react';
import './CartCounter.scss';
const minus = require('img/minus.svg');
const plus = require('img/plus.svg');
const trashCan = require('img/trashcan.svg');
export function CartCounter({ value, title, onIncrease, onDecrease }) {
    return (React.createElement("div", { className: "cart-counter" },
        React.createElement("button", { className: "cart-counter__button", "aria-label": `Kjøp ${value - 1} ${title}`, onClick: () => {
                onDecrease();
            } },
            React.createElement(Icon, { svg: value > 1 ? minus : trashCan, size: "medium" })),
        React.createElement("input", { value: value, tabIndex: -1, readOnly: true }),
        React.createElement("button", { className: "cart-counter__button", "aria-label": `Kjøp ${value + 1} ${title}`, onClick: () => {
                onIncrease();
            } },
            React.createElement(Icon, { svg: plus, size: "medium" }))));
}

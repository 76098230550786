import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { canDeliver as _canDeliver, deadlinePassed as _deadlinePassed, } from 'utilities/distribution';
import { getOffset, getWeekDays } from '../../utils/calendar';
import './Calendar.scss';
export function Calendar({ selectedDeliveryDate, setDate }) {
    const dates = useSelector((state) => state.distribution.dates);
    const deadline = useSelector((state) => state.distribution.deadline);
    const offset = dates.length > 0 ? getOffset(dates[0].date) : 0;
    const weekDays = getWeekDays();
    return (React.createElement("div", { className: "calendar" },
        React.createElement("div", { className: "calendar__days-of-week" }, weekDays.map((d, index) => {
            return (React.createElement("div", { className: "calendar__day-of-week", key: index }, d));
        })),
        React.createElement("div", { className: "calendar__days" }, dates.map(({ date, canDeliver, capacityStatus }, index) => {
            const dayAvailable = _canDeliver({ date, canDeliver }, deadline);
            const noCapacity = !canDeliver && capacityStatus === 'FULL';
            const deadlinePassed = canDeliver && _deadlinePassed(date, deadline);
            const daySelected = moment(date).isSame(selectedDeliveryDate, 'day');
            const classes = classNames({
                calendar__day: true,
                'calendar__day--active': daySelected && dayAvailable,
                'calendar__day--unavailable': !dayAvailable && !noCapacity && !deadlinePassed,
                'calendar__day--no-capacity': noCapacity,
                'calendar__day--deadline-passed': deadlinePassed,
                [`calendar__day--offset-${offset}`]: index === 0,
            });
            return (React.createElement("div", { key: date, className: classes },
                React.createElement("button", { type: "button", disabled: daySelected || !dayAvailable, onClick: () => dayAvailable &&
                        !daySelected &&
                        setDate(moment(date).format('YYYY-MM-DD')) }, moment(date).format('D'))));
        })),
        React.createElement("div", { className: "calendar__legend" },
            React.createElement("span", { className: "calendar__legend-item calendar__legend-item--available" }, "Ledig"),
            React.createElement("span", { className: "calendar__legend-item calendar__legend-item--unavailable" }, "Ikke levering"),
            React.createElement("span", { className: "calendar__legend-item calendar__legend-item--no-capacity" }, "Fullbooket"))));
}

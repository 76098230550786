import Icon from 'components/UI/Icon';
import React from 'react';
import './CheckoutSubPageHeader.scss';
const closeIcon = require('img/close-icon.svg');
const backIcon = require('img/chevron-right-tall.svg');
function CheckoutSubPageHeader({ title, onClose }) {
    return (React.createElement("div", { className: "checkout-sub-page__header" },
        React.createElement("button", { className: "checkout-sub-page__header__close-button checkout-sub-page__header__close-button--desktop", onClick: onClose },
            React.createElement(Icon, { svg: backIcon })),
        React.createElement("h1", { className: "checkout-sub-page__header__title" }, title),
        React.createElement("button", { className: "checkout-sub-page__header__close-button checkout-sub-page__header__close-button--mobile", onClick: onClose },
            React.createElement(Icon, { svg: closeIcon }))));
}
export default CheckoutSubPageHeader;

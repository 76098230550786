import classNames from 'classnames';
import Icon from 'components/UI/Icon';
import React from 'react';
import './Checkbox.scss';
const Checkmark = require('img/checkmark.svg');
function Checkbox({ isChecked, setIsChecked, className, size = 'large', }) {
    const checkboxClasses = classNames({
        'common-checkbox__custom': true,
        'common-checkbox__custom--selected': isChecked,
        [`common-checkbox--${size}`]: true,
    });
    return (React.createElement("label", { className: `common-checkbox ${!!className ? className : ''}` },
        React.createElement("span", { className: checkboxClasses, onClick: setIsChecked ? () => setIsChecked(!isChecked) : undefined }, isChecked ? React.createElement(Icon, { svg: Checkmark, size: size }) : null)));
}
export default Checkbox;

import { goBack, push } from 'connected-react-router';
export function navigateBack(dispatch, history, location) {
    const historyIsEmpty = history.action === 'POP';
    if (historyIsEmpty) {
        dispatch(push('/'));
        return;
    }
    if (location.pathname.startsWith('/my-page/order/')) {
        dispatch(push('/my-page/orders'));
        return;
    }
    if (location.pathname === '/my-page/orders') {
        dispatch(push('/my-page'));
        return;
    }
    if (location.pathname === '/my-page') {
        dispatch(push('/'));
        return;
    }
    dispatch(goBack());
}

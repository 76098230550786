import { initAmplitudeDeviceId } from 'utilities/init';
export function setCookie(cName, cValue, expDays) {
    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/';
}
export function getCookie(cName) {
    const name = cName + '=';
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach((val) => {
        if (val.indexOf(name) === 0)
            res = val.substring(name.length);
    });
    return res;
}
export function acceptAll() {
    return (dispatch, getState) => {
        setCookie('_cookieConsent', 'all', 90);
        gtag('consent', 'update', {
            ad_storage: 'granted',
            ad_personalization: 'granted',
            ad_user_data: 'granted',
            analytics_storage: 'granted',
            functionality_storage: 'granted',
        });
        initAmplitudeDeviceId(getState().personalInfo.email);
        dispatch({ type: 'CONSENT_ALL' });
    };
}
export function rejectAll() {
    return (dispatch) => {
        setCookie('_cookieConsent', 'none', 90);
        gtag('consent', 'update', {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'denied',
            functionality_storage: 'denied',
        });
        dispatch({ type: 'CONSENT_REJECT' });
    };
}
export function acceptAnalytics() {
    return (dispatch, getState) => {
        setCookie('_cookieConsent', 'analytics', 90);
        gtag('consent', 'update', {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'granted',
            functionality_storage: 'granted',
        });
        initAmplitudeDeviceId(getState().personalInfo.email);
        dispatch({ type: 'CONSENT_ANALYTICS' });
    };
}
export function acceptAds() {
    return (dispatch) => {
        setCookie('_cookieConsent', 'ad', 90);
        gtag('consent', 'update', {
            ad_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            analytics_storage: 'denied',
            functionality_storage: 'granted',
        });
        dispatch({ type: 'CONSENT_ADS' });
    };
}

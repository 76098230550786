import './style.scss'

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class ReportMissingAddress extends Component {
    render() {
        const { address, email } = this.props
        const addressString = `${address.streetName} ${address.streetNo} ${address.zip} ${address.city}`
        return (
            <a
                className="report-missing-address"
                href={`mailto:${email}?subject=Adressesjekk%20Morgenlevering%20-%20${addressString}&body=Hei,%20jeg%20ønsker%20å%20bestille%20til%20denne%20adressen%20${addressString},%20men%20jeg%20får%20melding%20om%20at%20det%20ikke%20kan%20leveres%20til%20den.%20Kan%20dere%20vurdere%20adressen%20på%20nytt?`}
            >
                Ønsker du at vi skal vurdere levering til denne adressen?
            </a>
        )
    }
}

ReportMissingAddress.propTypes = {
    address: PropTypes.object,
    email: PropTypes.string,
}

function mapStateToProps({ address }) {
    return {
        address: address.active,
        email: process.env.SUPPORT_EMAIL,
    }
}

export default connect(mapStateToProps)(ReportMissingAddress)

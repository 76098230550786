import { Card, LinkBox } from 'common';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDateAndMonth, getDateAsText } from 'utilities/date';
import './DeliveryDateCard.scss';
function DeliveryDateCard() {
    const deliveryDate = useSelector((state) => state.checkout.deliveryDate);
    const deliveryDateText = getDateAsText(deliveryDate);
    const dispatch = useDispatch();
    return (React.createElement(Card, { title: "Leveringsdag" },
        React.createElement("div", { className: "delivery-date-card__date-text" }, `${deliveryDateText}, ${getDateAndMonth(deliveryDate)}`),
        React.createElement(LinkBox, { text: "Endre dato", onClick: () => {
                dispatch(push('/checkout/delivery-date'));
            }, colorStyle: "gray" })));
}
export default DeliveryDateCard;

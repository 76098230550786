const baseState = {
    searchValues: {},
    streets: [],
    numbers: [],
    floors: [],
    households: [],
    streetIds: [],
    isFinished: false,
    unknownAddress: false,
    addressId: null,
    deliveryPointId: null,
    streetNoDeliveryPointId: null,
    streetName: null,
    streetNo: null,
    showHouseholds: false,
    entrance: null,
    floorNo: null,
    floorType: null,
    flatNo: null,
    co: null,
    zip: null,
    city: null,
    countryCode: null,
    formAutoNavigate: false,
    navigateFromField: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    lastRenderedLocation: null,
}

export default function reducer(
    state = {
        ...baseState,
    },
    action
) {
    switch (action.type) {
        case 'SET_ADDRESS_HELPER_STATE':
            return {
                ...state,
                ...action.payload,
            }
        case 'RESET_ADDRESS_FORM':
            return {
                ...baseState,
            }
        case 'TOGGLE_EDIT_ADDRESS':
            return {
                ...state,
                ...baseState,
            }
        case 'FETCH_STREET_ADDRESS_SUCCESS':
            return {
                ...state,
                streets: action.payload.data.streets,
                numbers: [],
                floors: [],
                households: [],
                isFinished: false,
                unknownAddress: action.payload.data.totalResults === 0,
            }
        case 'FETCH_STREET_NUMBERS_SUCCESS':
            return {
                ...state,
                numbers: action.payload.data.streetNumbers,
                floors: [],
                households: [],
                isFinished: false,
                unknownAddress: action.payload.data.streetNumbers.length === 0,
            }
        case 'FETCH_STREET_NUMBERS_FAILED':
            return {
                ...state,
                unknownAddress: true,
            }
        case 'FETCH_FLOORS_SUCCESS':
            const floors = action.payload.data
            const isFinished = floors.length < 1
            return { ...state, floors: floors, isFinished: isFinished }
        case 'FETCH_FLOORS_FAILED':
            return {
                ...state,
                unknownAddress: true,
            }
        case 'FETCH_HOUSEHOLDS_SUCCESS':
            return { ...state, households: action.payload.data }
        case 'FETCH_HOUSEHOLDS_FAILED':
            return {
                ...state,
                unknownAddress: true,
            }
        case 'SET_STREET_ADDRESS': {
            const street = action.payload

            return {
                ...state,
                streetIds: street.streetIds,
                isFinished: false,
                verifyAddressResponse: {},
                streetName: street.streetName,
                city: street.city,
                countryCode: street.countryCode,
                addressId: null,
                streetNo: null,
                showHouseholds: false,
                entrance: null,
                zip: null,
                deliveryPointId: null,
                streetNoDeliveryPointId: null,
                floorNo: null,
                floorType: null,
                flatNo: null,
                numbers: [],
                searchValues: {
                    ...state.searchValues,
                    ['streetno']: null,
                    ['floor']: null,
                    ['flat']: null,
                },
            }
        }
        case 'SET_STREET_NUMBER': {
            const number = action.payload

            return {
                ...state,
                addressId: number.addressId,
                verifyAddressResponse: {},
                streetNo: number.streetNo,
                showHouseholds: number.showHouseholds,
                isFinished: !number.showHouseholds,
                entrance: number.entrance,
                zip: number.postalCode,
                deliveryPointId: number.deliveryPointId,
                streetNoDeliveryPointId: number.deliveryPointId,
                floorNo: null,
                floorType: null,
                flatNo: null,
                searchValues: {
                    ...state.searchValues,
                    ['floor']: null,
                    ['flat']: null,
                },
            }
        }
        case 'SET_FLOOR': {
            const floor = action.payload

            return {
                ...state,
                verifyAddressResponse: {},
                floorNo: floor.floorNo,
                floorType: floor.floorType,
                isFinished: false,
                flatNo: null,
                searchValues: {
                    ...state.searchValues,
                    ['flat']: null,
                },
            }
        }
        case 'SET_HOUSEHOLD': {
            const household = action.payload
            if (household == null) return state

            return {
                ...state,
                isFinished: true,
                verifyAddressResponse: {},
                flatNo: household.flatNo,
                deliveryPointId: household.deliveryPointId,
            }
        }
        case 'SET_FIRST_NAME': {
            return {
                ...state,
                firstName: action.payload,
            }
        }
        case 'SET_LAST_NAME': {
            return {
                ...state,
                lastName: action.payload,
            }
        }
        case 'SET_PHONE_NUMBER': {
            return {
                ...state,
                phoneNumber: action.payload,
            }
        }
        case 'SET_CO':
            return {
                ...state,
                co: action.payload,
            }
        case 'SEARCH_ADDRESS':
            return {
                ...state,
                searchValues: { ...state.searchValues, ...action.payload },
            }
        case 'USER_LOGOUT':
            return { ...baseState }
        default:
            return state
        case 'SET_LAST_RENDERED_LOCATION':
            return {
                ...state,
                lastRenderedLocation: action.payload,
            }
    }
}

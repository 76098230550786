export default function reducer(
    state = {
        enabled: {},
    },
    action
) {
    switch (action.type) {
        case 'ENABLE_FEATURE':
            const enabled = { ...state.enabled }
            enabled[action.payload] = true
            return {
                ...state,
                enabled,
            }
        default:
            return state
    }
}

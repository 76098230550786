var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, ButtonRow, useOnClickOutside } from 'common';
import Icon from 'components/UI/Icon';
import React, { useState } from 'react';
import { isValidMobile } from 'utilities/validators';
import { floorSearch, householdSearch, streetNumberSearch, streetSearch, } from 'actions/AddressHelper';
import { v4 } from 'uuid';
import { useDispatch } from 'react-redux';
import './FindAddressButton.scss';
import { AddressSearch } from '../addressSearch';
import classNames from 'classnames';
import { splitFullName } from 'utilities/address';
const MagicWand = require('img/navigationIcons/occasions.svg');
const Warning = require('img/warning_triangle.svg');
export const FindAddressButton = (props) => {
    const dispatch = useDispatch();
    const [dropdownOptions, setDropdownOptions] = useState(null);
    const dropdrownRef = React.createRef();
    useOnClickOutside(dropdrownRef, () => setDropdownOptions(null));
    const onOptionSelected = (address) => __awaiter(void 0, void 0, void 0, function* () {
        const phone = !isValidMobile((props === null || props === void 0 ? void 0 : props.phoneNumber) || '') && address.phoneNumber.length > 0
            ? address.phoneNumber
            : props.phoneNumber || '';
        setDropdownOptions(null);
        const data = yield getAddressHelperData(address, phone);
        if (data) {
            dispatch({ type: 'SET_ADDRESS_HELPER_STATE', payload: data });
        }
        else {
            dispatch({ type: 'ADDRESS_SEARCH_FAILED' });
        }
        props.onAddressSelected(Object.assign(Object.assign({}, address), { phoneNumber: phone }));
    });
    const search = () => {
        if (!props.fullName && !props.phoneNumber)
            return;
        AddressSearch.search({
            fullName: props.fullName,
            phoneNumber: props.phoneNumber || '',
        }, (res) => {
            if (res.length == 0) {
                setDropdownOptions({
                    message: 'Fant ingen adresser som matcher søket. Fyll inn adressen under.',
                });
            }
            else if (res.length == 1) {
                onOptionSelected(res[0]);
            }
            else {
                selectResult(res);
            }
        });
    };
    const selectResult = (results) => {
        setDropdownOptions({ options: results });
    };
    const classes = classNames('address-search-button', {
        'address-search-button__expanded': dropdownOptions != null,
    });
    return (React.createElement("div", { className: classes, ref: dropdrownRef },
        React.createElement(ButtonRow, { display: 'stretch' },
            React.createElement(Button, { style: 'secondary', type: 'action', onClick: search },
                React.createElement(Icon, { size: 'small', svg: MagicWand }),
                "Automatisk adresses\u00F8k")),
        React.createElement("div", { className: 'address-search-button__dropdown-animated' },
            dropDownOptionsIsSuccess(dropdownOptions) && (React.createElement("div", { className: 'address-search-button__dropdown' },
                dropdownOptions.options.slice(0, 3).map((res) => (React.createElement(DropdownOption, { key: v4(), res: res, onClick: onOptionSelected }))),
                React.createElement("div", { onClick: () => setDropdownOptions(null), className: 'address-search-button__dropdown__footer' }, "Fant ikke den du lette etter? Fyll inn adressen under."))),
            dropDownOptionsIsFailure(dropdownOptions) && (React.createElement("div", { className: 'address-search-button__dropdown' },
                React.createElement("div", { onClick: () => setDropdownOptions(null), className: 'address-search-button__dropdown__footer' },
                    React.createElement(Icon, { svg: Warning, size: 'small' }),
                    dropdownOptions.message))))));
};
const dropDownOptionsIsSuccess = (options) => {
    return options != null && options.options != null;
};
const dropDownOptionsIsFailure = (options) => {
    return options != null && options.message != null;
};
const DropdownOption = (props) => {
    const { res, onClick } = props;
    return (React.createElement("div", { className: 'address-search-button__dropdown__option', onClick: () => {
            onClick(props.res);
        } },
        React.createElement("div", { className: 'address-search-button__dropdown__option__name' }, res.fullName),
        React.createElement("div", { className: 'address-search-button__dropdown__option__address' },
            res.streetName,
            " ",
            res.houseNumber,
            res.entrance,
            " ",
            res.apartmentNumber),
        React.createElement("div", { className: 'address-search-button__dropdown__option__address' },
            res.postalCode,
            " ",
            res.city)));
};
const getAddressHelperData = (data, phone) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    try {
        // streetSearch
        const streetRes = yield streetSearch(`${data.streetName}, ${data.city}`, null);
        const street = streetRes.data.streets.find((it) => normalizeStreetName(it.streetName) === normalizeStreetName(data.streetName) &&
            it.city.toLowerCase() === data.city.toLowerCase());
        // streetNumberSearch
        const streetNumberQuery = (_a = data === null || data === void 0 ? void 0 : data.houseNumber) !== null && _a !== void 0 ? _a : '';
        const streetNumberRes = yield streetNumberSearch(streetNumberQuery, street.streetIds);
        const streetNumbers = streetNumberRes.data.streetNumbers;
        let streetNumber;
        if (!data.entrance &&
            streetNumbers.length > 1 &&
            !streetNumbers.find((it) => it.entrance == null)) {
            // datafactory didnt give an entrance, so we assume entrance is A
            streetNumber = streetNumbers.find((it) => { var _a, _b; return it.streetNo == data.houseNumber && ((_b = (_a = it.entrance) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '') == 'a'; });
        }
        else {
            streetNumber = streetNumbers.find((it) => {
                var _a, _b, _c, _d;
                return it.streetNo == data.houseNumber &&
                    ((_b = (_a = it.entrance) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '') == ((_d = (_c = data.entrance) === null || _c === void 0 ? void 0 : _c.toLowerCase()) !== null && _d !== void 0 ? _d : '');
            });
        }
        // floorSearch
        const deliveryPointId = (_b = streetNumber === null || streetNumber === void 0 ? void 0 : streetNumber.deliveryPointId) !== null && _b !== void 0 ? _b : 0;
        let householdData = {
            floorNo: null,
            floorType: null,
            floors: null,
            flatNo: null,
            households: null,
        };
        if ((streetNumber === null || streetNumber === void 0 ? void 0 : streetNumber.showHouseholds) && data.apartmentNumber) {
            const floorType = data.apartmentNumber.substring(0, 1);
            const floorNo = parseInt(data.apartmentNumber.substring(1, 3));
            const floorRes = yield floorSearch(deliveryPointId);
            const householdRes = yield householdSearch(deliveryPointId, floorType, floorNo);
            householdData = {
                floorNo,
                floorType,
                floors: floorRes.data,
                flatNo: parseInt(data.apartmentNumber.substring(3, 5)),
                households: householdRes.data,
            };
        }
        const { firstName, lastName } = splitFullName(data.fullName);
        return Object.assign(Object.assign({}, householdData), { deliveryPointId: deliveryPointId, showHouseholds: streetNumber.showHouseholds, countryCode: street.countryCode, streets: streetRes.data.streets, numbers: streetNumbers, phoneNumber: phone, firstName: firstName, lastName: lastName, streetName: street.streetName, streetNo: streetNumber.streetNo, entrance: streetNumber.entrance, zip: streetNumber.postalCode, city: street.city, isFinished: true, searchValues: {
                streetname: street.streetName,
                streetno: streetNumber.streetNo + (streetNumber.entrance || ''),
                floor: (householdData === null || householdData === void 0 ? void 0 : householdData.floorNo) ? householdData.floorNo + '. etasje' : null,
                flat: (householdData === null || householdData === void 0 ? void 0 : householdData.flatNo) ? data.apartmentNumber : null,
            } });
    }
    catch (e) {
        if (process.env.ENVIRONMENT != 'production') {
            console.error(e);
        }
        return undefined;
    }
});
function normalizeStreetName(streetName) {
    return streetName
        .toLowerCase()
        .normalize()
        .replace(/[^\w\s]/gi, '')
        .replace(/\s+/g, ' ')
        .trim();
}

import groq from 'groq';
import React, { useEffect, useState } from 'react';
import sanityClient from 'utilities/sanityClient';
import ArticleCarousel from './ArticleCarousel';
export function DynamicArticleCarousel({ documentId }) {
    const [articleEntry, setArticleEntry] = useState();
    useEffect(() => {
        const query = groq `
            *[ _id == $documentId ][0] {
                title,
                subtitle,
                mobileSubtitle,
                target,
                targetText,
                articles[] -> {
                    "image": mainImage,
                    "slug": slug.current,
                    preamble,
                    title
                }
            }`;
        const minimizedQuery = query.replace(/[\s\n]/, '');
        let isSubscribed = true;
        sanityClient
            .fetch(minimizedQuery, { documentId })
            .then((document) => (isSubscribed ? setArticleEntry(document) : null));
        return () => {
            isSubscribed = false;
        };
    }, [documentId]);
    return (articleEntry && React.createElement(ArticleCarousel, Object.assign({}, articleEntry))) || null;
}

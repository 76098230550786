import { EditAddressForm, NewMainAddress, SelectableAddressItem } from 'address';
import { Modal } from 'common';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { addressIsEqual } from 'utilities/address';
import './RecipientList.scss';
function RecipientList({ recipients, handleSelect, currentlySelectedRecipients, handleNewPrimaryAddress, }) {
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const currentRegion = useSelector((state) => state.region);
    const [editAddress, setEditAddress] = useState();
    const [newMainAddressModalOpen, setNewMainAddressModalOpen] = useState(false);
    const [focusPhoneInput, setFocusPhoneInput] = useState(false);
    const handleDeletePrimaryAddress = () => {
        setNewMainAddressModalOpen(true);
    };
    const openNewPrimaryAddress = () => {
        handleNewPrimaryAddress();
    };
    return (React.createElement("div", { className: 'recipient-list' },
        newMainAddressModalOpen && (React.createElement(Modal, { className: "recipient-list__new-primary-modal", onClose: () => setNewMainAddressModalOpen(false) },
            React.createElement(NewMainAddress, { addresses: recipients, closeModal: () => setNewMainAddressModalOpen(false), openNewPrimaryAddress: openNewPrimaryAddress, shouldDeleteCurrentPrimary: true }))),
        editAddress && (React.createElement(EditAddressForm, { addressToEdit: editAddress, closeModal: () => setEditAddress(undefined), focusPhoneInput: focusPhoneInput })),
        recipients &&
            sortBy(recipients, (r) => { var _a; return (_a = r.deliveryAddress.firstName) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase(); }).map((recipient, index) => (React.createElement(SelectableAddressItem, { key: index, address: recipient, onSelect: () => (recipient === null || recipient === void 0 ? void 0 : recipient.deliveryAddress.mobile) && handleSelect(recipient), isSelected: currentlySelectedRecipients.some((x) => addressIsEqual(x === null || x === void 0 ? void 0 : x.deliveryAddress, recipient.deliveryAddress)), multi: orderType === 'group', discourage: orderType === 'group' &&
                    (recipient === null || recipient === void 0 ? void 0 : recipient.deliveryAddress.transportProduct) !==
                        currentRegion.transportProduct, onEdit: (focusPhone) => {
                    setFocusPhoneInput(focusPhone || false);
                    setEditAddress(recipient);
                }, discourageReason: "Denne adressen er ikke i valgt region", onPrimaryAddressDelete: handleDeletePrimaryAddress })))));
}
export default RecipientList;

import React from 'react';
import { NavLink } from 'react-router-dom';
import './BlogPostNotFound.scss';
function BlogPostNotFound() {
    return (React.createElement("section", { className: "blog-post-not-found" },
        React.createElement("h1", { className: "blog-post-not-found__heading" }, "Fant ikke artikkelen"),
        React.createElement("p", { className: "blog-post-not-found__lead" },
            "Kanskje du finner noe annet du liker",
            ' ',
            React.createElement(NavLink, { to: "/magasinet", className: "blog-post-not-found__lead__link" }, "blant de andre artiklene"),
            "?")));
}
export default BlogPostNotFound;

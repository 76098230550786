import axios from 'axios'
import { handleNetworkError } from 'actions/Error'
import { push } from 'connected-react-router'

export function gotoSubscriptionInfoOrOverview() {
    return (dispatch, getState) => {
        const subscriptionOverviewPage = '/subscription'
        const subscriptionInfoPage = '/fast-levering'
        const hasSubscriptionProducts = !!getState().subscription.products.length
        const hasSubscription = getState().subscription.hasSubscription
        const isLoggedIn = getState().user.isLoggedIn

        if (!isLoggedIn) {
            dispatch(push(subscriptionInfoPage))
        } else {
            if (hasSubscriptionProducts || hasSubscription) {
                dispatch(push(subscriptionOverviewPage))
            } else {
                dispatch(push(subscriptionInfoPage))
            }
        }
    }
}

export function gotoSubscriptionPausePage() {
    return (dispatch, getState) => {
        if (getState().router.location.pathname !== '/subscriptionPause') {
            dispatch(push('/subscriptionPause'))
        }
    }
}

export function setSubscriptionMode(subscription) {
    return { type: 'SET_SUBSCRIPTION_MODE', payload: subscription }
}

export function fetchSubscription(onCompleted) {
    return (dispatch, getState) => {
        if (!getState().user.isLoggedIn) return
        getSubscriptions(dispatch, onCompleted)
    }
}

function getSubscriptions(dispatch, onCompleted) {
    dispatch({ type: 'FETCH_SUBSCRIPTION' })
    axios
        .get(`${process.env.CORE_API}/subscription`)
        .then((response) => {
            dispatch({
                type: 'FETCH_SUBSCRIPTION_SUCCESS',
                payload: response.data,
            })
            onCompleted && onCompleted()
        })
        .catch((err) => {
            // 404 code indicates no subscription for user (should rather be 204 from backend)
            if (err && err.response && err.response.status == 404) {
                dispatch({ type: 'FETCH_SUBSCRIPTION_NO_SUBSCRIPTION', payload: null })
                onCompleted && onCompleted()
            } else {
                dispatch({ type: 'FETCH_SUBSCRIPTION_FAILED', payload: err })
                dispatch(handleNetworkError(err))
                onCompleted && onCompleted()
            }
        })
}

export function getStopDates() {
    return (dispatch) => {
        dispatch({
            type: 'GET_STOP_DATES',
        })
        axios
            .get(`${process.env.CORE_API}/subscription/stopDates`, {})
            .then((response) => {
                dispatch({
                    type: 'GET_STOP_DATES_SUCCESS',
                    payload: response.data,
                })
            })
            .catch((err) => {
                dispatch({ type: 'GET_STOP_DATES_FAILED', payload: err })
            })
    }
}

export function pauseSubscription(startDate, endDate) {
    return (dispatch) => {
        dispatch({
            type: 'PAUSE_SUBSCRIPTION',
        })
        axios
            .post(`${process.env.CORE_API}/subscription/stopDates`, {
                startDate: startDate,
                endDate: endDate,
            })
            .then((response) => {
                dispatch({
                    type: 'PAUSE_SUBSCRIPTION_SUCCESS',
                    payload: response.data,
                })
                dispatch(push('/subscription'))
            })
            .catch((err) => {
                dispatch({ type: 'PAUSE_SUBSCRIPTION_FAILED', payload: err })
            })
    }
}

export function unpauseSubscription() {
    return (dispatch) => {
        dispatch({
            type: 'UNPAUSE_SUBSCRIPTION',
        })
        axios
            .post(`${process.env.CORE_API}/subscription/stopDates`, {})
            .then((response) => {
                dispatch({
                    type: 'UNPAUSE_SUBSCRIPTION_SUCCESS',
                    payload: response.data,
                })
                dispatch(push('/subscription'))
            })
            .catch((err) => {
                dispatch({ type: 'UNPAUSE_SUBSCRIPTION_FAILED', payload: err })
            })
    }
}

export function increaseWeekday(product, weekday) {
    return {
        type: 'INCREASE_SUBSCRIPTION_WEEKDAY',
        payload: {
            product,
            weekday,
        },
    }
}

export function decreaseWeekday(product, weekday) {
    return {
        type: 'DECREASE_SUBSCRIPTION_WEEKDAY',
        payload: {
            product,
            weekday,
        },
    }
}

export function editProduct(product) {
    return {
        type: 'BEGIN_EDIT_SUBSCRIPTION',
        payload: { productId: product.id },
    }
}

export function saveSubscriptions(dispatch, subscriptionState, onError) {
    if (subscriptionState.hasSubscription) {
        const payload = subscriptionState.products.filter((p) => !p.isDraft)
        axios
            .put(`${process.env.CORE_API}/subscription`, payload)
            .then(() => {
                getSubscriptions(dispatch)
                dispatch({ type: 'COMMIT_EDITED_SUBSCRIPTION_SUCCESS' })
            })
            .catch((err) => {
                getSubscriptions(dispatch, onError && onError(err))
            })
    }
}

export function saveChanges(product) {
    return (dispatch, getState) => {
        dispatch({ type: 'COMMIT_EDITED_SUBSCRIPTION', payload: product })
        saveSubscriptions(dispatch, getState().subscription, (err) => {
            dispatch({ type: 'UPDATE_SUBSCRIPTION_FAILED', payload: err })
        })
    }
}

export function cancelChanges(product) {
    return { type: 'ROLLBACK_EDITED_SUBSCRIPTION', payload: product }
}

export function addProductToSubscription(product, replace = false) {
    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_PRODUCT_TO_SUBSCRIPTION',
            payload: {
                product,
                replace,
            },
        })
        if (replace) {
            // We need to save since we have just cleaned out previous subscriptions
            // SUB-2040
            saveSubscriptions(dispatch, getState().subscription)
        }
    }
}

export function removeProduct(product) {
    return (dispatch, getState) => {
        const subscriptionState = getState().subscription
        const productIsInDraft = !subscriptionState.products.some(
            (subscriptionProduct) => subscriptionProduct.id == product.id
        )
        dispatch({
            type: 'REMOVE_PRODUCT_FROM_SUBSCRIPTION',
            payload: { data: getState().subscription, product },
        })
        if (!productIsInDraft) {
            saveSubscriptions(dispatch, getState().subscription, (err) => {
                dispatch({
                    type: 'REMOVE_PRODUCT_FROM_SUBSCRIPTION_FAILED',
                    payload: err,
                })
            })
        }
    }
}

export function activateSubscription(product, replace = false) {
    return (dispatch) => {
        dispatch(
            toggleSubscription(true, () => {
                if (product) {
                    dispatch(addProductToSubscription(product, replace))
                }
            })
        )
    }
}

export function deactivateSubscription() {
    return toggleSubscription(false)
}

function toggleSubscription(activate, onComplete) {
    return (dispatch, getState) => {
        if (!getState().user.isLoggedIn) return
        dispatch({ type: 'TOGGLE_SUBSCRIPTION' })
        if (!activate) {
            dispatch({ type: 'SET_SUBSCRIPTION_MODE', payload: activate })
        }
        axios
            .post(`${process.env.CORE_API}/subscription/toggleStatus`)
            .then(() => {
                dispatch({
                    type: 'TOGGLE_SUBSCRIPTION_SUCCESS',
                    payload: { activate },
                })
                getSubscriptions(dispatch, onComplete)
            })
            .catch((err) => {
                dispatch({ type: 'TOGGLE_SUBSCRIPTION_FAILED', payload: err })
                dispatch(handleNetworkError(err))
            })
    }
}

import { removeCouponCode, setCouponCode } from 'actions/Coupon';
import { Button } from 'common';
import CheckboxWithLabel from 'common/components/CheckboxWithLabel/CheckboxWithLabel';
import NewTextInput from 'common/components/TextInput/NewTextInput';
import Form from 'components/UI/Form';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import './CheckoutCouponCode.scss';
import { checkCartCore } from '../../cart/actions/cartActions';
function CheckoutCouponCode({ code, errorCode, className }) {
    const dispatch = useDispatch();
    const codeIsValid = useSelector((state) => state.coupon.codeIsValid);
    const discountCode = useSelector((state) => { var _a; return (_a = state.coupon.discount) === null || _a === void 0 ? void 0 : _a.code; });
    const [showInputField, setShowInputField] = useState(discountCode != null || codeIsValid);
    const setUseCouponCode = (newState) => {
        setShowInputField(newState);
        if (!newState) {
            dispatch(removeCouponCode());
        }
    };
    const textInputState = codeIsValid ? 'disabled' : errorCode ? 'invalid' : 'incomplete';
    const errorMessage = (errorCode) => {
        switch (errorCode) {
            case 'couponError_missingUserData':
                return 'E-postadressen må legges inn før rabattkoden kan godkjennes';
            case 'couponError_couponInvalidCode':
                return 'Ugyldig rabattkode';
            case 'couponError_couponAuthenticatedOnly':
                return 'Du må logge inn for å bruke denne rabattkoden.';
            case 'couponError_couponExpired':
                return 'Rabattkoden er utløpt.';
            case 'couponError_couponNotActiveYet':
                return 'Rabattkoden er ikke aktiv enda.';
            case 'couponError_couponLimitExceeded':
                return 'Rabattkoden er brukt opp.';
            case 'couponError_couponUserLimitExceeded':
                return 'Rabattkoden er brukt opp.';
            default:
                return errorCode;
        }
    };
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "checkoutCouponCode--checkbox-container" },
            React.createElement(CheckboxWithLabel, { isChecked: showInputField, setIsChecked: setUseCouponCode, labelText: "Bruk rabattkode" })),
        showInputField && (React.createElement(Form, { onSubmit: () => {
                if (!codeIsValid) {
                    dispatch(checkCartCore());
                }
                else {
                    dispatch(removeCouponCode());
                }
            }, className: "checkoutCouponCode--form" },
            React.createElement(NewTextInput, { inputState: textInputState, className: "checkoutCouponCode--input", value: codeIsValid ? discountCode : code, onChange: (val) => dispatch(setCouponCode(val)), errorMessage: errorCode ? errorMessage(errorCode) : 'Rabattkode må fylles ut' }),
            React.createElement(Button, { type: "submit", style: "secondary", className: "checkoutCouponCode--button" }, codeIsValid ? 'Endre' : 'Legg til')))));
}
const mapStateToProps = ({ coupon }) => {
    return {
        code: coupon.code,
        valid: coupon.codeIsValid,
        checked: coupon.codeChecked,
        errorCode: coupon.errorCode,
    };
};
export default connect(mapStateToProps)(CheckoutCouponCode);

import findIndex from 'lodash/findIndex'
import moment from 'moment'

export const getSubscriptionWithProductInfo = (subscription, products) => {
    return subscription.products
        .map((s) => ({
            id: s.id.toString(),
            total: getTotalSum(s, subscription.editProducts),
            totalQuantity: s.weekdayQuantities.reduce((accum, obj) => accum + obj.quantity, 0),
            subscription: getCorrectProduct(s, subscription.editProducts),
            edit:
                subscription.editProducts && subscription.editProducts.some((ep) => ep.id === s.id),
            product: products.find((p) => p.id.toString() === s.id.toString()),
            changed: elementsHaveChanged(s, subscription.editProducts, subscription.products),
            canCancel: elementCanBeCancelled(s, subscription.editProducts, subscription.products),
            orderDays: s.weekdayQuantities
                .map((wq) => ({
                    weekday: wq.weekday,
                    quantity: wq.quantity,
                }))
                .filter((od) => od.quantity > 0)
                .sort((a, b) => a.weekday - b.weekday),
        }))
        .filter((n) => n.product)
}

const elementCanBeCancelled = (s, _, products) => {
    return products.some(
        (p) =>
            p.id.toString() === s.id.toString() &&
            products
                .find((p) => p.id.toString() === s.id.toString())
                .weekdayQuantities.some((wq) => wq.quantity > 0)
    )
}

const getCorrectProduct = (s, editProducts) =>
    editProducts && editProducts.some((ep) => ep.id === s.id)
        ? editProducts.find((ep) => ep.id === s.id)
        : s

const getTotalSum = (subscription, editProducts) => {
    if (editProducts && editProducts.some((ep) => ep.id === subscription.id)) {
        return +(
            editProducts.find((ep) => ep.id === subscription.id).netAmount +
            editProducts.find((ep) => ep.id === subscription.id).vatAmount
        ).toFixed(2)
    }

    return +(subscription.netAmount + subscription.vatAmount).toFixed(2)
}

const elementsHaveChanged = (subscription, editProducts, products) => {
    if (editProducts && editProducts.some((ep) => ep.id === subscription.id)) {
        const e1 = editProducts.find((ep) => ep.id === subscription.id)
        const e2 = products.find((p) => p.id === subscription.id)
        return !elementsAreEqual(e1, e2)
    }
    return false
}

const elementsAreEqual = (e1, e2) => {
    const e1Filtered = e1.weekdayQuantities.filter((item) => item && item.quantity > 0)
    const e2Filtered = e2.weekdayQuantities.filter((item) => item && item.quantity > 0)
    const weekdayQuantitiesAreEqual =
        e1Filtered.length === e2Filtered.length &&
        !e1Filtered
            .map((wq) =>
                e2Filtered.find((wq2) => wq2.weekday === wq.weekday && wq2.quantity === wq.quantity)
                    ? true
                    : false
            )
            .some((val) => val === false)
    const idsAreEqual = e1.id === e2.id
    return weekdayQuantitiesAreEqual && idsAreEqual
}

export const getContainsSubscriptions = (subscriptions) => {
    return subscriptions.products.some(
        (s) =>
            s.weekdayQuantities &&
            s.weekdayQuantities.length > 0 &&
            s.weekdayQuantities.some((wq) => wq.quantity > 0)
    )
}

export const isEditMode = (subscription) =>
    subscription.editProducts && subscription.editProducts.length > 0

export const calculateBadgeCount = (subscription) => {
    const badgeCount = subscription.products.filter(
        (product) => product.weekdayQuantities && product.weekdayQuantities.length === 0
    )

    return badgeCount.length
}

export function addProduct(products, product) {
    const index = findIndex(products, (p) => p.id.toString() === product.id.toString())
    if (index >= 0) return products

    return [...products, makeProductSubscription(product)]
}

export function removeProduct(products, productId) {
    return products.filter((p) => p.id.toString() !== productId.toString())
}

function ensureEditProducts(products, editProducts) {
    return editProducts.filter((ep) => products.some((p) => p.id.toString() === ep.id.toString()))
}

export function addEditProduct(editProducts, products, productId) {
    const editIndex = findIndex(editProducts, (p) => p.id.toString() === productId.toString())
    if (editIndex >= 0) return ensureEditProducts(products, editProducts)

    const index = findIndex(products, (p) => p.id.toString() === productId.toString())
    const ans = [...ensureEditProducts(products, editProducts), { ...products[index] }]
    return ans
}

export function removeEditProduct(products, productId) {
    return ensureEditProducts(
        products,
        products.filter((p) => p.id.toString() !== productId.toString())
    )
}

export function verifyProduct(products, product) {
    return products.filter(
        (p) =>
            (p.id.toString() === product.id.toString() &&
                p.weekdayQuantities.some((wq) => wq.quantity > 0)) ||
            p.id.toString() !== product.id.toString()
    )
}

export function commitEditProduct(editProducts, products, product) {
    const editIndex = findIndex(editProducts, (p) => p.id.toString() === product.id.toString())
    const index = findIndex(products, (p) => p.id.toString() === product.id.toString())
    const retVal = [...products]
    retVal[index] = { ...editProducts[editIndex] }
    delete retVal[index].isDraft
    return retVal
}

function ensureWeekdayForProduct(products, productId, weekday) {
    const subscriptionProduct =
        products && products.find((p) => p.id.toString() === productId.toString())
    if (!subscriptionProduct) {
        return []
    }

    if (!subscriptionProduct.weekdayQuantities.some((wq) => wq.weekday === weekday)) {
        return [...subscriptionProduct.weekdayQuantities, { quantity: 0, weekday: weekday }]
    }
    return subscriptionProduct.weekdayQuantities
}

export function changeAmountForDay(products, product, weekday, value) {
    const weekdayQuantities = ensureWeekdayForProduct(products, product.id, weekday).map((item) =>
        item.weekday === weekday
            ? {
                  ...item,
                  quantity: Math.max(item.quantity + value, 0),
              }
            : item
    )
    return products.map((item) =>
        item.id.toString() === product.id.toString()
            ? {
                  ...item,
                  weekdayQuantities,
              }
            : item
    )
}

function makeProductSubscription(product) {
    return {
        id: product.id,
        weekdayQuantities: [],
        netAmount: getNetAmountFromProductPrice(product),
        vatAmount: getVatAmountFromProductPrice(product),
        vatRate: product.vatRate,
        isDraft: true,
        imageUrl:
            product.navigationImage &&
            (product.navigationImage.medium || product.navigationImage.fullSize),
        title: product.title,
        itemPrice: product.price,
    }
}

export function aggregateAmounts(products, editProducts) {
    let netAmount = 0
    let vatAmount = 0

    if (products) {
        products.forEach((product) => {
            const editProduct = editProducts.find((ep) => ep.id == product.id)
            if (editProduct) {
                netAmount += getTotalNetAmount(editProduct)
                vatAmount += getTotalVatAmount(editProduct)
            } else {
                netAmount += getTotalNetAmount(product)
                vatAmount += getTotalVatAmount(product)
            }
        })
    }
    return { netAmount, vatAmount }
}

const getNetAmountFromProductPrice = (product) => {
    if (product.netAmount) {
        return product.netAmount
    }
    const price = product.price || product.itemPrice
    return (price / (product.vatRate + 100)) * 100
}

const getVatAmountFromProductPrice = (product) => {
    if (product.vatAmount) {
        return product.vatAmount
    }
    const price = product.price || product.itemPrice
    return (price / (product.vatRate + 100)) * product.vatRate
}

export function getSubscriptionProducts(products) {
    return products.filter((p) => p.subscriptionProduct)
}

export function isPaused(stopDates) {
    if (stopDates === null || stopDates === undefined || (stopDates && stopDates.length === 0)) {
        return false
    } else {
        const endDate = stopDates.slice(-1)[0]
        return moment().isSameOrBefore(endDate, 'day')
    }
}

function getTotalNetAmount(product) {
    const totalQuantity = getTotalQuantity(product)
    return getNetAmountFromProductPrice(product) * totalQuantity
}

function getTotalVatAmount(product) {
    const totalQuantity = getTotalQuantity(product)
    return getVatAmountFromProductPrice(product) * totalQuantity
}

function getTotalQuantity(product) {
    return product.weekdayQuantities.reduce(
        (runningTotalDays, currentWeekday) => runningTotalDays + currentWeekday.quantity,
        0
    )
}

let apiConfig = {}

if (process.env.API_USER && process.env.API_PASSWORD) {
    apiConfig.auth = {
        username: process.env.API_USER,
        password: process.env.API_PASSWORD,
    }
}

export default apiConfig

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Form extends Component {
    constructor(props) {
        super(props)
        this.state = { submitted: false }
    }

    handleSubmit(event) {
        const form = event.target
        const valid = !form.checkValidity || form.checkValidity()
        event.preventDefault()

        // TODO bekreft at alle ugyldige input skal markeres onSubmit
        this.setState({ submitted: true })

        if (!valid) {
            return false
        }

        if (valid && this.props.onSubmit) {
            this.props.onSubmit(event)
        }
    }

    render() {
        return (
            <form
                className={classNames(this.props.className, {
                    'form-submitted': this.state.submitted,
                })}
                onSubmit={this.handleSubmit.bind(this)}
                noValidate
            >
                {this.props.children}
            </form>
        )
    }
}

Form.propTypes = {
    className: PropTypes.string,
    onSubmit: PropTypes.func,
}

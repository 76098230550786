import { Price } from 'common'
import { push } from 'connected-react-router'
import kebabCase from 'lodash/kebabCase'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { capitalize } from 'utilities/text'
import './style.scss'

export class SubscriptionItem extends Component {
    render() {
        const { item, dispatch } = this.props
        const { product, total, orderDays } = item
        const urlName = kebabCase(product.title)

        const days = orderDays.map((d) => ({
            day: capitalize(moment.weekdays(d.weekday)),
            weekday: d.weekday,
            quantity: d.quantity,
        }))

        return (
            <div className="subscription-checkout-item">
                <div className="subscription-checkout-item__totalQuantity">
                    <span>{item.totalQuantity}</span>
                </div>
                <div className="subscription-checkout-item__text">
                    <span
                        onClick={() => dispatch(push(`/product/${product.id}/${urlName}`))}
                        className="subscription-checkout-item__title"
                    >
                        {product.title}
                    </span>
                    <span className="subscription-checkout-item__days">
                        {days.map((d) => (
                            <span key={d.weekday}>
                                {d.day}: {d.quantity}
                                &nbsp;&nbsp;
                            </span>
                        ))}
                    </span>
                </div>
                <Price className="subscription-checkout-item__price" amount={total} />
            </div>
        )
    }
}

SubscriptionItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        product: PropTypes.object.isRequired,
        subscription: PropTypes.object.isRequired,
        orderDays: PropTypes.array.isRequired,
        totalQuantity: PropTypes.number.isRequired,
    }).isRequired,
    dispatch: PropTypes.func,
}

export default connect()(SubscriptionItem)

import { Button, ButtonRow } from 'common'
import Navigation from 'components/UI/Navigation'
import NavItem from 'components/UI/NavItem'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logout } from 'user'
import './style.scss'
import { gotoSubscriptionInfoOrOverview } from 'actions/Subscription'

class UserNavigation extends Component {
    render() {
        const { email, name, dispatch } = this.props
        return (
            <div className="user-navigation">
                {name && <h3 className="user-navigation__name">{name}</h3>}
                {email && <span className="user-navigation__email">{email}</span>}
                <Navigation type="dropdown">
                    <NavItem to="/my-page/orders">Ordrehistorikk</NavItem>
                    <NavItem to={'/my-page/addresses'}>Adresser</NavItem>
                    <NavItem to="/my-page/payment-methods">Betalingsmiddel</NavItem>
                    <NavItem to="#" callback={() => dispatch(gotoSubscriptionInfoOrOverview())}>
                        Fast levering
                    </NavItem>
                    {process.env.FAQ_URL && (
                        <NavItem href={process.env.FAQ_URL}>Ofte stilte spørsmål</NavItem>
                    )}
                </Navigation>
                <ButtonRow display="center">
                    <Button
                        type="action"
                        style="ghost"
                        size="small"
                        onClick={() => dispatch(logout())}
                    >
                        Logg ut
                    </Button>
                </ButtonRow>
            </div>
        )
    }
}

UserNavigation.propTypes = {
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    dispatch: PropTypes.func,
}

const mapStateToProps = ({ user }) => {
    let email = user.jwtData.spidEmail
    const name = `${user.spidData.givenName} ${user.spidData.familyName}`
    return { email, name }
}

export default connect(mapStateToProps)(UserNavigation)

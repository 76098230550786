/**
 * Find the value of a cart including discounts, excluding shipping.
 * @param cartState The cart state used to calculate the value
 * @returns {number} Cart value in NOK
 */
function getCartValue(cartState) {
    return (cartState.products.map(({ quantity, price }) => quantity * price).reduce((a, b) => a + b) -
        cartState.discount);
}
/**
 * Find the total number of products in a cart. Ordering for instance two pieces of bread is counted as two products.
 * @param cartState The cart state
 * @returns Total number of products in cart
 */
function getCartSize(cartState) {
    if (cartState.products.length == 0) {
        return 0;
    }
    return cartState.products.map(({ quantity }) => quantity).reduce((a, b) => a + b);
}
function getPromoDescription(couponCodeSuccessPayload) {
    const { discountValue, discountType, productIds } = couponCodeSuccessPayload;
    const discountUnit = discountType == 'FLAT' ? 'kr' : '%';
    let discountedProducts;
    if (productIds.length == 0) {
        discountedProducts = 'alle produkter';
    }
    else if (productIds.length == 1) {
        discountedProducts = `produktet med ID ${productIds[0]}`;
    }
    else {
        discountedProducts = `produktene med ID ${productIds.slice(0, -1).join(', ')} eller ${productIds[productIds.length - 1]}`;
    }
    return `${discountValue} ${discountUnit} i avslag på ${discountedProducts}`;
}
export { getCartValue, getCartSize, getPromoDescription };

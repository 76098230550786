import LoadingScreen from 'components/UI/LoadingScreen';
import groq from 'groq';
import { getPopularProducts, ProductCarousel, ProductContext, ProductGrid } from 'product';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import sanityClient from 'utilities/sanityClient';
import './PopularProducts.scss';
function PopularProducts({ documentId, limit }) {
    const allProducts = useSelector((state) => state.products.products);
    const isSubscriptionMode = useSelector((state) => state.subscription.isSubscriptionMode);
    const isMobile = useSelector((state) => state.ui.isMobile);
    const regionId = useSelector((state) => state.region.id);
    const deliveryDate = useSelector((state) => state.checkout.deliveryDate);
    const relevantProducts = useMemo(() => allProducts.filter((p) => p.soldOut === '0' && (!isSubscriptionMode || p.subscriptionProduct)), [allProducts, isSubscriptionMode]);
    const [popularProducts, setPopularProducts] = useState([]);
    const [popularProductsDocument, setPopularProductsDocument] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        let isSubscribed = true;
        getPopularProducts(regionId, deliveryDate, limit)
            .then((popularProductIds) => {
            isSubscribed
                ? setPopularProducts(popularProductIds.data
                    .map((popularProductId) => relevantProducts.find(({ id }) => popularProductId === id))
                    .flatMap((popularProduct) => !!popularProduct ? [popularProduct] : []))
                : null;
        })
            .catch(() => (isSubscribed ? setPopularProducts([]) : null));
        return () => {
            isSubscribed = false;
        };
    }, [deliveryDate, limit, regionId, relevantProducts]);
    useEffect(() => {
        const query = groq `*[ _id == $documentId ][0] {
            title,
            subtitle
        }`;
        let isSubscribed = true;
        sanityClient
            .fetch(query, { documentId })
            .then((document) => (isSubscribed ? setPopularProductsDocument(document) : null))
            .catch(() => isSubscribed
            ? setPopularProductsDocument({ title: undefined, subtitle: undefined })
            : null);
        return () => {
            isSubscribed = false;
        };
    }, [documentId]);
    useEffect(() => {
        if (popularProducts && popularProductsDocument) {
            setIsLoading(false);
        }
    }, [popularProducts, popularProductsDocument]);
    if (!isLoading && popularProducts.length < 4) {
        // We don't display popular products until we have at least 4 products to display
        return null;
    }
    return (React.createElement(ProductContext.Provider, { value: { context: 'popular-products' } },
        React.createElement("div", { className: "popular-products" },
            React.createElement("div", { className: "popular-products__heading-wrapper" },
                React.createElement("h2", null, popularProductsDocument === null || popularProductsDocument === void 0 ? void 0 : popularProductsDocument.title),
                (popularProductsDocument === null || popularProductsDocument === void 0 ? void 0 : popularProductsDocument.subtitle) && React.createElement("p", null, popularProductsDocument.subtitle)),
            isLoading ? (React.createElement(LoadingScreen, null)) : isMobile ? (React.createElement(ProductCarousel, { products: popularProducts || [], shrinkOnMobile: true })) : (React.createElement(ProductGrid, { products: popularProducts || [], shrinkProductTiles: (popularProducts === null || popularProducts === void 0 ? void 0 : popularProducts.length) > 5 })))));
}
export default PopularProducts;

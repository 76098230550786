import './style.scss';
const chevron = require('img/chevron.svg');
import React from 'react';
import Icon from 'components/UI/Icon';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
function NavItem({ to, dispatch, type, href, active, scrollToTop, callback, highlight, children }) {
    const classes = classNames({
        'nav-item': true,
        [`nav-item--${type}`]: type,
        'nav-item--active': active,
        'nav-item--highlight': highlight,
    });
    const showLink = to || href;
    return (React.createElement("li", { className: classes },
        showLink && (React.createElement("a", { href: href || to, onClick: (e) => {
                if (callback)
                    callback(e);
                if (href)
                    return;
                e.preventDefault();
                if (to)
                    dispatch(push(to));
                if (scrollToTop) {
                    window.scrollTo({ top: 0 });
                }
            } },
            children,
            type === 'column' && React.createElement(Icon, { svg: chevron }))),
        !showLink && children));
}
export default React.memo(connect()(NavItem));

const TWELVE_HOURS_IN_MILLISECONDS = 1000 * 60 * 60 * 12

export const loadState = () => {
    var state = getJSONFromLocalStorage('state')
    if (!state) {
        return undefined
    }
    if (state.timeStamp && state.timeStamp + TWELVE_HOURS_IN_MILLISECONDS > Date.now()) {
        delete state.timeStamp
        return state
    } else {
        console.log('State in local storage is more than a day old, clearing state')
        localStorage.removeItem('state')
    }
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (err) {
        console.warn('Error saving state', err)
    }
}

export const stateVersion = (version) => {
    const storageVersion = localStorage.getItem('version')
    if (storageVersion !== version) {
        console.log('Mismatched state version, clearing state')
        localStorage.removeItem('jwt')
        localStorage.removeItem('state')
        localStorage.setItem('version', version)
    }
}

export const getJSONFromLocalStorage = (key) => {
    try {
        return JSON.parse(getFromLocalStorage(key))
    } catch (err) {
        return undefined
    }
}

export const getFromLocalStorage = (key) => {
    const item = localStorage.getItem(key)
    if (item === null) {
        return undefined
    }
    return item
}

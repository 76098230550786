import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
const ABTestWrapper = (props) => {
    const dispatch = useDispatch();
    const testGroup = useMemo(() => getTestGroup(props.split), []);
    useEffect(() => {
        const splitString = `${props.split} / ${100 - props.split}`;
        dispatch({
            type: 'AB_TEST_VIEWED',
            payload: { name: props.name, testGroup: testGroup, split: splitString },
        });
    }, []);
    switch (testGroup) {
        case 'A':
            return props.createA();
        case 'B':
            return props.createB();
        default:
            return props.createA();
    }
};
function getTestGroup(split) {
    const testGroupKey = `abTestGroup-${split}`;
    const testGroup = localStorage.getItem(testGroupKey);
    if (testGroup) {
        return testGroup;
    }
    const newTestGroup = Math.random() < split / 100 ? 'A' : 'B';
    localStorage.setItem(testGroupKey, newTestGroup);
    return newTestGroup;
}
export default ABTestWrapper;

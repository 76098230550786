import { Pill, PillList, PillListType, PillType, useSanityDocument } from 'common';
import React, { useState } from 'react';
import { DynamicPageBuilder } from '../../../pagebuilder/DynamicPageBuilder';
import './TabSection.scss';
import { useDispatch } from 'react-redux';
export function TabSection({ documentId }) {
    const dispatch = useDispatch();
    const tabSection = useSanityDocument({
        documentId,
        queryTemplate: `
            title,
            tabs[] {
                title,
                "elements": pageBuilder[] {
                    "type": _type,
                    _type == "abTest" => @-> {
                        "name": title,
                        variantA->{ "type": _type, "elementId":_id },
                        variantB->{ "type": _type, "elementId":_id }
                    },
                    _type == "reference" => @-> {
                        "type": _type,
                        _type != "ctaButton" => {
                            "elementId": _id
                        },
                        _type == "ctaButton" => {
                            title,
                            target
                        }
                    },
                    _type != "reference" && _type != "abTest" => @ {
                        "type": content,
                    }
                }
            }
        `,
    });
    const [activeTab, setActiveTab] = useState(0);
    const track = (linkTitle) => {
        dispatch({
            type: 'LINK_LIST_CLICKED',
            payload: {
                listTitle: (tabSection === null || tabSection === void 0 ? void 0 : tabSection.title) || '',
                linkTitle: linkTitle,
            },
        });
    };
    const onTabClick = (tabIndex) => {
        var _a;
        setActiveTab(tabIndex);
        if ((_a = tabSection === null || tabSection === void 0 ? void 0 : tabSection.tabs[tabIndex]) === null || _a === void 0 ? void 0 : _a.title)
            track(tabSection.tabs[tabIndex].title);
    };
    if (!tabSection || tabSection.tabs.length === 0) {
        return null;
    }
    return (React.createElement("div", { className: "tab-section" },
        tabSection.title && React.createElement("h2", { className: "tab-section__title" }, tabSection.title),
        React.createElement(PillList, { listType: PillListType.Horizontal }, tabSection.tabs.map((tab, i) => (React.createElement(Pill, { key: i, pillType: PillType.Selectable, active: i === activeTab, onClick: () => onTabClick(i) }, tab.title)))),
        React.createElement(DynamicPageBuilder, { elements: tabSection.tabs[activeTab].elements })));
}

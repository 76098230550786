import { updateSubscriptionAddress } from 'actions/Address'
import { attemptFetchStock } from 'actions/Products'
import { fetchSubscription } from 'actions/Subscription'
import axios from 'axios'
import { push } from 'connected-react-router'
import { showTimedNotification } from 'notification'
import {
    hasAddress,
    hasContactInfo,
    hasEmail,
    hasPreferredPaymentMethod,
    hasProducts,
    hasSupportedAddress,
} from 'utilities/checkoutValidation'
import { getDateAsText, getDateYyyyMmDd } from 'utilities/date'
import { FEATURES, isFeatureEnabled } from 'common'

export function gotoCheckoutPage(checkoutMode) {
    return (dispatch) => {
        dispatch({ type: 'SET_CHECKOUT_MODE', payload: checkoutMode })
        dispatch(push('/checkout'))
        dispatch(beginCheckout())
    }
}

export function beginCheckout() {
    return (dispatch, getState) => {
        dispatch({ type: 'BEGIN_CHECKOUT', payload: getState().cart })
    }
}

function validateSubscription(data, dispatch, loggedIn) {
    if (!loggedIn) {
        dispatch({ type: 'ORDER_VALIDATION_FAILED', payload: 'notLoggedIn' })
        return false
    }

    let orderValid = true

    if (!hasContactInfo(data)) {
        dispatch({
            type: 'ORDER_VALIDATION_FAILED',
            payload: 'missingContactInfo',
        })
        orderValid = false
    }
    if (!hasEmail(data)) {
        dispatch({ type: 'ORDER_VALIDATION_FAILED', payload: 'missingEmail' })
        orderValid = false
    }
    if (!hasProducts(data)) {
        dispatch({ type: 'ORDER_VALIDATION_FAILED', payload: 'missingProducts' })
        orderValid = false
    }
    if (!hasAddress(data)) {
        dispatch({ type: 'ORDER_VALIDATION_FAILED', payload: 'missingAddress' })
        orderValid = false
    } else if (!hasSupportedAddress(data)) {
        dispatch({
            type: 'ORDER_VALIDATION_FAILED',
            payload: 'unsupportedAddress',
        })
        orderValid = false
    }
    if (!hasPreferredPaymentMethod(data)) {
        dispatch({ type: 'ORDER_VALIDATION_FAILED', payload: 'missingCard' })
        orderValid = false
    }

    return orderValid
}

function mapSubscriptionData(subscription) {
    return subscription.products.map((p) => ({
        ...p,
        id: parseInt(p.id),
    }))
}

export function placeSubscriptionOrder(
    subscription,
    deliveryAddress,
    email,
    preferredPaymentMethod,
    optinForNews
) {
    const url = `${process.env.CORE_API}/subscription`
    const subscriptionProducts = mapSubscriptionData(subscription)

    return (dispatch, getState) => {
        dispatch({
            type: 'PLACE_SUBSCRIPTION_ORDER',
            payload: { subscription, deliveryAddress, email, preferredPaymentMethod },
        })
        if (
            !validateSubscription(
                {
                    products: subscriptionProducts,
                    deliveryAddress,
                    email,
                    preferredPaymentMethod, // ?? Might not be set, if logged in user chooses stored payment method.
                    optinForNews,
                },
                dispatch,
                getState().user.isLoggedIn
            )
        )
            return

        const validFrom = getDateYyyyMmDd()
        dispatch(
            updateSubscriptionAddress(
                deliveryAddress,
                validFrom,
                () => {
                    axios
                        .post(url, subscriptionProducts)
                        .then((response) => {
                            if (response.data.status === 'failed') {
                                dispatch({
                                    type: 'PLACE_SUBSCRIPTION_ORDER_ERROR',
                                    payload: {
                                        type: 'placeSubscription',
                                        status: response.status,
                                        data: response.data,
                                    },
                                })
                            } else {
                                // Refresh subscription from server before dispatching SUCCESS to update state before any navigation, e.g. back to subscription page
                                dispatch(
                                    fetchSubscription(() => {
                                        dispatch({
                                            type: 'PLACE_SUBSCRIPTION_ORDER_SUCCESS',
                                            payload: response,
                                        })
                                    })
                                )
                            }
                        })
                        .catch((err) => {
                            const status =
                                (err && ((err.response && err.response.status) || err.status)) ||
                                '400'
                            const data =
                                (err && ((err.response && err.response.data) || err.data)) || {}
                            dispatch({
                                type: 'PLACE_SUBSCRIPTION_ORDER_FAILED',
                                payload: { type: 'placeSubscription', status, data },
                            })
                        })
                },
                (err) => {
                    const status =
                        (err && ((err.response && err.response.status) || err.status)) || '400'
                    const data = (err && ((err.response && err.response.data) || err.data)) || {}
                    dispatch({
                        type: 'PLACE_SUBSCRIPTION_ORDER_FAILED',
                        payload: { type: 'placeSubscription', status, data },
                    })
                }
            )
        )
    }
}

export function setDeliveryDate(deliveryDate, setByUser) {
    const capacityNearlyFull = deliveryDate.capacityStatus === 'NEARLY_FULL'

    return (dispatch, getState) => {
        dispatch({
            type: 'SET_DELIVERY_DATE',
            payload: {
                deliveryDate: deliveryDate.date,
                setByUser,
                capacityNearlyFull: capacityNearlyFull,
            },
        })

        if (!isFeatureEnabled(getState().features.enabled, FEATURES.STORE)) {
            dispatch(attemptFetchStock())
        }

        if (capacityNearlyFull) {
            dispatch(
                showTimedNotification({
                    style: 'INFO',
                    text: `Det nærmer seg fullbooket ${getDateAsText(
                        deliveryDate.date,
                        true
                    )}, vær rask å bestille :)`,
                })
            )
        }
    }
}

export function setOptinForNews(optinForNews) {
    return {
        type: 'SET_OPTIN_FOR_NEWS',
        payload: optinForNews,
    }
}

export function checkoutCompleted() {
    return (dispatch) => {
        dispatch({ type: 'CHECKOUT_COMPLETED' })
    }
}

import Icon from 'components/UI/Icon';
import { PillListMenu } from 'navigation';
import React from 'react';
import { useSelector } from 'react-redux';
import './CategoryList.scss';
const BackButton = require('img/navigationIcons/backbutton.svg');
function CategoryList({ goBack, hide }) {
    const categories = useSelector((state) => state.products.categories);
    const menuItems = categories
        ? categories.map((c) => ({
            text: c.name,
            navigationPath: `/products/${c.slug}`,
            onClick: hide,
        }))
        : [];
    const allProductsLinkObject = {
        name: 'Vis alle',
        href: '/products',
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'category-list__menu' },
            React.createElement("div", { className: 'category-list__header', onClick: goBack },
                React.createElement(Icon, { svg: BackButton, size: 'large' }),
                React.createElement("div", { className: 'category-list__title' }, "Tilbake")),
            React.createElement(PillListMenu, { heading: "Alle kategorier", items: menuItems, topLinkObject: allProductsLinkObject }))));
}
export default CategoryList;

import classNames from 'classnames';
import React from 'react';
import './ButtonRow.scss';
export function ButtonRow({ children, display, className, stickyOnMobile }) {
    const classes = classNames('button-row', {
        [`button-row--${display}`]: true,
        [`${className}`]: className,
        'button-row--sticky-on-mobile': stickyOnMobile,
    });
    return React.createElement("div", { className: classes }, children);
}

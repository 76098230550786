import { Button, ButtonRow } from 'common';
import { ProductContext, RelatedProducts } from 'product';
import React from 'react';
import { useSelector } from 'react-redux';
import './RelatedProductsInterrupt.scss';
function RelatedProductsInterrupt({ onCancel, onProceed }) {
    const productsInCart = useSelector((state) => state.cart.products);
    const regionId = useSelector((state) => state.region.id);
    const isMobile = useSelector((state) => state.ui.isMobile);
    return (React.createElement(ProductContext.Provider, { value: { context: 'related_products_interrupt' } },
        React.createElement("div", { className: "related-products-interrupt" },
            React.createElement("h2", null, "Lyst p\u00E5 noe mer?"),
            React.createElement(RelatedProducts, { productIds: productsInCart.map((p) => p.id), regionId: regionId, limit: 3 }),
            React.createElement(ButtonRow, { display: isMobile ? 'stretch' : 'center', className: "related-products-interrupt__buttons" },
                React.createElement(Button, { type: "action", style: "ghost", onClick: onCancel }, "Handle mer"),
                React.createElement(Button, { type: "action", style: "primary", onClick: onProceed }, "Fortsett til kassen")))));
}
export default RelatedProductsInterrupt;

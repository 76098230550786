import { Button, ButtonStack } from 'common';
import Link from 'components/UI/Link';
import Switch from 'components/UI/Switch';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CookieConsent.scss';
import { acceptAds, acceptAll, acceptAnalytics, getCookie, rejectAll } from '../actions/consentActions';
function CookieConsent() {
    const cookiesIsSet = !!getCookie('_cookieConsent');
    const isApp = useSelector((state) => state.ui.isApp);
    const dispatch = useDispatch();
    const [showCookieConsent, setShowCookieConsent] = useState(true);
    const [showCustomizeCookies, setShowCustomizeCookies] = useState(false);
    const [adConsent, setAdConsent] = useState(false);
    const [analyticsConsent, setAnalyticsConsent] = useState(false);
    const [hideCookieConsent, setHideCookieConsent] = useState(cookiesIsSet);
    const handleAccept = (ads, analytics) => {
        if (ads && analytics) {
            dispatch(acceptAll());
        }
        if (ads && !analytics) {
            dispatch(acceptAds());
        }
        if (!ads && analytics) {
            dispatch(acceptAnalytics());
        }
        if (!ads && !analytics) {
            dispatch(rejectAll());
        }
    };
    if (hideCookieConsent || isApp) {
        return null;
    }
    if (showCookieConsent) {
        return (React.createElement("div", { className: "cookie-consent__container" },
            React.createElement("div", { className: "cookie-consent__description" },
                React.createElement("h3", { className: "cookie-consent__description--header" }, "Vi benytter cookies"),
                "Morgenlevering samler inn data gjennom cookies for \u00E5 levere v\u00E5r tjeneste, forbedre nettsiden og markedsf\u00F8re v\u00E5r tjeneste gjennom tredjeparter. Vi kan gi deg en mer tilpasset og personlig tjeneste ved \u00E5 dele data med Facebook Snapchat og Google."),
            React.createElement("div", { className: "cookie-consent__description" },
                React.createElement("h3", { className: "cookie-consent__description--header" }, "Markedsf\u00F8ring"),
                "Vi deler din data med Facebook, Snapchat og Google for \u00E5 vise deg relevante annonser og/eller optimere v\u00E5r markedsf\u00F8ring p\u00E5 disse plattformene."),
            React.createElement(ButtonStack, { className: "cookie-consent__button-container" },
                React.createElement(Button, { type: "action", style: "primary", onClick: () => {
                        handleAccept(true, true);
                        setHideCookieConsent(!hideCookieConsent);
                    } }, "Aksepter alle"),
                React.createElement(Button, { type: "action", style: "ghost", onClick: () => {
                        setShowCustomizeCookies(true);
                        setShowCookieConsent(false);
                    } }, "Tilpass")),
            React.createElement("div", { className: "cookie-consent__description" },
                "For \u00E5 lese mer om hvordan vi bruker cookies kan du g\u00E5 til v\u00E5r",
                React.createElement(Link, { to: "/informasjon/personvern" }, "cookiepolicy"),
                ".")));
    }
    else if (showCustomizeCookies) {
        return (React.createElement("div", { className: "cookie-consent__container" },
            React.createElement("div", { className: "cookie-consent__scroll-on-mobile" },
                React.createElement("div", { className: "cookie-consent__cookie-information" },
                    React.createElement("h3", null, "Markedsf\u00F8ring"),
                    React.createElement("div", { className: "cookie-consent__cookie-information--description" }, "Markedsf\u00F8ringscookies brukes til \u00E5 spore bes\u00F8kende p\u00E5 tvers av nettsider. Hensikten er \u00E5 vise annonser som er relevante og  engasjerende for den enkelte brukeren, og dermed mer verdifulle for utgivere og tredjeparts-annons\u00F8rer."),
                    React.createElement("div", { className: "cookie-consent__consent-switch" },
                        React.createElement(Switch, { on: adConsent, onChange: () => setAdConsent(!adConsent) }),
                        React.createElement("div", { className: "cookie-consent__consent-switch--text" }, "Jeg godtar markedsf\u00F8ringscookies"))),
                React.createElement("div", { className: "cookie-consent__cookie-information" },
                    React.createElement("h3", null, "Analyse"),
                    React.createElement("div", { className: "cookie-consent__cookie-information--description" }, "Analysecookies hjelper nettsidens eier med \u00E5 forst\u00E5 hvordan bes\u00F8kende samhandler med nettsiden ved \u00E5 samle inn og rapportere opplysninger."),
                    React.createElement("div", { className: "cookie-consent__consent-switch" },
                        React.createElement(Switch, { on: analyticsConsent, onChange: () => setAnalyticsConsent(!analyticsConsent) }),
                        React.createElement("div", { className: "cookie-consent__consent-switch--text" }, "Jeg godtar analysescookies"))),
                React.createElement("div", { className: "cookie-consent__cookie-information" },
                    React.createElement("h3", null, "N\u00F8dvendige"),
                    React.createElement("div", { className: "cookie-consent__cookie-information--description" }, "N\u00F8dvendige cookies hjelper med \u00E5 gj\u00F8re en nettside brukbar ved \u00E5 aktivere grunnleggende funksjoner. Nettsiden vil ikke fungere slik den skal uten disse."),
                    React.createElement("div", { className: "cookie-consent__consent-switch" },
                        React.createElement(Switch, { on: true, onChange: () => undefined }),
                        React.createElement("div", { className: "cookie-consent__consent-switch--text" }, "Jeg godtar n\u00F8dvendige cookies")))),
            React.createElement(ButtonStack, { className: "cookie-consent__button-container" },
                React.createElement(Button, { type: "action", style: "primary", onClick: () => {
                        handleAccept(true, true);
                        setHideCookieConsent(!hideCookieConsent);
                    } }, "Aksepter alle"),
                React.createElement(Button, { type: "action", style: "ghost", onClick: () => {
                        handleAccept(adConsent, analyticsConsent);
                        setHideCookieConsent(!hideCookieConsent);
                    } }, "Lagre valgte cookies"))));
    }
    return null;
}
export default CookieConsent;

import React, { Component } from 'react'

import AppPortal from 'components/UI/AppPortal'
import Notification from 'components/UI/Notification'
import PropTypes from 'prop-types'
import ReportMissingAddress from 'components/Delivery/ReportMissingAddress'
import { capitalize } from 'utilities/text'
import { connect } from 'react-redux'

class CannotDeliverNotification extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hide: false,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.address !== this.props.address) {
            this.setState({ hide: false })
        }
    }
    handleClose() {
        this.setState({ hide: true })
    }
    render() {
        const { show, address } = this.props
        if (!show || this.state.hide || !address.streetName) return null
        return (
            <AppPortal>
                <Notification
                    className="notification__fixed"
                    title="Dessverre 😞"
                    onClose={() => this.handleClose()}
                >
                    <p>
                        Vi kan ikke levere til {capitalize(address.streetName)}, {address.city}
                    </p>
                    <p>Det kan komme av at vi ikke har levering til ditt område</p>
                    <ReportMissingAddress />
                </Notification>
            </AppPortal>
        )
    }
}

CannotDeliverNotification.propTypes = {
    show: PropTypes.bool,
    address: PropTypes.object,
    dispatch: PropTypes.func,
}

const mapStateToProps = ({ address, distribution }) => {
    return {
        show: !distribution.canDeliver && !distribution.distributionLoading,
        address: address.active,
    }
}

export default connect(mapStateToProps)(CannotDeliverNotification)

import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends React.Component {
    componentDidMount() {
        this.scrollToTop()
    }

    componentDidUpdate(props) {
        if (this.props.location !== props.location) {
            this.scrollToTop()
        }
    }

    scrollToTop() {
        this.disableScroll() || window.scrollTo(0, 0)
    }

    disableScroll() {
        if (this.props.history.action === 'POP') return true
    }

    render() {
        return null
    }
}

ScrollToTop.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
}

export default withRouter(connect()(ScrollToTop))

import { addToCart, removeFromCart, setProductQuantity } from 'cart';
import classNames from 'classnames';
import { Button, ButtonRow, Price } from 'common';
import Icon from 'components/UI/Icon';
import { ProductContext } from 'product';
import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import './AddToCartButton.scss';
const trashCanIcon = require('img/trashcan_with_padding.svg');
const minusIcon = require('img/minus.svg');
const plusIcon = require('img/plus.svg');
function handleAddToCart(event, dispatch, products, productContext, cartVisible) {
    if (products.length > 1) {
        dispatch({ type: 'ADD_BUNDLE_TO_CART', payload: { products, productContext } });
    }
    event.stopPropagation();
    products.forEach((product) => dispatch(addToCart(product, cartVisible, productContext)));
}
function handleIncreaseAmount(event, dispatch, products, productContext, cartVisible) {
    if (products.length > 1) {
        dispatch({ type: 'ADD_BUNDLE_TO_CART', payload: { products, productContext } });
    }
    event.stopPropagation();
    products.forEach((product) => dispatch(addToCart(product, cartVisible, productContext)));
}
function handleDecreaseAmount(event, dispatch, products, cartVisible) {
    if (products.length > 1) {
        dispatch({ type: 'REMOVE_BUNDLE_FROM_CART', payload: products });
    }
    event.stopPropagation();
    products.forEach((product) => dispatch(removeFromCart(product, cartVisible)));
}
function handleChangeAmount(value, dispatch, products) {
    const newQuantity = Math.floor(+value);
    products.forEach((product) => dispatch(setProductQuantity(newQuantity, product)));
}
function AddToCartButton({ product, shrinkOnMobile, amountInCart, soldOut, shrink = false, }) {
    const dispatch = useDispatch();
    const productContext = useContext(ProductContext);
    const isCartVisible = useSelector((state) => state.ui.showCart);
    const [amountInput, setAmountInput] = useState(amountInCart.toString());
    const products = Array.isArray(product) ? product : [product];
    const priceOfProducts = products.reduce((acc, p) => acc + p.price, 0);
    useEffect(() => {
        setAmountInput(amountInCart.toString());
    }, [amountInCart]);
    if (soldOut) {
        const soldOutClasses = classNames({
            'sold-out-button-placeholder': true,
            'sold-out-button-placeholder--shrink-on-mobile': shrinkOnMobile,
            'sold-out-button-placeholder--shrink': shrink,
        });
        return React.createElement("div", { className: soldOutClasses }, "Utsolgt p\u00E5 valgt dato");
    }
    const decreaseAmountClasses = classNames({
        'adjust-cart-content__button__icon': true,
        'adjust-cart-content__button__icon--trashcan': amountInCart < 2,
    });
    if (amountInCart > 0) {
        const adjustCartClasses = classNames({
            'adjust-cart-content': true,
            'adjust-cart-content--shrink-on-mobile': shrinkOnMobile,
            'adjust-cart-content--shrink': shrink,
        });
        return (React.createElement("div", { className: adjustCartClasses, onClick: (e) => e.stopPropagation() },
            React.createElement("button", { className: "adjust-cart-content__button", onClick: (e) => handleDecreaseAmount(e, dispatch, products, isCartVisible), onTouchEnd: () => undefined },
                React.createElement(Icon, { svg: amountInCart > 1 ? minusIcon : trashCanIcon, className: decreaseAmountClasses })),
            React.createElement("div", null,
                React.createElement("input", { type: "tel", className: "adjust-cart-content__input", size: amountInput.length || 1, value: amountInput, onChange: (e) => +e.target.value > -1 && setAmountInput(e.target.value), onBlur: (e) => {
                        handleChangeAmount(e.target.value, dispatch, products);
                    }, onKeyUp: (e) => {
                        if (e.key === 'Enter') {
                            e.currentTarget.blur();
                            handleChangeAmount(e.currentTarget.value, dispatch, products);
                        }
                    } }),
                React.createElement("span", { className: "adjust-cart-content__multiplier" }, "x\u00A0"),
                React.createElement(Price, { amount: priceOfProducts })),
            React.createElement("button", { className: "adjust-cart-content__button", onClick: (e) => handleIncreaseAmount(e, dispatch, products, productContext, isCartVisible), onTouchEnd: () => undefined },
                React.createElement(Icon, { svg: plusIcon, className: "adjust-cart-content__button__icon" }))));
    }
    const addClasses = classNames({
        'add-to-cart-button-v2': true,
        'add-to-cart-button-v2--shrink-on-mobile': shrinkOnMobile,
        'add-to-cart-button-v2--shrink': shrink,
    });
    return (React.createElement(ButtonRow, { display: "stretch" },
        React.createElement(Button, { type: "action", style: "secondary", onClick: (e) => handleAddToCart(e, dispatch, products, productContext, isCartVisible), className: addClasses },
            "Kj\u00F8p for ",
            React.createElement(Price, { amount: priceOfProducts }))));
}
function mapStateToProps(state, props) {
    const products = props.product instanceof Array ? props.product : [props.product];
    const productQuantities = products
        .map((p) => { var _a; return ((_a = state.cart.products.find((cartProduct) => cartProduct.id === p.id)) === null || _a === void 0 ? void 0 : _a.quantity) || 0; });
    return {
        product: props.product,
        shrinkOnMobile: props.shrinkOnMobile,
        amountInCart: Math.min(...productQuantities),
        soldOut: props.soldOut,
        shrink: props.shrink,
    };
}
export default connect(mapStateToProps)(AddToCartButton);

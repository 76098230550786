import { viewCart } from 'cart'

export function toggleUserDropdown(force) {
    return (dispatch) => {
        dispatch({ type: 'TOGGLE_USER_DROPDOWN', payload: force })
    }
}

export function toggleCart(force) {
    return (dispatch) => {
        dispatch({ type: 'TOGGLE_CART', payload: force })
        dispatch(viewCart())
    }
}

export function toggleDeliveryDateDropdown(force) {
    return (dispatch) => {
        dispatch({ type: 'TOGGLE_DELIVERY_DATE_DROPDOWN', payload: force })
    }
}

export function toggleAddressDropdown(force) {
    return (dispatch) => {
        dispatch({ type: 'TOGGLE_ADDRESS_DROPDOWN', payload: force })
    }
}

export function closeCorrectPlaceNotification() {
    return (dispatch) => {
        dispatch({ type: 'CLOSE_AUTO_REGION_NOTIFICATION' })
    }
}

export function hideDropDowns() {
    return (dispatch) => {
        dispatch({ type: 'HIDE_DROPDOWNS' })
    }
}

export function toggleIsTablet() {
    return {
        type: 'TOGGLE_IS_TABLET',
        payload: window.innerWidth >= 768 && window.innerWidth < 1024,
    }
}

export function toggleIsMobile() {
    return {
        type: 'TOGGLE_IS_MOBILE',
        payload: window.innerWidth < 768,
    }
}

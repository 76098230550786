function sortByReducer(state, [type, payload]) {
    switch (type) {
        case 'automatic':
            if (state.previouslyUserSelectedSortBy == null) {
                return Object.assign(Object.assign({}, state), { sortBy: payload });
            }
            else
                return state;
        case 'userInitialized':
            return {
                sortBy: payload,
                previouslyUserSelectedSortBy: payload,
            };
    }
}
export { sortByReducer };

import { AccordionItem, SafeHtml } from 'common';
import LoadingScreen from 'components/UI/LoadingScreen';
import React from 'react';
import './ProductDetailsAccordions.scss';
import NutritionTable from '../NutritionTable/NutritionTable';
function ProductDetailsAccordions({ details, loading }) {
    var _a, _b, _c;
    if (loading)
        return React.createElement(LoadingScreen, null);
    if (!details)
        return null;
    if (details.ingredienser == null &&
        details.allergener == null &&
        ((_b = (_a = details.nutritionPer100grams) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) == 0)
        return null;
    const formatArrayToStringWithAnd = (array) => {
        const formattedString = array.length <= 1
            ? array.join('')
            : `${array.slice(0, -1).join(', ')} og ${array.slice(-1)}`;
        return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
    };
    const headerItems = [
        details.ingredienser && 'ingredienser',
        details.allergener && 'allergener',
        details.nutritionPer100grams && 'næringsinnhold',
    ]
        .filter((item) => item);
    return (React.createElement("div", { className: "product-details" },
        React.createElement(AccordionItem, { header: formatArrayToStringWithAnd(headerItems) },
            details.ingredienser && (React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Ingredienser"),
                React.createElement(SafeHtml, { type: "div", html: details.ingredienser, className: "product-details__text" }))),
            details.allergener && (React.createElement(React.Fragment, null,
                React.createElement("h3", null, "Allergener"),
                React.createElement(SafeHtml, { type: "div", html: details.allergener, className: "product-details__text" }))),
            ((_c = details.nutritionPer100grams) === null || _c === void 0 ? void 0 : _c.length) > 0 && (React.createElement(React.Fragment, null,
                React.createElement("h3", null, "N\u00E6ringsinnhold per 100g"),
                React.createElement(NutritionTable, { nutrition: details.nutritionPer100grams }))))));
}
export default ProductDetailsAccordions;

import React from 'react';
import Select from 'components/UI/Select';
import { useDispatch, useSelector } from 'react-redux';
import { setRegionForExternalRegion } from 'actions/Regions';
const RegionDropDown = ({ redirect }) => {
    const dispatch = useDispatch();
    const { selectOption, selectOptions } = useSelector((state) => {
        const region = state.region;
        return {
            selectOption: { value: region.slug || '', label: region.name || '' },
            selectOptions: region.regions.map((r) => ({ value: r.externalRegion, label: r.name })),
        };
    });
    if (selectOptions.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null, "Velg region"),
        React.createElement(Select, { option: selectOption, options: selectOptions, onChange: (payload) => dispatch(setRegionForExternalRegion(payload, redirect)) })));
};
export default RegionDropDown;

import './style.scss'

import React, { Component } from 'react'

import Popover from 'components/UI/Popover'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'

const requiredPattern = '.*\\S.*' // At least one non-whitespace character

class TextInput extends Component {
    constructor(props) {
        super(props)

        this.state = {
            valid: true,
            touched: false,
            showFillHelp: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.setInput = this.setInput.bind(this)
    }

    componentDidMount() {
        this.checkValidity()
    }

    componentDidUpdate() {
        // if (
        //   prevProps.value !== this.props.value ||
        //   prevProps.valid !== this.props.valid
        // )
        // TODO: Find out why we're not updating in AddressHelper for hidden fields
        {
            this.checkValidity()
        }
    }

    handleChange(e) {
        this.props.onChange(e.target.value)
    }

    handleBlur(e) {
        this.setState({ touched: true })

        if (this.props.onBlur) {
            this.props.onBlur(e.target.value, e)
        }

        if (this.props.fillHelp) {
            this.setState({ showFillHelp: false })
        }
    }

    checkRequiredValidity() {
        const { value, required } = this.props
        const hasValue = !!(typeof value === 'string' ? value.trim() : value)

        return !required || hasValue
    }

    checkValidity() {
        let valid = true

        if (!this.checkRequiredValidity()) {
            valid = false
        } else if (this.props.valid !== undefined) {
            valid = this.props.valid
            this.setCustomValidity(valid)
        } else if (this.props.validator) {
            valid = this.props.validator(this.input.value)
            this.setCustomValidity(valid)
        } else {
            valid = this.input.checkValidity()
        }

        if (valid !== this.state.valid) {
            this.setState({ valid })
        }
    }

    setCustomValidity(valid) {
        this.input.setCustomValidity(valid ? '' : this.invalidHelp())
    }

    invalidHelp() {
        const { value, label, required, requiredHelp, invalidHelp, maxLengthWithInputHelp } =
            this.props
        const trimmedValue = typeof value === 'string' ? value.trim() : value
        if (value && maxLengthWithInputHelp < value.length) {
            return `${label} er for langt (${maxLengthWithInputHelp})`
        }
        if (required && !trimmedValue) {
            return requiredHelp || `${label} må fylles ut`
        } else if (label && label.length > 0) {
            return invalidHelp || `${label} er ikke gyldig`
        } else {
            return invalidHelp || 'Ugyldig'
        }
    }

    hasInputHelp() {
        return React.Children.toArray(this.props.children).some(
            (child) => child.type.displayName === 'InputHelp'
        )
    }

    setInput(input) {
        this.input = input
        if (this.props.inputRef) {
            this.props.inputRef(input)
        }
    }

    handleFocus(e) {
        if (this.props.onFocus) {
            this.props.onFocus(e)
        }
        if (this.props.fillHelp) {
            this.setState({ showFillHelp: true })
        }
    }

    focus() {
        this.input.focus()
    }

    render() {
        const {
            value,
            type,
            label,
            labelPostfix,
            id,
            className,
            icon,
            placeholder,
            disabled,
            maxLength,
            pattern,
            required,
            onKeyPress,
            autoComplete,
            autoFocus,
            fillHelp,
        } = this.props
        const inputId = id || kebabCase(label) + (labelPostfix ? '-' + labelPostfix : '')
        const classes = classNames({
            textinput: true,
            'input-group': true,
            'textinput--icon': icon,
            'textinput--help': this.hasInputHelp(),
            [`${className}`]: className,
            'textinput--no-label': !label,
            'textinput--invalid': !this.state.valid,
            'textinput--touched': this.state.touched,
        })
        const Input = type === 'textarea' ? 'textarea' : 'input'

        return (
            <div className={classes}>
                <label htmlFor={inputId}>{label}</label>
                <div className="textinput__wrapper">
                    {fillHelp && this.state.showFillHelp && <Popover>{fillHelp}</Popover>}
                    {icon && icon}
                    <Input
                        className={classNames({ 'textinput__input--disabled': disabled })}
                        ref={this.setInput}
                        id={inputId}
                        value={value || ''}
                        type={type || 'text'}
                        autoComplete={autoComplete}
                        autoFocus={autoFocus}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        onFocus={(e) => this.handleFocus(e)}
                        onKeyPress={onKeyPress}
                        placeholder={placeholder}
                        disabled={disabled}
                        maxLength={maxLength}
                        pattern={pattern || (required ? requiredPattern : null)}
                        required={required}
                    />
                    {this.props.children}
                </div>
                <span className="textinput__help">{this.invalidHelp()}</span>
            </div>
        )
    }
}

TextInput.propTypes = {
    valid: PropTypes.bool,
    invalidHelp: PropTypes.string,
    requiredHelp: PropTypes.string,
    fillHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    validator: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    labelPostfix: PropTypes.string,
    icon: PropTypes.element,
    inputRef: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    maxLength: PropTypes.number,
    maxLengthWithInputHelp: PropTypes.number,
    pattern: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
}

export default TextInput

import { placeSubscriptionOrder } from 'checkout';
import { Button } from 'common';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
function PlaceSubscriptionOrderButton() {
    const dispatch = useDispatch();
    const awaitingOrderResponse = useSelector((state) => state.checkout.awaitingOrderResponse);
    useEffect(() => {
        awaitingOrderResponse && dispatch(push('/checkout/processing'));
    }, [awaitingOrderResponse]);
    return (React.createElement(Button, { type: "action", style: "primary", onClick: () => dispatch(placeSubscriptionOrder()) }, "Bestill fast levering"));
}
export default PlaceSubscriptionOrderButton;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './DeadlineCounter.scss';
export function DeadlineCounter() {
    const deadline = useSelector((state) => state.distribution.deadline);
    const deliveryDate = useSelector((state) => state.checkout.deliveryDate);
    const [now, setNow] = useState(moment());
    const [timerId, setTimerId] = useState();
    useEffect(() => {
        setTimerId(setInterval(() => setNow(moment()), 1000 * 30));
        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, []);
    if (!deadline || !moment(deliveryDate).isSame(moment().add(1, 'day'), 'day')) {
        return null;
    }
    const remainingTime = moment(deadline).diff(now, 'minutes');
    if (remainingTime >= 60) {
        return null;
    }
    const cartDeadlineCounterText = remainingTime >= 1
        ? `Bestill innen ${remainingTime} minutter for levering i morgen`
        : remainingTime >= 0
            ? `Fristen for bestilling er ${moment(deadline).format('HH:mm')} for levering i morgen`
            : 'Fristen for bestilling er passert for levering i morgen';
    return (React.createElement("div", { className: "deadline" }, cartDeadlineCounterText));
}

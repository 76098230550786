import BundleProductTile from '../BundleProductTile/BundleProductTile';
import React from 'react';
import './BundleProductCarousel.scss';
function BundleProductCarousel({ bundles }) {
    return (React.createElement("div", { className: "bundle-scroll" }, bundles.map((bundle) => {
        return (React.createElement("div", { className: "bundle-scroll-item", key: bundle.title },
            React.createElement(BundleProductTile, { bundle: bundle })));
    })));
}
export default BundleProductCarousel;

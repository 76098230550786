import { motion } from 'framer-motion';
import { ProductTile } from 'product';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './ConceptPageHeader.scss';
import { calculateSizes } from './util';
function ConceptPageHeader({ headerImageSrc, heading, featuredProductIds, }) {
    const allProducts = useSelector(({ products }) => products.products);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const sizes = calculateSizes(featuredProducts.length, !!heading)[isMobile ? 'mobile' : 'desktop'];
    useEffect(() => {
        var _a;
        if ((featuredProductIds === null || featuredProductIds === void 0 ? void 0 : featuredProductIds.length) > 0 && (allProducts === null || allProducts === void 0 ? void 0 : allProducts.length) > 0) {
            setFeaturedProducts((_a = featuredProductIds
                .slice(0, 3)
                .map((featuredProductId) => allProducts.find(({ id }) => id == featuredProductId))
                .flatMap((featuredProduct) => (!!featuredProduct ? [featuredProduct] : []))) !== null && _a !== void 0 ? _a : []);
        }
    }, [featuredProductIds, allProducts]);
    const animationTransition = { type: 'spring', mass: 1, stiffness: 26.2, damping: 7.68 };
    const animationVariants = {
        header: {
            hidden: { height: Math.ceil(window.innerHeight * 0.95) },
            visible: {
                height: sizes.headerHeight,
                transition: Object.assign(Object.assign({}, animationTransition), { staggerChildren: 0.2 }),
            },
        },
        overlay: {
            hidden: { opacity: 0 },
            visible: { opacity: 1, transition: { duration: 1 } },
        },
        image: {
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
        },
        title: {
            hidden: { opacity: 0, y: 240 },
            visible: {
                opacity: 1,
                y: 0,
                transition: { y: animationTransition, scale: animationTransition },
            },
        },
        featuredWrapper: {
            hidden: { opacity: 0 },
            visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
        },
        featured: {
            hidden: { y: '100vh' },
            visible: {
                y: 0,
                transition: animationTransition,
            },
        },
    };
    return (React.createElement(motion.header, { className: "concept-page-header", variants: animationVariants.header, initial: "hidden", animate: "visible", style: { marginBottom: sizes.headerMarginBottom } },
        React.createElement(motion.img, { src: headerImageSrc, className: "concept-page-header__image", variants: animationVariants.image }),
        heading && (React.createElement(React.Fragment, null,
            React.createElement(motion.div, { className: "concept-page-header__overlay", variants: animationVariants.overlay }),
            React.createElement(motion.h1, { className: "concept-page-header__title" }, heading))),
        featuredProducts.length > 0 && (React.createElement(motion.div, { className: "concept-page-header__featured-products", variants: animationVariants.featuredWrapper, initial: "hidden", animate: "visible", style: { '--number-of-products': featuredProducts.length } }, featuredProducts.map((featuredProduct) => (React.createElement(motion.div, { key: featuredProduct.id, variants: animationVariants.featured, className: "concept-page-header__featured-products__product" },
            React.createElement(ProductTile, { isSubscriptionMode: false, product: featuredProduct, shrinkOnMobile: true }))))))));
}
export default ConceptPageHeader;

import { removeOrderError } from 'checkout';
import Icon from 'components/UI/Icon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Errors.scss';
import { generalError } from 'utilities/error';
const cancelSvg = require('img/cancel.svg');
export default function Errors() {
    const errors = useSelector((state) => state.newCheckout.errors);
    const dispatch = useDispatch();
    return (React.createElement("div", { className: "checkout-errors" }, errors === null || errors === void 0 ? void 0 : errors.map((error) => (React.createElement("div", { className: "checkout-errors__item", key: error.code || 'error' },
        generalError(`error_${error.code}`),
        React.createElement(Icon, { svg: cancelSvg, className: "checkout-errors__icon", onClick: () => {
                dispatch(removeOrderError(error));
            } }))))));
}

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
export function ListPageHeader({ children }) {
    const headerElement = React.createElement("h1", null, children);
    const [headerTextElement, setHeaderTextElement] = useState(null);
    const isMobile = useSelector((state) => state.ui.isMobile);
    useEffect(() => {
        setHeaderTextElement(document.getElementById('navigation-header__text'));
    }, [isMobile]);
    if (headerTextElement) {
        return ReactDOM.createPortal(headerElement, headerTextElement);
    }
    return headerElement;
}

const baseState = {
    loading: true,
    loadingError: false,
    orders: [],
    cancellingSingleOrderId: undefined,
    errors: {},
    orderStates: {},
}

function ordersWithoutSingleDeliveryOrder(orders, singleOrderId) {
    return orders.filter(
        (currentOrder) =>
            !(
                currentOrder.deliveries.length === 1 &&
                currentOrder.deliveries[0].singleOrderId === singleOrderId
            )
    )
}

function ordersWithoutMultipleDeliveriesOrder(orders, singleOrderId) {
    return orders.map((currentOrder) => {
        return {
            ...currentOrder,
            deliveries: currentOrder.deliveries.filter(
                (currentDelivery) => currentDelivery.singleOrderId !== singleOrderId
            ),
        }
    })
}

function ordersWithoutOldOrder(orders, singleOrderId) {
    return ordersWithoutMultipleDeliveriesOrder(
        ordersWithoutSingleDeliveryOrder(orders, singleOrderId),
        singleOrderId
    )
}

function ordersWithUpdatedOrder(orders, updatedOrder) {
    const ordersHaveDeliveriesForDeliveryDate = orders.some(
        (currentOrder) => currentOrder.date === updatedOrder.deliveryDate
    )

    if (ordersHaveDeliveriesForDeliveryDate) {
        return orders.map((currentOrder) => {
            if (currentOrder.date === updatedOrder.deliveryDate) {
                return {
                    ...currentOrder,
                    deliveries: [...currentOrder.deliveries, updatedOrder],
                }
            } else {
                return currentOrder
            }
        })
    } else {
        return [...orders, { date: updatedOrder.deliveryDate, deliveries: [updatedOrder] }]
    }
}

function reorganizedOrders(orders, updatedOrder) {
    return ordersWithUpdatedOrder(
        ordersWithoutOldOrder(orders, updatedOrder.singleOrderId),
        updatedOrder
    )
}

export default function reducer(state = baseState, action) {
    switch (action.type) {
        case 'FETCH_ORDERS':
            return { ...state, loading: true, loadingError: false, orders: [] }
        case 'FETCH_ORDERS_FAILED':
            return { ...state, loading: false, loadingError: true }
        case 'FETCH_ORDERS_SUCCESS':
            return { ...state, loading: false, orders: action.payload }
        case 'CANCEL_ORDER':
            return {
                ...state,
                cancellingSingleOrderId: action.payload.singleOrderId,
            }
        case 'CANCEL_ORDER_FAILED':
            return {
                ...state,
                cancellingSingleOrderId: undefined,
                errors: {
                    ...state.errors,
                    ...action.payload,
                },
            }
        case 'CANCEL_ORDER_SUCCESS':
            return {
                ...state,
                orders: state.orders.map((o) => {
                    return {
                        ...o,
                        deliveries: o.deliveries.map((d) => {
                            if (d.orderId === action.payload) {
                                return { ...d, cancelled: true }
                            } else {
                                return d
                            }
                        }),
                    }
                }),
                cancellingSingleOrderId: undefined,
            }
        case 'UPDATE_ORDER':
            return {
                ...state,
                orderStates: { ...state.orderStates, ...action.payload.orderStates },
            }
        case 'UPDATE_ORDER_SUCCESS':
            return {
                ...state,
                orders: reorganizedOrders(state.orders, action.payload.updatedOrder),
                orderStates: { ...state.orderStates, ...action.payload.orderStates },
                errors: { ...state.errors, ...action.payload.errors },
            }
        case 'UPDATE_ORDER_FAILED':
            return {
                ...state,
                orderStates: { ...state.orderStates, ...action.payload.orderStates },
                errors: { ...state.errors, ...action.payload.errors },
            }
        case 'CLEAR_ORDER_ERROR':
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...{ [action.payload.singleOrderId]: undefined },
                },
            }
        case 'USER_LOGOUT':
            return { ...baseState }
        default:
            return state
    }
}

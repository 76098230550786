import { getFromLocalStorage } from 'utilities/localStorage';
export const FEATURES = {
    VIPPS: 'vipps',
    STORE: 'store',
    DATAFACTORY: 'datafactory',
};
export function isFeatureEnabled(features, feature) {
    return features[feature];
}
export function getFeaturesEnabled() {
    const urlFeatures = getUrlFeatures();
    const localFeatures = getLocalStorageFeatures();
    const envFeatures = getEnvFeatures();
    return envFeatures.concat(localFeatures, urlFeatures);
}
function getEnvFeatures() {
    return process.env.FEATURES_ENABLED ? process.env.FEATURES_ENABLED.split(',') : [];
}
function getUrlFeatures() {
    const urlParams = new URLSearchParams(window.location.search);
    const features = urlParams.get('features');
    return features ? features.split(',') : [];
}
function getLocalStorageFeatures() {
    const features = getFromLocalStorage('features');
    return features ? features.split(',') : [];
}

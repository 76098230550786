var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { removeCouponCode } from 'actions/Coupon';
import axios from 'axios';
import { checkCart } from 'cart';
import { addressIsEqual } from 'utilities/address';
import { placeOrder } from '../actions/placeOrder';
const initialState = {
    recipients: [],
    orderType: 'single',
    errors: [],
    orderAttempts: 0,
    giftMessage: undefined,
    orderConfirmationData: undefined,
    orderConfirmationDataLoading: false,
    orderConfirmationDataErrored: false,
    orderConfirmationDataErrorMessage: undefined,
    showRelatedProductsInterrupt: true,
};
export function concatGiftMessageData(data) {
    var _a;
    if (!data)
        return '';
    const message = (_a = data.message) !== null && _a !== void 0 ? _a : '';
    const greeting = data.greeting ? '\n' + data.greeting : '';
    return message + greeting;
}
export const corePurchaseComplete = createAsyncThunk('newCheckout/corePurchaseComplete', (sessionId, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    return axios
        .get(`${process.env.CORE_API}/order/session/${sessionId}`)
        .then((response) => ({ response, sessionId }))
        .catch((error) => thunkApi.rejectWithValue(error.message));
}));
const checkoutSlice = createSlice({
    name: 'newCheckout',
    initialState: initialState,
    reducers: {
        addRecipient: (state, action) => {
            state.recipients.push(action.payload);
        },
        removeRecipientIfPresent: (state, action) => {
            state.recipients = state.recipients.filter((recipient) => !addressIsEqual(recipient.deliveryAddress, action.payload));
        },
        setRecipients: (state, action) => {
            state.recipients = action.payload;
        },
        setOrderType: (state, action) => {
            state.orderType = action.payload;
        },
        incrementOrderAttempts: (state) => {
            state.orderAttempts += 1;
        },
        resetOrderAttempts: (state) => {
            state.orderAttempts = 0;
        },
        addOrderError: (state, action) => {
            state.errors = state.errors ? state.errors : [];
            state.errors.push(action.payload);
        },
        removeOrderError: (state, action) => {
            state.errors = state.errors.filter((error) => error.code !== action.payload.code);
        },
        setGiftMessage: (state, action) => {
            state.giftMessage = action.payload;
        },
        setShowRelatedProductsInterrupt: (state, action) => {
            state.showRelatedProductsInterrupt = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(placeOrder.pending, (state) => {
            state.errors = [];
        });
        builder.addCase(corePurchaseComplete.pending, (state, _) => {
            state.orderConfirmationData = undefined;
            state.orderConfirmationDataLoading = true;
            state.orderConfirmationDataErrored = false;
            state.orderConfirmationDataErrorMessage = undefined;
        });
        builder.addCase(corePurchaseComplete.fulfilled, (state, action) => {
            state.orderConfirmationData = action.payload.response.data;
            state.orderConfirmationDataLoading = false;
            state.orderConfirmationDataErrored = false;
            state.orderConfirmationDataErrorMessage = undefined;
        });
        builder.addCase(corePurchaseComplete.rejected, (state, action) => {
            var _a;
            state.orderConfirmationData = undefined;
            state.orderConfirmationDataLoading = false;
            state.orderConfirmationDataErrored = true;
            state.orderConfirmationDataErrorMessage = (_a = action.payload) !== null && _a !== void 0 ? _a : 'rejected';
        });
        builder.addCase('SET_REGION', (state) => {
            state.recipients = [];
        });
        builder.addCase('CLEAR_ORDER_CONFIRMATION_DATA', (state) => {
            state.orderConfirmationData = undefined;
            state.giftMessage = undefined;
        });
    },
});
export function setOrderType(orderType) {
    return (dispatch, getState) => {
        dispatch(checkoutSlice.actions.setOrderType(orderType));
        if (orderType === 'group' && getState().coupon.codeIsValid) {
            dispatch(removeCouponCode());
        }
    };
}
export function setRecipients(newRecipients) {
    return (dispatch) => {
        dispatch(checkoutSlice.actions.setRecipients(newRecipients));
        dispatch(checkCart());
    };
}
export function removeRecipientIfPresent(recipient) {
    return (dispatch) => {
        dispatch(checkoutSlice.actions.removeRecipientIfPresent(recipient));
        dispatch(checkCart());
    };
}
export const { addRecipient, addOrderError, incrementOrderAttempts, resetOrderAttempts, removeOrderError, setGiftMessage, setShowRelatedProductsInterrupt, } = checkoutSlice.actions;
export default checkoutSlice.reducer;

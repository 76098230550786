import './style.scss'

import React, { Component } from 'react'

import SubscriptionItem from 'components/Subscription/SubscriptionItem'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getSubscriptionWithProductInfo } from 'utilities/subscription'

export class SubscriptionContent extends Component {
    render() {
        const { subscriptionInfo } = this.props
        if (subscriptionInfo.length < 1) {
            return (
                <div className="subscription-content subscription-content--empty">
                    Du har ingen faste leveringer.
                </div>
            )
        }

        return (
            <div className="subscription-content">
                {subscriptionInfo.map((item, idx) => {
                    return <SubscriptionItem key={idx} item={item} />
                })}
            </div>
        )
    }
}

SubscriptionContent.propTypes = {
    subscriptionInfo: PropTypes.array,
    isMobile: PropTypes.bool,
    view: PropTypes.string,
}

export const mapStateToProps = ({ ui, products, subscription }) => {
    return {
        isMobile: ui.isMobile,
        subscriptionInfo: getSubscriptionWithProductInfo(subscription, products.products),
    }
}

export default connect(mapStateToProps)(SubscriptionContent)

import { deactivatePrimaryAddress, saveAddress, setActiveAddress, storeAddress, toggleEditAddress, } from 'actions/Address';
import { toggleAddressDropdown } from 'actions/UI';
import { useCoalescedAddresses } from 'address';
import { RecipientList } from 'checkout';
import { Button, ButtonRow } from 'common';
import Alert from 'components/UI/Alert';
import Loading from 'components/UI/LoadingScreen';
import { GenericAddressForm } from 'delivery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalError } from 'utilities/error';
export default function AddressDropdownDelivery({ isRegionDropdownShowing, }) {
    const dispatch = useDispatch();
    const [isCheckingAddress, setIsCheckingAddress] = useState(false);
    const [shouldCreateNewPrimaryAddress, setShouldCreateNewPrimaryAddress] = useState(false);
    const addresses = useCoalescedAddresses();
    const noAddresses = addresses.length < 1;
    const editAddress = useSelector((state) => state.address.edit);
    const activeAddress = useSelector((state) => state.address.active);
    const savingAddress = useSelector((state) => state.address.savingAddress);
    const savingAddressError = useSelector((state) => state.address.savingAddressError);
    const distributionLoading = useSelector((state) => state.distribution.distributionLoading);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const [addressCompleteHandler, setAddressCompleteHandler] = useState();
    const handleStoreAddress = (address) => {
        if (address) {
            if (!isLoggedIn) {
                dispatch(storeAddress(address));
                dispatch(toggleEditAddress(false));
            }
            else {
                dispatch(saveAddress(address.deliveryAddress, shouldCreateNewPrimaryAddress));
                setIsCheckingAddress(true);
                if (shouldCreateNewPrimaryAddress) {
                    dispatch(deactivatePrimaryAddress());
                    setShouldCreateNewPrimaryAddress(false);
                }
            }
        }
    };
    const handleSelectAddress = (address, close) => {
        dispatch(setActiveAddress(address));
        close && dispatch(toggleAddressDropdown());
    };
    const handleEditAbort = () => {
        if (!noAddresses) {
            dispatch(toggleEditAddress());
        }
        else {
            dispatch(toggleAddressDropdown(false));
        }
    };
    const handleAddressComplete = () => {
        if (!isCheckingAddress && addressCompleteHandler) {
            addressCompleteHandler.handleAddressComplete();
        }
    };
    const handleNewPrimaryAddress = () => {
        setShouldCreateNewPrimaryAddress(true);
        dispatch(toggleEditAddress(true));
    };
    useEffect(() => {
        if (isCheckingAddress && !distributionLoading && !savingAddress) {
            setIsCheckingAddress(false);
        }
    }, [distributionLoading, savingAddress]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null, isRegionDropdownShowing
            ? 'Eller velg leveringsadresse'
            : 'Velg leveringsadresse'),
        savingAddressError && (React.createElement("div", { className: "address-dropdown__error-alert" },
            React.createElement(Alert, { type: 'danger' }, generalError(savingAddressError)))),
        editAddress ? (React.createElement(React.Fragment, null, savingAddress ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
            React.createElement(GenericAddressForm, { onComplete: handleStoreAddress, hideButton: true, setAddressCompleteHandler: setAddressCompleteHandler }),
            React.createElement(ButtonRow, { display: 'space-between' },
                React.createElement(Button, { type: "action", style: "ghost", onClick: handleEditAbort }, "Tilbake"),
                React.createElement(Button, { type: "action", style: "primary", onClick: handleAddressComplete }, "Legg til")))))) : (React.createElement(React.Fragment, null,
            savingAddress && React.createElement(Loading, null),
            React.createElement(RecipientList, { recipients: addresses, currentlySelectedRecipients: activeAddress ? [activeAddress] : [], handleSelect: (address) => handleSelectAddress(address, false), handleNewPrimaryAddress: handleNewPrimaryAddress }))),
        !editAddress && (React.createElement(ButtonRow, { display: "center" },
            React.createElement(Button, { type: "action", style: "secondary", onClick: () => dispatch(toggleEditAddress(true)) }, "Ny adresse")))));
}

import { gotoCheckoutPage } from 'actions/Checkout';
import { setDeliverySms } from 'actions/PersonalInfo';
import { toggleCart } from 'actions/UI';
import { CartDeliverTo } from 'cart';
import { CheckoutCvc, PlaceOrderButton } from 'checkout';
import { Button, ButtonStack } from 'common';
import CheckboxWithLabel from 'common/components/CheckboxWithLabel/CheckboxWithLabel';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { PaymentMethodType } from 'types/paymentMethodType';
import { webLogin } from 'user';
import './CartCheckout.scss';
function CartCheckout({ address, canDeliverToAddress, canGoToCartOnly, deliveryDate, hasItemsInCart, isLoggedIn, }) {
    const dispatch = useDispatch();
    const goToCheckout = () => {
        dispatch(toggleCart(false));
        dispatch(gotoCheckoutPage('SINGLE_ORDER'));
    };
    const [oneClickCheckout, setOneClickCheckout] = useState(false);
    const deliverySms = useSelector((state) => state.personalInfo.deliverySms);
    const activeAddressMobile = useSelector((state) => { var _a, _b; return (_b = (_a = state.address.active) === null || _a === void 0 ? void 0 : _a.deliveryAddress) === null || _b === void 0 ? void 0 : _b.mobile; });
    const setSms = (newValue) => dispatch(setDeliverySms(newValue));
    const hasAddress = !isEmpty(address);
    return (React.createElement("div", { className: "cart-checkout" }, hasItemsInCart && (React.createElement("div", null,
        hasAddress && address && (React.createElement(CartDeliverTo, { address: address, deliveryDate: deliveryDate })),
        canGoToCartOnly && (React.createElement(ButtonStack, null,
            React.createElement(Button, { type: "action", style: "primary", disabled: !canDeliverToAddress, className: "go-to-checkout-btn", onClick: () => goToCheckout() }, "G\u00E5 til kassen"))),
        !isLoggedIn && (React.createElement("div", { className: "cart-checkout__checkout-details" },
            "Har du allerede en bruker? Da kan du betale rett fra handlekurven.",
            React.createElement("br", null),
            React.createElement(Button, { type: "action", style: "ghost", size: "small", onClick: () => webLogin(), className: "cart-checkout__login-button" }, "Logg inn"))),
        !canGoToCartOnly && (React.createElement("div", null,
            oneClickCheckout && (React.createElement("div", { className: "cart-checkout__one-click-checkout" },
                React.createElement("div", { className: "cart-checkout__cvc-section" },
                    React.createElement("hr", { className: "cart-checkout__cvc-section--divider" }),
                    React.createElement("h3", { className: "cart-checkout__cvc-section--title" }, "Legg inn din CVC-kode"),
                    React.createElement(CheckoutCvc, null)),
                React.createElement(CheckboxWithLabel, { isChecked: deliverySms, setIsChecked: setSms, labelText: `Send SMS til ${activeAddressMobile} når pakken er levert.` }))),
            oneClickCheckout && (React.createElement(ButtonStack, null,
                React.createElement(PlaceOrderButton, null),
                React.createElement(Button, { type: "action", style: "ghost", onClick: () => goToCheckout() }, "Eller g\u00E5 til kassen for \u00E5 endre"))),
            !oneClickCheckout && (React.createElement(ButtonStack, null,
                React.createElement(Button, { type: "action", style: "primary", className: "go-to-checkout-btn", onClick: () => setOneClickCheckout(true) }, "G\u00E5 rett til betaling"),
                React.createElement(Button, { type: "action", style: "secondary", onClick: () => goToCheckout() }, "G\u00E5 til kassen")))))))));
}
const mapStateToProps = ({ address, cart, checkout, distribution, newPaymentMethod, newCheckout, user, }) => {
    var _a;
    const paymentMethodTypeIsVipps = checkout.paymentMethodType === PaymentMethodType.VIPPS;
    const isSingleOrder = newCheckout.orderType === 'single';
    const cartTotalNotZero = cart.total > 0;
    const canGoToCartOnly = !checkout.oneClickCheckoutEnabled ||
        (paymentMethodTypeIsVipps && cartTotalNotZero) ||
        newPaymentMethod.active ||
        !isSingleOrder;
    return {
        address: (_a = address.active) === null || _a === void 0 ? void 0 : _a.deliveryAddress,
        canDeliverToAddress: distribution.canDeliver,
        canGoToCartOnly,
        deliveryDate: checkout.deliveryDate,
        hasItemsInCart: cart.products.length > 0,
        isGift: newCheckout.orderType === 'gift',
        isLoggedIn: user.isLoggedIn,
    };
};
export default connect(mapStateToProps)(CartCheckout);

import './style.scss'

import { CSSTransition, TransitionGroup } from 'react-transition-group'
import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

const PAGE_TRANSITION_DURATION = 300

class PageTransition extends Component {
    constructor(props) {
        super(props)
        this.back = false
        this.animate = false
        this.history = [props.location.key]
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const location = nextProps.location
        const action = nextProps.history.action
        const key = location.key

        this.back = this.goingBack(action, key)
        this.animate = !this.samePage(location)
        this.updateHistory(action, key, location)
    }

    goingBack(action, key) {
        return action === 'POP' && key === this.history[this.history.length - 2]
    }

    samePage(location) {
        return location.pathname === this.props.location.pathname
    }

    updateHistory(action, key, location) {
        if (location === this.props.location) return

        if (this.back) {
            this.history.pop()
        } else if (action === 'REPLACE') {
            this.history[this.history.length - 1] = key
        } else {
            this.history.push(key)
        }
    }

    render() {
        const { location, isMobile } = this.props
        const classes = classNames({
            'page-transition-container': true,
            'page-transition-back': this.back,
            'page-transition-disabled': !this.animate,
        })

        // TODO: avoid checking pathname
        if (
            !isMobile ||
            /\/products\/.*/.test(location.pathname) ||
            /\/bedrift\/utvalg\/.*/.test(location.pathname)
        )
            return this.props.children

        return (
            <div className={classes}>
                <TransitionGroup component={null}>
                    <CSSTransition
                        key={location.key}
                        classNames="slide"
                        timeout={this.animate ? PAGE_TRANSITION_DURATION : 0}
                    >
                        <div className="page-transition-content">{this.props.children}</div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        )
    }
}

PageTransition.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    isMobile: PropTypes.bool,
}

export default PageTransition

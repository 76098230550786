import { fetchStreetNames, fetchStreetNumbers, setCO, setFloor, setHousehold, setStreetAddress, setStreetNumber, } from 'actions/AddressHelper';
import NewTextInput from 'common/components/TextInput/NewTextInput';
import InputRow from 'components/UI/InputRow';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findBestFloorMatch, findBestHouseholdMatch, findBestStreetNameMatch, findBestStreetNoMatch, formatFloor, formatHousehold, formatStreetName, formatStreetNo, } from 'utilities/address';
import './AddressHelper.scss';
import AddressInput from './AddressInput';
import ApartmentNumberHelp from './ApartmentNumberHelp/ApartmentNumberHelp';
import { capitalize } from 'lodash';
const FIELD_NAME_STREETNAME = 'streetname';
const FIELD_NAME_STREETNO = 'streetno';
const FIELD_NAME_FLOOR = 'floor';
const FIELD_NAME_HOUSEHOLD = 'flat';
function AddressHelper({ noFocus, highlightInvalid }) {
    const dispatch = useDispatch();
    const fetchSteetNames = (streetName) => {
        dispatch(fetchStreetNames(streetName));
    };
    const delayedFetchStreetNames = debounce(fetchSteetNames, 200);
    const onStreetNameChange = (streetName) => {
        delayedFetchStreetNames(streetName);
    };
    const addressHelper = useSelector((state) => state.addressHelper);
    const { streets, searchValues, numbers, households, floors, unknownAddress, formAutoNavigate: initialFormAutoNavigate, navigateFromField: initialNavigateFromField, } = addressHelper;
    const currentAddress = {
        deliveryPointId: addressHelper.deliveryPointId,
        streetName: addressHelper.streetName,
        streetNo: addressHelper.streetNo,
        entrance: addressHelper.entrance,
        floorNo: addressHelper.floorNo,
        floorType: addressHelper.floorType,
        flatNo: addressHelper.flatNo,
        co: addressHelper.co,
        zip: addressHelper.zip,
        city: addressHelper.city,
        countryCode: addressHelper.countryCode,
        firstName: addressHelper.firstName,
        lastName: addressHelper.lastName,
        mobile: addressHelper.phoneNumber,
    };
    const [formAutoNavigate, setFormAutoNavigate] = useState(initialFormAutoNavigate);
    const [navigateFromField, setNavigateFromField] = useState(initialNavigateFromField);
    // Have to use callback refs because useRef refs cannot be used in the dependency array of useEffect
    const [streetInput, setStreetInput] = useState();
    const [numberInput, setNumberInput] = useState();
    const [floorInput, setFloorInput] = useState();
    const [householdInput, setHouseholdInput] = useState();
    const [coInput, setCoInput] = useState();
    const streetInputRef = useCallback((node) => setStreetInput(node), []);
    const numberInputRef = useCallback((node) => setNumberInput(node), []);
    const floorInputRef = useCallback((node) => setFloorInput(node), []);
    const householdInputRef = useCallback((node) => setHouseholdInput(node), []);
    const coInputRef = useCallback((node) => setCoInput(node), []);
    useEffect(() => {
        noFocus || (streetInput === null || streetInput === void 0 ? void 0 : streetInput.focus());
    }, [noFocus, streetInput]);
    const setAutoNavigate = (_formAutoNavigate, _navigateFromField) => {
        // Must exit a different field to update autonavigate
        const update = !(addressHelper.formAutoNavigate && addressHelper.navigateFromField === _navigateFromField);
        if (update) {
            setFormAutoNavigate(_formAutoNavigate);
            setNavigateFromField(_navigateFromField);
        }
    };
    const resetAutoNavigate = () => {
        setFormAutoNavigate(false);
        setNavigateFromField(undefined);
    };
    useEffect(() => {
        if (formAutoNavigate) {
            switch (navigateFromField) {
                case FIELD_NAME_STREETNAME:
                    numberInput === null || numberInput === void 0 ? void 0 : numberInput.focus();
                    break;
                case FIELD_NAME_STREETNO:
                    floorInput === null || floorInput === void 0 ? void 0 : floorInput.focus();
                    break;
                case FIELD_NAME_FLOOR:
                    householdInput === null || householdInput === void 0 ? void 0 : householdInput.focus();
                    break;
                case FIELD_NAME_HOUSEHOLD:
                    coInput === null || coInput === void 0 ? void 0 : coInput.focus();
                    break;
            }
        }
    }, [navigateFromField, formAutoNavigate, numberInput, floorInput, householdInput, coInput]);
    return (React.createElement("div", { className: "address-helper" },
        React.createElement(InputRow, null,
            React.createElement(AddressInput, { onChange: (value) => onStreetNameChange(value), className: "address-helper__street", inputRef: streetInputRef, items: streets, name: FIELD_NAME_STREETNAME, label: "Gatenavn", errorMessage: "Gatenavn m\u00E5 fylles ut", fillHelp: React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "t-strong" }, "Tips:"),
                    " Skriv gatenavn",
                    React.createElement("span", { className: "t-input" }, ","),
                    " og",
                    React.createElement("span", { className: "t-input" }, "poststed")), value: searchValues['streetname'], view: formatStreetName, findBestMatchIndex: findBestStreetNameMatch, onComplete: (street, name, autoNavigate, wasTab) => {
                    setAutoNavigate(autoNavigate && !wasTab, name);
                    dispatch(setStreetAddress(street));
                }, setSelectedValue: (value) => capitalize(value.streetName), onFocus: () => resetAutoNavigate(), highlightInvalid: highlightInvalid, isActive: true }),
            React.createElement(AddressInput, { inputRef: numberInputRef, className: "address-helper__number", items: numbers, name: FIELD_NAME_STREETNO, value: searchValues['streetno'], label: "Nr", view: formatStreetNo, findBestMatchIndex: findBestStreetNoMatch, onChange: (e) => {
                    dispatch(fetchStreetNumbers(e, addressHelper.streetIds));
                }, onComplete: (number, name, autoNavigate) => {
                    setAutoNavigate(autoNavigate, name);
                    dispatch(setStreetNumber(number));
                }, onFocus: () => resetAutoNavigate(), highlightInvalid: highlightInvalid, isActive: true, errorMessage: "Gatenummer m\u00E5 fylles ut" })),
        React.createElement(InputRow, null,
            React.createElement(NewTextInput, { inputState: 'disabled', className: "address-helper__text-input", onChange: () => undefined, value: currentAddress.city, label: "Poststed" }),
            React.createElement(NewTextInput, { inputState: 'disabled', className: "address-helper__text-input", onChange: () => undefined, value: currentAddress.zip, label: "Postnummer" })),
        addressHelper.showHouseholds && (React.createElement(InputRow, null,
            React.createElement(AddressInput, { inputRef: floorInputRef, items: floors || [], label: "Etasje", name: FIELD_NAME_FLOOR, value: searchValues['floor'] || '', view: (item) => formatFloor(item), findBestMatchIndex: (items, value) => findBestFloorMatch(items, value), onComplete: (floor, name, autoNavigate) => {
                    setAutoNavigate(autoNavigate, name);
                    dispatch(setFloor(floor));
                }, onFocus: () => resetAutoNavigate(), highlightInvalid: highlightInvalid, isActive: true, errorMessage: "Etasje m\u00E5 fylles ut" }),
            React.createElement(AddressInput, { inputRef: householdInputRef, items: households || [], name: FIELD_NAME_HOUSEHOLD, value: searchValues['flat'] || '', label: "Leilighet", view: (household) => formatHousehold(currentAddress.floorType || '', currentAddress.floorNo || 0, household.flatNo), findBestMatchIndex: (items, value) => findBestHouseholdMatch(items, currentAddress.floorType || '', currentAddress.floorNo || 0, value), onComplete: (household, name) => {
                    setAutoNavigate(true, name);
                    dispatch(setHousehold(household));
                }, onFocus: () => resetAutoNavigate(), highlightInvalid: highlightInvalid, isActive: true, helpIcon: React.createElement(ApartmentNumberHelp, null), errorMessage: "Leilighetsnummer m\u00E5 fylles ut" }))),
        unknownAddress && (React.createElement("span", { className: "address-helper__unknown" }, "Vi leverer dessverre ikke til din adresse.")),
        React.createElement(NewTextInput, { inputState: "incomplete", areaInput: true, className: "address-helper__text-input", value: currentAddress.co, maxLength: 255, inputRef: coInputRef, onChange: (e) => {
                dispatch(setCO(e));
            }, label: "Navn p\u00E5 \u201Ceier\u201D av postadressen/postkasse, eventuell tilleggsinfo (r\u00F8dt hus, gul postkasse etc.)\n                        Omr\u00E5det du bestiller til bestemmer om det er levering i postkasse eller p\u00E5 d\u00F8rmatten." })));
}
export default AddressHelper;

import React, { Component } from 'react'

import AppPortal from 'components/UI/AppPortal'
import Notification from 'components/UI/Notification'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class DistributionErrorNotification extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hide: false,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.dates !== this.dates) {
            this.setState({ hide: false })
        }
    }
    handleClose() {
        this.setState({ hide: true })
    }
    render() {
        const { show, dates } = this.props
        if (!show || this.state.hide || !dates) return null
        return (
            <AppPortal>
                <Notification title="Teknisk feil" onClose={() => this.handleClose()}>
                    <p>
                        Vi opplever dessverre en teknisk feil med oppslag av enkelte adresser. Dette
                        gjør at enkelte ikke får bestilt. Vi jobber med å rette feilen. Vennligst
                        prøv igjen senere.
                    </p>
                </Notification>
            </AppPortal>
        )
    }
}

DistributionErrorNotification.propTypes = {
    show: PropTypes.bool,
    dates: PropTypes.array,
    dispatch: PropTypes.func,
}

const mapStateToProps = ({ distribution }) => {
    return { show: distribution.error, dates: distribution.dates }
}

export default connect(mapStateToProps)(DistributionErrorNotification)

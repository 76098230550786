import { setCardCvc } from 'actions/NewPaymentMethod';
import TextInput from 'components/UI/TextInput';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isValidCvc } from 'utilities/validators';
import './CheckoutCvc.scss';
function CheckoutCVC({ cvc }) {
    const dispatch = useDispatch();
    const [cvcInput, setCvcInput] = useState();
    const orderAttempts = useSelector((state) => state.newCheckout.orderAttempts);
    const cvcInputRef = useCallback((node) => setCvcInput(node), []);
    useEffect(() => {
        return () => {
            dispatch(setCardCvc(''));
        };
    }, []);
    useEffect(() => {
        if (orderAttempts > 0 && cvcInput) {
            cvcInput.reportValidity();
        }
    }, [orderAttempts]);
    return (React.createElement("div", { className: "checkout-cvc" },
        React.createElement(TextInput, { inputRef: cvcInputRef, className: "checkout-cvc__cvc", label: "CVC", type: "tel", value: cvc, maxLength: 3, validator: isValidCvc, invalidHelp: "Ugyldig CVC kode", onChange: (val) => dispatch(setCardCvc(val)), required: true }),
        React.createElement("p", { className: "checkout-cvc__inputHelpText" }, "De tre siste tallene bak signaturfeltet.")));
}
const mapStateToProps = ({ newPaymentMethod }) => {
    return {
        cvc: newPaymentMethod.cvc,
    };
};
export default connect(mapStateToProps)(CheckoutCVC);

import { saveAddress, setActiveAddress, storeAddress } from 'actions/Address';
import { AddressItem, EditAddressForm } from 'address';
import { OrderTypeToggle, useOnInvalidOrderAttempt } from 'checkout';
import { Card, LinkBox } from 'common';
import { push } from 'connected-react-router';
import { GenericAddressForm } from 'delivery';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './RecipientCard.scss';
import RecipientCardContentGroup from './RecipientCardContentGroup';
function RecipientCard() {
    const dispatch = useDispatch();
    const recipientCardRef = useRef(null);
    const [editAddress, setEditAddress] = useState();
    const [focusPhoneInput, setFocusPhoneInput] = useState(false);
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const orderAttempts = useSelector((state) => state.newCheckout.orderAttempts);
    const numRecipients = useSelector((state) => state.newCheckout.recipients.length);
    const checkoutMode = useSelector((state) => state.checkout.checkoutMode);
    const canDeliver = useSelector((state) => state.distribution.canDeliver);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const isSubscription = checkoutMode === 'SUBSCRIPTION_ORDER';
    const recipientsTitle = orderType === 'group' ? 'Mottakere' : 'Mottaker';
    const selectedAddressInfo = useSelector((state) => state.address.active);
    const addresses = useSelector((state) => state.address.addresses);
    const transportProduct = useSelector((state) => state.region.transportProduct);
    const addressIsValid = selectedAddressInfo !== null;
    const handleUseAddress = (address) => {
        if (address) {
            dispatch(isLoggedIn ? saveAddress(address.deliveryAddress, false) : storeAddress(address));
            dispatch(setActiveAddress(address));
        }
    };
    useEffect(() => {
        if (!addressIsValid && addresses.length > 0) {
            const addressInCurrentRegion = addresses.find((address) => address.deliveryAddress.transportProduct === transportProduct);
            addressInCurrentRegion && dispatch(setActiveAddress(addressInCurrentRegion));
        }
    }, [addresses, transportProduct]);
    const invalidInput = !addressIsValid || numRecipients > 10;
    useOnInvalidOrderAttempt(invalidInput && !!recipientCardRef.current, () => {
        var _a;
        const rect = (_a = recipientCardRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        rect && window.scrollBy({ top: rect.y - 100, behavior: 'smooth' });
    });
    return (React.createElement(Card, { title: recipientsTitle, ref: recipientCardRef },
        React.createElement("div", { className: "recipient-card" },
            editAddress && (React.createElement(EditAddressForm, { addressToEdit: editAddress, canChangePrimaryAddress: false, closeModal: () => setEditAddress(undefined), focusPhoneInput: focusPhoneInput })),
            !isSubscription && (React.createElement("div", { className: "recipient-card__centered" },
                React.createElement(OrderTypeToggle, { name: "checkout" }))),
            orderType === 'group' && React.createElement(RecipientCardContentGroup, null),
            orderType !== 'group' &&
                (addressIsValid && selectedAddressInfo ? (React.createElement(React.Fragment, null,
                    React.createElement(AddressItem, { address: selectedAddressInfo.deliveryAddress, className: "recipient-card__address", isPrimary: selectedAddressInfo.isPrimary, onEdit: (focusPhone) => {
                            setFocusPhoneInput(focusPhone || false);
                            setEditAddress(selectedAddressInfo);
                        }, warningText: !canDeliver ? 'Vi kan dessverre ikke levere til denne adressen' : undefined }),
                    React.createElement(LinkBox, { colorStyle: "gray", text: "Endre mottaker(e)", className: "recipient-card__add-recipients-linkbox", onClick: () => dispatch(push('/checkout/address')) }))) : (React.createElement(GenericAddressForm, { onComplete: handleUseAddress, highlightInvalid: orderAttempts > 0 }))))));
}
export default RecipientCard;

import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Button.scss';
const isActionButton = (props) => props.type === 'action';
const isSubmitButton = (props) => props.type === 'submit';
const isInternalLinkButton = (props) => props.type === 'navigation';
const isExternalLinkButton = (props) => props.type === 'externalLink';
export function Button(props) {
    const { children, style, size, padding, className } = props;
    const classes = classNames('button', {
        [`button--${style}`]: true,
        'button--disabled': (isActionButton(props) || isSubmitButton(props)) && props.disabled,
        'button--small': size === 'small',
        'button--tight': padding === 'tight',
        [`${className}`]: className,
    });
    if (isActionButton(props)) {
        return (React.createElement("button", { type: "button", onClick: props.onClick, disabled: props.disabled, className: classes }, children));
    }
    if (isSubmitButton(props)) {
        return (React.createElement("button", { type: "submit", disabled: props.disabled, className: classes }, children));
    }
    if (isInternalLinkButton(props)) {
        return (React.createElement(NavLink, { to: props.to, className: classes }, children));
    }
    if (isExternalLinkButton(props)) {
        return (React.createElement("a", { href: props.href, className: classes }, children));
    }
    return null;
}

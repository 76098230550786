import React from 'react';
import ArticleBodyImage from '../components/ArticleBodyImage/ArticleBodyImage';
import ArticleImageQuote from '../components/ArticleImageQuote/ArticleImageQuote';
const portableTextBlogComponents = {
    types: {
        image: ({ value }) => React.createElement(ArticleBodyImage, { value: value }),
        pictureQuote: ({ value }) => React.createElement(ArticleImageQuote, { value: value }),
    },
};
export { portableTextBlogComponents };

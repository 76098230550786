import './style.scss'

import React, { Component } from 'react'

import PropTypes from 'prop-types'

class Overlay extends Component {
    handleClick(e) {
        this.props.onClick(e)
    }
    render() {
        return (
            <div className="overlay" onClick={(e) => this.handleClick(e)}>
                {this.props.children}
            </div>
        )
    }
}

Overlay.propTypes = {
    onClick: PropTypes.func,
}

export default Overlay

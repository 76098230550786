import { useOnInvalidOrderAttempt, useSelectedPaymentOption } from 'checkout';
import { Card } from 'common';
import React, { useRef } from 'react';
import CheckoutPaymentMethodTypeSelector from '../CheckoutPaymentMethodTypeSelector';
export default function PaymentCard() {
    const paymentCardRef = useRef(null);
    const selectedPaymentOption = useSelectedPaymentOption();
    useOnInvalidOrderAttempt(selectedPaymentOption.type === 'NONE', () => {
        var _a;
        const rect = (_a = paymentCardRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        rect && window.scrollBy({ top: rect.y - 100, behavior: 'smooth' });
    });
    return (React.createElement(Card, { title: "Betaling", ref: paymentCardRef },
        React.createElement(CheckoutPaymentMethodTypeSelector, null)));
}

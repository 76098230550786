import classNames from 'classnames';
import React from 'react';
import NavigationLink from '../NavigationLink/NavigationLink';
import './Pill.scss';
var PillType;
(function (PillType) {
    PillType["Link"] = "Link";
    PillType["Selectable"] = "Selectable";
    PillType["SelectableLink"] = "SelectableLink";
})(PillType || (PillType = {}));
const Pill = React.forwardRef(function PillFunc({ children, pillType, onClick, navigationPath, active = false, type = 'primary' }, ref) {
    const classes = classNames({
        pill: true,
        'pill--link': pillType === PillType.Link,
        'pill--selectable': pillType === PillType.Selectable,
        'pill--selectable-link': pillType === PillType.SelectableLink,
        'pill--active': active,
        [`pill--${type}`]: true,
    });
    const content = navigationPath ? (React.createElement(NavigationLink, { to: navigationPath, classes: classes, onClick: onClick }, children)) : (React.createElement("button", { className: classes, onClick: onClick }, children));
    return React.createElement("li", { ref: ref }, content);
});
export { Pill, PillType };

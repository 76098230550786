import './CheckoutTerms.scss';
import React from 'react';
import Link from 'components/UI/Link';
function CheckoutTerms() {
    return (React.createElement("div", { className: "checkout-terms" },
        React.createElement("div", { className: "checkout-terms__label" },
            "Ved \u00E5 fullf\u00F8re godtar du",
            ' ',
            React.createElement(Link, { to: "/informasjon/personvern" }, "personvern og avtalevilk\u00E5rene v\u00E5re"),
            ".")));
}
export default CheckoutTerms;

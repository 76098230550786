import React from 'react';
import { useSelector } from 'react-redux';
export function CartItemError({ error, disabledForRegion, soldOut, tooMany, itemsAvailable, deadlinePassed, }) {
    const regionName = useSelector((state) => state.region.name);
    if (disabledForRegion || error == 'NotActiveInRegion') {
        return (React.createElement("span", { className: "cart-item__note" },
            "Varen kan ikke leveres til ",
            regionName,
            "."));
    }
    if (error == 'ItemNotFound' || error == 'MissingInventory') {
        return (React.createElement("span", { className: "cart-item__note" }, "Varen er ikke tilgjengelig."));
    }
    if (soldOut) {
        return (React.createElement("span", { className: "cart-item__note" },
            "Vi er dessverre utsolgt for denne varen til valgt leveringsdag. Fors\u00F8k en annen leveringsdag eller fjern varen.",
            ' '));
    }
    if (tooMany || error == 'NotEnoughStock') {
        return (React.createElement("span", { className: "cart-item__note" },
            "Vi har kun ",
            itemsAvailable,
            " av denne varen p\u00E5 lager."));
    }
    if (deadlinePassed) {
        return (React.createElement("span", { className: "cart-item__note" }, "Bestillingsfristen for varen har g\u00E5tt ut."));
    }
    return null;
}

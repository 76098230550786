import { CartBadge } from 'cart';
import classNames from 'classnames';
import Icon from 'components/UI/Icon';
import React from 'react';
import './CartButton.scss';
import { useSelector } from 'react-redux';
import sumBy from 'lodash/sumBy';
import { FEATURES, isFeatureEnabled } from 'common';
const cartIcon = require('img/cart.svg');
function CartButton({ isActive = false, onClick, }) {
    const isStoreEnabled = useSelector(({ features }) => isFeatureEnabled(features.enabled, FEATURES.STORE));
    const deliveryDate = useSelector(state => state.checkout.deliveryDate);
    const { cartCount, hasUnavailableProducts } = useSelector((state) => {
        return {
            cartCount: sumBy(state.cart.products, (cartItem) => cartItem.quantity),
            hasUnavailableProducts: isStoreEnabled ?
                state.cart.products.some(p => p.availability.length > 1 ?
                    p.availability.some(a => a.date == deliveryDate && a.quantity < p.quantity)
                    : p.availability[0].quantity < p.quantity)
                : state.cart.hasDisabledProductsForRegion || !state.cart.hasEnoughQuantity,
        };
    });
    const classes = classNames({
        'cart-button': true,
        'cart-button--unavailable-products': hasUnavailableProducts,
        'cart-button--active': isActive,
    });
    return (React.createElement("button", { className: classes, "aria-label": "\u00C5pne handlekurven", onClick: onClick },
        React.createElement(Icon, { svg: cartIcon, size: "medium" }),
        cartCount > 0 && (React.createElement(CartBadge, { count: cartCount, hasUnavailableProducts: hasUnavailableProducts }))));
}
export default CartButton;

import { NavigationLink, Pill, PillList, PillListType, PillType } from 'common';
import React from 'react';
import './PillListMenu.scss';
function PillListMenu({ heading, items, topLinkObject }) {
    return (React.createElement("div", { className: "pill-list-menu" },
        React.createElement("div", { className: "pill-list-menu__header" },
            React.createElement("h2", null, heading),
            topLinkObject && (React.createElement(NavigationLink, { classes: "pill-list-menu__header__link", to: topLinkObject.href }, topLinkObject.name))),
        React.createElement(PillList, { listType: PillListType.Vertical }, items.map((item, i) => (React.createElement(Pill, { key: i, pillType: PillType.Link, onClick: item.onClick, navigationPath: item.navigationPath }, item.text))))));
}
export { PillListMenu };

import { CartButton, viewCart } from 'cart';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { DeliveryOptionsButton } from 'delivery';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import './MobileBottomNavigation.scss';
function MobileBottomNavigation({ cartCount, }) {
    const dispatch = useDispatch();
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [isFirstRun, setIsFirstRun] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [touchStart, setTouchStart] = useState();
    const isScrollable = () => window.document.body.scrollHeight > window.innerHeight;
    const classes = classNames({
        'mobile-bottom-navigation__cart-button': true,
        'mobile-bottom-navigation__cart-button--animated': shouldAnimate,
    });
    const outerClasses = classNames({
        'mobile-bottom-navigation': true,
        'mobile-bottom-navigation--hidden': !isVisible,
    });
    const handleScroll = () => {
        const heightToHideFrom = window.document.body.clientHeight - window.innerHeight;
        const scrollY = Math.ceil(window.pageYOffset);
        if (scrollY >= heightToHideFrom) {
            isVisible && setIsVisible(false);
        }
        else {
            setIsVisible(true);
        }
    };
    const handleWheelOverscroll = (e) => {
        if (!isScrollable())
            setIsVisible(e.deltaY < 0);
    };
    const handleTouchOverscrollStart = (e) => {
        if (!isScrollable()) {
            setTouchStart(e.targetTouches[0].clientY);
        }
    };
    const handleTouchOverscroll = (e) => {
        if (touchStart && !isScrollable()) {
            setIsVisible(e.targetTouches[0].clientY > touchStart);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    useEffect(() => {
        window.addEventListener('wheel', handleWheelOverscroll);
        window.addEventListener('touchmove', handleTouchOverscroll);
        window.addEventListener('touchstart', handleTouchOverscrollStart);
        return () => {
            window.removeEventListener('wheel', handleWheelOverscroll);
            window.removeEventListener('touchmove', handleTouchOverscroll);
            window.removeEventListener('touchstart', handleTouchOverscrollStart);
        };
    }, [touchStart]);
    useEffect(() => {
        let isSubscribed = true;
        if (!isFirstRun) {
            setShouldAnimate(true);
            setTimeout(() => {
                isSubscribed ? setShouldAnimate(false) : null;
            }, 1000);
        }
        else {
            setIsFirstRun(false);
        }
        return () => {
            isSubscribed = false;
        };
    }, [cartCount]);
    return (React.createElement("div", { className: outerClasses },
        React.createElement(DeliveryOptionsButton, null),
        React.createElement("div", { className: classes },
            React.createElement(CartButton, { onClick: () => {
                    dispatch(viewCart());
                    dispatch(push('/cart'));
                } }))));
}
function mapStateToProps({ cart }) {
    return {
        cartCount: cart.products.reduce((prev, curr) => prev + curr.quantity, 0),
        hasUnavailableProducts: cart.hasDisabledProductsForRegion || !cart.hasEnoughQuantity,
    };
}
export default connect(mapStateToProps)(MobileBottomNavigation);

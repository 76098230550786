import classNames from 'classnames';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import './ProducerTile.scss';
function ProducerTile({ producer, hideImage }) {
    var _a;
    const dispatch = useDispatch();
    const classes = classNames({
        'producer-tile': true,
        'producer-tile--no-image': hideImage,
    });
    return (React.createElement("div", { className: classes, onClick: () => dispatch(push(`/produsent/${producer.slug}`)) },
        !hideImage && producer.backgroundImage && (React.createElement("picture", { className: "producer-tile__top-content" },
            React.createElement("source", { type: "producer-tile__top-content__main-image", srcSet: producer.backgroundImage.webp }),
            React.createElement("img", { className: "producer-tile__top-content__main-image", src: producer.backgroundImage.fallback, alt: "" }))),
        React.createElement("div", { className: "producer-tile__bottom-content" },
            React.createElement("div", { className: "producer-tile__bottom-content__heading" },
                ((_a = producer.logo) === null || _a === void 0 ? void 0 : _a.small) && (React.createElement("img", { className: "producer-tile__bottom-content__heading__logo", src: producer.logo.small, alt: "" })),
                React.createElement("div", { className: "producer-tile__bottom-content__heading__title" }, producer.name)),
            React.createElement("hr", { className: "producer-tile__bottom-content__separator" }),
            React.createElement("div", { className: "producer-tile__bottom-content__description" }, producer.description))));
}
export default ProducerTile;

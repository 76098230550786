import { Button, Spinner } from 'common';
import TextInput from 'components/UI/TextInput';
import groq from 'groq';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import sanityClient, { sanityImageBuilder } from 'utilities/sanityClient';
import { isValidEmail } from 'utilities/validators';
import './NewsletterRecruiter.scss';
import { subscribeToNewsletter } from './newsletterRecruiterActions';
export function NewsletterRecruiter({ documentId }) {
    const [document, setDocument] = useState();
    const [email, setEmail] = useState();
    const [submitState, setSubmitState] = useState('initial');
    const regionId = useSelector((state) => state.region.id);
    const isMobile = useSelector((state) => state.ui.isMobile);
    useEffect(() => {
        const query = groq `
            *[ _id == $documentId ][0] {
                image,
                title,
                preamble,
                reservations,
                inputPlaceholder,
                buttonText,
                confirmationText
            }
        `.replace(/[\s\n]/, '');
        let isSubscribed = true;
        sanityClient
            .fetch(query, { documentId })
            .then((document) => (isSubscribed ? setDocument(document) : null));
        return () => {
            isSubscribed = false;
        };
    }, [documentId]);
    const handleSubscribeToNewsletter = () => {
        if (email && isValidEmail(email)) {
            setSubmitState('pending');
            subscribeToNewsletter(email, regionId).then((success) => setSubmitState(success ? 'success' : 'error'));
        }
    };
    if (!document) {
        return null;
    }
    const imageUrl = sanityImageBuilder
        .image(document.image)
        .auto('format')
        .width(400)
        .fit('min')
        .dpr(window.devicePixelRatio)
        .url();
    return (React.createElement("div", { className: "newsletter-recruiter" },
        React.createElement("div", { className: "newsletter-recruiter__outer-wrapper" },
            React.createElement("div", { className: "newsletter-recruiter__inner-wrapper" },
                React.createElement("div", null,
                    React.createElement("img", { className: "newsletter-recruiter__image", src: imageUrl })),
                React.createElement("div", { className: "newsletter-recruiter__text-content" },
                    React.createElement("h2", { className: "newsletter-recruiter__title" }, document.title),
                    React.createElement("p", { className: "newsletter-recruiter__preamble" }, document.preamble),
                    {
                        initial: (React.createElement("div", { className: "newsletter-recruiter__input-row" },
                            React.createElement(TextInput, { placeholder: document.inputPlaceholder, onChange: setEmail, value: email }),
                            React.createElement(Button, { type: "action", style: "primary", padding: isMobile ? 'tight' : 'default', disabled: !email || !isValidEmail(email), onClick: handleSubscribeToNewsletter }, document.buttonText))),
                        pending: React.createElement(Spinner, { className: "newsletter-recruiter__spinner" }),
                        success: (React.createElement("p", { className: "newsletter-recruiter__confirmation" }, document.confirmationText)),
                        error: (React.createElement("p", { className: "newsletter-recruiter__error" }, "Beklager, det skjedde en feil")),
                    }[submitState],
                    document.reservations && (React.createElement("p", { className: "newsletter-recruiter__reservations" }, document.reservations)))))));
}

const baseState = {
    action: '',
    product: null,
    route: null,
}

export default function reducer(state = baseState, action) {
    switch (action.type) {
        case 'ADD_PRODUCT_TO_SUBSCRIPTION': {
            const { product } = action.payload
            if (!product.subscriptionProduct) return { ...state }
            return {
                ...state,
                action: 'add',
                route: '/subscription',
                product,
            }
        }
        case 'NOTIFY_SET_SUBSCRIPTION_MODE':
            return {
                ...state,
                action: 'mode_changed',
                route: null,
                product: null,
            }
        case 'RESET_SUBSCRIPTION_NOTIFICATION':
        case '@@router/LOCATION_CHANGE':
        case 'USER_LOGOUT':
            return { ...baseState }
        default:
            return state
    }
}

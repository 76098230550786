const baseState = {
    loading: false,
    addPaymentMethodFailed: false,
    preferred: {},
    subscription: {},
    all: [],
}

export default function reducer(state = baseState, action) {
    switch (action.type) {
        case 'FETCH_PAYMENT_METHODS':
            return { ...state, loading: state.all.length === 0 }
        case 'FETCH_PAYMENT_METHODS_FAILED':
            return {
                ...state,
                loading: false,
            }
        case 'SET_PREFERRED_PAYMENT_METHOD':
            const preferredPaymentMethodFromState = state.all.filter(
                (p) => p.paymentMethodId === action.payload
            )[0]
            return {
                ...state,
                preferred:
                    preferredPaymentMethodFromState != null
                        ? preferredPaymentMethodFromState
                        : state.preferred,
            }
        case 'FETCH_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS':
            return {
                ...state,
                subscription: action.payload,
            }
        case 'FETCH_PAYMENT_METHODS_SUCCESS':
            const preferred = action.payload.filter((p) => p.preferred)[0]
            const all = action.payload
            return {
                ...state,
                loading: false,
                preferred: preferred ? preferred : {},
                all,
            }
        case 'ADD_PAYMENT_METHOD_FAILED':
            return {
                ...state,
                addPaymentMethodFailed: true,
            }
        case 'ADD_PAYMENT_METHOD':
            return {
                ...state,
                addPaymentMethodFailed: false,
            }
        case 'USER_LOGOUT':
            return { ...baseState }
        default:
            return state
    }
}

import { userLogin, userLogout, userSessionChange } from 'user'

import { Identity } from '@schibsted/account-sdk-browser/es5/identity'

class SPiD {
    constructor() {
        try {
            const config = {
                clientId: process.env.SPID_CLIENT_ID,
                env: process.env.SPID_ENV,
                redirectUri: window.location.href,
                sessionDomain: process.env.SPID_SESSION_DOMAIN,
            }
            this.identity = new Identity(config)
        } catch (e) {
            console.warn('Unable to initialize SPiD -> ' + e)
        }
    }
    initSpid(store) {
        this.identity.on('login', (data) => store.dispatch(userLogin(data)))
        this.identity.on('logout', () => store.dispatch(userLogout()))
        this.identity.on('sessionChange', (data) => store.dispatch(userSessionChange(data)))
//////////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
/////////
//////////////////
        this.identity.isLoggedIn().then((isLoggedIn) => {
            if (isLoggedIn) {
                this.identity.getUser().then((data) => {
                    store.dispatch(userLogin(data))
                })
            }
        })
    }
}

export function goToSpidReceipt(externalPaymentOrderId) {
    var spidUrl = 'https://' + process.env.SPID_SERVER + '/'
    var target = spidUrl + 'account/purchasehistory?p2p_order_id=' + externalPaymentOrderId
    target += '&redirect_uri=' + encodeURIComponent(location.href)
    return target
}

export default new SPiD()

import React from 'react';
import { sanityImageBuilder } from 'utilities/sanityClient';
import './BundleProductTile.scss';
import { AddToCartButton } from 'product';
import { useIsSoldOut } from '../../hooks';
function BundleProductTile({ bundle }) {
    var _a;
    const isSoldOut = useIsSoldOut(bundle.products);
    const uniqueProducers = [...new Set(bundle.products.map((p) => (p.producer ? p.producer.name : p.vendor)))];
    const uniqueTags = removeDuplicates(bundle.products.flatMap((product) => product.filterTags), filterTagEquals);
    const uniqueBadges = removeDuplicates(bundle.products.map((product) => product.badge), badgeEquals);
    return (React.createElement("div", { className: 'product-bundle-tile' },
        React.createElement("img", { src: sanityImageBuilder
                .image(bundle.mainImage)
                .auto('format')
                .dpr(window.devicePixelRatio)
                .url(), className: 'product-bundle-tile__image', alt: bundle.title }),
        React.createElement("div", { className: 'product-bundle-tile__title' },
            React.createElement("h3", null, (_a = bundle.title) !== null && _a !== void 0 ? _a : bundle.products.map((p) => p.title).join(' + ')),
            React.createElement("span", null,
                "Fra",
                ' ',
                uniqueProducers.length === 1
                    ? uniqueProducers[0]
                    : `${uniqueProducers.slice(0, -1).join(', ')} og ${uniqueProducers.at(-1)}`)),
        React.createElement("div", { className: 'product-bundle-tile__badges' },
            React.createElement("div", { className: 'product-bundle-tile__left' }, uniqueTags.map((tag) => tag.image && (React.createElement("img", { src: tag.image, alt: tag.name, key: tag.name + tag.image })))),
            React.createElement("div", { className: 'product-tile__badges__right' }, uniqueBadges.map(badge => {
                return (badge === null || badge === void 0 ? void 0 : badge.image) && (React.createElement("img", { src: badge.image, alt: badge.name }));
            }))),
        React.createElement("div", null,
            React.createElement(AddToCartButton, { product: bundle.products, shrinkOnMobile: true, soldOut: isSoldOut }))));
}
function filterTagEquals(tag1, tag2) {
    return (tag1 === null || tag1 === void 0 ? void 0 : tag1.name) === (tag2 === null || tag2 === void 0 ? void 0 : tag2.name) && (tag1 === null || tag1 === void 0 ? void 0 : tag1.image) === (tag2 === null || tag2 === void 0 ? void 0 : tag2.image);
}
function badgeEquals(badge1, badge2) {
    return (badge1 === null || badge1 === void 0 ? void 0 : badge1.name) === (badge2 === null || badge2 === void 0 ? void 0 : badge2.name) && (badge1 === null || badge1 === void 0 ? void 0 : badge1.image) === (badge2 === null || badge2 === void 0 ? void 0 : badge2.image);
}
function removeDuplicates(array, comparator) {
    return array.filter((item, index, self) => index === self.findIndex((t) => comparator(t, item)));
}
export default BundleProductTile;

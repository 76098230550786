import classNames from 'classnames';
import Icon from 'components/UI/Icon';
import React from 'react';
import './LinkBox.scss';
const exclamationPoint = require('img/exclamationpoint.svg');
const LinkBox = React.forwardRef(function LinkBoxFunc({ onClick, colorStyle = 'gray', text, iconSvg, warning, className }, ref) {
    const classes = classNames('link-box', `link-box__${colorStyle}`, {
        [`${className}`]: className,
    });
    return (React.createElement("button", { className: classes, onClick: onClick, ref: ref },
        warning && (React.createElement("div", null,
            React.createElement(Icon, { className: "link-box__icon", svg: exclamationPoint }))),
        iconSvg && (React.createElement("div", null,
            React.createElement(Icon, { className: "link-box__icon", svg: iconSvg }))),
        React.createElement("label", null, text)));
});
export default LinkBox;

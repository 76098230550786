import { getFullAddressFromAddressHelper } from 'address';
import classNames from 'classnames';
import { Button, ButtonRow, CheckboxWithLabel } from 'common';
import NewTextInput from 'common/components/TextInput/NewTextInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isValidAddress, isValidMobile } from 'utilities/validators';
import './GenericAddressForm.scss';
import OldAddressHelper from '../../address/components/OldAddressHelper';
function GenericAddressForm({ onComplete, hideButton, setAddressCompleteHandler, highlightInvalid: highlightInvalidFromParent, edit, isPrimary = false, primaryAddressOptionEnabled = true, focusPhoneInput = false, }) {
    useClearIfLocationChanged();
    const firstName = useSelector((state) => state.addressHelper.firstName) || '';
    const lastName = useSelector((state) => state.addressHelper.lastName) || '';
    const phoneNumber = useSelector((state) => state.addressHelper.phoneNumber);
    const addressHelperIsFinished = useSelector((state) => state.addressHelper.isFinished);
    const addressHelper = useSelector((state) => state.addressHelper);
    const [highlightInvalid, setHighlightInvalid] = useState(false);
    const [isPrimaryAddress, setIsPrimaryAddress] = useState(false);
    const fullAddressHelperAddress = useMemo(() => getFullAddressFromAddressHelper(addressHelper, isPrimaryAddress), [addressHelper, isPrimaryAddress]);
    const invalidInputState = highlightInvalid || highlightInvalidFromParent ? 'invalid' : 'incomplete';
    const dispatch = useDispatch();
    const setPhoneNumber = (newPhoneNumber) => {
        dispatch({
            type: 'SET_PHONE_NUMBER',
            payload: newPhoneNumber,
        });
    };
    const setFirstName = (newFirstName) => {
        dispatch({
            type: 'SET_FIRST_NAME',
            payload: newFirstName,
        });
    };
    const setLastName = (newLastName) => {
        dispatch({
            type: 'SET_LAST_NAME',
            payload: newLastName,
        });
    };
    useEffect(() => {
        dispatch({
            type: 'ADDRESS_FORM_OPENED',
            payload: { action: edit ? 'edit' : 'new_address' },
        });
    }, []);
    const firstNameIsValid = firstName.length > 0 && firstName.length <= 40;
    const lastNameIsValid = lastName.length > 0 && lastName.length <= 40;
    const onPressSubmit = useCallback(() => {
        if (addressHelperIsFinished && isValidAddress(fullAddressHelperAddress === null || fullAddressHelperAddress === void 0 ? void 0 : fullAddressHelperAddress.deliveryAddress)) {
            onComplete(fullAddressHelperAddress);
            dispatch({
                type: 'ADDRESS_SAVED',
                payload: { action: edit ? 'edit' : 'new_address' },
            });
        }
        else {
            setHighlightInvalid(true);
        }
    }, [addressHelperIsFinished, fullAddressHelperAddress]);
    const onPressCancel = () => {
        onComplete();
    };
    useEffect(() => {
        setIsPrimaryAddress(isPrimary);
    }, []);
    useEffect(() => {
        setAddressCompleteHandler &&
            setAddressCompleteHandler({ handleAddressComplete: onPressSubmit });
    }, [onPressSubmit]);
    useEffect(() => {
        return () => {
            dispatch({ type: 'RESET_ADDRESS_ERROR' });
        };
    }, []);
    const phoneNumberInputRef = useCallback((phoneInputElement) => {
        if (focusPhoneInput && phoneInputElement) {
            phoneInputElement.focus();
        }
    }, [focusPhoneInput]);
    const classes = classNames({
        'generic-address-form': true,
        'generic-address-form--no-checkbox': !primaryAddressOptionEnabled,
    });
    return (React.createElement(React.Fragment, null,
        !edit && (React.createElement("div", { className: "generic-address-form" },
            React.createElement(NewTextInput, { inputState: firstNameIsValid ? 'valid' : invalidInputState, value: firstName, className: "generic-address-form__text-input", onChange: setFirstName, maxLength: 40, label: "Fornavn", errorMessage: "Fornavn m\u00E5 fylles ut" }),
            React.createElement(NewTextInput, { inputState: lastNameIsValid ? 'valid' : invalidInputState, value: lastName, className: "generic-address-form__text-input", onChange: setLastName, maxLength: 40, label: "Etternavn", errorMessage: "Etternavn m\u00E5 fylles ut" }),
            React.createElement(OldAddressHelper, { noFocus: true, highlightInvalid: highlightInvalid || highlightInvalidFromParent }),
            !hideButton && (React.createElement(ButtonRow, { display: "stretch" },
                React.createElement(Button, { type: "action", style: "primary", onClick: onPressSubmit }, "Lagre"))))),
        edit && (React.createElement("div", { className: classes },
            primaryAddressOptionEnabled && (React.createElement(CheckboxWithLabel, { isChecked: isPrimaryAddress, setIsChecked: (on) => setIsPrimaryAddress(on), labelText: "Bruk som hovedadresse", className: "generic-address-form__primary-address-checkbox" })),
            React.createElement(NewTextInput, { inputState: phoneNumber && isValidMobile(phoneNumber) ? 'valid' : invalidInputState, value: phoneNumber, className: "generic-address-form__text-input--mobile", onChange: setPhoneNumber, maxLength: 40, label: "Telefonnummer til mottaker av pakken", errorMessage: "Telefonnummer m\u00E5 fylles ut", inputRef: phoneNumberInputRef }),
            React.createElement("p", { className: "generic-address-form__field-explanation" }, 'Telefonnummeret blir brukt til å sende leveringsbekreftelser og gavemeldinger.'),
            React.createElement(NewTextInput, { inputState: firstNameIsValid ? 'valid' : invalidInputState, value: firstName, className: "generic-address-form__text-input", onChange: setFirstName, maxLength: 40, label: "Fornavn", errorMessage: "Fornavn m\u00E5 fylles ut" }),
            React.createElement(NewTextInput, { inputState: lastNameIsValid ? 'valid' : invalidInputState, value: lastName, className: "generic-address-form__text-input", onChange: setLastName, maxLength: 40, label: "Etternavn", errorMessage: "Etternavn m\u00E5 fylles ut" }),
            React.createElement(OldAddressHelper, { noFocus: true, highlightInvalid: highlightInvalid || highlightInvalidFromParent, edit: true }),
            React.createElement(ButtonRow, { display: "grow-right" },
                React.createElement(Button, { type: "action", style: "ghost", onClick: onPressCancel }, "Avbryt"),
                React.createElement(Button, { type: "action", style: "primary", onClick: onPressSubmit }, "Lagre"))))));
}
function useClearIfLocationChanged() {
    const dispatch = useDispatch();
    const currentLocation = useLocation().pathname;
    const lastLocation = useSelector((state) => state.addressHelper.lastRenderedLocation || currentLocation);
    useEffect(() => {
        if (lastLocation != currentLocation) {
            dispatch({ type: 'RESET_ADDRESS_FORM' });
        }
        return () => {
            dispatch({ type: 'SET_LAST_RENDERED_LOCATION', payload: currentLocation });
        };
    }, []);
}
export default GenericAddressForm;

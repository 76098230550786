import './style.scss'

import React, { Component } from 'react'

class Popover extends Component {
    render() {
        return (
            <div className="popover">
                <div className="popover__content">{this.props.children}</div>
            </div>
        )
    }
}

export default Popover

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    notifications: [],
};
const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        showNotification: (state, action) => {
            state.notifications.push(action.payload);
        },
        hideNotification: (state) => {
            const currentNotification = state.notifications.slice(-1)[0];
            if (currentNotification) {
                state.notifications = state.notifications.filter((n) => n.style !== currentNotification.style && n.text !== currentNotification.text);
            }
        },
    },
});
export const { showNotification, hideNotification } = notificationSlice.actions;
export default notificationSlice.reducer;

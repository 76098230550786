import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import './BlogPostMeta.scss';
function BlogPostMeta({ categoryTitle, publishedAt, textColor, className }) {
    const classes = classNames({
        'blog-post-meta': true,
        [`${className}`]: className,
    });
    return (React.createElement("div", { className: classes, style: { color: textColor } },
        React.createElement("span", { className: "blog-post-meta__category" }, categoryTitle),
        React.createElement("span", { className: "blog-post-meta__date" }, moment(publishedAt).format('Do MMM YYYY'))));
}
export default BlogPostMeta;

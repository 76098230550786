import { Menu, MenuButton, MenuList, MenuItem } from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { SafeHtml } from 'common';
import React, { useState } from 'react';
import { SortOption } from './productSort';
import './SortDropdown.scss';
const checkmark = require('./checkmark.svg');
const downArrow = require('img/expandArrow.svg');
function SortDropdown({ defaultSortBy, onChangeSort }) {
    const sortOptions = [
        SortOption.recommended,
        SortOption.name,
        SortOption.dateDesc,
        SortOption.priceDesc,
        SortOption.priceAsc,
    ];
    const [currentSort, setCurrentSort] = useState(defaultSortBy);
    const sortOptionText = (tag) => {
        switch (tag) {
            case SortOption.recommended: return 'Anbefalt';
            case SortOption.name: return 'Navn';
            case SortOption.dateDesc: return 'Nyeste';
            case SortOption.priceDesc: return 'Pris (høy-lav)';
            case SortOption.priceAsc: return 'Pris (lav-høy)';
        }
    };
    return (React.createElement(Menu, { className: "sort-dropdown" },
        React.createElement(MenuButton, { className: "sort-dropdown__button" },
            "Sorter",
            ' ',
            React.createElement(SafeHtml, { type: "div", html: downArrow, className: "sort-dropdown__button__down-arrow" })),
        React.createElement(MenuList, { className: "sort-dropdown__list" }, sortOptions.map((tag, index) => {
            return (React.createElement(MenuItem, { className: "sort-dropdown__list__option", onSelect: () => {
                    onChangeSort(tag);
                    setCurrentSort(tag);
                }, key: index },
                sortOptionText(tag),
                tag === currentSort && (React.createElement(SafeHtml, { type: "div", className: "sort-dropdown__list__option__checkmark", html: checkmark }))));
        }))));
}
export default SortDropdown;

import { useEffect, useState } from 'react';
import sanityClient from 'utilities/sanityClient';
import groq from 'groq';
export function useSanityDocument({ documentId, queryTemplate, conditions, queryParams, }) {
    const [document, setDocument] = useState();
    useEffect(() => {
        let isSubscribed = true;
        const query = groq `
            *[_id == $documentId ${conditions ? '&& ' + conditions : ''}][0] {
                ${queryTemplate}
            }`.replace(/[\s\n]/g, '');
        sanityClient
            .fetch(query, Object.assign({ documentId }, queryParams))
            .then((doc) => {
            if (isSubscribed)
                setDocument(doc);
        })
            .catch(console.error);
        return () => {
            isSubscribed = false;
        };
    }, [documentId, queryTemplate, conditions, queryParams]);
    return document;
}

import isEmpty from 'lodash/isEmpty'

export function hasContactInfo(orderData) {
    try {
        return !!orderData.deliveryAddress.firstName && !!orderData.deliveryAddress.lastName
    } catch (error) {
        return false
    }
    return true
}

export function hasEmail(orderData) {
    try {
        return !!orderData.email || !!orderData.unauthenticatedOrderData.email
    } catch (error) {
        return false
    }
    return true
}

export function hasProducts(orderData) {
    try {
        return orderData.products.length > 0
    } catch (error) {
        return false
    }
    return true
}

export function hasAddress(orderData) {
    try {
        return !!orderData.deliveryAddress.deliveryPointId
    } catch (error) {
        return false
    }
    return true
}

export function hasSupportedAddress(orderData) {
    try {
        return !!orderData.deliveryAddress.transportProduct
    } catch (error) {
        return false
    }
    return true
}

export function hasDeliveryDate(orderData) {
    try {
        return !!orderData.deliveryDate
    } catch (error) {
        return false
    }
    return true
}

export function hasCardToken(orderData) {
    try {
        return !!orderData.adyenCardToken
    } catch (error) {
        return false
    }
    return true
}

export function hasPreferredPaymentMethod(orderData) {
    try {
        return !!(orderData.preferredPaymentMethod && !isEmpty(orderData.preferredPaymentMethod))
    } catch (error) {
        return false
    }
}

export function hasEnoughQuantity(enoughQuantity) {
    try {
        return !!enoughQuantity
    } catch (error) {
        return false
    }
    return true
}

import { deactivateAddress, deleteStoredAddress } from 'actions/Address';
import { removeRecipientIfPresent } from 'checkout';
import classNames from 'classnames';
import { Checkbox } from 'common';
import React from 'react';
import { useDispatch } from 'react-redux';
import AddressItem from './AddressItem';
import './SelectableAddressItem.scss';
function SelectableAddressItem({ address, canDelete = true, discourage, discourageReason, isSelected, multi, onEdit, onPrimaryAddressDelete, onSelect, }) {
    const dispatch = useDispatch();
    const itemClasses = classNames({
        'address-item': true,
        'address-item--selected': isSelected,
        'address-item--warning': discourage,
    });
    const handleDeletePrimaryAddress = () => {
        onPrimaryAddressDelete && onPrimaryAddressDelete();
    };
    const handleDelete = () => {
        dispatch(removeRecipientIfPresent(address.deliveryAddress));
        if (address.addressId) {
            dispatch(deactivateAddress(address, address.addressId));
        }
        else {
            dispatch(deleteStoredAddress(address.deliveryAddress));
        }
    };
    const handleKeyPress = (event) => {
        const target = event.target;
        const tag = target === null || target === void 0 ? void 0 : target.tagName;
        if (event.key === 'Enter' && tag !== 'BUTTON' && tag !== 'INPUT') {
            onSelect();
        }
    };
    const isPrimary = address.isPrimary;
    return (React.createElement("div", { className: "selectable-address-item", onClick: onSelect, tabIndex: 0, onKeyPress: handleKeyPress },
        React.createElement(AddressItem, { address: address.deliveryAddress, className: itemClasses, isPrimary: isPrimary, onDelete: (canDelete && (!isPrimary ? handleDelete : handleDeletePrimaryAddress)) ||
                undefined, onEdit: onEdit, warningText: discourage ? discourageReason : undefined }, multi && React.createElement(Checkbox, { isChecked: isSelected, className: "address-item__checkbox" }))));
}
export default SelectableAddressItem;

import { push } from 'connected-react-router'

export function isPushFeatureEnabledChanged(isEnabled) {
    return { type: 'PUSH_IS_FEATURE_ENABLED_CHANGED', payload: isEnabled }
}

export function pushHasAskedForAuthorization() {
    return { type: 'PUSH_HAS_ASKED_FOR_AUTHORIZATION' }
}

export function pushAuthorizationChanged(authorization) {
    return { type: 'PUSH_AUTHORIZATION_CHANGED', payload: authorization }
}

export function pushTokenChanged(token) {
    return { type: 'PUSH_TOKEN_CHANGED', payload: token }
}

export function pushNotificationReceived(notification, mode) {
    return (dispatch) => {
        if (notification.route && mode !== 'Running') {
            setTimeout(() => {
                dispatch(push(notification.route))
            }, 500)
        } else {
            dispatch({
                type: 'PUSH_NOTIFICATION_RECEIVED',
                payload: { notification, mode },
            })
        }
    }
}

export function pushErrorOccurred(context, error) {
    return { type: 'PUSH_ERROR_OCCURED', payload: { context, error } }
}

export function changePushNotificationOption(option, value) {
    return {
        type: 'PUSH_NOTIFICATION_CHANGE_OPTION',
        payload: { option, value },
    }
}

/**
 * Add a postfix to each className in a string separated by whitespace using standard BEM notation.
 * @example
 * // returns 'a__foo b__foo'
 * postfixClassNames('a b', 'foo').
 * @example
 * // returns 'a--bar b--bar'
 * postfixClassNames('a b', 'bar', '--')
 * @param className - String containing classNames separated by whitespace
 * @param affix - String to append to each className
 * @param separator - String to separate each className from the affix
 */
function postfixClassnames(className, affix, separator = '__') {
    return className
        .split(' ')
        .map((c) => c + separator + affix)
        .join(' ');
}
export { postfixClassnames };

import ConceptPageHeader from 'conceptPage/components/ConceptPageHeader/ConceptPageHeader';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sanityImageBuilder } from 'utilities/sanityClient';
import { useSanityDocument } from 'common/utils/useSanityDocument';
import React from 'react';
export default function BannerConceptPage(props) {
    const regionId = useSelector(({ region }) => region.id);
    const data = useSanityDocument({
        documentId: props.documentId,
        queryTemplate: `
        title, 
        mainImage, 
        "featuredProductIds": regionProducts.region_${regionId}
        `,
    });
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const mainImageUrl = useMemo(function getMainImageUrl() {
        if (!(data === null || data === void 0 ? void 0 : data.mainImage))
            return undefined;
        const headerHeightInPx = isMobile ? 80 : 75;
        return sanityImageBuilder
            .image(data.mainImage)
            .fit('crop')
            .height(window.innerHeight - headerHeightInPx)
            .width(Math.ceil(window.innerWidth * 0.9))
            .dpr(window.devicePixelRatio)
            .auto('format')
            .url();
    }, [data === null || data === void 0 ? void 0 : data.mainImage, isMobile]);
    return (React.createElement(ConceptPageHeader, { heading: data === null || data === void 0 ? void 0 : data.title, headerImageSrc: mainImageUrl, featuredProductIds: (data === null || data === void 0 ? void 0 : data.featuredProductIds) || [] }));
}

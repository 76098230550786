var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import Icon from 'components/UI/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import React, { forwardRef, useState } from 'react';
import './AccordionItem.scss';
const chevron = require('img/down-button-icon.svg');
const AccordionItem = forwardRef(function AccordionItem(_a, ref) {
    var { header, children, className = null } = _a, delegated = __rest(_a, ["header", "children", "className"]);
    const [showDetails, setShowDetails] = useState(false);
    const handleClick = () => {
        setShowDetails(!showDetails);
    };
    const classes = classNames({
        'accordion-item': true,
        [`${className}`]: className,
    });
    return (React.createElement("section", Object.assign({ className: classes }, delegated, { ref: ref }),
        React.createElement("button", { onClick: handleClick, className: "accordion-item__header" },
            header,
            React.createElement(motion.div, { animate: { rotate: showDetails ? 180 : 0 } },
                React.createElement(Icon, { className: "accordion-item__header__icon", svg: chevron }))),
        React.createElement(AnimatePresence, { initial: false }, showDetails && (React.createElement(motion.div, { key: "accordion-item", variants: {
                open: { opacity: 1, height: 'auto', paddingBottom: 16 },
                collapsed: { opacity: 0, height: 0, paddingBottom: 0 },
            }, initial: "collapsed", animate: "open", exit: "collapsed", className: "accordion-item__content" }, children)))));
});
export default motion(AccordionItem);

import { setDeliveryDate } from 'actions/Checkout';
import { checkCart } from 'cart';
import { Calendar } from 'common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
function DeliveryDate({ onDateSelected }) {
    const dispatch = useDispatch();
    const dates = useSelector((state) => state.distribution.dates);
    const deliveryDate = useSelector((state) => state.checkout.deliveryDate);
    const handleSetDate = (date) => {
        dispatch(setDeliveryDate(dates.find((d) => d.date === date), true));
        dispatch(checkCart());
        onDateSelected && onDateSelected(date);
    };
    return React.createElement(Calendar, { selectedDeliveryDate: deliveryDate, setDate: handleSetDate });
}
export default DeliveryDate;

import classNames from 'classnames';
import React from 'react';
import AccordionItem from '../AccordionItem';
import './Card.scss';
const Card = React.forwardRef(function cardFunc({ title, floating, children, className, subcard, accordion }, ref) {
    const classes = classNames({
        'common-ui-card': true,
        'common-ui-card__floating': floating,
        'common-ui-card--subcard': subcard,
        [`${className}`]: className,
    });
    return (React.createElement(React.Fragment, null, accordion ? (React.createElement(AccordionItem, { className: classes, ref: ref, header: title }, children)) : (React.createElement("div", { className: classes, ref: ref },
        title && React.createElement("h2", { className: "common-ui-card__title" }, title),
        children))));
});
export default Card;

import { setSubscriptionMode } from 'actions/Subscription';
import classNames from 'classnames';
import { NavigationLink, Pill, PillList, PillListType, PillType } from 'common';
import Switch from 'components/UI/Switch';
import { ProductGrid } from 'product';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPaused } from 'utilities/subscription';
import { filterProducts } from '../../util';
import { SortOption, sortProducts } from '../SortDropdown/productSort';
import SortDropdown from '../SortDropdown/SortDropdown';
import './FilterableCategoryProductGrid.scss';
import { sortByReducer } from './FilterableCategoryProductGridUtils';
function FilterableCategoryProductGrid({ products, categories = [], allCategoriesLinkText, limit = 0, allowSort = false, showSubscriptionSwitch = false, showCount = true, showCountCategory = true, handleCategorySelect, isConceptPageProducts = false, showAllProductsPill = false, filterOnSuppliers = false, pillListHeading = '', }) {
    var _a;
    const dispatch = useDispatch();
    const showSubsSwitch = useSelector((state) => state.user.isLoggedIn &&
        !state.subscription.stopped &&
        !isPaused(state.subscription.stopDates)) && showSubscriptionSwitch;
    const isSubscriptionMode = useSelector((state) => state.subscription.isSubscriptionMode);
    const [allProducts, setAllProducts] = useState(products);
    const [filteredProducts, setFilteredProducts] = useState(allProducts);
    const [activeCategory, setActiveCategory] = useState();
    const [numberOfFilteredProducts, setNumberOfFilteredProducts] = useState(0);
    const pillListRef = useRef(null);
    const showPillsAsSecondary = isConceptPageProducts && !filterOnSuppliers;
    const initialSortByState = {
        sortBy: SortOption.recommended,
        previouslyUserSelectedSortBy: null,
    };
    const [sortByState, sortByDispatch] = useReducer(sortByReducer, initialSortByState);
    const setSortBy = (sortOption) => sortByDispatch(['userInitialized', sortOption]);
    useEffect(() => {
        sortByDispatch(['automatic', SortOption.recommended]);
    }, [activeCategory]);
    // On artisanproduct page, scroll pill list to start on tab change
    useEffect(() => {
        var _a;
        (_a = pillListRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
            left: 0,
            behavior: 'smooth',
        });
    }, [filterOnSuppliers]);
    useEffect(() => {
        setAllProducts([...products]);
    }, [products]);
    useEffect(() => {
        if (showAllProductsPill && isConceptPageProducts) {
            setActiveCategory(undefined);
        }
    }, [filterOnSuppliers, isConceptPageProducts, showAllProductsPill]);
    useEffect(() => {
        let newFilteredProducts = filterProducts(products, isSubscriptionMode, activeCategory, undefined, filterOnSuppliers);
        if (limit > 0) {
            setNumberOfFilteredProducts(newFilteredProducts.length);
            newFilteredProducts = newFilteredProducts.slice(0, limit);
        }
        setFilteredProducts(newFilteredProducts);
    }, [
        activeCategory,
        allProducts,
        limit,
        isSubscriptionMode,
        filterOnSuppliers,
        products,
    ]);
    useEffect(() => {
        if (products) {
            sortProducts(products, sortByState.sortBy);
            setFilteredProducts(filterProducts(products, isSubscriptionMode, activeCategory, undefined, filterOnSuppliers));
        }
    }, [products, sortByState.sortBy, activeCategory, filterOnSuppliers, isSubscriptionMode]);
    const handlePillClick = (e, category) => {
        var _a;
        const eventTarget = e.target;
        const leftOffset = eventTarget.offsetLeft;
        (_a = pillListRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy({
            top: 0,
            // TODO: Figure out why manually adding 8px is necessary here, and
            // not in URLControlledFilterableCategoryProductGrid.tsx
            left: leftOffset - (pillListRef.current.scrollLeft + 8),
            behavior: 'smooth',
        });
        setActiveCategory(category);
        handleCategorySelect && (category === null || category === void 0 ? void 0 : category.slug) && handleCategorySelect(category.slug);
    };
    /**
     * TODO: If the "go to all products" link needs styling (which it currently lacks),
     * this element needs to be added to the .scss
     */
    const classes = classNames({
        'filterable-category-product-grid__all-products-link': true,
    });
    const categoryFiltersClasses = classNames({
        'filterable-category-product-grid__category-filters': true,
        'filterable-category-product-grid__category-filters--short': isConceptPageProducts,
    });
    const categoryFilter = (React.createElement("div", { className: categoryFiltersClasses },
        showAllProductsPill && (React.createElement(PillList, { listType: PillListType.Horizontal, ref: pillListRef, heading: pillListHeading }, [
            React.createElement(Pill, { key: 'allCategories', pillType: PillType.Selectable, onClick: (e) => handlePillClick(e), active: !activeCategory, type: showPillsAsSecondary ? 'secondary' : 'primary' }, "Alle"),
            ...categories.map((category) => (React.createElement(Pill, { key: category.id, pillType: PillType.Selectable, onClick: (e) => handlePillClick(e, category), active: category.id === (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.id), type: showPillsAsSecondary ? 'secondary' : 'primary' }, category.name))),
        ])),
        !showAllProductsPill && (React.createElement(PillList, { listType: PillListType.Horizontal, ref: pillListRef }, categories.map((category) => (React.createElement(Pill, { key: category.id, pillType: PillType.Selectable, onClick: (e) => handlePillClick(e, category), active: category.id === (activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.id) }, category.name)))))));
    return (React.createElement(React.Fragment, null,
        categories.length > 1 && categoryFilter,
        !isConceptPageProducts && (React.createElement("div", { className: "filterable-category-product-grid__pre-content" },
            React.createElement("div", { className: "filterable-category-product-grid__content" },
                showSubsSwitch && (React.createElement("div", { className: "filterable-category-product-grid__subscription-switch" },
                    React.createElement("span", null, "Fast levering"),
                    React.createElement(Switch, { on: isSubscriptionMode, onChange: (val) => dispatch(setSubscriptionMode(val)) }))),
                showCount && (React.createElement("p", { className: "filterable-category-product-grid__count-text" }, showCountCategory
                    ? `Viser ${filteredProducts.length} av ${numberOfFilteredProducts} produkter innen "${activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.name}"`
                    : `Viser ${filteredProducts.length} av ${numberOfFilteredProducts}`))),
            React.createElement("div", { className: "filterable-category-product-grid__content" },
                allowSort && (React.createElement(SortDropdown, { defaultSortBy: sortByState.sortBy, onChangeSort: setSortBy, key: sortByState.sortBy })),
                allCategoriesLinkText && (React.createElement(NavigationLink, { to: `/products/${(_a = activeCategory === null || activeCategory === void 0 ? void 0 : activeCategory.slug) !== null && _a !== void 0 ? _a : ''}`, classes: classes }, allCategoriesLinkText))))),
        React.createElement(ProductGrid, { products: filteredProducts })));
}
export default FilterableCategoryProductGrid;

import { useInView } from 'react-intersection-observer';
import React, { useContext, useEffect } from 'react';
import { ProductContext } from 'product';
import { useDispatch } from 'react-redux';
import BundleProductTile from '../BundleProductTile/BundleProductTile';
import './BundleProductGrid.scss';
function BundleProductGrid({ bundles }) {
    const productContext = useContext(ProductContext);
    const dispatch = useDispatch();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });
    useEffect(() => {
        if (inView) {
            dispatch({
                type: 'VIEW_ITEM_LIST',
                payload: {
                    products: bundles.flatMap(({ products }) => products),
                    productContext
                }
            });
        }
    }, [inView, dispatch, productContext, bundles]);
    return (React.createElement("div", { ref: ref, className: 'product-bundle-grid' }, bundles.map((bundle) => (React.createElement(BundleProductTile, { bundle: bundle, key: bundle.title })))));
}
export default BundleProductGrid;

import './style.scss'

import React, { Component } from 'react'

import CheckoutAdd from 'components/Checkout/CheckoutAdd'
import CheckoutField from 'components/Checkout/CheckoutField'
import Icon from 'components/UI/Icon'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isValidEmail } from 'utilities/validators'
import warning from 'img/warning.svg'

export class CheckoutShowContactInfo extends Component {
    render() {
        const { isGift, firstName, lastName, email, mobile } = this.props
        const noName = !firstName || !lastName
        const validEmail = email && isValidEmail(email)

        const title = isGift ? 'Avsender:' : 'Kontaktinfo:'
        const addTitle = isGift ? 'Legg til avsender' : 'Legg til kontaktinfo'

        return (
            <CheckoutField title={title} editUrl="/checkout/contactinfo">
                {!noName && (
                    <div>
                        <div>
                            {!validEmail && (
                                <Icon
                                    className={'checkoutShowContactInfo__warning'}
                                    svg={warning}
                                />
                            )}
                            {firstName} {lastName}
                        </div>
                        {email && <div className="subtext">{email}</div>}
                        {mobile && <div className="subtext">{mobile}</div>}
                    </div>
                )}
                {noName && <CheckoutAdd>{addTitle}</CheckoutAdd>}
            </CheckoutField>
        )
    }
}

CheckoutShowContactInfo.propTypes = {
    isGift: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string,
}

const mapStateToProps = ({ personalInfo, newCheckout }) => {
    const { firstName, lastName, mobile, email } = personalInfo
    return { isGift: newCheckout.orderType !== 'single', firstName, lastName, email, mobile }
}

export default connect(mapStateToProps)(CheckoutShowContactInfo)

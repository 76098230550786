import { Button, ButtonRow } from 'common';
import React, { useState } from 'react';
import CartAddRecipientsModal from '../CartAddRecipientsModal';
import './EmptyGroupOrderCart.scss';
export function EmptyGroupOrderCart() {
    const [showAddressModal, setShowAddressModal] = useState(false);
    return (React.createElement(React.Fragment, null,
        showAddressModal && (React.createElement(CartAddRecipientsModal, { onClose: () => setShowAddressModal(false) })),
        React.createElement("div", { className: "empty-group-order-cart" },
            React.createElement("h3", { className: "empty-group-order-cart__header" }, "Send samme levering til hele gjengen!"),
            "Du kan sende til 10 mottakere innenfor samme region. Alle mottar den samme handlekurven.",
            React.createElement(ButtonRow, { display: "stretch", className: "empty-group-order-cart__buttons" },
                React.createElement(Button, { type: "action", style: "primary", onClick: () => setShowAddressModal(true) }, "Legg til mottakere")))));
}

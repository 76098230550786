import { checkCartCore } from 'cart/actions/cartActions'

export function setCouponCode(code) {
    return (dispatch) => {
        dispatch({ type: 'SET_COUPON_CODE', payload: code })
    }
}

/*
export function validateCouponCode() {
    return (dispatch, getState) => {
        const freight = getState().cart.freight
        const products = getState().cart.products
        const couponCode = getState().coupon.code
        const deliveryDate = getState().checkout.deliveryDate
        const authenticatedUserId = getState().user.spidData.userId
        const unAuthenticatedEmail = getState().personalInfo.email
        const userInfo = () => {
            if (authenticatedUserId) return { authenticatedUserId }
            else return { unAuthenticatedEmail }
        }

        dispatch({
            type: 'VALIDATE_COUPON_CODE',
        })
        axios
            .post(`${process.env.CORE_API}/coupon/calculateCouponDiscount`, {
                products: asSingleOrderProducts(products),
                couponCode: couponCode,
                deliveryDate: deliveryDate,
                freight: {
                    amount: freight.amount,
                    externalId: freight.externalId,
                },
                ...userInfo(),
            })
            .then((response) => {
                dispatch({
                    type: 'VALIDATE_COUPON_CODE_SUCCESS',
                    payload: response.data,
                })
            })
            .catch((err) => {
                dispatch({ type: 'VALIDATE_COUPON_CODE_FAILED', payload: err })
            })
    }
}
*/

export function removeCouponCode() {
    return (dispatch) => {
        dispatch(_removeCouponCode())
        dispatch(checkCartCore())
    }
}

function _removeCouponCode() {
    return (dispatch) => {
        dispatch({ type: 'REMOVE_COUPON_CODE' })
    }
}

import './AddressDropdown.scss';
import React from 'react';
import { toggleEditAddress } from 'actions/Address';
import { toggleAddressDropdown } from 'actions/UI';
import { useCoalescedAddresses } from 'address';
import classNames from 'classnames';
import RegionDropDown from 'components/RegionDropDown/RegionDropDown';
import Alert from 'components/UI/Alert';
import Icon from 'components/UI/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'utilities/text';
import AddressDropdownDelivery from './AddressDropdownDelivery';
import { DropDown } from 'common';
const marker = require('img/marker.svg');
const warning = require('img/warning.svg');
function AddressDropdown({ isRegionDropDownShowing, portalTo }) {
    const dispatch = useDispatch();
    const canDeliver = useSelector((state) => state.distribution.canDeliver || state.distribution.distributionLoading);
    const activeAddress = useSelector((state) => { var _a; return (_a = state.address.active) === null || _a === void 0 ? void 0 : _a.deliveryAddress; });
    const { showAddressDropdown: expanded, showApartmentNumberHelpModal, modalOpen, } = useSelector((state) => state.ui);
    const loading = useSelector((state) => state.address.loading);
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const regionName = useSelector((state) => state.region.name);
    const noAddresses = useCoalescedAddresses().length < 1;
    const getTitle = () => {
        if (!activeAddress || orderType === 'group') {
            return regionName ? capitalize(regionName) : 'Din adresse';
        }
        return `${capitalize(activeAddress.streetName)} ${activeAddress.streetNo}${activeAddress.entrance || ''}`;
    };
    const handleClose = () => {
        if (!showApartmentNumberHelpModal) {
            dispatch(toggleAddressDropdown(false));
            dispatch(toggleEditAddress(false));
        }
    };
    const classes = classNames({
        'address-dropdown': true,
        'address-dropdown--expanded': expanded,
        'address-dropdown--no-delivery': !canDeliver,
    });
    return (React.createElement("div", { className: classes },
        React.createElement(DropDown, { portalTo: portalTo, title: getTitle(), loading: loading, expanded: expanded, scrollable: true, icon: canDeliver ? (React.createElement(Icon, { className: "address-dropdown__icon", svg: marker, size: "medium" })) : (React.createElement(Icon, { className: "address-dropdown__icon--warning", svg: warning })), onClose: () => handleClose(), onClick: () => {
                dispatch(toggleAddressDropdown());
                if (noAddresses) {
                    dispatch(toggleEditAddress(true));
                }
            }, disableCloseOnClickOutside: modalOpen },
            React.createElement("p", { className: "address-dropdown__description" }, "Morgenlevering.no leverer frokost, bakst og gaver i samarbeid med lokale leverand\u00F8rer. Fyll inn adresse, s\u00E5 viser vi deg riktig produktutvalg."),
            React.createElement(RegionDropDown, { redirect: false }),
            orderType === 'group' ? (React.createElement(Alert, { type: "success" }, "Send til flere er valgt. Du legger til leveringsadressene i kassen.")) : (React.createElement(AddressDropdownDelivery, { isRegionDropdownShowing: isRegionDropDownShowing })))));
}
export default AddressDropdown;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { handleNetworkError } from 'actions/Error';
import axios from 'axios';
import { throttle } from 'lodash';
import { showTimedNotification } from 'notification';
import apiConfig from 'utilities/apiConfig';
import { FEATURES, isFeatureEnabled } from 'common';
const throttledCheckCart = throttle((dispatch) => {
    dispatch(checkCartCore());
    dispatch(checkCart());
}, 1500);
export function addToCart(product, cartVisible, productContext) {
    return (dispatch, getState) => {
        const productsInCart = getState().cart.products;
        dispatch({ type: 'ADD_TO_CART', payload: { product: Object.assign(Object.assign({}, product), { productContext }), productsInCart } });
        if (cartVisible) {
            throttledCheckCart(dispatch);
        }
        else {
            dispatch({ type: 'PENDING_CHECK_CART' });
        }
    };
}
export function checkCartCore() {
    return (dispatch, getState) => {
        const state = getState();
        const cartProducts = state.cart.products.map((product) => ({
            id: product.id,
            quantity: product.quantity,
            price: product.price,
            vatRate: product.vatRate,
        }));
        const regionId = state.region.id;
        const orderTotal = state.cart.totalWithoutFreight;
        const authenticatedUserId = state.user.spidData.userId;
        const unAuthenticatedEmail = state.personalInfo.email;
        const deliveryDate = state.checkout.deliveryDate;
        const couponCode = state.coupon.code;
        const userInfo = () => {
            if (authenticatedUserId)
                return { authenticatedUserId };
            else
                return { unAuthenticatedEmail };
        };
        axios
            .post(`${process.env.CORE_API}/cart/check`, Object.assign({ products: cartProducts, couponCode,
            orderTotal,
            regionId,
            deliveryDate }, userInfo()))
            .then((response) => {
            dispatch({ type: 'GET_FREIGHT_SUCCESS', payload: response.data.freight });
            dispatch({ type: 'CHECK_CART_SUCCESS', payload: response.data });
        })
            .catch((err) => {
            console.warn(err);
            dispatch({ type: 'VALIDATE_COUPON_CODE_FAILED', payload: err });
            dispatch(handleNetworkError(err));
        });
    };
}
export function viewCart() {
    return (dispatch, getState) => {
        dispatch({ type: 'VIEW_CART', payload: getState().cart });
    };
}
function getProductsWithMultipliedQuantity(products, multiplier) {
    return products.map((product) => (Object.assign(Object.assign({}, product), { quantity: product.quantity * multiplier })));
}
export function addOrderToCart(order) {
    return (dispatch, getState) => {
        const products = getState().products.products;
        order.products.forEach(({ id, quantity }) => {
            const product = products.find((p) => +p.id === +id);
            while (product && quantity > 0) {
                dispatch({ type: 'ADD_TO_CART', payload: { product } });
                quantity--;
            }
        });
        dispatch(checkCartCore());
        dispatch(checkCart());
    };
}
export function removeFromCart(product, cartVisible) {
    return function (dispatch, getState) {
        dispatch({ type: 'REMOVE_FROM_CART', payload: product });
        if (getState().cart.products.length == 0)
            return;
        if (cartVisible) {
            throttledCheckCart(dispatch);
        }
        else {
            dispatch({ type: 'PENDING_CHECK_CART' });
        }
    };
}
export function setProductQuantity(quantity, product) {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_CART_QUANTITY', payload: { quantity, product } });
        dispatch(checkCartCore());
        dispatch(checkCart());
    };
}
export function checkCart() {
    return (dispatch, getState) => {
        if (isFeatureEnabled(getState().features.enabled, FEATURES.STORE)) {
            return;
        }
        const deliveryDate = getState().checkout.deliveryDate;
        const cartProducts = getState().cart.products;
        const region = getState().region.slug;
        const isMobile = getState().ui.isMobile;
        const orderType = getState().newCheckout.orderType;
        const recipients = getState().newCheckout.recipients;
        const products = orderType === 'group'
            ? getProductsWithMultipliedQuantity(cartProducts, Math.max(recipients.length, 1))
            : cartProducts;
        dispatch({ type: 'FETCH_CHECK_CART', payload: [] });
        axios
            .post(`${process.env.API_URL}/api/check-cart`, {
            deliveryDate: deliveryDate,
            products: products,
            region: `${region}_no`,
        }, apiConfig)
            .then((response) => {
            const hasDisabledProductsForRegion = response.data.products.some((p) => p.disabledForRegion);
            const hasEnoughQuantity = !response.data.products.some((p) => p.quantity > p.quantityPossible);
            dispatch({
                type: 'FETCH_CHECK_CART_FULLFILLED',
                payload: Object.assign(Object.assign({}, response.data), { hasDisabledProductsForRegion, hasEnoughQuantity }),
            });
            if (hasDisabledProductsForRegion || !hasEnoughQuantity) {
                dispatch(showTimedNotification({
                    style: 'ERROR',
                    text: 'Noe i handlekurven kan ikke leveres til valgt sted på denne datoen',
                }));
                if (!isMobile) {
                    dispatch({
                        type: 'TOGGLE_CART',
                        payload: true,
                    });
                }
            }
        })
            .catch((err) => {
            console.warn(err);
            dispatch({ type: 'FETCH_CHECK_CART_REJECTED', payload: err });
            dispatch(handleNetworkError(err));
        });
    };
}
export function getFreight() {
    return (dispatch, getState) => __awaiter(this, void 0, void 0, function* () {
        const state = getState();
        const regionId = state.region.id;
        const orderTotal = state.cart.totalWithoutFreight;
        const email = state.personalInfo.email;
        dispatch({ type: 'GET_FREIGHT' });
        try {
            const response = yield axios.get(`${process.env.CORE_API}/freight/${regionId}`, {
                params: {
                    orderTotal: orderTotal,
                    email: email,
                },
            });
            dispatch({
                type: 'GET_FREIGHT_SUCCESS',
                payload: response.data,
            });
        }
        catch (err) {
            console.error(err);
            dispatch({ type: 'GET_FREIGHT_FAILED', payload: err });
        }
    });
}

import { deleteStoredAddress, editAddress, editAddressAndSetAsPrimary, setNewMainAddress, storeAddress, } from 'actions/Address';
import { restoreAddressHelper } from 'actions/AddressHelper';
import { Modal } from 'common';
import { GenericAddressForm } from 'delivery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addressIsEqual } from 'utilities/address';
import NewMainAddress from '../NewMainAddress/NewMainAddress';
import './EditAddressForm.scss';
function EditAddressForm({ addressToEdit, canChangePrimaryAddress = true, closeModal, openNewPrimaryAddress, focusPhoneInput = false, }) {
    const dispatch = useDispatch();
    const [mustChooseNewMainAddress, setMustSelectNewMainAddress] = useState(false);
    const addresses = useSelector((state) => state.address.allAddresses);
    const hasAddressId = !!addressToEdit.addressId;
    const primaryAddressOptionEnabled = hasAddressId && canChangePrimaryAddress;
    useEffect(() => {
        dispatch(restoreAddressHelper(addressToEdit.deliveryAddress));
    }, []);
    const handleAddressComplete = (address) => {
        if (address) {
            const setToPrimary = !addressToEdit.isPrimary && address.isPrimary;
            const primaryNotChanged = addressToEdit.isPrimary === address.isPrimary;
            if (!hasAddressId) {
                dispatch(deleteStoredAddress(addressToEdit.deliveryAddress));
                dispatch(storeAddress(address));
            }
            else if (!setToPrimary && !primaryNotChanged) {
                setMustSelectNewMainAddress(true);
                return;
            }
            else if (!addressIsEqual(address.deliveryAddress, addressToEdit.deliveryAddress, false)) {
                if (setToPrimary) {
                    dispatch(editAddressAndSetAsPrimary(address.deliveryAddress, addressToEdit.addressId));
                }
                else {
                    dispatch(editAddress(address.deliveryAddress, addressToEdit.addressId));
                }
            }
            else if (setToPrimary) {
                dispatch(setNewMainAddress(addressToEdit));
            }
        }
        closeModal && closeModal();
    };
    return (React.createElement(Modal, { className: "edit-address-form", onClose: closeModal, labelId: "edit-address-form-title" }, mustChooseNewMainAddress ? (React.createElement(NewMainAddress, { addresses: addresses, closeModal: closeModal, openNewPrimaryAddress: openNewPrimaryAddress })) : (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: "edit-address-form__title", id: "edit-address-form-title" }, "Rediger adressen"),
        React.createElement(GenericAddressForm, { edit: true, isPrimary: addressToEdit.isPrimary, onComplete: handleAddressComplete, primaryAddressOptionEnabled: primaryAddressOptionEnabled, focusPhoneInput: focusPhoneInput })))));
}
export default EditAddressForm;

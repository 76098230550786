import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import kebabCase from 'lodash/kebabCase';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { addToCart, CartCounter, removeFromCart } from 'cart';
import { Price } from 'common';
import React from 'react';
import { CartItemError } from './CartItemError';
export function CartItemNew({ item, deadline, deliveryDate }) {
    var _a, _b;
    const dispatch = useDispatch();
    const { title, id, quantity, disabledForRegion, supplier, error } = item;
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const recipients = useSelector((state) => state.newCheckout.recipients);
    const product = useSelector((state) => state.products.products.find((p) => p.id === id));
    const quantityMultiplier = orderType === 'group' ? Math.max(recipients.length, 1) : 1;
    const itemsAvailable = (product === null || product === void 0 ? void 0 : product.availability.length) == 1
        ? product.availability[0].quantity
        : (_b = (_a = product === null || product === void 0 ? void 0 : product.availability.find((a) => a.date == deliveryDate)) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 0;
    const tooMany = quantity * quantityMultiplier > itemsAvailable && itemsAvailable > 0;
    const mainImage = (() => {
        if ('small' in item.navigationImage) {
            return item.navigationImage.small;
        }
        return item.navigationImage.fullSize;
    })();
    const deadlinePassed = (() => {
        if (supplier) {
            const supplierDeadline = moment(supplier.deadline, 'hh:mm:ss').isBefore(deadline)
                ? moment(supplier.deadline, 'hh:mm:ss')
                : deadline;
            return moment(deliveryDate).isSame(moment().add(1, 'days'), 'day')
                ? moment().isAfter(supplierDeadline)
                : false;
        }
    })();
    const totalPriceItem = item.quantity > 1 ? item.price * item.quantity : item.price;
    const soldOut = itemsAvailable == 0;
    const urlName = kebabCase(title);
    const classes = classNames({
        'cart-item': true,
        'cart-item--disabled': disabledForRegion || soldOut || deadlinePassed || error,
        'cart-item--too-many': tooMany,
    });
    return (React.createElement("div", { className: classes },
        React.createElement("div", { className: "cart-item__image" },
            React.createElement("img", { src: mainImage, alt: title })),
        React.createElement("div", { className: "cart-item__text-container" },
            React.createElement("div", { className: "cart-item__text", onClick: () => {
                    dispatch(push(`/product/${id}/${urlName}`));
                } },
                React.createElement("span", { className: "cart-item__title" }, title)),
            React.createElement("div", { className: "cart-item__price-container" },
                React.createElement(CartCounter, { value: quantity, title: title, onIncrease: () => {
                        dispatch(addToCart(item, true, undefined));
                    }, onDecrease: () => {
                        dispatch(removeFromCart(item, true));
                    } }),
                React.createElement(Price, { className: "cart-item__price", amount: totalPriceItem })),
            React.createElement(CartItemError, { error: error, disabledForRegion: disabledForRegion, soldOut: soldOut, tooMany: tooMany, itemsAvailable: itemsAvailable, deadlinePassed: deadlinePassed }))));
}

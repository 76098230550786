import React, { useEffect } from 'react';
const LINE_HEIGHT_FOR_TRUNCATION_ACTIVATION = 4;
const LINES_VISIBLE_AFTER_TRUNCATION = 3;
function ProductDescription({ description, useTruncation }) {
    const [isTruncated, setIsTruncated] = React.useState(true);
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const [isTruncatedActive, setIsTruncatedActive] = React.useState(false);
    const contentRef = React.useRef(null);
    useEffect(() => {
        const element = contentRef.current;
        if (useTruncation && element) {
            const fontSize = parseFloat(getComputedStyle(element).fontSize);
            const lineHeight = fontSize * 1.5;
            const maxHeightBeforeTruncating = lineHeight * LINE_HEIGHT_FOR_TRUNCATION_ACTIVATION;
            const maxHeightWhenTruncating = lineHeight * LINES_VISIBLE_AFTER_TRUNCATION;
            setIsOverflowing(element.scrollHeight > maxHeightWhenTruncating);
            setIsTruncatedActive(element.scrollHeight > maxHeightBeforeTruncating);
        }
    }, [useTruncation, description]);
    return (React.createElement("div", { className: "product__content__description" },
        React.createElement("p", { ref: contentRef, className: useTruncation && isTruncated && isTruncatedActive
                ? 'product__content__description__truncated'
                : 'product__content__description__expanded' }, description),
        useTruncation && isOverflowing && isTruncatedActive && (React.createElement("button", { className: 'product__content__description__read-more', onClick: () => setIsTruncated(!isTruncated) }, isTruncated ? 'Vis mer' : 'Vis mindre'))));
}
export default ProductDescription;

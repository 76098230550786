import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
const defaultSanityClient = sanityClient({
    projectId: '6m3meqvv',
    dataset: process.env.ENVIRONMENT === 'production' ? 'production' : 'test',
    apiVersion: '2022-05-03',
    useCdn: true,
});
const sanityImageBuilder = imageUrlBuilder(defaultSanityClient);
export { defaultSanityClient as default, sanityImageBuilder };

export function submit3DSecureChallengeForm(response, returnTo) {
    var form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', response.data.redirectUrl)
    var mdInput = document.createElement('input')
    mdInput.setAttribute('name', 'MD')
    mdInput.setAttribute('type', 'hidden')
    mdInput.setAttribute('value', response.data.md)
    form.appendChild(mdInput)
    var paReqInput = document.createElement('input')
    paReqInput.setAttribute('name', 'PaReq')
    paReqInput.setAttribute('type', 'hidden')
    paReqInput.setAttribute('value', response.data.paRequest)
    form.appendChild(paReqInput)
    var termUrl = new URL(response.data.termUrl)
    if (returnTo) {
        termUrl.searchParams.append('returnTo', returnTo)
    }
    var termUrlInput = document.createElement('input')
    termUrlInput.setAttribute('name', 'TermUrl')
    termUrlInput.setAttribute('type', 'hidden')
    termUrlInput.setAttribute('value', termUrl.toString())
    form.appendChild(termUrlInput)
    document.body.appendChild(form)
    form.submit()
}

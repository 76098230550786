import React from 'react';
import './GenericAddressForm.scss';
import OldAddressForm from './OldAddressForm';
import SauAddressForm from './SauAddressForm';
import { useSelector } from 'react-redux';
import { FEATURES, isFeatureEnabled } from 'common';
function GenericAddressForm(props) {
    const isDatafactoryEnabled = useSelector(({ features }) => isFeatureEnabled(features.enabled, FEATURES.DATAFACTORY));
    if (isDatafactoryEnabled) {
        return React.createElement(SauAddressForm, Object.assign({}, props));
    }
    else {
        return React.createElement(OldAddressForm, Object.assign({}, props));
    }
}
export default GenericAddressForm;

import { AddressItem } from 'address';
import Alert from 'components/UI/Alert';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FieldLinkBox from '../FieldLinkBox/FieldLinkBox';
import './RecipientCard.scss';
function RecipientCardContentGroup() {
    const recipients = useSelector((state) => state.newCheckout.recipients);
    const transportProduct = useSelector((state) => state.region.transportProduct);
    const linkboxText = 'Legg til mottakere';
    const dispatch = useDispatch();
    const infoboxText = 'Velg opp til 10 personer du ønsker å sende denne bestillingen til.';
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "recipient-card__centered recipient-card__helptext" }, "Alle mottakere m\u00E5 bo i samme region."),
        recipients.length > 10 ? (React.createElement(Alert, { type: "danger" },
            "Du kan maks sende til 10 mottakere i \u00E9n bestilling. (",
            recipients.length,
            " mottakere er valgt.)")) : null,
        React.createElement(React.Fragment, null,
            recipients.length > 0 ? (recipients.map((recipient, index) => (React.createElement(AddressItem, { address: recipient.deliveryAddress, key: index, className: "recipient-card__group-address", isPrimary: recipient.isPrimary, removeable: true, warningText: transportProduct !== recipient.deliveryAddress.transportProduct
                    ? 'Denne adressen er ikke i valgt region'
                    : undefined })))) : (React.createElement("div", { className: "recipient-card__infobox" }, infoboxText.split('\n').map((x, index) => (React.createElement("div", { key: index }, x))))),
            React.createElement(FieldLinkBox, { text: linkboxText, onClick: () => {
                    dispatch(push('/checkout/address'));
                }, isValid: recipients.length > 1 }))));
}
export default RecipientCardContentGroup;

import { resetFilterAndSort } from 'actions/Products';
import { Pill, PillList, PillListType, PillType, useOnClickOutside } from 'common';
import { SearchResultDropdownProductItem } from 'components/Search/SearchResultDropdownProductItem/SearchResultDropdownProductItem';
import { push } from 'connected-react-router';
import kebabCase from 'lodash/kebabCase';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import './SearchResultDropdown.scss';
export function SearchResultDropdownV2(props) {
    var _a, _b, _c;
    const dispatch = useDispatch();
    const countResults = props.producers.length + props.products.length;
    const ref = useRef(null);
    useOnClickOutside(ref, props.onClose);
    const isMobileOrTablet = useSelector((state) => state.ui.isMobile || state.ui.isTablet);
    const MAX_SEARCH_RESULTS = isMobileOrTablet ? 3 : 8;
    const handleItemClick = (url, productId, productTitle, productPrice, searchRank) => {
        if (productId) {
            dispatch({
                type: 'SEARCH_RESULT_CLICKED',
                payload: { productId, productTitle, productPrice, searchRank },
            });
        }
        props.onClose();
        dispatch(push(url));
    };
    const topOffset = ((_a = props.anchorRef) === null || _a === void 0 ? void 0 : _a.current)
        ? { top: props.anchorRef.current.offsetHeight + 'px' }
        : undefined;
    const remainingResultsCount = countResults - MAX_SEARCH_RESULTS;
    const relevantCategories = props.products
        .map((product) => product.category[0])
        .reduce((unique, category) => {
        if (!unique.some((item) => item.id === category.id)) {
            unique.push(category);
        }
        return unique;
    }, [])
        .slice(0, 7);
    const slugMap = useSelector(({ products }) => products.categories.reduce((map, category) => {
        if (category.slug != null) {
            map.set(category.id, category.slug);
        }
        return map;
    }, new Map()));
    const dropdown = (React.createElement("div", { className: "search-result-dropdown", ref: ref, style: topOffset },
        React.createElement("div", { className: "search-result-dropdown__products" },
            React.createElement("div", { className: 'search-result-dropdown__products__header' },
                React.createElement("h3", null, "Produkter"),
                remainingResultsCount > 0 && (React.createElement("a", { className: "search-result-dropdown__see-more", onClick: () => {
                        dispatch(resetFilterAndSort());
                        dispatch(push(`/search/${encodeURIComponent(props.query)}`));
                        props.onClose();
                    } },
                    "Vis alle ",
                    countResults,
                    " treff"))),
            React.createElement("div", { className: 'search-result-dropdown__products__grid' }, props.products.map((product, idx) => {
                if (idx >= MAX_SEARCH_RESULTS) {
                    return;
                }
                return (React.createElement(SearchResultDropdownProductItem, { type: isMobileOrTablet ? 'small' : 'large', key: product.id, product: product, onClick: () => handleItemClick(`/product/${product.id}/${kebabCase(product.title)}`, product.id, product.title, product.price, idx + 1) }));
            }))),
        React.createElement("div", { className: 'search-result-dropdown-v2__categories' },
            React.createElement("h3", null, "Relevante kategorier"),
            React.createElement(PillList, { listType: PillListType.HorizontalWrap, centered: true }, relevantCategories.map((category, idx) => {
                var _a;
                const categoryName = (_a = category.title) !== null && _a !== void 0 ? _a : category.name;
                const slug = slugMap.get(category.id);
                return (React.createElement(Pill, { pillType: PillType.Selectable, key: idx, navigationPath: '/products/' + slug, onClick: () => {
                        props.onClose();
                    } }, categoryName));
            })))));
    if (countResults === 0)
        return null;
    if (!props.anchorRef)
        return dropdown;
    return ((_b = props.anchorRef) === null || _b === void 0 ? void 0 : _b.current)
        ? ReactDOM.createPortal(dropdown, (_c = props.anchorRef) === null || _c === void 0 ? void 0 : _c.current)
        : null;
}

import { removeRecipientIfPresent } from 'checkout';
import classNames from 'classnames';
import { IconButton } from 'common';
import React from 'react';
import { useDispatch } from 'react-redux';
import { formatAddress } from 'utilities/address';
import './AddressItem.scss';
const garbageCan = require('img/garbagecan.svg');
const pencil = require('img/pencil.svg');
const remove = require('img/remove.svg');
function AddressItem({ address, children, className, isPrimary = false, onDelete, onEdit, removeable = false, warningText, }) {
    const dispatch = useDispatch();
    const hasMobile = !!address.mobile;
    const classes = classNames('address-item', {
        [`${className}`]: className,
        'address-item--warning': !hasMobile,
    });
    const handleEdit = (e) => {
        e.stopPropagation();
        onEdit && onEdit();
    };
    const handleDelete = (e) => {
        e.stopPropagation();
        !!onDelete && onDelete();
    };
    return (React.createElement("div", { className: classes },
        React.createElement("div", { className: "address-item--content" },
            children,
            React.createElement("div", null,
                React.createElement("div", { className: "address-item__name" }, `${address.firstName} ${address.lastName}`),
                React.createElement("div", { className: "address-item__address" },
                    address.mobile ? React.createElement("div", null, address.mobile) : null,
                    React.createElement("div", null, formatAddress(address)),
                    React.createElement("div", null,
                        address.zip,
                        " ",
                        address.city),
                    isPrimary && (React.createElement("div", { className: "address-item__primary-indicator" }, "Hovedadresse")),
                    warningText && (React.createElement("div", { className: "address-item__warning-text" }, warningText)))),
            React.createElement("div", { className: "address-item__actions" },
                removeable && (React.createElement(IconButton, { svg: remove, onClick: () => dispatch(removeRecipientIfPresent(address)), ariaLabel: "Remove", className: "address-item__actions__remove-from-group-icon" })),
                onEdit && React.createElement(IconButton, { svg: pencil, onClick: handleEdit, ariaLabel: "Edit" }),
                onDelete && (React.createElement(IconButton, { svg: garbageCan, onClick: handleDelete, ariaLabel: "Delete" })))),
        !hasMobile && onEdit && (React.createElement("button", { onClick: () => onEdit(true), className: "address-item__add-mobile-btn" },
            React.createElement("div", { className: "address-item__add-mobile-btn--text" }, "Legg til mobilnummer")))));
}
export default AddressItem;

function mapNotification(notificationJson) {
    if (!notificationJson) return null

    try {
        const notification = JSON.parse(notificationJson)
        if (!notification) return null

        if (notification['google.sent_time']) {
            return mapAndroidNotification(notification)
        } else {
            return mapIOSNotification(notification)
        }
    } catch (error) {
        console.error('Failed to parse notification json: ' + notificationJson, error.message)
        return null
    }
}

function mapAndroidNotification(notification) {
    return {
        title: notification['gcm.notification.title'] || notification.title,
        body:
            notification['gcm.notification.body'] ||
            notification.body ||
            notification.message ||
            null,
        route: notification.route || notification.uri || null,
        // messageId: notification['google.message_id'] || null,
        // sentTime: notification['google.sent_time'] || null,
    }
}

function mapIOSNotification(notification) {
    const aps = notification.aps
    return {
        title: (aps.alert && aps.alert.title) || aps.alert || null,
        body: (aps.alert && aps.alert.body) || null,
        route: (notification.payload && notification.payload.route) || notification.uri || null,
    }
}

export { mapNotification }

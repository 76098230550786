import groq from 'groq';
import React, { useEffect, useRef, useState } from 'react';
import sanityClient, { sanityImageBuilder } from 'utilities/sanityClient';
import Banner from '../Banner/Banner';
import SafeHtml from '../SafeHtml/SafeHtml';
import './BannerCarousel.scss';
const downArrow = require('img/arrow-down.svg');
export function BannerCarousel({ documentId }) {
    const bannerCarouselRef = useRef(null);
    const [banners, setBanners] = useState([]);
    const [centeredBannerIndex, setCenteredBannerIndex] = useState(0);
    const windowMidpoint = window.innerWidth / 2;
    const customProperties = {
        '--number-of-banners': banners.length,
        '--side-padding': `${Math.ceil(windowMidpoint - 360)}px`,
    };
    // Fetch the banners
    useEffect(() => {
        const query = groq `
            *[ _id == $documentId ][0] {
                banners[]-> {
                    "id": _id,
                    title,
                    subtitle,
                    target,
                    mainImage,
                    overlayImage
                },
            }
        `;
        let isSubscribed = true;
        sanityClient.fetch(query, { documentId }).then((bannerCarouselDocument) => {
            var _a;
            const fetchedBanners = (_a = bannerCarouselDocument === null || bannerCarouselDocument === void 0 ? void 0 : bannerCarouselDocument.banners) !== null && _a !== void 0 ? _a : [];
            if (isSubscribed && fetchedBanners.length > 0) {
                setBanners(fetchedBanners);
            }
        });
        return () => {
            isSubscribed = false;
        };
    }, [documentId]);
    useEffect(() => {
        if (bannerCarouselRef.current && banners.length > 2) {
            const secondBanner = bannerCarouselRef.current.querySelectorAll('.banner')[1];
            bannerCarouselRef.current.scrollLeft =
                secondBanner.offsetLeft - Math.ceil(window.innerWidth / 2);
        }
    }, [banners.length]);
    function scrollToIndex(index) {
        var _a;
        const bannerNode = (_a = bannerCarouselRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.banner')[index];
        bannerNode === null || bannerNode === void 0 ? void 0 : bannerNode.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        });
    }
    function handleScroll() {
        var _a;
        const bannerNodes = (_a = bannerCarouselRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.banner');
        if (bannerNodes) {
            for (let i = 0; i < bannerNodes.length; i++) {
                const { left, right } = bannerNodes[i].getBoundingClientRect();
                if (left <= windowMidpoint && right >= windowMidpoint) {
                    if (centeredBannerIndex != i) {
                        setCenteredBannerIndex(i);
                    }
                    break;
                }
            }
        }
    }
    return (React.createElement("section", { className: "banner-carousel--wrapper", style: customProperties },
        React.createElement("div", { className: "banner-carousel", ref: bannerCarouselRef, onScroll: handleScroll },
            React.createElement("div", null),
            banners.map((banner) => (React.createElement(Banner, { key: banner.id, title: banner.title, subtitle: banner.subtitle, target: banner.target, mainImage: {
                    desktop: sanityImageBuilder
                        .image(banner.mainImage)
                        .auto('format')
                        .width(Math.min(1185, Math.ceil(window.innerWidth)))
                        .height(Math.ceil(0.362 * Math.min(1185, Math.ceil(window.innerWidth))))
                        .fit('min')
                        .dpr(window.devicePixelRatio)
                        .url(),
                    mobile: sanityImageBuilder
                        .image(banner.mainImage)
                        .auto('format')
                        .width(Math.min(1185, Math.ceil(window.innerWidth)))
                        .fit('min')
                        .dpr(window.devicePixelRatio)
                        .url(),
                } }))),
            React.createElement("div", null)),
        banners.length > 1 && (React.createElement(React.Fragment, null,
            React.createElement(SafeHtml, { type: "button", className: "banner-carousel__btn banner-carousel__btn--prev", onClick: () => scrollToIndex(centeredBannerIndex - 1 < 0
                    ? banners.length - 1
                    : centeredBannerIndex - 1), html: downArrow, "aria-label": "Forrige banner" }),
            React.createElement(SafeHtml, { type: "button", className: "banner-carousel__btn banner-carousel__btn--next", onClick: () => scrollToIndex((centeredBannerIndex + 1) % banners.length), html: downArrow, "aria-label": "Neste banner" })))));
}

import classNames from 'classnames';
import Icon from 'components/UI/Icon';
import React from 'react';
import './IconButton.scss';
export function IconButton({ svg, onClick, ariaLabel, disabled, size, className, }) {
    const classes = classNames('icon-button', {
        'icon-button--disabled': disabled,
        [`${className}`]: className,
    });
    size = size || 'medium';
    return (React.createElement("button", { className: classes, onClick: onClick, "aria-label": ariaLabel, disabled: disabled },
        React.createElement(Icon, { svg: svg, size: size })));
}

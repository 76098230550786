import { Component } from 'react'
import ReactDOM from 'react-dom'

class AppPortal extends Component {
    render() {
        return ReactDOM.createPortal(this.props.children, document.getElementById('app'))
    }
}

export default AppPortal

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './Banner.scss';
const Banner = React.forwardRef(function Banner({ title, subtitle, target, mainImage, overlayImage }, ref) {
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const dispatch = useDispatch();
    function handleClick() {
        dispatch({ type: 'PROMOTION_CLICKED', payload: { promotionTitle: title } });
    }
    return (React.createElement(NavLink, { to: target, className: "banner", ref: ref, onClick: handleClick },
        React.createElement("div", { className: "banner__image-wrapper" },
            React.createElement("img", { className: "banner__image-wrapper__main-image", src: isMobile ? mainImage.mobile : mainImage.desktop, alt: "" }),
            overlayImage && (React.createElement("img", { className: "banner__image-wrapper__overlay-image", src: overlayImage, alt: "" }))),
        React.createElement("div", { className: "banner__text-wrapper" },
            React.createElement("h2", { className: "banner__text-wrapper__title" }, title),
            React.createElement("p", { className: "banner__text-wrapper__lead-text" }, subtitle))));
});
export default Banner;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sanityImageBuilder } from 'utilities/sanityClient';
import './ArticleBodyImage.scss';
function ArticleBodyImage({ value: image }) {
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const imgSrc = useMemo(function getImageSrc() {
        if (isMobile) {
            return sanityImageBuilder
                .image(image)
                .width(window.innerWidth)
                .auto('format')
                .fit('min')
                .dpr(window.devicePixelRatio)
                .url();
        }
        return sanityImageBuilder
            .image(image)
            .width(window.innerWidth)
            .height(434)
            .auto('format')
            .fit('min')
            .dpr(window.devicePixelRatio)
            .url();
    }, [image, isMobile]);
    return React.createElement("img", { className: "article-body-image", src: imgSrc });
}
export { ArticleBodyImage as default };

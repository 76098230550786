import { Price } from 'common'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './style.scss'

class SubscriptionTotal extends Component {
    render() {
        const { total } = this.props
        return (
            <div className="subscription-total">
                <div className="subscription-total--shipping">
                    <span>Frakt:</span> <Price amount={0} />
                </div>
                <div className="subscription-total--sum">
                    <span>Totalpris pr. uke</span> <Price amount={total} />
                </div>
            </div>
        )
    }
}

SubscriptionTotal.propTypes = {
    total: PropTypes.number,
}

export const mapStateToProps = ({ subscription }) => {
    return {
        total: +(subscription.netAmount + subscription.vatAmount).toFixed(2),
    }
}

export default connect(mapStateToProps)(SubscriptionTotal)

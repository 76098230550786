import React, { useEffect, useMemo, useState } from 'react';
import TopBanner from './TopBanner';
import groq from 'groq';
import sanityClient, { sanityImageBuilder } from 'utilities/sanityClient';
export function DynamicTopBanner({ documentId }) {
    const [sanityTopBanner, setSanityTopBanner] = useState();
    useEffect(() => {
        const query = groq `
            *[ _id == $documentId ][0] {
                buttons,
                title,
                subtitle,
                mobileSubtitle,
                toolbar,
                image
            }`.replace(/[\s\n]/, '');
        let isSubscribed = true;
        sanityClient.fetch(query, { documentId }).then((document) => {
            if (isSubscribed) {
                setSanityTopBanner(document);
            }
        });
        return () => {
            isSubscribed = false;
        };
    }, [documentId]);
    const imageSrc = useMemo(function getImageSrc() {
        if ((sanityTopBanner === null || sanityTopBanner === void 0 ? void 0 : sanityTopBanner.image) == undefined)
            return undefined;
        return sanityImageBuilder
            .image(sanityTopBanner.image)
            .width(window.innerWidth)
            .height(576)
            .auto('format')
            .dpr(window.devicePixelRatio)
            .url();
    }, [sanityTopBanner]);
    return sanityTopBanner ? React.createElement(TopBanner, Object.assign({}, sanityTopBanner, { image: imageSrc })) : null;
}

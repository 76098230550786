import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sanityImageBuilder } from 'utilities/sanityClient';
import { NavLink } from 'react-router-dom';
import './ArticleCarousel.scss';
import classNames from 'classnames';
import ItemCarouselHeading from 'components/ItemCarouselHeading/ItemCarouselHeading';
function Article({ image, slug, title, preamble, numberOfArticles }) {
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const imgSrc = useMemo(function getImgSrc() {
        return sanityImageBuilder
            .image(image)
            .auto('format')
            .width(isMobile
            ? 359
            : Math.ceil(
            // Each article entry should fill the available space divided by the number of articles,
            // subtracting the gap between the articles.
            Math.min(1185, window.innerWidth) / numberOfArticles -
                16 * (numberOfArticles - 1)))
            .height(224)
            .fit('min')
            .dpr(window.devicePixelRatio)
            .url();
    }, [image, isMobile, numberOfArticles]);
    const desktopHeadingStyles = [
        { '--size': '3rem', '--line-height': 1.17 },
        { '--size': '2.5rem', '--line-height': 1.2 },
        { '--size': '1.5rem', '--line-height': 1.2 },
    ];
    return (React.createElement(NavLink, { to: `/magasinet/${slug}`, className: "article-carousel__articles__article-wrapper" },
        React.createElement("article", null,
            React.createElement("div", { style: { '--image-url': `url(${imgSrc})` }, className: "article-carousel__articles__article-wrapper__image-container" }),
            React.createElement("h2", { className: "article-carousel__articles__article-wrapper__title", style: desktopHeadingStyles[numberOfArticles - 1] }, title),
            React.createElement("p", null, preamble))));
}
function ArticleCarousel({ articles, mobileSubtitle, subtitle, title, target, targetText, }) {
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const articlesWrapperClasses = classNames({
        'article-carousel__articles': true,
        'article-carousel__articles--scroll-on-mobile': articles.length > 1,
    });
    return (React.createElement("section", { className: "article-carousel" },
        React.createElement(ItemCarouselHeading, { title: title, subtitle: isMobile ? mobileSubtitle : subtitle, target: target, targetText: targetText }),
        React.createElement("div", { className: articlesWrapperClasses, style: { '--number-of-articles': articles.length } }, articles.map((article) => (React.createElement(Article, Object.assign({ key: article.slug, numberOfArticles: articles.length }, article)))))));
}
export default ArticleCarousel;

import { SafeHtml } from 'common';
import { motion } from 'framer-motion';
import React from 'react';
import './BlogPostLoading.scss';
const logo = require('img/morgenlevering_logo_no_text.svg');
function BlogPostLoading() {
    return (React.createElement("div", { className: "blog-post-loading" },
        React.createElement(SafeHtml, { type: motion.div, className: "blog-post-loading__logo", html: logo, animate: { y: [0, -24, -24, 0] }, transition: {
                repeat: Infinity,
                duration: 1.3,
                repeatDelay: 0.4,
                times: [0, 0.4, 0.6, 1],
            } }),
        React.createElement("h1", { className: "blog-post-loading__title" },
            "Henter",
            React.createElement("br", null),
            "artikkelen\u00A0\u2026")));
}
export default BlogPostLoading;

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

class Link extends Component {
    render() {
        const { to, onClick, dispatch, ...props } = this.props

        return (
            <a
                href={to}
                onClick={(event) => {
                    event.preventDefault()
                    onClick && onClick(event)
                    dispatch(push(to))
                }}
                {...props}
            />
        )
    }
}

Link.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    onClick: PropTypes.func,
    dispatch: PropTypes.func,
}

export default connect()(Link)

import { useOnInvalidOrderAttempt } from 'checkout';
import { LinkBox } from 'common';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
function FieldLinkBox({ onClick, text, isValid }) {
    const linkBoxRef = useRef(null);
    const orderAttempts = useSelector((state) => state.newCheckout.orderAttempts);
    const colorStyle = !isValid && orderAttempts > 0 ? 'red' : 'gray';
    useOnInvalidOrderAttempt(!isValid, () => {
        var _a;
        const rect = (_a = linkBoxRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        rect && window.scrollBy({ top: rect.y - 100, behavior: 'smooth' });
    });
    return React.createElement(LinkBox, { onClick: onClick, text: text, colorStyle: colorStyle, ref: linkBoxRef });
}
export default FieldLinkBox;

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'components/UI/Spinner'

class LoadingScreen extends PureComponent {
    render() {
        const { transparent = false } = this.props

        return (
            <div style={{ margin: '0 auto', width: '5rem' }}>
                {transparent ? (
                    <Spinner transparent={transparent} />
                ) : (
                    <Spinner type="logo" size="extra-large" />
                )}
            </div>
        )
    }
}

LoadingScreen.propTypes = {
    transparent: PropTypes.bool,
}

export default LoadingScreen

import classNames from 'classnames';
import React from 'react';
import { getVideoIdFromVimeoUrl } from './utils';
import './VimeoVideo.scss';
function VimeoVideo({ url, className }) {
    const videoId = getVideoIdFromVimeoUrl(url);
    if (videoId == null)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames({ 'vimeo-video--wrapper': true, className: className }) },
            React.createElement("iframe", { className: "vimeo-video__player", src: `https://player.vimeo.com/video/${encodeURIComponent(videoId)}`, frameBorder: "0", allow: "autoplay; fullscreen; picture-in-picture", allowFullScreen: true })),
        React.createElement("script", { src: "https://player.vimeo.com/api/player.js" })));
}
export default VimeoVideo;

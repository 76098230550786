import classNames from 'classnames';
import Icon from 'components/UI/Icon';
import { hideNotification } from 'notification';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DesktopNotification.scss';
const CloseButton = require('img/navigationIcons/closebutton.svg');
const InfoIcon = require('img/info.svg');
function DesktopNotification({ isInSubHeader = false }) {
    const dispatch = useDispatch();
    const notification = useSelector((state) => state.notification.notifications.slice(-1)[0]);
    if (!notification) {
        return null;
    }
    const classes = classNames({
        'desktop-notification': true,
        'desktop-notification--in-sub-header': isInSubHeader,
        [`desktop-notification--${notification.style.toLowerCase()}`]: notification.style,
    });
    const handleClose = () => {
        dispatch(hideNotification());
    };
    return (React.createElement("div", { className: classes },
        React.createElement("p", { className: "desktop-notification__text" },
            notification.style === 'INFO' && React.createElement(Icon, { svg: InfoIcon }),
            notification.text),
        React.createElement("button", { className: "desktop-notification__close-button", onClick: handleClose },
            React.createElement(Icon, { size: "medium", svg: CloseButton }))));
}
export default DesktopNotification;

export function calculateSizes(numberOfProducts, hasHeading) {
    switch (numberOfProducts) {
        case 0:
            return {
                mobile: {
                    headerHeight: 240,
                    headerMarginBottom: 32,
                },
                desktop: {
                    headerHeight: 520,
                    headerMarginBottom: 96,
                },
            };
        case 1:
            return {
                mobile: {
                    headerHeight: hasHeading ? 530 : 380,
                    headerMarginBottom: hasHeading ? 300 : 120,
                },
                desktop: {
                    headerHeight: hasHeading ? 850 : 500,
                    headerMarginBottom: hasHeading ? 190 : 160,
                },
            };
        case 2:
            return {
                mobile: {
                    headerHeight: hasHeading ? 480 : 380,
                    headerMarginBottom: hasHeading ? 220 : 100,
                },
                desktop: {
                    headerHeight: hasHeading ? 850 : 520,
                    headerMarginBottom: hasHeading ? 210 : 140,
                },
            };
        default:
            return {
                mobile: {
                    headerHeight: hasHeading ? 620 : 580,
                    headerMarginBottom: hasHeading ? 280 : 120,
                },
                desktop: {
                    headerHeight: hasHeading ? 850 : 520,
                    headerMarginBottom: hasHeading ? 280 : 180,
                },
            };
    }
}

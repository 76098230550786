import React from 'react';
import TextInput from 'components/UI/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { isValidCardExpiration } from 'utilities/validators';
import { setCardExpiration } from 'actions/NewPaymentMethod';
function ExpirationDateInput() {
    const expiry = useSelector((state) => state.newPaymentMethod.expiry);
    const dispatch = useDispatch();
    return (React.createElement(TextInput, { className: "new-payment-method-form__expiry", label: "Utl\u00F8psdato", requiredHelp: "Utl\u00F8psdato m\u00E5 fylles ut", invalidHelp: "Ugyldig dato. Bruk formatet MM/\u00C5\u00C5", validator: isValidCardExpiration, maxLength: 5, type: "tel", value: expiry, placeholder: "MM/\u00C5\u00C5", onChange: (val) => dispatch(setCardExpiration(val)), required: true }));
}
export default ExpirationDateInput;

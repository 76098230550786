import React from 'react';
import classNames from 'classnames';
import './style.scss';
function Alert({ children, type }) {
    const classes = classNames({
        alert: true,
        [`alert--${type}`]: type,
    });
    return React.createElement("div", { className: classes }, children);
}
export default Alert;

import moment from 'moment';
const baseState = {
    dates: [],
    canDeliver: true,
    error: false,
    distributionLoading: true,
    deadline: undefined,
    deadlineRange: undefined,
    subscriptionDeadline: moment().hour(18).minute(0).second(0),
};
export default function reducer(state = baseState, action) {
    switch (action.type) {
        case 'FETCH_DISTRIBUTION_FOR_ADDRESS_SUCCESS':
        case 'FETCH_DISTRIBUTION_FOR_REGION_SUCCESS':
            return Object.assign(Object.assign({}, state), { deadline: action.payload.deadline
                    ? moment(action.payload.deadline, 'HH:mm:ss')
                    : state.deadline, dates: action.payload.dates, error: false, distributionLoading: false });
        case 'TOGGLE_CAN_DELIVER':
            return Object.assign(Object.assign({}, state), { canDeliver: action.payload });
        case 'FETCH_DISTRIBUTION_FOR_REGION_FAILED':
        case 'FETCH_DISTRIBUTION_FOR_ADDRESS_FAILED':
            return Object.assign(Object.assign({}, state), { error: true, distributionLoading: false });
        case 'FETCH_DISTRIBUTION_FOR_REGION':
        case 'FETCH_DISTRIBUTION_FOR_ADDRESS':
            return Object.assign(Object.assign({}, state), { error: false, distributionLoading: true });
        case 'FETCH_DEADLINE_SUCCESS':
            return Object.assign(Object.assign({}, state), { deadlineRange: {
                    earliestDeadline: moment(action.payload.earliestDeadline, 'HH:mm:ss'),
                    latestDeadline: moment(action.payload.latestDeadline, 'HH:mm:ss'),
                } });
        case 'FETCH_FETCH_SUBSCRIPTION_DEADLINE_DEADLINE_SUCCESS':
            return Object.assign(Object.assign({}, state), { subscriptionDeadline: moment(action.payload) });
        case 'USER_LOGOUT':
            return Object.assign(Object.assign({}, baseState), { dates: state.dates });
        default:
            return state;
    }
}

import keys from 'lodash/keys'
import isString from 'lodash/isString'
import isBoolean from 'lodash/isBoolean'
import isInteger from 'lodash/isInteger'
import isNumber from 'lodash/isNumber'

function mapToUserPropertyArray(userProps) {
    if (!userProps) return

    const userProperties = keys(userProps).map((name) => mapToUserProperty(name, userProps[name]))
    return userProperties.filter((property) => !!property)
}

function mapToUserProperty(name, value) {
    if (isString(value)) {
        return { name, stringValue: value }
    }

    if (isBoolean(value)) {
        return { name, boolValue: value }
    }

    if (isInteger(value)) {
        return { name, intValue: value }
    }

    if (isNumber(value)) {
        return { name, doubleValue: value }
    }

    console.error(`Intercom native: unsupported value: ${value} for user property: ${name}`)
    return undefined
}

export { mapToUserPropertyArray }

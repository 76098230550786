import { CartDropdown } from 'cart';
import { NavigationLink } from 'common';
import Logo from 'components/Header/Logo';
import Icon from 'components/UI/Icon';
import UserDesktop from 'components/User/Desktop';
import { MainNavigation } from 'navigation';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import './Header.scss';
import SearchInput from 'components/Search/SearchInput/SearchInput';
const leftChevron = require('img/chevron-left.svg');
function Header() {
    const location = useLocation();
    const isCheckout = new RegExp(/^\/checkout/).test(location.pathname);
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const handleShowDropdown = (show) => {
        setDropdownVisible(show);
        setSearchExpanded(show);
    };
    const handleSearchBarBlur = () => {
        if (!dropdownVisible) {
            setSearchExpanded(false);
        }
    };
    const ref = React.useRef(null);
    return (React.createElement("header", { className: 'header', ref: ref },
        React.createElement("div", { className: 'header__default' },
            React.createElement(Logo, null),
            React.createElement("div", { className: "middle", "data-lengthened": searchExpanded },
                React.createElement("div", null),
                !isCheckout && (React.createElement("div", { className: 'search-wrapper' },
                    React.createElement(SearchInput, { size: 'small', onShowDropdown: (show) => {
                            handleShowDropdown(show);
                        }, onFocus: () => {
                            setSearchExpanded(true);
                        }, onBlur: () => {
                            handleSearchBarBlur();
                        } }))),
                !isCheckout ? (React.createElement("div", { className: 'header__links' }, !searchExpanded && React.createElement(MainNavigation, null))) : (React.createElement(NavigationLink, { to: "/", classes: "header__back-to-store-link" },
                    React.createElement(Icon, { svg: leftChevron, className: "header__back-to-store-link__icon" }),
                    "Tilbake til nettbutikken"))),
            React.createElement("div", { className: 'right' },
                !isCheckout && React.createElement(CartDropdown, null),
                React.createElement(UserDesktop, null)))));
}
export default Header;

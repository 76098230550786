import classNames from 'classnames';
import React from 'react';
import './PillList.scss';
var PillListType;
(function (PillListType) {
    /*
     * Horizonal list, becomes scrollable on mobile
     */
    PillListType["Horizontal"] = "Horizontal";
    /*
     * Horizonal list, wraps on mobile
     */
    PillListType["HorizontalWrap"] = "HorizontalWrap";
    PillListType["Vertical"] = "Vertical";
})(PillListType || (PillListType = {}));
const PillList = React.forwardRef(function PillList({ children, listType, centered = true, heading = '' }, ref) {
    const classes = classNames({
        'pill-list': true,
        'pill-list--horizontal': listType === PillListType.Horizontal,
        'pill-list--horizontal-wrap': listType === PillListType.HorizontalWrap,
        'pill-list--vertical': listType === PillListType.Vertical,
        'pill-list--horizontal--centered': listType === PillListType.Horizontal && centered,
    });
    return (React.createElement(React.Fragment, null,
        heading.length > 0 && React.createElement("h5", { className: "pill-list__heading" }, heading),
        React.createElement("ul", { ref: ref, className: classes }, children)));
});
export { PillList, PillListType };

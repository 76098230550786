var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import DOMPurify from 'dompurify';
import React, { createElement } from 'react';
const SafeHtml = React.forwardRef(function SafeHtmlFC(_a, ref) {
    var { type, html } = _a, delegate = __rest(_a, ["type", "html"]);
    // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
    return createElement(type, Object.assign(Object.assign({}, delegate), { ref, dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(html) } }));
});
export default SafeHtml;

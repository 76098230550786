import LoadingScreen from 'components/UI/LoadingScreen';
import { getRelatedProducts, ProductCarousel, ProductContext, ProductGrid } from 'product';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
function RelatedProducts({ productIds, regionId, limit }) {
    const allProducts = useSelector((state) => state.products.products);
    const isSubscriptionMode = useSelector((state) => state.subscription.isSubscriptionMode);
    const isMobile = useSelector((state) => state.ui.isMobile);
    const relevantProducts = useMemo(() => allProducts.filter((p) => p.soldOut === '0' && (!isSubscriptionMode || p.subscriptionProduct)), [allProducts, isSubscriptionMode]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { context: parentContext } = useContext(ProductContext);
    useEffect(() => {
        let isSubscribed = true;
        getRelatedProducts(productIds, regionId).then((relatedIds) => {
            if (isSubscribed) {
                setRelatedProducts(relevantProducts
                    .filter((p) => relatedIds.data.some((rId) => rId === p.id))
                    .slice(0, limit));
                setIsLoading(false);
            }
        });
        return () => {
            isSubscribed = false;
        };
    }, [productIds, regionId, limit, relevantProducts]);
    return (React.createElement(ProductContext.Provider, { value: { parentContext, context: 'Product_Recommendations' } }, isLoading ? (React.createElement(LoadingScreen, null)) : isMobile ? (React.createElement(ProductCarousel, { products: relatedProducts || [], shrinkOnMobile: true })) : (React.createElement(ProductGrid, { products: relatedProducts || [], minColumnsOnDesktop: 3, maxColumnsOnDesktop: 3, shrinkProductTiles: true }))));
}
export default RelatedProducts;

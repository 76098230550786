import React, { useEffect } from 'react';
import { CartCheckout } from 'cart';
import { useDispatch } from 'react-redux';
import { toggleCart } from 'actions/UI';
import './CartCheckoutMobile.scss';
function CartCheckoutMobile() {
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(toggleCart(false));
        };
    }, []);
    return (React.createElement("div", { className: "cart-checkout-mobile" },
        React.createElement(CartCheckout, null)));
}
export default CartCheckoutMobile;

import React, { Component } from 'react'
import './style.scss'

class CheckoutAdd extends Component {
    render() {
        return <div className="checkout-add">{this.props.children}</div>
    }
}

export default CheckoutAdd

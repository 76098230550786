import classNames from 'classnames';
import React, { useState } from 'react';
import AccordionChild from './AccordionChild';
import './Accordion.scss';
function Accordion({ items, className }) {
    const [expanded, setExpanded] = useState(false);
    const classes = classNames({
        accordion: true,
        [`${className}`]: className,
    });
    return (React.createElement("ul", { className: classes }, items.map(({ heading, content }, idx) => (React.createElement(AccordionChild, { key: idx, expanded: idx === expanded, onClick: () => setExpanded(idx === expanded ? false : idx), heading: heading, className: classNames({
            accordion__child: true,
            [`${className}__child`]: className != undefined,
        }) }, content)))));
}
export default Accordion;

import './DeliveryDateDropdown.scss';
import React from 'react';
import DeliveryDate from 'components/DeliveryDate';
import Icon from 'components/UI/Icon';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getDateAsText } from 'utilities/date';
import { toggleDeliveryDateDropdown } from 'actions/UI';
import { DropDown } from 'common';
import { capitalize } from 'utilities/text';
const calendarIcon = require('img/calendar.svg');
const DeliveryDateDropdown = (props) => {
    const dispatch = useDispatch();
    const title = useSelector((state) => getDateAsText(state.checkout.deliveryDate));
    const expanded = useSelector((state) => state.ui.showDeliveryDatesDropdown);
    const isSubscriptionMode = useSelector((state) => state.subscription.isSubscriptionMode);
    const deadlineMessage = useDeadlineMessage();
    const showDeadlineMessage = true;
    const handleClick = () => {
        if (!isSubscriptionMode)
            dispatch(toggleDeliveryDateDropdown());
    };
    const classes = classNames({
        'delivery-date-dropdown': true,
        'delivery-date-dropdown--expanded': expanded,
    });
    return (React.createElement("div", { className: classes },
        React.createElement(DropDown, { portalTo: props.portalTo, title: isSubscriptionMode ? 'Fast levering' : title ? title : 'Velg dato', icon: React.createElement(Icon, { svg: calendarIcon, size: "medium" }), expanded: expanded, disabled: isSubscriptionMode, onClose: () => dispatch(toggleDeliveryDateDropdown(false)), onClick: handleClick, disableCloseOnClickOutside: false, loading: false, scrollable: false },
            React.createElement("div", { className: 'delivery-date-dropdown__calendar' },
                React.createElement("h2", { className: "delivery-date-dropdown__heading" }, "N\u00E5r skal vi levere?"),
                React.createElement(DeliveryDate, { onDateSelected: () => dispatch(toggleDeliveryDateDropdown()) })),
            deadlineMessage && showDeadlineMessage && (React.createElement("p", { className: "delivery-date-dropdown__deadline-message" }, deadlineMessage)))));
};
const useDeadlineMessage = () => {
    var _a;
    const deadlineRange = useSelector((state) => state.distribution.deadlineRange);
    const deadline = useSelector((state) => state.distribution.deadline);
    const activeAddress = useSelector((state) => state.address.active);
    const region = useSelector((state) => state.region.name);
    const addressHasOneDeadline = (_a = deadlineRange === null || deadlineRange === void 0 ? void 0 : deadlineRange.latestDeadline) === null || _a === void 0 ? void 0 : _a.isSame(deadlineRange === null || deadlineRange === void 0 ? void 0 : deadlineRange.earliestDeadline, 'minute');
    if (activeAddress && deadline) {
        return `Siste frist for bestilling til ${addressString(activeAddress)} er klokken ${deadline.format('HH:mm')}`;
    }
    if (region && deadlineRange && addressHasOneDeadline) {
        return `Siste frist for bestilling til ${capitalize(region)} er klokken ${deadlineRange.earliestDeadline.format('HH:mm')}`;
    }
    if (region && deadlineRange && !addressHasOneDeadline) {
        return `Siste frist for bestilling til ${capitalize(region)} er mellom klokken ${deadlineRange.earliestDeadline.format('HH:mm')} og ${deadlineRange.latestDeadline.format('HH:mm')}. Velg adresse for eksakt tidspunkt`;
    }
    return undefined;
};
const addressString = (address) => {
    return `${capitalize(address.deliveryAddress.streetName)} ${address.deliveryAddress.streetNo}, ${capitalize(address.deliveryAddress.city)}`;
};
export default DeliveryDateDropdown;

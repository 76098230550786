import { PortableText } from '@portabletext/react';
import DynamicConceptPageBodyImage from 'conceptPage/components/DynamicConceptPageBodyImage/DynamicConceptPageBodyImage';
import React from 'react';
import './FreeText.scss';
export function FreeText(props) {
    return (React.createElement("div", { className: "free-text" },
        React.createElement(PortableText, { value: props.body, components: {
                types: {
                    image: ({ value }) => (React.createElement(DynamicConceptPageBodyImage, { image: value })),
                },
                block: {
                    center: ({ children }) => (React.createElement("div", { className: "dynamic-concept-page__content__body--centered" }, children)),
                },
            } })));
}

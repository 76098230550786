import { validateJwt } from 'user'

export function clearQueryParams(w) {
    const goTo = removeURLParameter(w.location.pathname + w.location.search, 'jwt')
    w.history.replaceState({}, 'noQueryParams', goTo)
}

function removeURLParameter(url, parameter) {
    var urlparts = url.split('?')
    if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '='
        var pars = urlparts[1].split(/[&;]/g)

        for (var i = pars.length; i-- > 0; ) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1)
            }
        }

        url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
        return url
    } else {
        return url
    }
}

export function getJWTTokenFromUrl(urlParams) {
    const urlToken = urlParams.match(/jwt=([a-zA-Z0-9\.]*)/)
    if (urlToken && urlToken.length > 0) {
        return atob(urlToken[1])
    }
}

export function initToken(store) {
    let tokenSource = ''
    let jwtToken

    let urlToken = getJWTTokenFromUrl(window.location.search)
    if (urlToken) {
        tokenSource = 'url'
        jwtToken = urlToken
    } else {
        let storageToken = localStorage.getItem('jwt')
        if (storageToken) {
            tokenSource = 'storage'
            jwtToken = storageToken
        }
    }

    if (jwtToken) {
        store.dispatch(validateJwt(jwtToken, tokenSource))
        clearQueryParams(window)
    } else {
        store.dispatch({ type: 'NO_JWT_PRESENT', payload: {} })
    }
}

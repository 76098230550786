export default function reducer(
    state = {
        regions: [],
        slug: null,
        transportProduct: null,
        name: null,
        id: null,
    },
    action
) {
    switch (action.type) {
        case 'GET_REGIONS_SUCCESS':
            return {
                ...state,
                regions: action.payload,
            }
        case 'GET_AUTO_REGION_SUCCESS':
            return {
                ...state,
                slug: action.payload.externalRegion,
                transportProduct: action.payload.transportProduct,
                name: action.payload.name,
                id: action.payload.regionId,
            }
        case 'SET_REGION':
            return {
                ...state,
                slug: action.payload.externalRegion,
                name: action.payload.name,
                transportProduct: action.payload.transportProduct,
                id: action.payload.id,
            }
        default:
            return state
    }
}

import classNames from 'classnames';
import Icon from 'components/UI/Icon';
import { hideNotification } from 'notification';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './MobileBottomNotification.scss';
const CloseButton = require('img/navigationIcons/closebutton.svg');
function MobileBottomNotification() {
    const dispatch = useDispatch();
    const notification = useSelector((state) => state.notification.notifications.slice(-1)[0]);
    if (!notification) {
        return null;
    }
    const classes = classNames({
        'mobile-bottom-notification': true,
        [`mobile-bottom-notification--${notification.style.toLowerCase()}`]: notification.style,
    });
    const handleClose = () => {
        dispatch(hideNotification());
    };
    return (React.createElement("div", { className: classes },
        React.createElement("p", { className: "mobile-bottom-notification__text" }, notification.text),
        React.createElement("button", { className: "mobile-bottom-notification__close-button", onClick: handleClose },
            React.createElement(Icon, { svg: CloseButton, size: 'medium' }))));
}
export default MobileBottomNotification;

const defaultSortBy = 'name'

export default function reducer(
    state = {
        products: [],
        productsLoading: false,
        recommendations: [],
        recommendationsLoading: false,
        categories: [],
        suppliers: [],
        productsWithDetails: {},
        myProducts: [],
        featured: [],
        sortBy: defaultSortBy,
        filterTags: [],
        stock: { productIdToQuantityPossible: {} },
    },
    action
) {
    switch (action.type) {
        case 'FETCH_ALL_PRODUCTS':
            return { ...state, productsLoading: true }
        case 'FETCH_ALL_PRODUCTS_FAILED':
            return { ...state, productsLoading: false }
        case 'FETCH_ALL_PRODUCTS_SUCCESS':
            return {
                ...state,
                products: action.payload,
                productsLoading: false,
            }
        case 'FETCH_RECOMMENDATIONS':
            return { ...state, recommendationsLoading: true }
        case 'FETCH_RECOMMENDATIONS_FAILED':
            return { ...state, recommendationsLoading: false }
        case 'FETCH_RECOMMENDATIONS_SUCCESS':
            return {
                ...state,
                recommendations: action.payload,
                recommendationsLoading: false,
            }
        case 'FETCH_FEATURED_PRODUCTS':
            return { ...state }
        case 'FETCH_FEATURED_PRODUCTS_FAILED':
            return { ...state }
        case 'FETCH_FEATURED_PRODUCTS_SUCCESS':
            return { ...state, featured: action.payload }
        case 'FETCH_MY_PRODUCTS':
            return { ...state }
        case 'FETCH_MY_PRODUCTS_FAILED':
            return { ...state }
        case 'FETCH_MY_PRODUCTS_SUCCESS':
            return { ...state, myProducts: action.payload }
        case 'FETCH_CATEGORIES':
            return { ...state }
        case 'FETCH_CATEGORIES_FAILED':
            return { ...state }
        case 'FETCH_CATEGORIES_SUCCESS':
            return { ...state, categories: action.payload }
        case 'FETCH_SUPPLIERS':
            return { ...state }
        case 'FETCH_SUPPLIERS_FAILED':
            return { ...state }
        case 'FETCH_SUPPLIERS_SUCCESS':
            return { ...state, suppliers: action.payload }
        case 'FETCH_PRODUCT_DETAILS':
            return { ...state }
        case 'FETCH_PRODUCT_DETAILS_FAILED':
            return { ...state }
        case 'FETCH_PRODUCT_DETAILS_SUCCESS':
            return {
                ...state,
                productsWithDetails: {
                    ...state.productsWithDetails,
                    [action.payload.id]: action.payload,
                },
            }
        case 'FETCH_ALL_PRODUCT_DETAILS_SUCCESS':
            return {
                ...state,
                productsWithDetails: action.payload,
            }
        case 'FETCH_STOCK_SUCCESS':
            return { ...state, stock: action.payload }
        case 'RESET_PRODUCT_DETAILS':
            return { ...state, productsWithDetails: {} }
        case 'USER_LOGOUT':
            return { ...state, myProducts: [] }
        case 'RESET_PRODUCT_FILTER_AND_SORT':
            return { ...state, filterTags: [], sortBy: defaultSortBy }
        case 'SET_PRODUCT_FILTER_TAGS':
            return { ...state, filterTags: action.payload }
        case 'SET_PRODUCT_SORT_BY':
            return { ...state, sortBy: action.payload }
        default:
            return state
    }
}

import './style.scss'

import React, { Component } from 'react'

import Icon from 'components/UI/Icon'
import PropTypes from 'prop-types'
import UserNavigation from 'components/User/UserNavigation'
import { connect } from 'react-redux'
import { toggleUserDropdown } from 'actions/UI'
import userIcon from 'img/user.svg'
import { DropDown } from 'common'

class UserDropDown extends Component {
    render() {
        const { expanded, dispatch } = this.props
        return (
            <DropDown
                className="user-drop-down"
                onClose={() => dispatch(toggleUserDropdown(false))}
                onClick={() => dispatch(toggleUserDropdown())}
                expanded={expanded}
                icon={<Icon svg={userIcon} size="medium" />}
                ariaLabel="Brukerinformasjon"
            >
                <UserNavigation />
            </DropDown>
        )
    }
}

UserDropDown.propTypes = {
    expanded: PropTypes.bool,
    dispatch: PropTypes.func,
}

function mapStateToProps({ ui }) {
    return { expanded: ui.showUserDropdown }
}

export default connect(mapStateToProps)(UserDropDown)

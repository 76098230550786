import { RecipientCard } from 'checkout';
import { Button, ButtonRow, Card } from 'common';
import { goBack } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import './DeliveryPage.scss';
import TimeAndPlace from './TimeAndPlace';
function DeliveryPage() {
    const dispatch = useDispatch();
    return (React.createElement("div", { className: "delivery-page" },
        React.createElement("div", { className: "delivery-page__card-container" },
            React.createElement(Card, { title: "Sted og tid" },
                React.createElement(TimeAndPlace, null)),
            React.createElement(RecipientCard, null),
            React.createElement(ButtonRow, { display: "stretch" },
                React.createElement(Button, { type: "action", style: "primary", onClick: () => dispatch(goBack()) }, "Ferdig")))));
}
export default DeliveryPage;

import React from 'react';
import './TopBannerToolbar.scss';
import classNames from 'classnames';
import DeliveryDateDropdown from 'components/DeliveryDateDropdown/DeliveryDateDropdown';
import { AddressDropdown } from 'address';
import { useSelector } from 'react-redux';
import { DeliveryOptionsButton } from 'delivery';
export function TopBannerToolbar(props) {
    var _a, _b;
    const isMobile = useSelector((state) => state.ui.isMobile);
    const ref = React.useRef(null);
    return (React.createElement("div", { className: classNames('toolbar', props.className) },
        React.createElement("div", { className: 'toolbar__inner' },
            React.createElement("h3", { className: 'toolbar__inner__header' }, "Velg adresse og tid for levering"),
            isMobile ? (React.createElement(DeliveryOptionsButton, null)) : (React.createElement("div", { className: 'toolbar__inner__dropdown' },
                React.createElement(AddressDropdown, { isRegionDropDownShowing: false, portalTo: (_a = ref === null || ref === void 0 ? void 0 : ref.current) !== null && _a !== void 0 ? _a : undefined }),
                React.createElement(DeliveryDateDropdown, { portalTo: (_b = ref === null || ref === void 0 ? void 0 : ref.current) !== null && _b !== void 0 ? _b : undefined }))),
            React.createElement("div", { className: 'toolbar__portal-target', ref: ref }))));
}

export function isAppUrl() {
    let appSubdomain = new RegExp(/^app\./)
    if (appSubdomain.test(window.location.host)) return true
    return window.location.search.includes('app=true')
}
export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

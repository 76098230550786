import './style.scss'

import * as Types from 'types'

import React, { Component } from 'react'

import { AddressListItem } from 'address'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'

class AddressList extends Component {
    getCurrentAddress() {
        const { isSubscriptionAddress, activeAddress, subscriptionAddress } = this.props

        return isSubscriptionAddress ? subscriptionAddress : activeAddress.deliveryAddress
    }

    render() {
        const { addresses, canDeliver, onSelected } = this.props
        const currentAddress = this.getCurrentAddress()

        return (
            <div className="address-list">
                {addresses &&
                    addresses.map((address, index) => (
                        <AddressListItem
                            key={index}
                            address={address.deliveryAddress}
                            isCurrent={isEqual(address.deliveryAddress, currentAddress)}
                            canDeliver={canDeliver}
                            onSelect={() => onSelected && onSelected(address)}
                        />
                    ))}
            </div>
        )
    }
}

AddressList.propTypes = {
    isSubscriptionAddress: PropTypes.bool,
    addresses: PropTypes.arrayOf(Types.address),
    activeAddress: PropTypes.any,
    subscriptionAddress: PropTypes.any,
    canDeliver: PropTypes.bool,
    onSelected: PropTypes.func,
    dispatch: PropTypes.func,
}

const mapStateToProps = ({ address, distribution }) => {
    return {
        addresses: address.addresses,
        activeAddress: address.active,
        subscriptionAddress: address.subscription,
        canDeliver: distribution.canDeliver || distribution.distributionLoading,
    }
}

export default connect(mapStateToProps)(AddressList)

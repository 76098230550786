const baseState = {
    active: true,
    cardHolderName: '',
    cardNo: '',
    expiry: '',
    cvc: '',
    token: null,
    tokenGenerating: false,
    tokenError: false,
    type: 'Nytt kort',
    saveCardConsent: false,
}

export default function reducer(state = baseState, action) {
    switch (action.type) {
        case 'SET_NEW_CARD':
            return {
                ...state,
                active: true,
            }
        case 'USER_LOGOUT':
            return { ...baseState }
        case 'SET_CARD_HOLDER_NAME':
            return {
                ...state,
                cardHolderName: action.payload,
                token: null,
                tokenError: false,
            }
        case 'SET_CARD_NO':
            return {
                ...state,
                cardNo: action.payload,
                token: null,
                tokenError: false,
            }
        case 'SET_CARD_EXPIRATION':
            return {
                ...state,
                expiry: action.payload,
                token: null,
                tokenError: false,
            }
        case 'SET_CARD_CVC':
            return {
                ...state,
                cvc: action.payload,
                token: null,
                tokenError: false,
            }
        case 'SET_SAVE_CARD_CONSENT':
            return {
                ...state,
                saveCardConsent: action.payload,
            }
        case 'GENERATE_TOKEN':
            return {
                ...state,
                tokenGenerating: true,
            }
        case 'GENERATE_TOKEN_SUCCESS':
            return {
                ...state,
                token: action.payload,
                tokenGenerating: false,
                tokenError: false,
            }
        case 'GENERATE_TOKEN_FAILED':
            return {
                ...state,
                tokenGenerating: false,
                tokenError: true,
            }
        case 'FETCH_PAYMENT_METHODS_SUCCESS':
            return {
                ...baseState,
                active: !(action.payload.length > 0),
            }
        case 'NO_JWT_PRESENT':
            return {
                ...state,
                active: true,
            }
        case 'PLACE_ORDER_ERROR':
        case 'PLACE_ORDER_SUCCESS':
        case '2_PHASE_COMPLETE':
            return {
                ...state,
                cvc: state.active ? state.cvc : baseState.cvc,
                token: state.active ? state.token : baseState.token,
            }

        case 'SET_PREFERRED_PAYMENT_METHOD':
            // Emitted when the user selects a previously saved card as payment method
            // In this case, we do not want to use the newly added card for payment
            return {
                ...state,
                active: false,
            }

        default:
            return state
    }
}

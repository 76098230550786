import React from 'react';
import './ProductImage.scss';
function ProductImage({ product, className }) {
    if ('fullSize' in product.navigationImage) {
        return (React.createElement("picture", { className: 'product-image' },
            React.createElement("source", { type: "image/webp", srcSet: `
                    ${product.navigationImage.webp_300x497} 1x,
                    ${product.navigationImage.webp_600x994} 2x
                    ` }),
            React.createElement("img", { loading: "lazy", className: className, src: product.navigationImage.fallback, alt: product.title })));
    }
    return (React.createElement("picture", { className: 'product-image' },
        React.createElement("source", { type: "image/webp", srcSet: `
                        ${product.navigationImage.webp_400x400} 400w,
                        ${product.navigationImage.webp_600x600} 600w,
                        ${product.navigationImage.webp_800x800} 800w
                    `, sizes: "(max-width: 767px) 200px, 400px" }),
        React.createElement("img", { loading: "lazy", className: className, src: product.navigationImage.large, alt: product.title })));
}
export default ProductImage;

import React from 'react'

import Loading from 'components/UI/LoadingScreen'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { getParameterByName } from 'utilities/url'

function PaymentMethod3DSecureCallbackHandler() {
    const dispatch = useDispatch()
    const status = getParameterByName('status')
    const context = getParameterByName('context')

    if (status === 'FAILED' || status === 'ERROR') {
        dispatch({ type: 'ADD_PAYMENT_METHOD_FAILED' })
    }
    if (context === 'checkout') {
        dispatch(push('/checkout/payment'))
    } else {
        dispatch(push('/my-page/payment-methods'))
    }

    return <Loading />
}

PaymentMethod3DSecureCallbackHandler.propTypes = {
    failed: PropTypes.bool,
    verified: PropTypes.bool,
    context: PropTypes.string,
}

export default connect()(PaymentMethod3DSecureCallbackHandler)

import { mapNotification } from './pushMapper'

function toBool(strVal) {
    return strVal.toLowerCase() === 'true'
}

function registerHandlers(handlers) {
    window.pushNotificationIsFeatureEnabledChanged = (isEnabled) => {
        handlers &&
            handlers.isFeatureEnabledChanged &&
            handlers.isFeatureEnabledChanged(toBool(isEnabled))
    }

    window.pushNotificationAuthorizationChanged = (authorization) => {
        handlers && handlers.authorizationChanged && handlers.authorizationChanged(authorization)
    }

    window.pushNotificationTokenChanged = (token) => {
        handlers && handlers.tokenChanged && handlers.tokenChanged(token)
    }

    window.pushNotificationReceived = (json, mode) => {
        const notification = mapNotification(json)

        handlers &&
            handlers.notificationReceived &&
            handlers.notificationReceived(notification, mode)
    }

    window.pushNotificationErrorOccurred = (context, error) => {
        handlers && handlers.errorOccurred && handlers.errorOccurred(context, error)
    }
}

function hasAndroidBridge() {
    return !!PushNotificationsAndroidBridge
}

function postRequest(request) {
    if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.pushNotifications &&
        window.webkit.messageHandlers.pushNotifications.postMessage
    ) {
        webkit.messageHandlers.pushNotifications.postMessage(JSON.stringify(request))
        return true
    }
    return false
}

function requestPushPermission(title, reason, okButtonTitle, cancelButtonTitle) {
    let request = {
        action: 'requestPushPermission',
        payload: {
            title,
            reason,
            okButton: okButtonTitle,
            cancelButton: cancelButtonTitle,
        },
    }

    return postRequest(request)
}

function requestPushSettings(title, reason, okButtonTitle, cancelButtonTitle) {
    if (hasAndroidBridge()) {
        PushNotificationsAndroidBridge.requestPushSettings(
            title,
            reason,
            okButtonTitle,
            cancelButtonTitle
        )
        return true
    }

    let request = {
        action: 'requestPushSettings',
        payload: {
            title,
            reason,
            okButton: okButtonTitle,
            cancelButton: cancelButtonTitle,
        },
    }

    return postRequest(request)
}

function registerPushUserId(userId) {
    let request = {
        action: 'registerPushUserId',
        payload: {
            title: '',
            reason: '',
            okButton: '',
            cancelButton: '',
            userId: userId.toString(),
        },
    }

    return postRequest(request)
}

export { registerHandlers, requestPushPermission, requestPushSettings, registerPushUserId }

import { Button, ButtonRow } from 'common';
import { push } from 'connected-react-router';
import { FilterableCategoryProductGrid, ProductContext } from 'product';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
export function ProductsWithCategoriesGrid() {
    const dispatch = useDispatch();
    const { products: allProducts, categories } = useSelector((state) => state.products);
    const { isMobile, isTablet } = useSelector((state) => state.ui);
    const [selectedCategorySlug, setSelectedCategorySlug] = useState();
    const handleCategorySelect = (slug) => {
        setSelectedCategorySlug(slug);
    };
    return (React.createElement("div", { className: "pagebuilder__products-with-categories" },
        React.createElement("h2", null, "Utforsk hele butikken"),
        React.createElement(ProductContext.Provider, { value: { context: 'HomePage' } },
            React.createElement(FilterableCategoryProductGrid, { products: allProducts, categories: categories, allCategoriesLinkText: "G\u00E5 til alle produkter", limit: isMobile ? 6 : isTablet ? 9 : 12, handleCategorySelect: handleCategorySelect, showCount: true, showCountCategory: !isMobile })),
        React.createElement(ButtonRow, { display: "center", className: "pagebuilder__products-with-categories__button-container" },
            React.createElement(Button, { type: "action", style: "primary", onClick: () => dispatch(push(selectedCategorySlug
                    ? `/products/${selectedCategorySlug}`
                    : '/products')) }, "Se alle produkter"))));
}

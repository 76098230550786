import Dialog from '@reach/dialog';
import '@reach/dialog/styles.css';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SafeHtml from '../SafeHtml/SafeHtml';
import './Modal.scss';
const cancelIcon = require('img/boldcancel.svg');
function Modal({ onClose, children, className, showCloseButton = true, labelId }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: 'TOGGLE_MODAL_OPEN', payload: true });
        return () => {
            dispatch({ type: 'TOGGLE_MODAL_OPEN', payload: false });
        };
    }, [dispatch]);
    const classes = classNames({
        modal: true,
        [`${className}`]: className,
    });
    return (React.createElement(Dialog, { onDismiss: onClose, className: classes, "aria-labelledby": labelId },
        showCloseButton && (React.createElement(SafeHtml, { type: "button", "aria-label": "Lukk vinduet", html: cancelIcon, className: "modal__exit", onClick: onClose })),
        children));
}
export default Modal;

import { SafeHtml } from 'common';
import Icon from 'components/UI/Icon';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedStreetAddress } from 'utilities/address';
import { getDateAsText } from 'utilities/date';
import './DeliveryOptionsButton.scss';
import { capitalize } from 'utilities/text';
const warning = require('img/warning.svg');
const calendarIcon = require('img/calendar.svg');
const marker = require('img/marker.svg');
const chevronIcon = require('img/chevron.svg');
function DeliveryOptionsButton() {
    const dispatch = useDispatch();
    const activeAddress = useSelector((state) => { var _a; return (_a = state.address.active) === null || _a === void 0 ? void 0 : _a.deliveryAddress; });
    const regionName = useSelector((state) => state.region.name);
    const deliveryDate = useSelector((state) => state.checkout.deliveryDate);
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const date = capitalize(getDateAsText(deliveryDate, true, true, true));
    const location = getFormattedStreetAddress(activeAddress) || regionName;
    const unsupportedAddress = useSelector((state) => !state.distribution.canDeliver);
    const getAddressButtonText = () => {
        if (orderType === 'group') {
            return regionName;
        }
        else if (!location) {
            return regionName;
        }
        else {
            return location;
        }
    };
    const getDateButtonText = () => {
        if (date) {
            return date;
        }
        else {
            return 'Velg dato';
        }
    };
    return (React.createElement("div", { className: "delivery-options-button", onClick: () => {
            dispatch(push('/delivery'));
        }, onKeyDown: (e) => {
            e.key === 'Enter' && dispatch(push('/delivery'));
        }, tabIndex: 0 },
        React.createElement("div", null,
            unsupportedAddress ? (React.createElement(Icon, { className: "delivery-options-button__warning", svg: warning, size: 'medium' })) : (React.createElement(Icon, { className: "delivery-options-button__icon", svg: marker, size: 'medium' })),
            React.createElement(SafeHtml, { type: "p", html: getAddressButtonText(), className: "delivery-options-button__text" })),
        React.createElement("div", null,
            React.createElement(Icon, { className: "delivery-options-button__icon", svg: calendarIcon, size: 'medium' }),
            React.createElement(SafeHtml, { type: "p", html: getDateButtonText(), className: "delivery-options-button__text" })),
        React.createElement(Icon, { className: "delivery-options-button__icon delivery-options-button__icon__chevron", svg: chevronIcon, size: "small" })));
}
export default DeliveryOptionsButton;

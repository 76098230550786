import { PortableText } from '@portabletext/react';
import '@reach/accordion/styles.css';
import groq from 'groq';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { hasNativeIntercom, showMessenger } from 'utilities/intercom';
import { IntercomAPI } from 'utilities/reactIntercom';
import sanityClient from 'utilities/sanityClient';
import Accordion from '../Accordion';
import SafeHtml from '../SafeHtml/SafeHtml';
import './Footer.scss';
import { gotoSubscriptionInfoOrOverview } from 'actions/Subscription';
import { useDispatch } from 'react-redux';
const logo = require('img/morgenlevering_logo_no_text.svg');
const facebookLogo = require('img/facebook.svg');
const instagramLogo = require('img/instagram.svg');
const tiktokLogo = require('img/tiktok.svg');
const appStoreBadge = require('img/Download_on_the_App_Store_Badge_NO_RGB_wht_100317.svg');
function Footer() {
    var _a;
    const [footerDocument, setFooterDocument] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        const query = groq `
        *[ _type == "footer" ][0] {
            bottomContentLeft,
            bottomContentRight,
            content[] {
                content,
                heading
            },
            linksSection {
                links[] {
                    target,
                    title
                },
                showIntercomLink,
                showSubscriptionLink,
                socialMediaLinks,
                appLinks
            }
        }`.replace(/\n\s/, '');
        let isSubscribed = true;
        sanityClient
            .fetch(query)
            .then((document) => (isSubscribed ? setFooterDocument(document) : null));
        return () => {
            isSubscribed = false;
        };
    }, []);
    if (footerDocument == null)
        return null;
    const items = footerDocument.content.map((block) => ({
        heading: block.heading,
        content: (React.createElement(PortableText, { value: block.content, components: {
                types: {
                    inlineLink: ({ value }) => (React.createElement(NavLink, { to: `/${value.target}` }, value.text)),
                },
            } })),
    }));
    function showIntercom(e) {
        e.preventDefault();
        if (hasNativeIntercom()) {
            return showMessenger();
        }
        return IntercomAPI('show');
    }
    return (React.createElement("footer", { className: "main-footer" },
        React.createElement("section", { className: "main-footer__top-content" },
            React.createElement(Accordion, { items: items, className: "main-footer__top-content__accordion" }),
            React.createElement("div", { className: "main-footer__top-content__links" },
                React.createElement("ul", { className: "main-footer__top-content__links--left" },
                    footerDocument.linksSection.links.map((link) => (React.createElement("li", { className: "main-footer__top-content__links__link", key: link.target },
                        React.createElement(NavLink, { to: `/${link.target}` }, link.title)))),
                    footerDocument.linksSection.showSubscriptionLink && (React.createElement("li", { className: "main-footer__top-content__links__link" },
                        React.createElement("button", { onClick: () => dispatch(gotoSubscriptionInfoOrOverview()) }, "Fast Levering"))),
                    footerDocument.linksSection.showIntercomLink && (React.createElement("li", { className: "main-footer__top-content__links__link" },
                        React.createElement("button", { onClick: showIntercom }, "Kontakt kundesupport")))),
                React.createElement("ul", { className: "main-footer__top-content__links--right" },
                    footerDocument.linksSection.socialMediaLinks.instagram && (React.createElement("li", { className: "main-footer__top-content__links__link main-footer__top-content__links__link--icon" },
                        React.createElement(SafeHtml, { type: "a", html: instagramLogo, href: `https://instagram.com/${footerDocument.linksSection.socialMediaLinks.instagram}`, target: "_blank", rel: "noreferrer" }))),
                    footerDocument.linksSection.socialMediaLinks.facebook && (React.createElement("li", { className: "main-footer__top-content__links__link main-footer__top-content__links__link--icon" },
                        React.createElement(SafeHtml, { type: "a", html: facebookLogo, href: `https://facebook.com/${footerDocument.linksSection.socialMediaLinks.facebook}`, target: "_blank", rel: "noreferrer" }))),
                    footerDocument.linksSection.socialMediaLinks.tiktok && (React.createElement("li", { className: "main-footer__top-content__links__link main-footer__top-content__links__link--icon" },
                        React.createElement(SafeHtml, { type: "a", html: tiktokLogo, href: `https://tiktok.com/${footerDocument.linksSection.socialMediaLinks.tiktok}`, target: "_blank", rel: "noreferrer" })))),
                React.createElement("ul", { className: "main-footer__top-content__links--right-bottom" }))),
        React.createElement("section", { className: "main-footer__bottom-content" },
            React.createElement("div", { className: "main-footer__bottom-content__grid" },
                React.createElement("div", { className: "main-footer__bottom-content__grid__one" },
                    React.createElement("p", { className: "main-footer__bottom-content__grid__heading" }, footerDocument.bottomContentLeft.heading),
                    React.createElement("p", { className: "main-footer__bottom-content__grid__text" }, footerDocument.bottomContentLeft.content),
                    ((_a = footerDocument.linksSection.appLinks) === null || _a === void 0 ? void 0 : _a.apple) && (React.createElement("div", { className: "main-footer__bottom-content__grid__badge" },
                        React.createElement(SafeHtml, { type: "a", html: appStoreBadge, href: `https://apps.apple.com/${footerDocument.linksSection.appLinks.apple}`, target: "_blank", rel: "noreferrer" })))),
                React.createElement("div", { className: "main-footer__bottom-content__grid__logos" },
                    React.createElement(SafeHtml, { type: "div", html: logo })),
                React.createElement("div", { className: "main-footer__bottom-content__grid__two" },
                    React.createElement("p", { className: "main-footer__bottom-content__grid__heading" }, footerDocument.bottomContentRight.heading),
                    React.createElement("p", { className: "main-footer__bottom-content__grid__text" }, footerDocument.bottomContentRight.content))))));
}
export default Footer;

import { Button, ButtonRow, Modal } from 'common';
import React from 'react';
import './ConfirmModal.scss';
function ConfirmModal({ onCancel, onOk, title, cancelText, okText, destructive, description, }) {
    const descriptionList = description
        ? Array.isArray(description)
            ? description
            : [description]
        : [];
    return (React.createElement(Modal, { onClose: onCancel, className: "confirm-modal", labelId: "confirm-modal-content-title" },
        React.createElement("div", { className: "confirm-modal__content" },
            title ? (React.createElement("div", { className: "confirm-modal__content--title", id: "confirm-modal-content-title" }, title)) : null,
            descriptionList.map((text, idx) => (React.createElement("div", { key: idx, className: "confirm-modal__content--description" }, text))),
            React.createElement(ButtonRow, { display: "center" },
                React.createElement(Button, { type: "action", style: "ghost", onClick: onCancel }, cancelText),
                React.createElement(Button, { type: "action", style: destructive ? 'destructive' : 'primary', onClick: onOk }, okText)))));
}
export default ConfirmModal;

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CustomerServiceWeb from './CustomerServiceWeb'
import CustomerServiceNative from './CustomerServiceNative'
import { hasNativeIntercom, getUserPropsFromState } from '../../utilities/intercom'

class CustomerService extends Component {
    render() {
        const { ...otherProps } = this.props

        if (hasNativeIntercom()) {
            return <CustomerServiceNative {...otherProps} />
        }

        return <CustomerServiceWeb {...otherProps} />
    }
}

CustomerService.propTypes = {
    intercomAppId: PropTypes.string,
    userId: PropTypes.string,
    userHash: PropTypes.string,
    userEmail: PropTypes.string,
    intercomUserProps: PropTypes.object,
    notificationOptions: PropTypes.array,
    hasItemsInCart: PropTypes.bool,
}

function mapStateToProps(state) {
    return {
        intercomAppId: process.env.INTERCOM_APP_ID,
        userId: state.user.spidData.userId
            ? '' + state.user.spidData.userId
            : state.user.spidData.userId,
        userEmail: state.user.jwtData.spidEmail,
        userHash: state.user.jwtData.intercomUserHash,
        intercomUserProps: getUserPropsFromState(state),
        hasItemsInCart: state.cart.products.length > 0,
        notificationOptions: state.push.options,
    }
}

export default connect(mapStateToProps)(CustomerService)

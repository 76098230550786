import { webLogin } from 'user'
import UserDropDown from 'components/User/DropDown'
import UserLoginButton from 'components/User/LoginButton'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './style.scss'

export class UserDesktop extends Component {
    render() {
        const { isLoggedIn } = this.props
        if (isLoggedIn)
            return (
                <div className="user-desktop">
                    <UserDropDown />
                </div>
            )
        return (
            <div className="user-desktop">
                <UserLoginButton onClick={() => webLogin()} />
            </div>
        )
    }
}

UserDesktop.propTypes = {
    isLoggedIn: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user }) => {
    return {
        isLoggedIn: user.isLoggedIn,
    }
}

export default connect(mapStateToProps)(UserDesktop)

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { handleNetworkError } from 'actions/Error';
import axios from 'axios';
import { formatFloor, formatHousehold, formatStreetName, formatStreetNo } from 'utilities/address';
export const streetSearch = (query, regionSlug) => {
    return axios.get(`${process.env.AH_URL}/${process.env.AH_COUNTRYCODE}/streetSearch/${encodeURIComponent(query)}`, {
        params: {
            apiKey: process.env.AH_API_KEY,
            limitToOfficial: process.env.AH_LIMIT_TO_OFFICIAL,
            limit: 200,
            location: regionSlug,
        },
    });
};
export const streetNumberSearch = (query, streetIds) => axios.get(`${process.env.AH_URL}/${process.env.AH_COUNTRYCODE}/streetNumberSearch/${streetIds.toString()}`, {
    params: {
        streetNumber: query,
        apiKey: process.env.AH_API_KEY,
        limitToOfficial: process.env.AH_LIMIT_TO_OFFICIAL,
        limit: 100,
    },
});
export const floorSearch = (deliveryPointId) => axios.get(`${process.env.AH_URL}/${process.env.AH_COUNTRYCODE}/address/${deliveryPointId}/floors`, {
    params: {
        apiKey: process.env.AH_API_KEY,
    },
});
export const householdSearch = (deliveryPointId, floorType, floorNo) => axios.get(`${process.env.AH_URL}/${process.env.AH_COUNTRYCODE}/address/${deliveryPointId}/floor/${floorType}-${floorNo}/households`, {
    params: {
        apiKey: process.env.AH_API_KEY,
    },
});
export function fetchStreetNames(query) {
    return (dispatch, getState) => {
        streetSearch(query, getState().region.slug)
            .then((response) => {
            dispatch({ type: 'FETCH_STREET_ADDRESS_SUCCESS', payload: response });
        })
            .catch((err) => {
            dispatch({ type: 'FETCH_STREET_ADDRESS_FAILED', payload: err });
            dispatch(handleNetworkError(err));
        });
    };
}
export function fetchStreetNumbers(query, streetIds) {
    return (dispatch) => {
        streetNumberSearch(query, streetIds)
            .then((response) => {
            dispatch({ type: 'FETCH_STREET_NUMBERS_SUCCESS', payload: response });
        })
            .catch((err) => {
            dispatch({ type: 'FETCH_STREET_NUMBERS_FAILED', payload: err });
            dispatch(handleNetworkError(err));
        });
    };
}
export function fetchFloors(deliveryPointId) {
    return (dispatch) => {
        floorSearch(deliveryPointId)
            .then((response) => {
            dispatch({ type: 'FETCH_FLOORS_SUCCESS', payload: response });
        })
            .catch((err) => {
            console.error(err);
            dispatch({ type: 'FETCH_FLOORS_FAILED', payload: err });
            dispatch(handleNetworkError(err));
        });
    };
}
export function fetchHouseholds(deliveryPointId, floorNo, floorType) {
    return (dispatch) => {
        axios
            .get(`${process.env.AH_URL}/${process.env.AH_COUNTRYCODE}/address/${deliveryPointId}/floor/${floorType}-${floorNo}/households`, {
            params: {
                apiKey: process.env.AH_API_KEY,
            },
        })
            .then((response) => {
            dispatch({ type: 'FETCH_HOUSEHOLDS_SUCCESS', payload: response });
        })
            .catch((err) => {
            dispatch({ type: 'FETCH_HOUSEHOLDS_FAILED', payload: err });
            dispatch(handleNetworkError(err));
        });
    };
}
export function setCO(co) {
    return (dispatch) => {
        dispatch({ type: 'SET_CO', payload: co });
    };
}
export function setStreetAddress(street) {
    return (dispatch) => {
        dispatch({ type: 'SET_STREET_ADDRESS', payload: street });
        dispatch(fetchStreetNumbers('', street.streetIds));
    };
}
export function setStreetNumber(number) {
    return (dispatch) => {
        dispatch({ type: 'SET_STREET_NUMBER', payload: number });
        if (number.showHouseholds) {
            dispatch(fetchFloors(number.deliveryPointId));
        }
    };
}
export function setFloor(floor) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_FLOOR', payload: floor });
        dispatch(fetchHouseholds(getState().addressHelper.streetNoDeliveryPointId, floor.floorNo, floor.floorType));
    };
}
export function setHousehold(household) {
    return (dispatch) => {
        dispatch({ type: 'SET_HOUSEHOLD', payload: household });
    };
}
export function searchAddress(query) {
    return (dispatch) => {
        dispatch({ type: 'SEARCH_ADDRESS', payload: query });
    };
}
export function restoreAddressHelper(address) {
    return (dispatch) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c;
        dispatch({
            type: 'SET_FIRST_NAME',
            payload: (_a = address.firstName) !== null && _a !== void 0 ? _a : '',
        });
        dispatch({
            type: 'SET_LAST_NAME',
            payload: (_b = address.lastName) !== null && _b !== void 0 ? _b : '',
        });
        dispatch({
            type: 'SET_PHONE_NUMBER',
            payload: (_c = address.mobile) !== null && _c !== void 0 ? _c : '',
        });
        dispatch(setCO(address.co));
        const streetSearchResponse = yield streetSearch(`${address.streetName}, ${address.city}`, null);
        dispatch({ type: 'FETCH_STREET_ADDRESS_SUCCESS', payload: streetSearchResponse });
        const street = streetSearchResponse.data.streets[0];
        dispatch(setStreetAddress(street));
        const streetNumbers = yield streetNumberSearch(address.streetNo, street.streetIds).then((res) => res.data.streetNumbers);
        const streetNumber = streetNumbers.find((x) => x.streetNo === address.streetNo && x.entrance == address.entrance);
        dispatch(setStreetNumber(streetNumber));
        dispatch(searchAddress({
            streetname: formatStreetName(street),
            streetno: formatStreetNo(streetNumber),
        }));
        if (streetNumber.showHouseholds) {
            const floorSearchResponse = yield floorSearch(streetNumber.deliveryPointId);
            const floors = floorSearchResponse.data;
            const floor = floors.find((f) => f.floorNo === address.floorNo);
            dispatch(setFloor(floor));
            const householdSearchResponse = yield householdSearch(streetNumber.deliveryPointId, (floor === null || floor === void 0 ? void 0 : floor.floorType) || '', (floor === null || floor === void 0 ? void 0 : floor.floorNo) || 0);
            const households = householdSearchResponse.data;
            const household = households.find((h) => h.flatNo === address.flatNo);
            if (household == null)
                return;
            dispatch(setHousehold(household));
            dispatch(searchAddress({
                floor: formatFloor(floor),
                flat: formatHousehold((floor === null || floor === void 0 ? void 0 : floor.floorType) || '', (floor === null || floor === void 0 ? void 0 : floor.floorNo) || 0, household.flatNo),
            }));
        }
    });
}

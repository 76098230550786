import classNames from 'classnames';
import Icon from 'components/UI/Icon';
import { PillListMenu } from 'navigation';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import './SidebarMenu.scss';
const Menu = require('img/menu.svg');
const Cross = require('img/cancel.svg');
function SidebarMenu({ categories }) {
    const [showMenu, setShowMenu] = useState(false);
    const backdropClasses = classNames({
        'sidebar-menu__backdrop': true,
        'sidebar-menu__backdrop--open': showMenu,
    });
    const wrapperClasses = classNames({
        'sidebar-menu__wrapper': true,
        'sidebar-menu__wrapper--open': showMenu,
    });
    const menuItems = categories
        ? categories.map((c) => ({
            text: c.name,
            onClick: () => {
                setShowMenu(false);
            },
            navigationPath: `/products/${c.slug}`,
            tabIndex: showMenu ? 0 : -1,
        }))
        : [];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "sidebar-menu__open-button", tabIndex: 0, onClick: () => setShowMenu(true), onKeyDown: (e) => e.key === 'Enter' && setShowMenu(true) },
            React.createElement(Icon, { svg: Menu }),
            "Utvalget"),
        React.createElement("div", { className: backdropClasses, onClick: () => setShowMenu(false) }),
        React.createElement("div", { className: wrapperClasses },
            React.createElement("div", { className: "sidebar-menu__menu" },
                React.createElement("a", { className: "sidebar-menu__close-button", "aria-label": "Lukk meny", tabIndex: showMenu ? 0 : -1, onClick: (e) => {
                        e.preventDefault();
                        setShowMenu(false);
                    }, onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            setShowMenu(false);
                        }
                    } },
                    React.createElement(Icon, { svg: Cross })),
                React.createElement("div", { className: "sidebar-menu__menu__shrinkable-box" }),
                React.createElement(PillListMenu, { heading: "Alle kategorier", items: menuItems })))));
}
function mapStateToProps({ products }) {
    return {
        categories: products.categories,
    };
}
export default connect(mapStateToProps)(SidebarMenu);

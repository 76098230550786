import { resetOrderAttempts, setOrderType } from 'checkout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './OrderTypeToggle.scss';
function OrderTypeToggle({ name }) {
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const dispatch = useDispatch();
    const onClick = (e) => {
        dispatch(resetOrderAttempts());
        dispatch(setOrderType(e.currentTarget.value));
    };
    return (React.createElement("div", { className: "toggleContainer" },
        React.createElement("input", { type: "radio", id: name + 'single', name: name, defaultValue: "single", onChange: onClick, checked: orderType === 'single' }),
        React.createElement("label", { htmlFor: name + 'single' }, "Meg selv"),
        React.createElement("input", { type: "radio", id: name + 'gift', name: name, defaultValue: "gift", onChange: onClick, checked: orderType === 'gift' }),
        React.createElement("label", { htmlFor: name + 'gift' }, "Gave"),
        React.createElement("input", { type: "radio", id: name + 'group', name: name, defaultValue: "group", onChange: onClick, checked: orderType === 'group' }),
        React.createElement("label", { htmlFor: name + 'group' }, "Send til flere")));
}
export default OrderTypeToggle;

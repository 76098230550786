import { capitalize } from 'utilities/text'
import moment from 'moment'

export const getDateAsText = (date, lowercase, nonBreakingSpace, usePrepositionsForCurrentWeek) => {
    if (!date) return ''

    var text = usePrepositionsForCurrentWeek
        ? moment(date).calendar(null, {
              nextWeek: '[På] dddd',
          })
        : moment(date).calendar()

    if (lowercase) {
        text = text.toLowerCase()
    } else {
        text = capitalize(text)
    }

    if (nonBreakingSpace) {
        text = text.replace(' ', '&nbsp;')
    }

    return text
}

export function parseExpirationDate(input) {
    const values = input.match(/([0-9]{2})[\/]([0-9]{2})/)
    return {
        month: values[1],
        year: '20' + values[2], // Assuming that noone will have an expirationDate starting with 21 in the next 75 years
    }
}

export function getNextPossibleDistributionDates() {
    const possibleDistributionDates = []
    for (var i; i < process.env.DISTRIBUTION_LIMIT_DAYS; i++) {
        possibleDistributionDates.push(moment().add(i, days).format('YYYY-DD-MM'))
    }
    return possibleDistributionDates
}

export function getDateYyyyMmDd(date) {
    return moment(date).format('YYYY-MM-DD')
}

export function getDateAndMonth(date, nonBreakingSpace) {
    var text = moment(date).format('Do MMMM')

    if (nonBreakingSpace) {
        text = text.replace(' ', '&nbsp;')
    }

    return text
}

export function getTimeAsText(date) {
    return moment(date).format('HH:mm')
}

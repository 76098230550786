import { DynamicArticleCarousel } from 'blog';
import { BannerCarousel, Button, ButtonRow, DynamicBanner, DynamicEditorialProducts, LinkList, NewsletterRecruiter, TabSection, USPList, } from 'common';
import { DynamicBannerV2 } from 'common/components/BannerV2/DynamicBannerV2';
import ABTestWrapper from 'components/ABTest/ABTestWrapper';
import { DynamicTopBanner, ProductsWithCategoriesGrid } from 'home';
import { FrequentlyBoughtByUser, PopularProducts } from 'product';
import React from 'react';
import { useSelector } from 'react-redux';
import './DynamicPageBuilder.scss';
import { elementIsCtaButton } from './types';
import BannerConceptPage from 'common/components/BannerConceptPage/BannerConceptPage';
import DynamicFreeText from 'common/components/FreeText/DynamicFreeText';
import RegionVisibility from 'common/components/RegionVisibility/RegionVisibility';
import DynamicEditorialBundleProducts from 'common/components/EditorialBundleProducts/DynamicEditorialBundleProducts';
export const DynamicPageBuilder = (props) => {
    const regionId = useSelector((state) => state.region.id);
    const getElementForType = (element) => {
        switch (element === null || element === void 0 ? void 0 : element.type) {
            case 'abTest':
                const abTestElement = element;
                return (React.createElement(ABTestWrapper, { name: abTestElement.name, split: 50, createA: () => getElementForType(abTestElement.variantA), createB: () => getElementForType(abTestElement.variantB), key: abTestElement.name }));
            case 'regionVisibility':
                return React.createElement(RegionVisibility, { key: element.elementId, documentId: element.elementId });
            case 'allProductsGrid':
                return React.createElement(ProductsWithCategoriesGrid, { key: "all-products" });
            case 'articleEntry':
                return (React.createElement(DynamicArticleCarousel, { key: element.elementId, documentId: element.elementId }));
            case 'banner':
                return React.createElement(DynamicBanner, { documentId: element.elementId, key: element.elementId });
            case 'bannerV2':
                return React.createElement(DynamicBannerV2, { documentId: element.elementId, key: element.elementId });
            case 'bannerCarousel':
                return React.createElement(BannerCarousel, { documentId: element.elementId, key: element.elementId });
            case 'bannerConceptPage':
                return React.createElement(BannerConceptPage, { documentId: element.elementId, key: element.elementId });
            case 'ctaButton':
                if (elementIsCtaButton(element)) {
                    return (React.createElement(ButtonRow, { display: "center", className: "pagebuilder__cta-button", key: `cta-button-${element.title}` },
                        React.createElement(Button, { type: "navigation", style: "primary", to: element.target }, element.title)));
                }
                return null;
            case 'editorialProductsSection':
                return (React.createElement(DynamicEditorialProducts, { documentId: element.elementId, regionId: regionId, key: element.elementId }));
            case 'editorialBundlesSection':
                return (React.createElement(DynamicEditorialBundleProducts, { documentId: element.elementId, regionId: regionId, key: element.elementId }));
            case 'freeText':
                return React.createElement(DynamicFreeText, { documentId: element.elementId, key: element.elementId });
            case 'frequentlyBoughtByUser':
                return (React.createElement(FrequentlyBoughtByUser, { documentId: element.elementId, key: "frequently-bought-by-user" }));
            case 'linkList':
                return React.createElement(LinkList, { documentId: element.elementId, key: element.elementId });
            case 'newsletterRecruiter':
                return (React.createElement(NewsletterRecruiter, { documentId: element.elementId, key: element.elementId }));
            case 'popularProducts':
                return (React.createElement(PopularProducts, { documentId: element.elementId, key: element.elementId, limit: 6 }));
            case 'tabSection':
                return React.createElement(TabSection, { documentId: element.elementId, key: element.elementId });
            case 'topBanner':
                return React.createElement(DynamicTopBanner, { key: element.elementId, documentId: element.elementId });
            case 'uspList':
                return React.createElement(USPList, { documentId: element.elementId, key: element.elementId });
        }
        console.error('Unknown page element type ' + (element === null || element === void 0 ? void 0 : element.type));
        return null;
    };
    return React.createElement("div", { className: "pagebuilder" }, props.elements.map(getElementForType));
};

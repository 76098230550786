import './style.scss'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Icon from 'components/UI/Icon'
import PropTypes from 'prop-types'
import userIcon from 'img/user.svg'

class UserLoginButton extends Component {
    handleClick() {
        this.props.onClick()
    }
    render() {
        const { isTablet } = this.props
        return (
            <div className="user-login-btn" onClick={() => this.handleClick()}>
                <Icon svg={userIcon} size="medium" />
                {!isTablet && <span>Logg inn</span>}
            </div>
        )
    }
}

UserLoginButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isTablet: PropTypes.bool,
}

const mapStateToProps = ({ ui }) => {
    return {
        isTablet: ui.isTablet,
    }
}

export default connect(mapStateToProps)(UserLoginButton)

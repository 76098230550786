import './style.scss'

import React, { Component } from 'react'

class BottomBar extends Component {
    render() {
        return <div className="bottom-bar">{this.props.children}</div>
    }
}

export default BottomBar

import { Button } from 'common';
import React from 'react';
import './BannerV2.scss';
import classNames from 'classnames';
import { PortableText } from '@portabletext/react';
export default function BannerV2(props) {
    const classes = classNames({
        bannerV2: true,
        bannerV2__mirror: props.mirror,
    });
    return (React.createElement("div", { className: classes },
        React.createElement("div", { className: "bannerV2__image" },
            React.createElement("img", { src: props.image, alt: "" })),
        React.createElement("div", { className: "bannerV2__text-wrapper" },
            React.createElement("div", { className: "bannerV2__text-wrapper__content" },
                React.createElement("h2", { className: "bannerV2__text-wrapper__title" }, props.title),
                props.body && React.createElement(PortableText, { value: props.body }),
                props.button && (React.createElement(Button, { type: 'action', style: 'primary', className: 'bannerV2__cta-button', onClick: () => {
                        props.onClick();
                    } }, props.button.buttonText))))));
}

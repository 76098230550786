import classNames from 'classnames';
import { AddToCartButton } from 'product';
import React from 'react';
import './SearchResultDropdownProductItem.scss';
import { useIsSoldOut } from '../../../product/hooks';
export function SearchResultDropdownProductItem({ product, onClick, type = 'small', }) {
    var _a, _b;
    const soldOut = useIsSoldOut(product);
    const className = 'search-result-dropdown-item';
    const classes = classNames(className, {
        ['search-result-dropdown-item__' + type]: type,
    });
    const image = 'medium' in product.navigationImage
        ? (_a = product.navigationImage) === null || _a === void 0 ? void 0 : _a.medium
        : (_b = product.navigationImage) === null || _b === void 0 ? void 0 : _b.fullSize;
    return (React.createElement("div", { className: classes, onClick: onClick },
        React.createElement("img", { src: image, alt: product.title }),
        React.createElement("div", { className: `${className}__content` },
            React.createElement("div", { className: `${className}__vendor` }, product.vendor),
            React.createElement("div", { className: `${className}__title` }, product.title),
            React.createElement(AddToCartButton, { product: product, soldOut: soldOut, shrinkOnMobile: true, shrink: true }))));
}

import './style.scss'

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getErrorMessage } from 'utilities/error'

class Errors extends Component {
    getHumanReadableError(error) {
        return getErrorMessage(error)
    }

    render() {
        const { errors } = this.props
        if (errors.length < 1) return null
        return (
            <div className="checkout-errors">
                <ul>
                    {errors.map((e, k) => {
                        return <li key={k}>{this.getHumanReadableError(e)}</li>
                    })}
                </ul>
            </div>
        )
    }
}

Errors.propTypes = {
    errors: PropTypes.array,
    allProducts: PropTypes.array,
    cartProducts: PropTypes.array,
}

const mapStateToProps = ({ checkout, products, cart }) => {
    return {
        errors: checkout.errors,
        allProducts: products.products,
        cartProducts: cart.products,
    }
}

export default connect(mapStateToProps)(Errors)

export function getBaseUserState(logout) {
    return {
        isLoggedInSpid: logout ? false : undefined,
        isLoggedInSubscribe: logout ? false : undefined,
        isLoggedIn: logout ? false : undefined,
        spidData: {},
        jwtData: {},
        jwt: '',
        tokenSource: '',
        addresses: [],
    }
}

export default function reducer(state = getBaseUserState(false), action) {
    switch (action.type) {
        case 'USER_LOGIN':
            return {
                ...state,
                spidData: action.payload,
                isLoggedInSpid: true,
                isLoggedIn:
                    state.isLoggedInSubscribe !== undefined
                        ? !!state.isLoggedInSubscribe
                        : undefined,
            }
        case 'USER_LOGOUT':
            localStorage.removeItem('jwt')
            localStorage.removeItem('state')
            return getBaseUserState(true)
        case 'USER_SESSION_CHANGE':
            return {
                ...state,
                spidData: action.payload,
            }
        case 'VALIDATE_JWT_SUCCESS':
            return {
                ...state,
                jwtData: action.payload.jwtData,
                jwt: action.payload.jwt,
                isLoggedInSubscribe: true,
                isLoggedIn: state.isLoggedInSpid !== undefined ? !!state.isLoggedInSpid : undefined,
                tokenSource: action.payload.tokenSource,
            }
        case 'VALIDATE_JWT_FAILED':
            localStorage.removeItem('jwt')
            let newState = { ...state }
            return {
                ...newState,
                jwtData: {},
                jwt: '',
                isLoggedInSubscribe: false,
                isLoggedIn: false,
                tokenSource: action.payload.tokenSource,
            }
        case 'NO_JWT_PRESENT':
            return {
                ...state,
                isLoggedInSubscribe: false,
                isLoggedIn: false,
            }
        default:
            return state
    }
}

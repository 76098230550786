import ReadMoreButton from 'common/components/ReadMoreButton';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sanityImageBuilder } from 'utilities/sanityClient';
import BlogPostMeta from '../BlogPostMeta/BlogPostMeta';
import './BlogPostHeader.scss';
function BlogPostHeader({ image, title, categoryTitle, publishedAt, handleReadMoreClick, }) {
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const mainImageUrl = useMemo(function getMainImageUrl() {
        if (isMobile) {
            return sanityImageBuilder
                .image(image)
                .auto('format')
                .fit('min')
                .width(350)
                .height(550)
                .dpr(window.devicePixelRatio)
                .url();
        }
        else {
            return sanityImageBuilder
                .image(image)
                .auto('format')
                .fit('min')
                .width(360)
                .height(525)
                .dpr(window.devicePixelRatio)
                .url();
        }
    }, [image, isMobile]);
    const backgroundImageUrl = useMemo(function getBackgroundImageUrl() {
        return sanityImageBuilder
            .image(image)
            .auto('format')
            .fit('min')
            .width(window.innerWidth)
            .height(340)
            .url();
    }, [image]);
    return (React.createElement("header", { className: "blog-post-header" },
        React.createElement("div", { className: "blog-post-header__content" },
            React.createElement("div", { className: "blog-post-header__image-wrapper__background-container" },
                React.createElement("div", { className: "blog-post-header__image-wrapper__background", style: { '--background-image': `url(${backgroundImageUrl})` } })),
            React.createElement("div", { className: "blog-post-header__image-wrapper" },
                React.createElement("img", { className: "blog-post-header__image-wrapper__image", src: mainImageUrl })),
            React.createElement("div", { className: "blog-post-header__title-container" },
                React.createElement("h1", { className: "blog-post-header__title" }, title),
                !isMobile && (React.createElement(BlogPostMeta, { categoryTitle: categoryTitle, publishedAt: publishedAt, textColor: "white", className: "blog-post-header__meta" })),
                React.createElement(ReadMoreButton, { handleClick: handleReadMoreClick, className: "blog-post-header__read-more-button" })))));
}
export default BlogPostHeader;

import moment from 'moment'

export function isTomorrow(date) {
    const tomorrow = () => moment().add(1, 'days')
    const momentDate = moment(date)
    return momentDate.isSame(tomorrow(), 'day')
}

export function deadlinePassed(date, deadline) {
    const momentDate = moment(date)
    return momentDate.isBefore(deadline) || (isTomorrow(date) && moment().isAfter(deadline))
}

export function canDeliver({ canDeliver, date }, deadline) {
    return canDeliver && !deadlinePassed(date, deadline)
}

export function getEarliestDeliveryDate(dates, deadline) {
    return dates.find((date) => canDeliver(date, deadline))
}

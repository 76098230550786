import adyen from 'adyen-cse-js'
import { parseExpirationDate } from 'utilities/date'

export function setCardHolderNameFromLogin({ givenName, familyName }) {
    const cardHolderName = `${givenName || ''} ${familyName || ''}`.trim()
    return (dispatch) => {
        dispatch(setCardHolderName(cardHolderName))
    }
}

export function setCardHolderName(cardHolderName) {
    return {
        type: 'SET_CARD_HOLDER_NAME',
        payload: cardHolderName,
    }
}

export function setCardNo(cardNo) {
    return (dispatch) => {
        dispatch({
            type: 'SET_CARD_NO',
            payload: cardNo,
        })
    }
}

export function setCardExpiration(input) {
    const values = input.replace(/([0-9]{2})\D*([0-9]{1,2})/, '$1/$2')
    return (dispatch) => {
        dispatch({
            type: 'SET_CARD_EXPIRATION',
            payload: values,
        })
    }
}

export function setCardCvc(cvc) {
    return (dispatch) => {
        dispatch({
            type: 'SET_CARD_CVC',
            payload: cvc,
        })
    }
}

export function setSaveCardConsent(saveCardConsent) {
    return (dispatch) => {
        dispatch({
            type: 'SET_SAVE_CARD_CONSENT',
            payload: saveCardConsent,
        })
    }
}

export function generateToken(onSuccess) {
    return (dispatch, getState) => {
        const state = getState()
        const { cardHolderName, cardNo, expiry, cvc } = state.newPaymentMethod
        const expiration = parseExpirationDate(expiry)
        dispatch({ type: 'GENERATE_TOKEN' })
        try {
            const cseInstance = adyen.createEncryption(process.env.ADYEN_ENCRYPT_KEY, {})
            let payload = {
                holderName: cardHolderName,
                number: cardNo,
                cvc: cvc,
                expiryMonth: expiration.month,
                expiryYear: expiration.year,
                generationtime: new Date().toISOString(),
            }
            const token = cseInstance.encrypt(payload)
            if (token) {
                dispatch({ type: 'GENERATE_TOKEN_SUCCESS', payload: token })
                dispatch({ type: 'ADD_PAYMENT_INFO', payload: getState().cart })
                onSuccess && onSuccess(cardNo, token)
            } else {
                dispatch({ type: 'GENERATE_TOKEN_FAILED' })
            }
        } catch (err) {
            console.error(err)
            dispatch({ type: 'GENERATE_TOKEN_FAILED' })
        }
    }
}

export function generateCVCToken() {
    return (dispatch, getState) => {
        const state = getState()
        const { cvc } = state.newPaymentMethod
        dispatch({ type: 'GENERATE_TOKEN' })
        try {
            const cseInstance = adyen.createEncryption(process.env.ADYEN_ENCRYPT_KEY, {})
            let payload = {
                cvc: cvc,
                generationtime: new Date().toISOString(),
            }
            const token = cseInstance.encrypt(payload)
            if (token) {
                dispatch({ type: 'GENERATE_TOKEN_SUCCESS', payload: token })
                dispatch({ type: 'ADD_PAYMENT_INFO', payload: getState().cart })
            } else {
                dispatch({ type: 'GENERATE_TOKEN_FAILED' })
            }
            return token
        } catch (err) {
            console.error(err)
            dispatch({ type: 'GENERATE_TOKEN_FAILED' })
        }
    }
}

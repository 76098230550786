export function getFullAddressFromAddressHelper(addressHelper, isPrimaryAddress) {
    const deliveryAddress = {
        deliveryPointId: addressHelper.deliveryPointId,
        streetName: addressHelper.streetName,
        streetNo: addressHelper.streetNo,
        entrance: addressHelper.entrance,
        floorNo: addressHelper.floorNo,
        floorType: addressHelper.floorType,
        flatNo: addressHelper.flatNo,
        co: addressHelper.co,
        zip: addressHelper.zip,
        city: addressHelper.city,
        countryCode: addressHelper.countryCode,
        firstName: addressHelper.firstName || '',
        lastName: addressHelper.lastName || '',
        mobile: addressHelper.phoneNumber,
    };
    return {
        isPrimary: isPrimaryAddress,
        deliveryAddress: Object.assign(Object.assign({}, deliveryAddress), { deliverySms: true }),
    };
}

import { uniqWith } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { addressIsEqual } from 'utilities/address';
export function useCoalescedAddresses() {
    const addressesFromAllAddresses = useSelector((state) => state.address.allAddresses);
    const addressesFromAddresses = useSelector((state) => state.address.addresses);
    return useMemo(() => {
        const extraAddresses = addressesFromAddresses.filter((x) => !addressesFromAllAddresses.some((y) => addressIsEqual(x.deliveryAddress, y.deliveryAddress)));
        return uniqWith([...addressesFromAllAddresses, ...extraAddresses], (x, y) => addressIsEqual(x.deliveryAddress, y.deliveryAddress));
    }, [addressesFromAllAddresses, addressesFromAddresses]);
}

import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
function NavigationLink({ to, classes, children, tabIndex = 0, onClick, }) {
    const dispatch = useDispatch();
    return (React.createElement("a", { className: classes, href: to, onClick: (e) => {
            e.preventDefault();
            onClick && onClick(e);
            dispatch(push(to));
        }, tabIndex: tabIndex }, children));
}
export default NavigationLink;

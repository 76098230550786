import './style.scss'

import React, { Component } from 'react'

import InlineSVG from 'svg-inline-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class Icon extends Component {
    render() {
        var { size, className, svg, ...parentProps } = this.props
        const classes = classNames({
            icon: true,
            [`icon--${this.props.size}`]: size,
            [`${this.props.className}`]: className,
        })
        return <InlineSVG {...parentProps} className={classes} src={svg} />
    }
}

Icon.propTypes = {
    svg: PropTypes.string.isRequired,
    size: PropTypes.oneOf([
        'xsmall',
        'small',
        'medium',
        'large',
        'xlarge',
        'full_logo',
        'header_logo',
    ]),
    className: PropTypes.string,
}

export default Icon

import valid from 'card-validator'

export function capitalize(text) {
    if (!text) return ''
    return text[0].toUpperCase() + text.substring(1).toLowerCase()
}

export function cardFormat(cardNo) {
    if (!cardNo) return
    return cardNo
        .toString()
        .replace(/([\*0-9]{4})\D*([\*0-9]{4})\D*([\*0-9]{4})\D*([0-9]{4})/, '$1 $2 $3 $4')
}

export function cardFormatMasked(cardNo) {
    return cardNo.replace(
        /([\*0-9]{4})\D*([\*0-9]{4})\D*([\*0-9]{4})\D*([0-9]{4})/,
        '**** **** **** $4'
    )
}

export function cardFormatShort(cardNo) {
    return (
        cardNo && cardNo.replace(/([\*0-9]{4})\D*([\*0-9]{4})\D*([\*0-9]{4})\D*([0-9]{4})/, '$3 $4')
    )
}

export function cardIssuerFromNumber(cardNo) {
    const validObject = valid.number(cardNo)
    if (validObject.card && validObject.card.niceType) {
        return validObject.card.niceType
    }
    return null
}

export function pad(n, width, z) {
    z = z || '0'
    n = n + ''
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export function priceNumberToString(price) {
    if (typeof price == 'number') {
        return price.toString().replace('.', ',')
    }
    return price
}

import { ListPageHeader, } from 'common';
import ScrollToTop from 'components/UI/ScrollToTop';
import Switch from 'components/UI/Switch';
import { ProducerGrid } from 'producer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ProducerListPage.scss';
function ProducerListPage() {
    const dispatch = useDispatch();
    const producers = useSelector(({ producers }) => {
        var _a;
        // copy array since redux state is read-only from here and .sort() is in-place
        const producerList = (_a = [...producers === null || producers === void 0 ? void 0 : producers.producers]) !== null && _a !== void 0 ? _a : [];
        return producerList.sort((a, b) => {
            if (a.name > b.name)
                return 1;
            if (a.name < b.name)
                return -1;
            return 0;
        });
    });
    const { name } = useSelector(({ region }) => region);
    const { isMobile, hideProducerListImages } = useSelector(({ ui }) => ui);
    return (React.createElement("div", { className: "producer-list-page" },
        React.createElement(ScrollToTop, null),
        React.createElement(ListPageHeader, null, "V\u00E5re samarbeidspartnere"),
        !isMobile && (React.createElement("div", { className: "producer-list-page__paragraph" },
            "Her finner du en oversikt over alle v\u00E5re leverand\u00F8rer som leverer til ",
            React.createElement("b", null, name),
            ".")),
        isMobile && (React.createElement("div", { className: "producer-list-page__list-switch" },
            React.createElement(Switch, { on: hideProducerListImages, onChange: (val) => dispatch({ type: 'TOGGLE_PRODUCER_LIST_IMAGES', payload: val }) }),
            "Vis som liste")),
        React.createElement(ProducerGrid, { producers: producers, hideImages: isMobile && hideProducerListImages })));
}
export default ProducerListPage;

import classNames from 'classnames';
import { ProductContext, ProductTile } from 'product';
import React, { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import './ProductGrid.scss';
function ProductGrid({ products, minColumnsOnDesktop = 4, maxColumnsOnDesktop = 5, className, shrinkProductTiles = false, onProductClick, }) {
    const classes = classNames({
        'product-grid': true,
        [`${className}`]: className,
    });
    const customCssProperties = {
        '--number-of-columns-on-desktop': products.length < minColumnsOnDesktop
            ? minColumnsOnDesktop
            : products.length > maxColumnsOnDesktop
                ? maxColumnsOnDesktop
                : products.length,
    };
    const productContext = useContext(ProductContext);
    const dispatch = useDispatch();
    const isSubscriptionMode = useSelector((state) => state.subscription.isSubscriptionMode);
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });
    useEffect(() => {
        if (inView) {
            dispatch({ type: 'VIEW_ITEM_LIST', payload: { products, productContext } });
        }
    }, [inView, dispatch, productContext, products]);
    return (React.createElement("div", { ref: ref, className: classes, style: customCssProperties }, products.map((product, idx) => (React.createElement(ProductTile, { key: product.id, product: product, shrinkOnMobile: true, isSubscriptionMode: isSubscriptionMode, shrink: shrinkProductTiles, onClick: onProductClick, gridPosition: idx + 1 })))));
}
export default ProductGrid;

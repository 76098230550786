import React, { useEffect } from 'react';
import { isValidCardNumber, isValidCvc } from 'utilities/validators';
import { setCardCvc, setCardHolderName, setCardNo, setSaveCardConsent, } from 'actions/NewPaymentMethod';
import InputRow from 'components/UI/InputRow';
import TextInput from 'components/UI/TextInput';
import { cardFormat } from 'utilities/text';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import { ExpirationDateInput } from 'checkout';
import { CheckboxWithLabel } from 'common';
function NewPaymentMethodForm() {
    // Redux
    const { cardHolderName, cardNo, cvc, saveCardConsent } = useSelector(({ newPaymentMethod }) => newPaymentMethod);
    const { firstName, lastName } = useSelector(({ personalInfo }) => personalInfo);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const dispatch = useDispatch();
    // If user has entered their name and no card name has been entered,
    // pre-fill the card holder name
    useEffect(() => {
        if (firstName && lastName && cardHolderName.length == 0) {
            dispatch(setCardHolderName(`${firstName} ${lastName}`));
        }
    }, []);
    return (React.createElement("div", { className: "new-payment-method-form" },
        React.createElement(TextInput, { label: "Kortinnehaverens navn", value: cardHolderName, onChange: (val) => dispatch(setCardHolderName(val)), required: true }),
        React.createElement(TextInput, { className: "new-payment-method-form__card-number", label: "Kortnummer", type: "tel", value: cardFormat(cardNo), invalidHelp: "Ugyldig kortnummer", maxLength: 19, validator: isValidCardNumber, onChange: (val) => dispatch(setCardNo(val)), required: true }),
        React.createElement(InputRow, null,
            React.createElement(ExpirationDateInput, null),
            React.createElement(TextInput, { className: "new-payment-method-form__cvc", label: "CVC", type: "tel", value: cvc, maxLength: 3, validator: isValidCvc, invalidHelp: "Ugyldig CVC kode", onChange: (val) => dispatch(setCardCvc(val)), required: true })),
        isLoggedIn && (React.createElement(CheckboxWithLabel, { isChecked: saveCardConsent, setIsChecked: (consent) => dispatch(setSaveCardConsent(consent)), labelText: "Lagre kortet for rask og enkel betaling fremover. Fjern kortet n\u00E5r som helst p\u00E5 Min side.", size: "medium" }))));
}
export default NewPaymentMethodForm;

import { PaymentMethodType } from 'types/paymentMethodType'

function getBaseState() {
    return {
        awaitingOrderResponse: false,
        orderResponse: null,
        errors: [],
        deliveryDate: null,
        optinForNews: false,
        validationErrors: [],
        checkoutMode: 'SINGLE_ORDER', // SINGLE_ORDER | SUBSCRIPTION_ORDER
        setByUser: false,
        capacityNearlyFull: false,
        paymentMethodType: PaymentMethodType.VIPPS,
        oneClickCheckoutEnabled: false,
    }
}

export default function reducer(state = getBaseState(), action) {
    switch (action.type) {
        case 'PLACE_ORDER':
        case 'PLACE_SUBSCRIPTION_ORDER':
            return {
                ...state,
                orderResponse: {},
                awaitingOrderResponse: true,
                errors: [],
                validationErrors: [],
            }
        case 'PLACE_SUBSCRIPTION_ORDER_FAILED':
            return {
                ...state,
                awaitingOrderResponse: false,
                errors: [...state.errors, action.payload],
            }
        case 'PLACE_ORDER_ERROR':
            return {
                ...state,
                awaitingOrderResponse: false,
                errors: [...state.errors, ...action.payload],
            }
        case 'PLACE_SUBSCRIPTION_ORDER_ERROR':
            return {
                ...state,
                awaitingOrderResponse: false,
                errors: [...state.errors, action.payload],
            }
        case 'PLACE_ORDER_SUCCESS':
            return {
                ...state,
                awaitingOrderResponse: false,
                orderResponse: action.payload.data,
                optinForNews: false,
            }
        case 'PLACE_SUBSCRIPTION_ORDER_SUCCESS':
            return {
                ...state,
                awaitingOrderResponse: false,
                orderResponse: { status: 'ok' },
                optinForNews: false,
            }
        case 'SET_CHECKOUT_MODE':
            return ['SINGLE_ORDER', 'SUBSCRIPTION_ORDER'].includes(action.payload)
                ? {
                      ...state,
                      checkoutMode: action.payload,
                      errors: [],
                  }
                : { ...state }
        case 'SET_DELIVERY_DATE':
            return {
                ...state,
                setByUser: action.payload.setByUser,
                deliveryDate: action.payload.deliveryDate,
                capacityNearlyFull: action.payload.capacityNearlyFull,
            }
        case 'SET_OPTIN_FOR_NEWS':
            return {
                ...state,
                optinForNews: action.payload,
            }
        case 'CHECKOUT_COMPLETED':
            return {
                ...state,
                orderResponse: null,
            }
        case 'ORDER_VALIDATION_FAILED': {
            let validationErrors = [...state.validationErrors, action.payload]
            return {
                ...state,
                awaitingOrderResponse: false,
                validationErrors,
            }
        }
        case 'USER_LOGOUT':
            return getBaseState()
        case 'SET_PAYMENT_METHOD_TYPE':
            return {
                ...state,
                paymentMethodType: action.payload,
            }
        case 'STOP_AWAITING_ORDER_RESPONSE':
            return {
                ...state,
                awaitingOrderResponse: false,
            }
        case 'TOGGLE_ONE_CLICK_CHECKOUT':
            return {
                ...state,
                oneClickCheckoutEnabled: action.payload,
            }
        default:
            return state
    }
}

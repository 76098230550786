import React from 'react';
import { sanityImageBuilder } from 'utilities/sanityClient';
import { motion } from 'framer-motion';
function DynamicConceptPageBodyImage({ image }) {
    const imgSrc = sanityImageBuilder
        .image(image)
        .width(560)
        .auto('format')
        .fit('min')
        .dpr(window.devicePixelRatio)
        .url();
    return (React.createElement(motion.img, { className: "dynamic-concept-page__content__body__image", src: imgSrc, initial: { opacity: 0, y: 50 }, whileInView: { opacity: 1, y: 0 }, transition: { duration: 1 }, viewport: { once: true, amount: 0.4 } }));
}
export default DynamicConceptPageBodyImage;

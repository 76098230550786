import { toggleCart } from 'actions/UI';
import { CartButton, CartContainer } from 'cart';
import { useOnClickOutside } from 'common';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import './CartDropdown.scss';
function CartDropdown() {
    const { showCart } = useSelector((state) => {
        return {
            showCart: state.ui.showCart,
        };
    });
    const isCartRoute = new RegExp(/^\/cart/).test(useLocation().pathname);
    const dispatch = useDispatch();
    const ref = useRef(null);
    useOnClickOutside(ref, () => {
        if (showCart) {
            dispatch(toggleCart(false));
        }
    });
    return (React.createElement("div", { ref: ref },
        React.createElement(CartButton, { isActive: showCart || isCartRoute, onClick: () => dispatch(toggleCart(!showCart)) }),
        showCart && React.createElement(CartContainer, null)));
}
export default CartDropdown;

import Loading from 'components/UI/LoadingScreen';
import { replace } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { completeVippsOrder } from '../actions/completeOrder';
function CheckoutCallbackVipps() {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!location.hash) {
            dispatch(replace('/checkout'));
        }
        const sessionId = location.hash.substr(1);
        dispatch(completeVippsOrder(sessionId));
    }, []);
    return React.createElement(Loading, null);
}
export default CheckoutCallbackVipps;

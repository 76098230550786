import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isMatch from 'lodash/isMatch';
import { addressIsEqual } from 'utilities/address';
const baseState = {
    addresses: [],
    allAddresses: [],
    active: undefined,
    subscription: {},
    nextSubscription: {},
    loading: false,
    loadingAll: false,
    edit: true,
    savingAddress: false,
    savingAddressError: undefined,
};
function isExistingAddress(state, address) {
    return !!find(state.addresses.map((x) => x.deliveryAddress), (a) => isMatch(a, address));
}
export default function reducer(state = baseState, action) {
    var _a;
    switch (action === null || action === void 0 ? void 0 : action.type) {
        case 'GET_ADDRESSES':
        case 'SET_NEW_MAIN_ADDRESS':
        case 'EDIT_ADDRESS':
            return Object.assign(Object.assign({}, state), { loading: true });
        case 'GET_ADDRESSES_FAILED':
        case 'SET_NEW_MAIN_ADDRESS_FAILED':
        case 'SET_NEW_MAIN_ADDRESS_SUCCESS':
            return Object.assign(Object.assign({}, state), { loading: false });
        case 'GET_ADDRESSES_SUCCESS': {
            return Object.assign(Object.assign({}, state), { addresses: action.payload.grouped, allAddresses: action.payload.ungrouped, edit: false, loading: false });
        }
        case 'GET_ALL_ADDRESSES':
            return Object.assign(Object.assign({}, state), { allAddresses: [], loadingAll: true });
        case 'GET_ALL_ADDRESSES_FAILED':
            return Object.assign(Object.assign({}, state), { loadingAll: false });
        case 'GET_ALL_ADDRESSES_SUCCESS':
            return Object.assign(Object.assign({}, state), { allAddresses: action.payload, loadingAll: false });
        case 'STORE_SUBSCRIPTION_ADDRESS':
            return Object.assign({}, state);
        case 'STORE_SUBSCRIPTION_ADDRESS_SUCCESS': {
            const address = action.payload;
            const active = isEmpty(state.active) ? address : state.active;
            if (isExistingAddress(state, address.deliveryAddress)) {
                return Object.assign(Object.assign({}, state), { active, subscription: address.deliveryAddress });
            }
            else {
                const addresses = [address, ...state.addresses];
                return Object.assign(Object.assign({}, state), { addresses,
                    active, subscription: address.deliveryAddress });
            }
        }
        case 'STORE_SUBSCRIPTION_ADDRESS_FAILED':
            return Object.assign({}, state); // TODO: Sende melding til bruker?
        case 'SET_SUBSCRIPTION_ADDRESS': {
            return Object.assign(Object.assign({}, state), { subscription: action.payload });
        }
        case 'SET_NEXT_SUBSCRIPTION_ADDRESS': {
            return Object.assign(Object.assign({}, state), { nextSubscription: action.payload });
        }
        case 'STORE_ADDRESS': {
            const address = action.payload;
            return Object.assign(Object.assign({}, state), { addresses: [address, ...state.addresses], active: address });
        }
        case 'ADD_TRANSPORT_PRODUCT_TO_ADDRESS': {
            const { addressInfo, transportProduct } = action.payload;
            const oldAddresses = state.addresses;
            const addressWithTransportProduct = Object.assign(Object.assign({}, addressInfo), { deliveryAddress: Object.assign(Object.assign({}, addressInfo.deliveryAddress), { transportProduct }) });
            return Object.assign(Object.assign({}, state), { addresses: [
                    addressWithTransportProduct,
                    ...oldAddresses.filter((x) => !addressIsEqual(addressInfo.deliveryAddress, x.deliveryAddress, false)),
                ], active: ((_a = state.active) === null || _a === void 0 ? void 0 : _a.deliveryAddress) && addressIsEqual(addressInfo.deliveryAddress, state.active.deliveryAddress)
                    ? addressWithTransportProduct
                    : state.active });
        }
        case 'DELETE_STORED_ADDRESS': {
            const addressToDelete = action.payload;
            return Object.assign(Object.assign({}, state), { addresses: [
                    ...state.addresses.filter((address) => !addressIsEqual(address.deliveryAddress, addressToDelete)),
                ] });
        }
        case 'USER_LOGOUT':
            return Object.assign({}, baseState);
        case 'SET_ACTIVE_ADDRESS':
            return Object.assign(Object.assign({}, state), { active: action.payload });
        case 'DELETE_ACTIVE_ADDRESS':
            const primaryAddress = state.nextSubscription || state.subscription;
            const isPrimary = action.payload;
            if (!!primaryAddress && !isPrimary) {
                return Object.assign(Object.assign({}, state), { active: primaryAddress });
            }
            return Object.assign(Object.assign({}, state), { active: undefined });
        case 'TOGGLE_EDIT_ADDRESS':
            return Object.assign(Object.assign({}, state), { edit: action.payload != undefined ? action.payload : !state.edit });
        case 'RESET_ADDRESS_ERROR':
            return Object.assign(Object.assign({}, state), { savingAddressError: undefined });
        case 'RESET_ADDRESS_FORM':
            return Object.assign(Object.assign({}, state), { savingAddress: false, savingAddressError: undefined });
        case 'SAVE_ADDRESS':
            return Object.assign(Object.assign({}, state), { savingAddress: true, savingAddressError: undefined });
        case 'SAVE_ADDRESS_FAILED':
            return Object.assign(Object.assign({}, state), { savingAddress: false, savingAddressError: action.payload });
        case 'SAVE_ADDRESS_SUCCESS':
            return Object.assign(Object.assign({}, state), { allAddresses: [
                    ...state.allAddresses.filter((a) => a.addressId !== action.payload.addressId),
                    action.payload,
                ], savingAddress: false });
        default:
            return state;
    }
}

import './style.scss'

import React, { Component } from 'react'

import AppPortal from 'components/UI/AppPortal'
import Icon from 'components/UI/Icon'
import PropTypes from 'prop-types'
import subscriptionIcon from 'img/subscription.svg'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { getRegionFromTransportProduct } from 'utilities/region'

const duration = 2500

export class SubscriptionNotification extends Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        clearTimeout(this.timer)

        if (this.shouldDisplay(nextProps)) {
            this.timer = setTimeout(
                () => this.props.dispatch({ type: 'RESET_SUBSCRIPTION_NOTIFICATION' }),
                duration
            )
        }
    }

    message() {
        const { action, product } = this.props

        switch (action) {
            case 'add':
                return `${product?.title} er lagt til. Klikk her for å velge leveringsdager og bekrefte.`
            case 'mode_changed':
                return 'Du ser nå produkter tilgjengelige for fast levering.'
        }
    }

    shouldDisplay(props) {
        const { action, product } = props
        if (!action) return false

        switch (action) {
            case '':
                return false
            case 'add':
                return product !== null
            default:
                return true
        }
    }

    handleClick = () => {
        const { route, dispatch } = this.props
        if (route) {
            dispatch(push(route))
        }
    }

    render() {
        if (!this.shouldDisplay(this.props)) return null

        return (
            <AppPortal>
                <div className="subscription-notification" onClick={this.handleClick}>
                    <Icon svg={subscriptionIcon} />
                    <span>{this.message()}</span>
                </div>
            </AppPortal>
        )
    }
}

SubscriptionNotification.propTypes = {
    hasSubscription: PropTypes.bool,
    currentRegion: PropTypes.string,
    subscriptionRegion: PropTypes.string,
    product: PropTypes.object,
    route: PropTypes.string,
    action: PropTypes.string,
    isMobile: PropTypes.bool,
    dispatch: PropTypes.func,
}

export const mapStateToProps = ({
    subscriptionNotification,
    subscription,
    products,
    region,
    ui,
}) => ({
    hasSubscription: subscription.hasSubscription,
    currentRegion: getRegionFromTransportProduct(
        subscription.currentTransportProduct,
        region.regions
    ),
    subscriptionRegion: getRegionFromTransportProduct(
        subscription.transportProduct,
        region.regions
    ),
    product: subscriptionNotification.product,
    action: subscriptionNotification.action,
    route: subscriptionNotification.route,
    products: products.products,
    isMobile: ui.isMobile,
})

export default connect(mapStateToProps)(SubscriptionNotification)

export function setFirstName(name) {
    return { type: 'SET_FIRSTNAME', payload: name }
}

export function setLastName(name) {
    return { type: 'SET_LASTNAME', payload: name }
}

export function setMobile(number) {
    return { type: 'SET_MOBILE', payload: number }
}

export function setEmail(email) {
    return { type: 'SET_EMAIL', payload: email }
}

export function setDeliverySms(bool) {
    return { type: 'SET_SMS_OPT-IN', payload: bool }
}

export function setPersonalInfo(firstName, lastName, mobile) {
    return {
        type: 'SET_PERSONAL_INFO',
        payload: {
            firstName,
            lastName,
            mobile,
        },
    }
}

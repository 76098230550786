import React, { Component } from 'react'

import CheckoutAdd from 'components/Checkout/CheckoutAdd'
import CheckoutField from 'components/Checkout/CheckoutField'
import PropTypes from 'prop-types'
import { capitalize } from 'utilities/text'
import { connect } from 'react-redux'

export class CheckoutShowSubscriptionAddress extends Component {
    render() {
        const { streetName, streetNo, entrance } = this.props
        return (
            <CheckoutField title="Adresse:" editUrl="/checkout/subscription-address">
                {streetName && (
                    <div>
                        {capitalize(streetName)} {streetNo}
                        {entrance || ''}
                    </div>
                )}
                {!streetName && <CheckoutAdd>Legg til adresse</CheckoutAdd>}
            </CheckoutField>
        )
    }
}

CheckoutShowSubscriptionAddress.propTypes = {
    streetName: PropTypes.string,
    streetNo: PropTypes.number,
    entrance: PropTypes.string,
}

const mapStateToProps = ({ address }) => {
    return {
        streetName: address.subscription.streetName,
        streetNo: address.subscription.streetNo,
        entrance: address.subscription.entrance,
    }
}

export default connect(mapStateToProps)(CheckoutShowSubscriptionAddress)

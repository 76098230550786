import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'components/UI/Icon';
import { Modal } from 'common';
import './ApartmentNumberHelp.scss';
const infoIcon = require('img/info.svg');
function InputHelp({ children }) {
    const dispatch = useDispatch();
    const show = useSelector((state) => state.ui.showApartmentNumberHelpModal);
    return (React.createElement("div", { className: "apartment-number-help" },
        React.createElement(Icon, { svg: infoIcon, onClick: () => dispatch({ type: 'TOGGLE_APARTMENT_NUMBER_HELP_MODAL' }) }),
        show && (React.createElement(Modal, { labelId: "apartment-number-help-heading", onClose: () => dispatch({ type: 'TOGGLE_APARTMENT_NUMBER_HELP_MODAL' }) }, children))));
}
function ApartmentNumberHelp() {
    return (React.createElement(InputHelp, null,
        React.createElement("h2", { id: "apartment-number-help-heading" }, "Leilighetsnummer"),
        React.createElement("p", null, "I etasjer med flere leiligheter finner budet frem ved \u00E5 telle antall vanlige d\u00F8rer fra venstre etter at budet entrer etasjen. Bruk illustrasjonen til \u00E5 finne ditt leilighetsnummer (d\u00F8rer til branntrapper, kott osv teller ikke med)"),
        React.createElement("img", { style: { maxWidth: 200 }, src: "https://di.no/addresshelper/diah_flat_illustration.png" }),
        React.createElement("p", null, "Ved \u00E5 fylle inn leilighetsnummer, og markere d\u00F8ren din med navn, sikrer du at pakken blir levert rett sted. Samtidig gj\u00F8r du jobben til budet litt enklere, takk for hjelpen!")));
}
export default ApartmentNumberHelp;

import { PureComponent } from 'react'
import PropTypes from 'prop-types'

import isEqual from 'lodash/isEqual'

import { setUserId, setUserProps, setLauncherOffset } from 'utilities/intercom'

class CustomerServiceNative extends PureComponent {
    static getDerivedStateFromProps(nextProps, prevState) {
        const { userId, intercomUserProps, hasItemsInCart, notificationOptions } = nextProps

        if (userId && userId !== prevState.userId) {
            setUserId(userId)
        }

        if (!isEqual(intercomUserProps, prevState.intercomUserProps)) {
            setUserProps(intercomUserProps)
        }

        const options = {}
        notificationOptions.forEach((o) => {
            options[o.key] = o.isOn
        })
        if (!isEqual(options, prevState.options)) {
            setUserProps(options)
        }

        if (hasItemsInCart !== prevState.hasItemsInCart) {
            setLauncherOffset(hasItemsInCart)
        }

        return { userId, intercomUserProps, hasItemsInCart, options }
    }

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return null
    }
}

CustomerServiceNative.propTypes = {
    userId: PropTypes.string,
    intercomUserProps: PropTypes.object,
    notificationOptions: PropTypes.array,
    hasItemsInCart: PropTypes.bool,
}

export default CustomerServiceNative

import classNames from 'classnames';
import { Button, IconButton } from 'common';
import Icon from 'components/UI/Icon';
import React, { useState } from 'react';
import { formatAddress } from 'utilities/address';
import './AddressListItem.scss';
const checkmark = require('img/checkmark.svg');
const warning = require('img/warning.svg');
const garbageCan = require('img/garbagecan.svg');
const pencil = require('img/pencil.svg');
function AddressListItem({ address, isCurrent, canDeliver, enableDelete, onDelete, onCancelDelete, onClickEdit, onSelect, }) {
    const [deleted, setDeleted] = useState(false);
    const handleSelect = () => {
        if (!isCurrent) {
            onSelect && onSelect();
        }
    };
    const handleEdit = (e) => {
        e.stopPropagation();
        onClickEdit && onClickEdit();
    };
    const handleDelete = (e) => {
        e.stopPropagation();
        setDeleted(true);
        onDelete && onDelete();
    };
    const handleCancelDelete = (e) => {
        e.stopPropagation();
        setDeleted(false);
        onCancelDelete && onCancelDelete();
    };
    const classes = classNames({
        'address-list-item': true,
        'address-list-item--active': isCurrent && !deleted,
        'address-list-item--invalid': isCurrent && !deleted && !canDeliver,
        'address-list-item--deleted': deleted,
        'address-list-item--deletable': !!onDelete,
        'address-list-item--selectable': !isCurrent && !!onSelect,
    });
    if (deleted) {
        return (React.createElement("div", { className: classes },
            formatAddress(address),
            " er fjernet.",
            React.createElement("br", null),
            React.createElement(Button, { type: "action", style: "ghost", size: "small", className: "address-list-item__cancel-delete-btn", onClick: handleCancelDelete }, "Angre?")));
    }
    return (React.createElement("div", { className: classes, onClick: handleSelect },
        React.createElement("div", { className: "address-list-item__data" },
            React.createElement("div", { className: "address-list-item__name" },
                address.firstName,
                " ",
                address.lastName),
            React.createElement("div", { className: "address-list-item__street" },
                formatAddress(address),
                React.createElement("br", null),
                React.createElement("small", { className: "address-list-item__city" },
                    address.zip,
                    " ",
                    address.city),
                React.createElement("br", null),
                address.co && (React.createElement("small", { className: "address-list-item__co" },
                    "C/O ",
                    address.co)))),
        React.createElement("div", { className: "address-list-item__actions" },
            onClickEdit && React.createElement(IconButton, { svg: pencil, onClick: handleEdit, ariaLabel: "Edit" }),
            !!onDelete && (React.createElement(IconButton, { svg: garbageCan, onClick: handleDelete, ariaLabel: "Delete", disabled: !enableDelete })),
            React.createElement("div", { className: "address-list-item__actions__info-icons" },
                isCurrent && canDeliver && React.createElement(Icon, { svg: checkmark, size: 'medium' }),
                isCurrent && !canDeliver && React.createElement(Icon, { svg: warning })))));
}
export default AddressListItem;

import classNames from 'classnames';
import Icon from 'components/UI/Icon';
import Popover from 'components/UI/Popover';
import { kebabCase } from 'lodash';
import React, { useState } from 'react';
import './NewTextInput.scss';
const checkmark = require('img/checkmark.svg');
function NewTextInput({ inputState, areaInput, className, value, onChange, label, children, maxLength, inputRef, onFocus, onBlur, autoComplete, fillHelp, helperText, errorMessage, helpIcon, placeholder, size, }) {
    const [showPopover, setShowPopover] = useState(false);
    const Input = areaInput ? 'textarea' : 'input';
    const inputClassname = `new-text-input__input new-text-input__input--${inputState}`;
    const handleChange = (e) => {
        onChange && onChange(e.target.value);
    };
    const handleBlur = (e) => {
        setShowPopover(false);
        onBlur && onBlur(e.target.value, e);
    };
    const handleFocus = (e) => {
        fillHelp && setShowPopover(true);
        onFocus && onFocus(e);
    };
    const inputId = kebabCase(label);
    const helperTextClasses = classNames('new-text-input__helper-text', {
        'new-text-input__helper-text--invalid': !!errorMessage && inputState === 'invalid',
    });
    const helperOrErrorText = !!errorMessage && inputState === 'invalid' ? errorMessage : !!helperText ? helperText : null;
    const RightSideIcon = inputState === 'valid' ? (React.createElement(Icon, { className: "new-text-input__icon", svg: checkmark, size: "medium" })) : null;
    return (React.createElement("div", { className: `new-text-input ${className ? className : ''}` },
        showPopover && React.createElement(Popover, null, fillHelp),
        label && (React.createElement("label", { className: "new-text-input__label", htmlFor: inputId }, label)),
        helpIcon ? helpIcon : null,
        React.createElement("div", { className: "new-text-input__input-container" },
            RightSideIcon,
            React.createElement(Input, { onChange: handleChange, id: inputId, className: inputClassname, value: value || '', ref: inputRef, placeholder: placeholder, onFocus: handleFocus, autoComplete: autoComplete, onBlur: handleBlur, maxLength: maxLength, disabled: inputState === 'disabled', "data-size": size || 'medium' })),
        helperOrErrorText && React.createElement("div", { className: helperTextClasses }, helperOrErrorText),
        children));
}
export default NewTextInput;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import './Radio.scss';
const STYLES = {
    large: {
        '--height': 32 + 'px',
        '--width': 32 + 'px',
        '--padding': 8 + 'px',
        '--checked-size': 16 + 'px',
    },
    small: {
        '--height': 24 + 'px',
        '--width': 24 + 'px',
        '--padding': 6 + 'px',
        '--checked-size': 12 + 'px',
    },
};
function Radio(_a) {
    var { radioButtonSize = 'small', children, className } = _a, delegated = __rest(_a, ["radioButtonSize", "children", "className"]);
    return (React.createElement("label", { className: 'radio-button-component--label' + className ? ' ' + className : '' },
        React.createElement("div", null,
            React.createElement("input", Object.assign({ type: "radio", className: "radio-button-component--input", style: STYLES[radioButtonSize] }, delegated))),
        children));
}
export default Radio;

import { AddRecipients } from 'checkout';
import { Modal } from 'common';
import React from 'react';
import './CartAddRecipientsModal.scss';
function CartAddRecipientsModal({ onClose }) {
    return (React.createElement(Modal, { onClose: onClose, className: "recipient-modal", labelId: "recipent-modal-title" },
        React.createElement("h1", { className: "recipient-modal__header", id: "recipent-modal-title" }, "Velg mottakere"),
        React.createElement(AddRecipients, { onClose: onClose })));
}
export default CartAddRecipientsModal;

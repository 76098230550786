import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class Switch extends Component {
    render() {
        return (
            <label className="switch">
                <input
                    type="checkbox"
                    onChange={(e) => this.props.onChange(e.target.checked)}
                    checked={this.props.on}
                />
                <span className="slider" />
            </label>
        )
    }
}

Switch.propTypes = {
    on: PropTypes.bool,
    onChange: PropTypes.func,
}

export default Switch

import './style.scss'

import { Button, ButtonRow, ButtonStack, Modal } from 'common'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

class SubscriptionStoppedModal extends PureComponent {
    getSubscriptionProductText = () => {
        const { subscriptions, products, product } = this.props
        let subscriptionProductText = ''
        let actionText = 'Vennligst bekreft at du ønsker å legge til nytt produkt i fast levering.'
        if (subscriptions) {
            if (subscriptions.length === 1) {
                const subscriptionProduct = products.find(
                    (p) => p.id === subscriptions[0].id.toString()
                )
                subscriptionProductText = `Du har fra før "${subscriptionProduct.title}" i din faste levering.`
                actionText = `Ønsker du å fjerne "${subscriptionProduct.title}" og legge til "${product.title}"?`
            } else if (subscriptions.length > 1) {
                subscriptionProductText = `Du har fra før ${subscriptions.length} produkter i din faste levering.`
                actionText = `Ønsker du å fjerne tidligere produkter og legge til "${product.title}"?`
            }
        }
        return { subscriptionProductText, actionText }
    }

    handleOnClose = () => {
        const { onClose } = this.props
        if (onClose) onClose()
    }

    handleStartSubscriptionAndAddProduct = (product, replace) => {
        const { startSubscriptionAndAddProduct } = this.props
        if (startSubscriptionAndAddProduct) startSubscriptionAndAddProduct(product, replace)
    }
    renderCancelButton() {
        return (
            <Button type="action" style="ghost" onClick={this.handleOnClose}>
                Avbryt
            </Button>
        )
    }

    renderConfirmButtons = () => {
        const { subscriptions, product } = this.props
        if (subscriptions && subscriptions.length > 0) {
            return (
                <>
                    <Button
                        type="action"
                        style="primary"
                        onClick={() => this.handleStartSubscriptionAndAddProduct(product)}
                    >
                        Nei, kun legg til
                    </Button>
                    <Button
                        type="action"
                        style="primary"
                        onClick={() => this.handleStartSubscriptionAndAddProduct(product, true)}
                    >
                        Ja, fjern og legg til
                    </Button>
                </>
            )
        } else if (!subscriptions || subscriptions.length === 0) {
            return (
                <Button
                    type="action"
                    style="primary"
                    onClick={() => this.handleStartSubscriptionAndAddProduct(product)}
                >
                    OK
                </Button>
            )
        }
    }

    render() {
        const { subscriptions, isMobile } = this.props
        let { subscriptionProductText, actionText } = this.getSubscriptionProductText()

        return (
            <Modal onClose={this.handleOnClose} labelId="subscription-stopped-modal-title">
                <div
                    className="subscription-stopped-modal__title"
                    id="subscription-stopped-modal-title"
                >
                    For å legge til flere produkter i fast levering må denne startes igjen.
                </div>
                {subscriptions && subscriptions.length > 0 && (
                    <div className="subscription-stopped-modal__products">
                        {subscriptionProductText}
                    </div>
                )}
                <div className="subscription-stopped-modal__actions">{actionText}</div>
                {!isMobile && (
                    <ButtonRow display="center">
                        {this.renderCancelButton()}
                        {this.renderConfirmButtons()}
                    </ButtonRow>
                )}
                {isMobile && (
                    <ButtonStack>
                        {this.renderConfirmButtons()}
                        {this.renderCancelButton()}
                    </ButtonStack>
                )}
            </Modal>
        )
    }
}

SubscriptionStoppedModal.propTypes = {
    isMobile: PropTypes.bool,
    product: PropTypes.object.isRequired,
    subscriptions: PropTypes.array,
    products: PropTypes.array,
    onClose: PropTypes.func,
    startSubscriptionAndAddProduct: PropTypes.func,
}

export default SubscriptionStoppedModal

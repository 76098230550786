import React from 'react';
import './TopBanner.scss';
import { TopBannerToolbar } from './TopBannerToolbar';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'common';
function TopBanner(props) {
    const dispatch = useDispatch();
    const isMobile = useSelector((state) => state.ui.isMobile);
    const onCtaClick = (target) => {
        dispatch({ type: 'PROMOTION_CLICKED', payload: { promotionTitle: props.title } });
        dispatch(push(target !== null && target !== void 0 ? target : ''));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("section", { className: 'topbanner' },
            React.createElement("img", { src: props.image, className: 'topbanner__image' }),
            React.createElement("div", { className: 'topbanner__content' },
                React.createElement("h1", null, props.title),
                React.createElement("p", null, isMobile ? props.mobileSubtitle : props.subtitle),
                props.buttons && props.buttons.length > 0 && (React.createElement("div", { className: 'topbanner__buttons' }, props.buttons.map(({ buttonText, target }, i) => {
                    return (React.createElement(Button, { key: i, type: 'action', style: 'primary', className: 'topbanner__cta-button', onClick: () => onCtaClick(target) }, buttonText));
                }))))),
        props.toolbar && React.createElement(TopBannerToolbar, { className: "topbanner__toolbar" })));
}
export default TopBanner;

import groq from 'groq';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import sanityClient, { sanityImageBuilder } from 'utilities/sanityClient';
import './USPList.scss';
function USPList({ documentId }) {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const [activeChildIndex, setActiveChildIndex] = useState(0);
    const onScroll = (e) => {
        var _a;
        const eventTarget = e.target;
        const scrollLeft = eventTarget.scrollLeft;
        const childWidth = (_a = eventTarget.childNodes[0]) === null || _a === void 0 ? void 0 : _a.offsetWidth;
        if (childWidth != undefined && childWidth != 0) {
            setActiveChildIndex(Math.floor(scrollLeft / childWidth));
        }
    };
    const [uspDocument, setUspList] = useState();
    useEffect(() => {
        const query = groq `
            *[ _id == $documentId ][0] {
                usps[] {
                    "key":_key,
                    title,
                    text,
                    image,
                },
                showLoggedOut,
                showLoggedIn
            }
        `;
        let isSubscribed = true;
        sanityClient.fetch(query, { documentId }).then((uspListDocument) => {
            var _a;
            const usps = (_a = uspListDocument === null || uspListDocument === void 0 ? void 0 : uspListDocument.usps.map((usp) => ({
                key: usp.key,
                title: usp.title,
                text: usp.text,
                image: sanityImageBuilder.image(usp.image).url(),
            }))) !== null && _a !== void 0 ? _a : [];
            if (isSubscribed && usps.length > 0) {
                setUspList(Object.assign(Object.assign({}, uspListDocument), { usps }));
            }
        });
        return () => {
            isSubscribed = false;
        };
    }, [documentId]);
    if (!uspDocument)
        return null;
    if (uspDocument.usps.length <= 0)
        return null;
    if ((uspDocument.showLoggedIn && uspDocument.showLoggedOut) == false) {
        if (uspDocument.showLoggedIn && !isLoggedIn)
            return null;
        if (uspDocument.showLoggedOut && isLoggedIn)
            return null;
    }
    return (React.createElement("div", { className: 'usp-list__wrapper' },
        React.createElement("div", { className: `usp-list usp-list--${uspDocument.usps.length}-items`, onScroll: onScroll }, uspDocument.usps.map((usp) => (React.createElement("div", { className: "usp-list__item", key: usp.key },
            React.createElement("img", { src: usp.image, alt: usp.title }),
            React.createElement("h2", null, usp.title),
            React.createElement("p", null, usp.text))))),
        React.createElement(USPListDotIndicator, { length: uspDocument.usps.length, activeIndex: activeChildIndex })));
}
function USPListDotIndicator({ length, activeIndex }) {
    const dots = [];
    for (let i = 0; i < length; i++) {
        dots.push(React.createElement("li", { key: i, className: i == activeIndex ? 'active' : '' }, "\u2022"));
    }
    return React.createElement("ul", { className: 'usp-list__dots' }, dots);
}
export default USPList;

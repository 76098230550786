import axios from 'axios';
export class AddressSearch {
    static search({ fullName, phoneNumber }, callback) {
        return axios
            .post(`${process.env.CORE_API}/address-search`, {
            fullName,
            phoneNumber,
        })
            .then((response) => {
            callback(response.data);
        });
    }
}

import { hideDropDowns } from 'actions/UI'
import classNames from 'classnames'
import Icon from 'components/UI/Icon'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import './style.scss'

const logo = require('img/morgenlevering_logo_text_right.svg')

class Logo extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            logoTranslateY: 1,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        const { isMobile } = this.props
        if (true || !isMobile) {
            const distanceY = window.pageYOffset || document.documentElement.scrollTop

            this.setState({ logoTranslateY: Math.max(0, 210 - distanceY) })
        }
    }
    render() {
        const { dispatch, isHomePage, hideLogoBeforeScroll } = this.props

        const { logoTranslateY } = this.state
        const classes = classNames({
            'logo-scrolled': hideLogoBeforeScroll && isHomePage && logoTranslateY !== 0,
            'logo-fixed': hideLogoBeforeScroll && isHomePage && logoTranslateY === 0,
        })
        return (
            <div className="logo">
                <div className={classes}>
                    <Icon
                        svg={logo}
                        size="header_logo"
                        onClick={() => {
                            dispatch(hideDropDowns())
                            dispatch(push('/'))
                        }}
                    />
                </div>
            </div>
        )
    }
}

Logo.propTypes = {
    dispatch: PropTypes.func,
    isCheckout: PropTypes.bool,
    isMobile: PropTypes.bool,
    isHomePage: PropTypes.bool,
    hideLogoBeforeScroll: PropTypes.bool,
}

Logo.defaultProps = {
    hideLogoBeforeScroll: false,
}

const mapStateToProps = ({ router, ui }) => {
    return {
        isMobile: ui.isMobile,
        isHomePage: router.location.pathname === '/',
    }
}

export default connect(mapStateToProps)(Logo)

import { setActiveAddress } from 'actions/Address'
import { getDistributionForRegion } from 'actions/Distribution'
import {
    isPushFeatureEnabledChanged,
    pushAuthorizationChanged,
    pushErrorOccurred,
    pushNotificationReceived,
    pushTokenChanged,
} from 'actions/Push'
import { getAutoRegion, getRegions, setRegionByRegionId } from 'actions/Regions'
import { toggleIsMobile, toggleIsTablet } from 'actions/UI'
import axios from 'axios'
import { toggleOneClickCheckout } from 'checkout'
import { getFeaturesEnabled } from 'common'
import { isEmpty } from 'lodash'
import throttle from 'lodash/throttle'
import moment from 'moment'
import { PaymentMethodType } from 'types/paymentMethodType'
import { tryShowSimplifiedLoginWidget } from 'user'
import { initToken } from 'utilities/jwtHelper'
import { saveState } from 'utilities/localStorage'
import { registerHandlers } from 'utilities/push'
import SPiD from 'utilities/spid'
import { isAppUrl } from 'utilities/url'
import { v4 as uuid } from 'uuid'
import * as amplitude from '@amplitude/analytics-browser'
import hash from 'hash.js'

/*
 Features can be overridden in localStorage using
 features = login,subscription
*/
function toggleFeatures(store) {
    getFeaturesEnabled().forEach((f) => {
        store.dispatch({ type: 'ENABLE_FEATURE', payload: f })
    })
}

function cacheToLocalStorage(store) {
    store.subscribe(
        throttle(() => {
            const stateToSave = {
                timeStamp: Date.now(),
                personalInfo: store.getState().personalInfo,
                push: store.getState().push,
                cart: store.getState().cart,
                coupon: store.getState().coupon,
                products: store.getState().products,
                subscription: store.getState().subscription,
                checkout: store.getState().checkout,
                address: store.getState().address,
                newCheckout: { ...store.getState().newCheckout }, // copy this part of state to not overwrite redux state below
                region: store.getState().region,
            }
            stateToSave.newCheckout.giftMessage = { message: undefined, greeting: undefined }
            stateToSave.newCheckout.orderConfirmationData = null
            saveState(stateToSave)
        }, 1000)
    )
}

function addDeviceListener(store) {
    const toggleDevice = () => {
        const ui = store.getState().ui
        const isMobileEvent = toggleIsMobile()
        const isTabletEvent = toggleIsTablet()

        // Only dispatch if state actually changes
        if (ui.isMobile !== isMobileEvent.payload) {
            store.dispatch(isMobileEvent)
        }
        if (ui.isTablet !== isTabletEvent.payload) {
            store.dispatch(isTabletEvent)
        }
    }
    toggleDevice()
    window.addEventListener(
        'resize',
        throttle(() => toggleDevice(), 50, { leading: false })
    )
}

export function initLocation(store) {
    const urlParams = new URLSearchParams(location.search.toLowerCase())
    if (urlParams.has('region')) {
        store.dispatch(getRegions()).then(() => {
            const region = urlParams.get('region')
            const reducerRegion = store
                .getState()
                .region.regions.find((r) => r.externalRegion === region)

            if (reducerRegion !== undefined) {
                store.dispatch({
                    type: 'SET_REGION',
                    payload: reducerRegion,
                })
            } else {
                store.dispatch(getAutoRegion())
            }
        })
    } else {
        const state = store.getState()
        const hasActiveAddress = (s) => !isEmpty(s.address.active)
        const hasSetRegion = (s) => s.region.id !== null

        store.dispatch(getRegions())
        if (hasActiveAddress(state)) {
            store.dispatch(setActiveAddress(state.address.active, true))
        } else if (hasSetRegion(state)) {
            store.dispatch(setRegionByRegionId(state.region.id))
            store.dispatch(getDistributionForRegion(state.region.transportProduct))
        } else {
            store.dispatch(getAutoRegion())
        }
    }
}

function initAxios(store) {
    axios.interceptors.request.use(
        (config) => {
            // Add request id to trace request to core
            // Note: cms and di fails if this header is present
            if (config.url.includes(process.env.CORE_API)) {
                config.headers['X-Request-Id'] = uuid()
            }

            // Add Authorization headers if logged in
            const jwt = store.getState().user.jwt
            if (!config.headers.Authorization && jwt && config.url.includes(process.env.CORE_API)) {
                config.headers.Authorization = `Bearer ${jwt}`
            }

            config.timeout = config.timeout || 30 * 1000

            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )
}

function initMoment() {
    moment.locale(process.env.MOMENT_LOCALE)

    if (process.env.MOMENT_LOCALE === 'nb') {
        moment.updateLocale('nb', {
            weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
            calendar: {
                sameDay: '[I dag]',
                nextDay: '[I morgen]',
                nextWeek: 'dddd',
                lastDay: '[I går]',
                lastWeek: '[Forrige] dddd',
                sameElse: '[Neste] dddd',
            },
        })
    }
    if (process.env.MOMENT_LOCALE === 'sv') {
        moment.updateLocale('sv', {
            calendar: {
                sameDay: '[I dag]',
                nextDay: '[I morgon]',
                nextWeek: 'dddd',
                lastDay: '[I går]',
                lastWeek: '[Föregående] dddd',
                sameElse: '[Nästa] dddd',
            },
        })
    }
}

function initPush(store) {
    const isApp = isAppUrl()
    if (isApp) {
        registerHandlers({
            isFeatureEnabledChanged: (isEnabled) => {
                store.dispatch(isPushFeatureEnabledChanged(isEnabled))
            },
            authorizationChanged: (authorization) => {
                store.dispatch(pushAuthorizationChanged(authorization))
            },
            tokenChanged: (token) => {
                store.dispatch(pushTokenChanged(token))
            },
            notificationReceived: (notification, mode) => {
                store.dispatch(pushNotificationReceived(notification, mode))
            },
            errorOccurred: (context, error) => {
                store.dispatch(pushErrorOccurred(context, error))
            },
        })
    }
}

function initSpid(store) {
    SPiD.initSpid(store)
    initToken(store)
}

function initSimplifiedLogin(store) {
    if (!store.getState()?.ui.isApp) {
        setTimeout(() => tryShowSimplifiedLoginWidget(store.getState()?.user), 2000)
    }
}

function initCheckout(store) {
    store.dispatch({ type: 'STOP_AWAITING_ORDER_RESPONSE' })

    if (isAppUrl()) {
        store.dispatch({
            type: 'SET_PAYMENT_METHOD_TYPE',
            payload: PaymentMethodType.CREDIT_CARD,
        })
    }

    store.subscribe(
        throttle(() => {
            store.dispatch(toggleOneClickCheckout())
        })
    )
}

function initCart(store) {
    store.dispatch({ type: 'REFRESH_CART' })
}

export function initAmplitudeDeviceId(email) {
    let hashedEmail
    if (email) {
        hashedEmail = hash.sha256().update(email).digest('hex')
    } else {
        hashedEmail = undefined
    }
    const defaultTracking = {
        attribution: false,
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
    }
    amplitude.init(process.env.AMPLITUDE_API_KEY, hashedEmail, { defaultTracking })

    const maxRetries = 10
    let retries = 0
    const trySetDeviceId = setInterval(() => {
        if (retries > maxRetries) {
            console.error('Could not get Amplitude device id')
            clearInterval(trySetDeviceId)
        }
        retries++
        const deviceId = amplitude.getDeviceId()
        if (deviceId) {
            setDeviceIdHeader(deviceId)
            dataLayer.push({ event: 'set_device_id', amplitudeDeviceId: deviceId })
            clearInterval(trySetDeviceId)
        }
    }, 1000)
}

function setDeviceIdHeader(deviceId) {
    axios.interceptors.request.use(
        (config) => {
            if (config.url.includes(process.env.CORE_API)) {
                config.headers['X-Amplitude-Device-Id'] = deviceId
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )
}

function initUI(store) {
    if (isAppUrl()) {
        store.dispatch({ type: 'IS_APP', payload: true })
    }
}

export function init(store) {
    initAxios(store)
    initLocation(store)
    addDeviceListener(store)
    initMoment()
    initPush(store)
    toggleFeatures(store)
    initSpid(store)
    initSimplifiedLogin(store)
    cacheToLocalStorage(store)
    initCheckout(store)
    initCart(store)
    initAmplitudeDeviceId(store.getState().personalInfo.email)
    initUI(store)
}

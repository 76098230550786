import { deactivatePrimaryAddress, setNewMainAddress } from 'actions/Address';
import { Button, ButtonRow, LinkBox } from 'common';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SelectableAddressItem from '../AddressItem/SelectableAddressItem';
import './NewMainAddress.scss';
function NewMainAddress({ addresses, closeModal, openNewPrimaryAddress, shouldDeleteCurrentPrimary = false, }) {
    const dispatch = useDispatch();
    const [selectedAddress, setSelectedAddress] = useState();
    const handleSave = (address) => {
        if (shouldDeleteCurrentPrimary) {
            dispatch(deactivatePrimaryAddress());
        }
        dispatch(setNewMainAddress(address));
        closeModal();
    };
    const handleNewAddress = () => {
        closeModal();
        openNewPrimaryAddress && openNewPrimaryAddress();
    };
    return (React.createElement("div", { className: "new-main-address" },
        React.createElement("h2", { id: "edit-address-form-title" }, "Velg din nye hovedadresse"),
        openNewPrimaryAddress && (React.createElement(React.Fragment, null,
            React.createElement(LinkBox, { className: "new-main-address__new-address-button", colorStyle: "gray", text: "Ny adresse", onClick: handleNewAddress }),
            React.createElement("p", null, "Velg en fra dine eksisterende"))),
        addresses
            .filter((address) => !address.isPrimary)
            .map((address) => (React.createElement(SelectableAddressItem, { address: address, key: address.addressId, isSelected: address.addressId === (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.addressId), onSelect: () => setSelectedAddress(address), canDelete: false }))),
        React.createElement(ButtonRow, { display: "stretch", className: "new-main-address__save-button" },
            React.createElement(Button, { type: "action", style: "primary", onClick: () => selectedAddress && handleSave(selectedAddress), disabled: selectedAddress === null }, "Lagre endringer"))));
}
export default NewMainAddress;

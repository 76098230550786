import classNames from 'classnames';
import { throttle } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import './HideScroll.scss';
function HideScroll({ children, top = 0, disabled = false }) {
    const divRef = useRef(null);
    const headerVisible = useHideScroll();
    const classes = classNames({
        'hide-scroll': !disabled,
        'hide-scroll--hidden': !headerVisible && !disabled,
    });
    return (React.createElement("div", { className: classes, ref: divRef, style: {
            '--top': top + 'px',
        } }, children));
}
export const useHideScroll = () => {
    const [visible, setVisible] = useState(true);
    useEffect(() => {
        let prev;
        let scrollY = window.scrollY;
        let scrollDistanceInARow = 0;
        let lastScrollDelta = 0;
        const handleScroll = () => {
            const scrollDelta = window.scrollY - scrollY;
            if (scrollDelta * lastScrollDelta > 0) {
                scrollDistanceInARow += Math.abs(scrollDelta);
            }
            else {
                scrollDistanceInARow = 0;
            }
            lastScrollDelta = scrollDelta;
            if (window.scrollY - scrollY > 0 && window.scrollY > 50 && scrollDistanceInARow > 100) {
                // Scrolled down, should be hidden
                if (prev !== false) {
                    setVisible(false);
                    prev = false;
                }
            }
            else if (scrollDistanceInARow > 100) {
                // Scrolled up, should be visible
                if (prev !== true) {
                    setVisible(true);
                    prev = true;
                }
            }
            if (window.scrollY < 50) {
                if (prev !== true) {
                    setVisible(true);
                }
            }
            scrollY = window.scrollY;
        };
        const onScroll = throttle(handleScroll, 50);
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    return visible;
};
export default HideScroll;

import React from 'react';
import './FreeFreightProgress.scss';
import { Price, ProgressBar } from 'common';
import { useSelector } from 'react-redux';
export function FreeFreightProgress() {
    const { orderTotalLeftUntilFreeFreight, freightReason } = useSelector((state) => state.cart.freight);
    const orderTotal = useSelector((state) => state.cart.totalWithoutFreight);
    if (freightReason === 'FREE_FREIGHT_PROMOTION') {
        return null;
    }
    if (freightReason !== 'DEFAULT' && freightReason !== 'ORDER_VALUE') {
        return null;
    }
    if (orderTotalLeftUntilFreeFreight > 300)
        return null;
    return (React.createElement("div", { className: "free-freight-progress" },
        orderTotalLeftUntilFreeFreight === 0 ? (React.createElement("p", null,
            "Du har oppn\u00E5dd",
            ' ',
            React.createElement("span", { className: "free-freight-progress__free-freight" }, "gratis frakt!"))) : (React.createElement("p", null,
            "Du er",
            ' ',
            React.createElement(Price, { amount: orderTotalLeftUntilFreeFreight, className: "free-freight-progress__amount-left" }),
            ' ',
            "unna gratis frakt")),
        React.createElement(ProgressBar, { value: orderTotal, total: orderTotal + orderTotalLeftUntilFreeFreight })));
}

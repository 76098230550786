import { setDeliverySms, setEmail } from 'actions/PersonalInfo';
import { useOnInvalidOrderAttempt } from 'checkout';
import { Card } from 'common';
import CheckboxWithLabel from 'common/components/CheckboxWithLabel/CheckboxWithLabel';
import NewTextInput from 'common/components/TextInput/NewTextInput';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValidEmail, isValidMobile } from 'utilities/validators';
import './EmailCard.scss';
function EmailCard() {
    const deliverySms = useSelector((state) => state.personalInfo.deliverySms);
    const email = useSelector((state) => state.personalInfo.email);
    const activeAddressMobile = useSelector((state) => { var _a; return (_a = state.address.active) === null || _a === void 0 ? void 0 : _a.deliveryAddress.mobile; });
    const orderAttempts = useSelector((state) => state.newCheckout.orderAttempts);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const inputFieldRef = useRef();
    const dispatch = useDispatch();
    const setSms = (newValue) => dispatch(setDeliverySms(newValue));
    const emailValid = isValidEmail(email);
    const inputFieldState = (() => {
        if (isLoggedIn) {
            return 'disabled';
        }
        else if (emailValid) {
            return 'valid';
        }
        else if (orderAttempts > 0) {
            return 'invalid';
        }
        return 'incomplete';
    })();
    useOnInvalidOrderAttempt(!emailValid, () => {
        var _a;
        const rect = (_a = inputFieldRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        rect && window.scrollBy({ top: rect.y - 100, behavior: 'smooth' });
    });
    return (React.createElement(Card, { title: "Kontaktinformasjon" },
        React.createElement(NewTextInput, { inputState: inputFieldState, value: email, onChange: (newValue) => dispatch(setEmail(newValue)), label: "E-post for kvittering", className: "email-card__textfield", inputRef: inputFieldRef, errorMessage: "E-post m\u00E5 fylles ut" }),
        activeAddressMobile && isValidMobile(activeAddressMobile) ? (React.createElement(CheckboxWithLabel, { isChecked: deliverySms, setIsChecked: setSms, labelText: `Send SMS til ${activeAddressMobile} når pakken er levert.` })) : null));
}
export default EmailCard;

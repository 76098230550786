import classNames from 'classnames';
import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import './CheckboxWithLabel.scss';
function CheckboxWithLabel({ isChecked, setIsChecked, labelText, size = 'large', className }) {
    const classes = classNames({
        'common-checkbox-with-label': true,
        [`${className}`]: className
    });
    return (React.createElement("div", { className: classes, onClick: () => setIsChecked(!isChecked) },
        React.createElement(Checkbox, { isChecked: isChecked, setIsChecked: setIsChecked, size: size }),
        React.createElement("div", null, labelText)));
}
export default CheckboxWithLabel;

import { Router } from 'common';
import { googleAnalyticsMiddleware } from 'common/middleware/analytics';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import merge from 'lodash/merge';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { AppContainer, setConfig as setHotLoaderConfig } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import smoothscroll from 'smoothscroll-polyfill';
import 'img/logo-full.png'; // Do not remove - used in email-receipts
import { init } from 'utilities/init';
import { loadState, stateVersion } from 'utilities/localStorage';
import createRootReducer from './reducers';
import { baseState as uiBaseState } from './reducers/uiReducer';
import './style/main.scss';
stateVersion('3');
const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadState();
const store = createStore(createRootReducer(history), persistedState && merge({ ui: uiBaseState }, persistedState), composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, googleAnalyticsMiddleware)));
init(store);
// Enable smooth scroll in Safari
smoothscroll.polyfill();
setHotLoaderConfig({ showReactDomPatchNotification: false });
const appNode = document.querySelector('#app');
ReactDOM.render(React.createElement(Suspense, { fallback: React.createElement("div", null) },
    React.createElement(AppContainer, null,
        React.createElement(Provider, { store: store },
            React.createElement(Router, { history: history })))), appNode);

import { activateSubscription, addProductToSubscription, gotoSubscriptionInfoOrOverview, } from 'actions/Subscription';
import { Button } from 'common';
import SubscriptionStoppedModal from 'components/Subscription/SubscriptionStoppedModal';
import WrongRegionModal from 'components/Subscription/WrongRegionModal';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
function AddToSubscriptionButton({ product, noPriorSubscriptions, productInSubscription, explicit, isStopped, wrongRegion, subscriptions, products, isMobile, productDetails, }) {
    const dispatch = useDispatch();
    const [showSubscriptionStoppedWarning, setShowSubscriptionStoppedWarning] = useState(false);
    const [showWrongRegionWarning, setShowWrongRegionWarning] = useState(false);
    const startSubscriptionAndAddProduct = (product, replace = false) => {
        dispatch(activateSubscription(product, replace));
        dispatch(gotoSubscriptionInfoOrOverview());
    };
    const handleClickAdd = (e) => {
        e.stopPropagation();
        if (wrongRegion) {
            setShowWrongRegionWarning(true);
            return;
        }
        if (isStopped) {
            setShowSubscriptionStoppedWarning(true);
            return;
        }
        dispatch(addProductToSubscription(product));
        if (noPriorSubscriptions) {
            dispatch(gotoSubscriptionInfoOrOverview());
        }
    };
    const closeModal = () => {
        setShowSubscriptionStoppedWarning(false);
        setShowWrongRegionWarning(false);
    };
    const renderModalStopped = () => {
        if (!showSubscriptionStoppedWarning)
            return null;
        return (React.createElement(SubscriptionStoppedModal, { onClose: closeModal, startSubscriptionAndAddProduct: startSubscriptionAndAddProduct, subscriptions: subscriptions, products: products, product: product, isMobile: isMobile }));
    };
    const renderModalWrongRegion = () => {
        if (!showWrongRegionWarning)
            return null;
        return React.createElement(WrongRegionModal, { onClose: closeModal });
    };
    if (productDetails) {
        return (React.createElement(Button, { type: "action", style: "ghost", size: "small", onClick: (e) => handleClickAdd(e), disabled: productInSubscription }, productInSubscription
            ? 'Lagt til'
            : 'Legg til på fast levering (gratis frakt)'));
    }
    else {
        return productInSubscription ? (React.createElement(Button, { type: "action", style: "secondary", size: "small", onClick: () => undefined, disabled: true }, "Lagt til")) : (React.createElement(Button, { type: "action", style: "secondary", size: "small", onClick: (e) => handleClickAdd(e) },
            !explicit && React.createElement("span", null, "Legg til"),
            explicit && React.createElement("span", null, "Fast levering"),
            renderModalWrongRegion(),
            renderModalStopped()));
    }
}
function mapStateToProps({ subscription, ui, products }, ownProps) {
    const productInSubscription = subscription.products.filter((p) => p.id.toString() == ownProps.product.id).length > 0;
    return {
        isMobile: ui.isMobile,
        isStopped: subscription.stopped,
        noPriorSubscriptions: subscription.products.length === 0,
        product: ownProps.product,
        productInSubscription,
        products: products.products,
        subscriptions: subscription.products,
        wrongRegion: subscription.transportProductStatus === 'OTHER_REGION',
    };
}
export default connect(mapStateToProps)(AddToSubscriptionButton);

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import sanityClient from 'utilities/sanityClient';
import EditorialBundleProducts from 'common/components/EditorialBundleProducts/EditorialBundleProducts';
function DynamicEditorialBundleProducts({ documentId, regionId }) {
    const [editorialBundleProductsDocument, setEditorialBundleProductsDocument] = useState();
    const [editorialBundleProductsData, setEditorialBundleProductsData] = useState(null);
    const isSubscriptionMode = useSelector((state) => state.subscription.isSubscriptionMode);
    const allProducts = useSelector((state) => state.products.products);
    useEffect(() => {
        const query = `*[_id == $documentId && $regionId in regionBundles.regions][0]{
            title,
            subtitle,
            mobileSubtitle,
            leadText,
            target,
            targetText,
            displayProducts,
            "bundles": regionBundles.region_${regionId}[]->{
                _id,
                title,
                description,
                leadText,
                mainImage,
                thumbImage,
                "productIds": regionProducts.region_${regionId}
            }
        }`;
        let isSubscribed = true;
        sanityClient
            .fetch(query, { documentId, regionId })
            .then((document) => (isSubscribed ? setEditorialBundleProductsDocument(document) : null))
            .catch(console.error);
        return () => {
            isSubscribed = false;
        };
    }, [documentId, regionId]);
    useEffect(() => {
        var _a, _b;
        if (editorialBundleProductsDocument == null || allProducts.length === 0) {
            return;
        }
        const bundles = (_a = editorialBundleProductsDocument.bundles) === null || _a === void 0 ? void 0 : _a.map((bundle) => {
            const products = bundle.productIds
                .map((productId) => allProducts.find(({ id }) => id === productId));
            if (products.some((product) => product == null)) {
                return null;
            }
            return Object.assign(Object.assign({}, bundle), { products });
        }).filter((bundle) => bundle != null);
        setEditorialBundleProductsData({
            title: editorialBundleProductsDocument.title,
            subtitle: editorialBundleProductsDocument.subtitle,
            mobileSubtitle: editorialBundleProductsDocument.mobileSubtitle,
            leadText: editorialBundleProductsDocument.leadText,
            displayProducts: (_b = editorialBundleProductsDocument.displayProducts) !== null && _b !== void 0 ? _b : 0,
            bundles,
        });
    }, [editorialBundleProductsDocument, allProducts]);
    if (isSubscriptionMode || editorialBundleProductsData == null || editorialBundleProductsData.bundles.length === 0) {
        return null;
    }
    return (React.createElement(EditorialBundleProducts, Object.assign({}, editorialBundleProductsData)));
}
export default DynamicEditorialBundleProducts;

import { mapToUserPropertyArray } from './intercomMapper'

function hasNativeIntercom() {
    return (
        (window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.intercom &&
            window.webkit.messageHandlers.intercom.postMessage) ||
        (window.IntercomAndroidBridge && window.IntercomAndroidBridge.postMessage)
    )
}

function postMessage(message) {
    if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.intercom &&
        window.webkit.messageHandlers.intercom.postMessage
    ) {
        window.webkit.messageHandlers.intercom.postMessage(JSON.stringify(message))
    } else if (window.IntercomAndroidBridge && window.IntercomAndroidBridge.postMessage) {
        window.IntercomAndroidBridge.postMessage(JSON.stringify(message))
    }
}

function setUserId(userId) {
    try {
        const msg = {
            action: 'setUserId',
            payload: { user_id: userId },
        }
        postMessage(msg)
    } catch (error) {
        console.error('Intercom native: Failed to setUserId', error && error.message)
    }
}

function setUserProps(userProps) {
    try {
        const properties = mapToUserPropertyArray(userProps)
        if (!properties || properties.length === 0) return

        const msg = {
            action: 'setUserProps',
            payload: { properties },
        }
        postMessage(msg)
    } catch (error) {
        console.error('Intercom native: Failed to setUserId', error && error.message)
    }
}

function setLauncherOffset(doOffset) {
    try {
        const msg = {
            action: 'setLauncherOffset',
            payload: { offset: doOffset ? 45 : 0 },
        }
        postMessage(msg)
    } catch (error) {
        console.error('Intercom native: Failed to setLauncherOffset', error && error.message)
    }
}

function showMessenger() {
    try {
        const msg = {
            action: 'showMessenger',
            payload: {},
        }
        postMessage(msg)
    } catch (error) {
        console.error('Intercom native: Failed to showMessenger', error && error.message)
    }
}

function getUserPropsFromState(state) {
    let props = {
        'Fast levering': state.subscription.hasSubscription,
        'Fast levering stoppet': state.subscription.stopped !== undefined,
    }

    if (state.region.name) {
        props['Region'] = state.region.name
    }

    return props
}

export {
    setUserId,
    setUserProps,
    setLauncherOffset,
    showMessenger,
    hasNativeIntercom,
    getUserPropsFromState,
}

import moment from 'moment';
export function getWeekDays() {
    const weekDayNames = [];
    for (let i = 1; i <= 7; i++) {
        weekDayNames.push(moment().day(i).format('ddd').toUpperCase());
    }
    return weekDayNames;
}
export function getOffset(date) {
    return Math.abs(moment().startOf('isoWeek').diff(date, 'days'));
}
export function getDefaultDeliveryDates() {
    const dates = [];
    const start = moment();
    const limitDays = process.env.DISTRIBUTION_LIMIT_DAYS
        ? parseInt(process.env.DISTRIBUTION_LIMIT_DAYS)
        : 10;
    for (let i = 1; i <= limitDays; i++) {
        dates.push(start.clone().add(i, 'days').format('YYYY-MM-DD'));
    }
    return dates;
}

import { PortableText } from '@portabletext/react';
import ScrollToTop from 'components/UI/ScrollToTop';
import groq from 'groq';
import { ProductCarousel, ProductGrid } from 'product';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import sanityClient from 'utilities/sanityClient';
import BlogPostHeader from '../../components/BlogPostHeader/BlogPostHeader';
import BlogPostLoading from '../../components/BlogPostLoading/BlogPostLoading';
import BlogPostMeta from '../../components/BlogPostMeta/BlogPostMeta';
import BlogPostNotFound from '../../components/BlogPostNotFound/BlogPostNotFound';
import { portableTextBlogComponents } from '../../util/BlogPostUtils';
import './BlogPost.scss';
export function BlogPost() {
    const { slug } = useParams();
    const [blogPostData, setBlogPostData] = useState(null);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [articleNotFound, setArticleNotFound] = useState(false);
    const [fetchErrored, setFetchErrored] = useState(false);
    const regionId = useSelector(({ region }) => region.id);
    const allProducts = useSelector(({ products }) => products.products);
    const bodyTextRef = useRef(null);
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const handleReadMoreClick = () => {
        var _a;
        // Add a bit of headspace to account for the header
        if ((_a = bodyTextRef.current) === null || _a === void 0 ? void 0 : _a.offsetTop) {
            const headSpace = isMobile ? 16 : 75;
            window.scrollTo({ top: bodyTextRef.current.offsetTop - headSpace, behavior: 'smooth' });
        }
    };
    useEffect(() => {
        const query = groq `
            *[ _type == "blogPost" && slug.current == $slug && publishedAt <= now() ][0] {
                author-> {
                    image,
                    name,
                    slug
                },
                body,
                category-> {
                    slug,
                    title
                },
                mainImage,
                publishedAt,
                title,
                "featuredProductIds": regionProducts.region_${regionId}
            }`;
        let isSubscribed = true;
        sanityClient
            .fetch(query, { slug })
            .then((data) => {
            if (isSubscribed) {
                if (data == null) {
                    setArticleNotFound(true);
                    return;
                }
                setBlogPostData(data);
            }
        })
            .catch(() => (isSubscribed ? setFetchErrored(true) : null));
        return () => {
            isSubscribed = false;
        };
    }, [slug, regionId]);
    useEffect(() => {
        var _a, _b;
        setFeaturedProducts((_b = (_a = blogPostData === null || blogPostData === void 0 ? void 0 : blogPostData.featuredProductIds) === null || _a === void 0 ? void 0 : _a.map((featuredProductId) => allProducts.find((product) => product.id == featuredProductId)).flatMap((product) => (!!product ? [product] : []))) !== null && _b !== void 0 ? _b : []);
    }, [blogPostData, allProducts]);
    if (fetchErrored) {
        return React.createElement("h1", null, "Noe gikk galt");
    }
    if (articleNotFound) {
        return React.createElement(BlogPostNotFound, null);
    }
    if (blogPostData == null) {
        return React.createElement(BlogPostLoading, null);
    }
    return (React.createElement("main", { className: "blog-post" },
        React.createElement(ScrollToTop, null),
        React.createElement(BlogPostHeader, { image: blogPostData.mainImage, title: blogPostData.title, categoryTitle: blogPostData.category.title, publishedAt: blogPostData.publishedAt, handleReadMoreClick: handleReadMoreClick }),
        React.createElement("div", { className: "blog-post__body", ref: bodyTextRef },
            React.createElement(BlogPostMeta, { publishedAt: blogPostData.publishedAt, categoryTitle: blogPostData.category.title, className: "blog-post__body__meta" }),
            React.createElement(PortableText, { value: blogPostData.body, components: portableTextBlogComponents })),
        featuredProducts.length > 0 && (React.createElement("div", { className: "blog-post__featured-products" },
            React.createElement("h2", { className: "blog-post__featured-products__heading" }, "Produkter i denne artikkelen"),
            isMobile ? (React.createElement(ProductCarousel, { products: featuredProducts, shrinkOnMobile: true })) : (React.createElement(ProductGrid, { products: featuredProducts, shrinkProductTiles: true }))))));
}

import { FEATURES, isFeatureEnabled } from 'common';
import React from 'react';
import { useSelector } from 'react-redux';
import './CartItem.scss';
import { hasAvailability } from '../../../product/util';
import { CartItemNew } from './CartItemNew';
import { CartItemOld } from './CartItemOld';
export default function CartItem(props) {
    const features = useSelector((state) => state.features);
    if (isFeatureEnabled(features.enabled, FEATURES.STORE)) {
        if (hasAvailability(props.item)) {
            const item = props.item;
            return React.createElement(CartItemNew, Object.assign({}, props, { item: item }));
        }
    }
    return React.createElement(CartItemOld, Object.assign({}, props));
}

import './style.scss'

import React, { Component } from 'react'

import Icon from 'components/UI/Icon'
import PropTypes from 'prop-types'
import close from 'img/cancel.svg'

class Notification extends Component {
    render() {
        return (
            <div className={`notification ${this.props.className}`}>
                <button className="notification__close-btn" onClick={() => this.props.onClose()}>
                    <Icon svg={close} />
                </button>
                <h2 className="notification__title">{this.props.title}</h2>
                {this.props.children}
            </div>
        )
    }
}

Notification.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClose: PropTypes.func,
    className: PropTypes.string,
}

export default Notification

import { Pill, PillList, PillListType, PillType, useSanityDocument } from 'common';
import React from 'react';
import { useDispatch } from 'react-redux';
import './LinkList.scss';
export function LinkList({ documentId }) {
    const dispatch = useDispatch();
    const linkList = useSanityDocument({
        documentId,
        queryTemplate: `
            title,
            links[] {
                title,
                target
            }
        `,
    });
    if (!linkList) {
        return null;
    }
    const track = (linkTitle) => {
        dispatch({
            type: 'LINK_LIST_CLICKED',
            payload: {
                listTitle: linkList.title,
                linkTitle: linkTitle,
            },
        });
    };
    return (React.createElement("div", { className: "link-list" },
        React.createElement("h2", { className: "link-list__title" }, linkList.title),
        React.createElement(PillList, { listType: PillListType.Horizontal }, linkList.links.map((link) => (React.createElement(Pill, { key: link.title, pillType: PillType.SelectableLink, navigationPath: link.target, onClick: () => track(link.title) }, link.title))))));
}

import React from 'react';
import { useSelector } from 'react-redux';
import { getDateAsText } from 'utilities/date';
import { capitalize } from 'utilities/text';
import './CartDeliverTo.scss';
export function CartDeliverTo({ address, deliveryDate }) {
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const getAddressString = (address) => {
        return orderType === 'group'
            ? 'flere adresser'
            : `${capitalize(address.streetName)}
          ${address.streetNo}${address.entrance || ''}, ${address.zip} ${capitalize(address.city)}`;
    };
    return (React.createElement("div", { className: "cart-deliver-to-address" },
        "Leveres ",
        React.createElement("strong", null, getDateAsText(deliveryDate, true)),
        " til ",
        React.createElement("strong", null, getAddressString(address)),
        "."));
}

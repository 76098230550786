import { ProductCarousel, ProductContext, ProductGrid } from 'product';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import './EditorialProducts.scss';
import ItemCarouselHeading from 'components/ItemCarouselHeading/ItemCarouselHeading';
function EditorialProducts({ title, subtitle, mobileSubtitle, leadText, target, targetText, displayProducts, products, }) {
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const { context: parentContext } = useContext(ProductContext);
    const slicedProducts = products.slice(0, displayProducts);
    const useCarousel = isMobile && slicedProducts.length <= 5;
    return (React.createElement(ProductContext.Provider, { value: { parentContext, context: (title === null || title === void 0 ? void 0 : title.replace(' ', '_')) || '' } },
        React.createElement("div", { className: "editorial-products" },
            React.createElement(ItemCarouselHeading, { title: title, subtitle: isMobile ? mobileSubtitle : subtitle, target: target, targetText: targetText }),
            !isMobile && leadText && (React.createElement("p", { className: "editorial-products__lead-text" }, leadText)),
            useCarousel ? (React.createElement(ProductCarousel, { products: slicedProducts || [], shrinkOnMobile: true })) : (React.createElement(ProductGrid, { products: slicedProducts || [] })))));
}
export default EditorialProducts;

import React from 'react';
import { sanityImageBuilder } from 'utilities/sanityClient';
import Banner from './Banner';
import { useSanityDocument } from 'common/utils/useSanityDocument';
export function DynamicBanner({ documentId }) {
    const document = useSanityDocument({
        documentId,
        queryTemplate: `
            title,
            subtitle,
            target,
            mainImage,
            overlayImage
        `,
    });
    if (!document) {
        return null;
    }
    return (React.createElement(Banner, { title: document.title, subtitle: document.subtitle, target: document.target, mainImage: {
            desktop: sanityImageBuilder
                .image(document.mainImage)
                .auto('format')
                .width(Math.min(1185, Math.ceil(window.innerWidth)))
                .height(Math.ceil(0.362 * Math.min(1185, Math.ceil(window.innerWidth))))
                .fit('min')
                .dpr(window.devicePixelRatio)
                .url(),
            mobile: sanityImageBuilder
                .image(document.mainImage)
                .auto('format')
                .width(Math.min(1185, Math.ceil(window.innerWidth)))
                .fit('min')
                .dpr(window.devicePixelRatio)
                .url(),
        }, overlayImage: document.overlayImage
            ? sanityImageBuilder.image(document.overlayImage).format('webp').url()
            : undefined }));
}

import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ReactSelect, { components } from 'react-select'
import './style.scss'

class Select extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            option: props.option,
            menuIsOpen: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.option.value !== state.option.value) {
            return {
                ...state,
                option: props.option,
            }
        } else {
            return null
        }
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption })
        if (this.props.onChange) {
            this.props.onChange(selectedOption.value)
        }
    }

    handleMenuOpen = () => {
        this.setState({ menuIsOpen: true })
    }

    handleMenuClose = () => {
        this.setState({ menuIsOpen: false })
    }

    render() {
        const { options } = this.props
        const { option, menuIsOpen } = this.state

        const CustomOption = (props) => {
            return props.isSelected ? (
                <div className="select__custom-option select__custom-option--is-selected">
                    <components.Option {...props} />
                </div>
            ) : (
                <div className="select__custom-option">
                    <components.Option {...props} />
                </div>
            )
        }

        const customStyles = (menuIsOpen) => {
            return {
                control: (styles) => ({
                    ...styles,
                    borderBottomLeftRadius: menuIsOpen ? 0 : styles.borderBottomLeftRadius,
                    borderBottomRightRadius: menuIsOpen ? 0 : styles.borderBottomRightRadius,
                }),
            }
        }

        return (
            <ReactSelect
                styles={customStyles(menuIsOpen)}
                className="select"
                classNamePrefix="select"
                name="region"
                components={{ Option: CustomOption }}
                placeholder="Region"
                value={option}
                onChange={this.handleChange}
                onMenuOpen={this.handleMenuOpen}
                onMenuClose={this.handleMenuClose}
                options={options}
                isSearchable={false}
            />
        )
    }
}

Select.propTypes = {
    option: PropTypes.object,
    options: PropTypes.array,
    onChange: PropTypes.func,
}

export default connect()(Select)

import React, { useMemo } from 'react';
import { sanityImageBuilder } from 'utilities/sanityClient';
import BannerV2 from 'common/components/BannerV2/BannerV2';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useSanityDocument } from 'common/utils/useSanityDocument';
export function DynamicBannerV2({ documentId }) {
    const dispatch = useDispatch();
    const banner = useSanityDocument({
        documentId,
        queryTemplate: `
            button,
            title,
            body,
            mirror,
            image
        `,
    });
    const imageSrc = useMemo(function getImageSrc() {
        if ((banner === null || banner === void 0 ? void 0 : banner.image) == undefined)
            return undefined;
        return sanityImageBuilder
            .image(banner.image)
            .auto('format')
            .dpr(window.devicePixelRatio)
            .url();
    }, [banner]);
    const onClick = () => {
        if (banner === null || banner === void 0 ? void 0 : banner.button) {
            dispatch(push(banner.button.target));
            dispatch({ type: 'PROMOTION_CLICKED', payload: { promotionTitle: banner.title } });
        }
    };
    if (!imageSrc)
        return null;
    return banner ? React.createElement(BannerV2, Object.assign({}, banner, { onClick: onClick, image: imageSrc })) : null;
}

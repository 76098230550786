export function getVideoIdFromVimeoUrl(url) {
    var _a, _b;
    if (url.length == 0)
        return null;
    if (!url.startsWith('http')) {
        url = 'https://' + url;
    }
    try {
        const urlObject = new URL(url);
        if (!urlObject.hostname.endsWith('vimeo.com'))
            return null;
        const match = /^\/(?<videoId>\d+)\/?/.exec(urlObject.pathname);
        return (_b = (_a = match === null || match === void 0 ? void 0 : match.groups) === null || _a === void 0 ? void 0 : _a.videoId) !== null && _b !== void 0 ? _b : null;
    }
    catch (error) {
        return null;
    }
}

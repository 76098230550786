import './style.scss'

import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'

export class Navigation extends PureComponent {
    render() {
        const { children, type } = this.props

        const classes = classNames({
            [`navigation--${kebabCase(type)}`]: type,
            navigation: true,
        })
        return (
            <nav className={classes}>
                <ul className="navigation__items">
                    {React.Children.map(children, (child) => {
                        if (child) {
                            return React.cloneElement(child, { type: type })
                        }
                    })}
                </ul>
            </nav>
        )
    }
}

Navigation.propTypes = {
    type: PropTypes.oneOf(['row', 'column', 'dropdown', 'large']),
    dispatch: PropTypes.func,
    children: PropTypes.any,
}

export default Navigation

import ItemCarouselHeading from 'components/ItemCarouselHeading/ItemCarouselHeading';
import groq from 'groq';
import { ProductCarousel, ProductGrid } from 'product';
import { ProductContext } from 'product/productContext';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import sanityClient from 'utilities/sanityClient';
import './FrequentlyBoughtByUser.scss';
const NUMBER_OF_PRODUCTS_TO_DISPLAY = 5;
export function FrequentlyBoughtByUser({ documentId }) {
    const allProducts = useSelector((state) => state.products.products);
    const myProductIds = useSelector((state) => state.products.myProducts);
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const [document, setDocument] = useState();
    const [myProducts, setMyProducts] = useState([]);
    const { context: parentContext } = useContext(ProductContext);
    useEffect(() => {
        const query = groq `
            *[ _id == $documentId ][0] {
                title,
                subtitle,
                mobileSubtitle
            }
        `.replace(/[\s\n]/, '');
        let isSubscribed = true;
        sanityClient
            .fetch(query, { documentId })
            .then((document) => (isSubscribed ? setDocument(document) : null));
        return () => {
            isSubscribed = false;
        };
    }, [documentId]);
    useEffect(() => {
        setMyProducts(myProductIds
            .map((myProductId) => allProducts.find((product) => parseInt(product.id) == myProductId))
            .flatMap((myProduct) => (!!myProduct ? [myProduct] : []))
            .slice(0, NUMBER_OF_PRODUCTS_TO_DISPLAY));
    }, [myProductIds, allProducts]);
    if (!document || myProducts.length === 0) {
        return null;
    }
    return (React.createElement(ProductContext.Provider, { value: { parentContext, context: 'FrequentlyBoughtByUser' } },
        React.createElement("div", { className: "frequently-bought-by-user" },
            React.createElement(ItemCarouselHeading, { title: document.title, subtitle: isMobile ? document.mobileSubtitle : document.subtitle }),
            isMobile ? (React.createElement(ProductCarousel, { products: myProducts, shrinkOnMobile: true })) : (React.createElement(ProductGrid, { products: myProducts })))));
}

import './style.scss'

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class ProductFilterIcon extends Component {
    render() {
        const { url, name, style } = this.props
        const styleClass = style ? style : null
        const classes = classNames({
            'product-tile-filter-icon': true,
            [`product-tile-filter-icon--${styleClass}`]: styleClass,
        })
        if (url == null) {
            return null
        } else {
            // nosemgrep: typescript.react.security.audit.react-http-leak.react-http-leak
            return <img title={name} alt={name} className={classes} src={url} />
        }
    }
}

ProductFilterIcon.propTypes = {
    url: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.string,
}

import './style.scss'

import React, { Component } from 'react'

import Icon from 'components/UI/Icon'
import PropTypes from 'prop-types'
import chevron from 'img/chevron-tall.svg'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

class CheckoutField extends Component {
    handleClick() {
        const { editUrl, dispatch } = this.props
        if (editUrl) {
            dispatch(push(editUrl))
        }
    }
    render() {
        const { title, children, editUrl, hasError } = this.props
        const classes = classNames({
            'checkout-field': true,
            'checkout-field__error': hasError,
        })

        return (
            <div className={classes} onClick={() => this.handleClick()}>
                <div className="checkout-field__body">
                    <div className="checkout-field__title">{title}</div>
                    <div className="checkout-field__content">{children}</div>
                </div>
                {editUrl && (
                    <button>
                        <Icon svg={chevron} />
                        Endre {title.replace(':', '').toLowerCase()}
                    </button>
                )}
            </div>
        )
    }
}

CheckoutField.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    editUrl: PropTypes.string,
    hasError: PropTypes.bool,
    dispatch: PropTypes.func,
}

export default connect()(CheckoutField)

import PropTypes from 'prop-types'

export const address = PropTypes.shape({
    deliveryPointId: PropTypes.number,
    streetName: PropTypes.string,
    streetNo: PropTypes.number,
    entrance: PropTypes.string,
    floorNo: PropTypes.number,
    floorType: PropTypes.string,
    flatNo: PropTypes.number,
    zip: PropTypes.string,
    city: PropTypes.string,
})

export const deliveryAddress = PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mobile: PropTypes.string,
    ...address,
})

export const storedPaymentPreference = PropTypes.shape({
    cardNo: PropTypes.string,
    expiry: PropTypes.string,
})

export const newPaymentMethod = PropTypes.shape({
    active: PropTypes.bool,
    cardHolderName: PropTypes.string,
    token: PropTypes.string,
    cardNo: PropTypes.string,
    cvc: PropTypes.string,
    expiry: PropTypes.string,
})

export const employee = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
})

export const personalInfo = PropTypes.shape({
    active: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
})

export const region = PropTypes.shape({
    id: PropTypes.number,
    shopId: PropTypes.number,
    availableWeekdays: PropTypes.arrayOf(PropTypes.number),
    transportProduct: PropTypes.string,
    externalRegion: PropTypes.string,
})

export const campaign = PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.shape({
        mobile: PropTypes.string,
        desktop: PropTypes.string,
    }),
    text: PropTypes.node,
    page_header: PropTypes.string,
    products: PropTypes.array,
    block: PropTypes.arrayOf(PropTypes.shape({ image: PropTypes.string, text: PropTypes.string })),
    background_color: PropTypes.string,
    title_text_color: PropTypes.string,
})

import { CartCheckout, CartContent, CartTotal, DeadlineCounter, EmptyGroupOrderCart, FreeFreightProgress, } from 'cart';
import { CheckoutCouponCode, OrderTypeToggle } from 'checkout';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import './CartContainer.scss';
function CartContainer() {
    const cartIsEmpty = useSelector((state) => state.cart.products.length === 0);
    const show = useSelector((state) => state.ui.showCart);
    const userIsLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const containerClass = classNames({
        'cart-container': true,
        'cart-container--show': show,
    });
    return (React.createElement("div", { className: containerClass },
        React.createElement("div", { className: "cart-container__order-type-toggle-container" },
            React.createElement(OrderTypeToggle, { name: "cart" })),
        React.createElement(DeadlineCounter, null),
        cartIsEmpty && orderType === 'group' ? React.createElement(EmptyGroupOrderCart, null) : React.createElement(CartContent, null),
        !cartIsEmpty && (React.createElement(React.Fragment, null,
            React.createElement(CartTotal, null),
            React.createElement(FreeFreightProgress, null),
            userIsLoggedIn && orderType !== 'group' ? (React.createElement(CheckoutCouponCode, { className: "cart-container__coupon" })) : null)),
        React.createElement(CartCheckout, null)));
}
export default CartContainer;

import classNames from 'classnames';
import { ProductTile } from 'product';
import React from 'react';
import { useSelector } from 'react-redux';
import './ProductCarousel.scss';
function ProductCarousel({ products, shrinkOnMobile = false }) {
    const isSubscriptionMode = useSelector((state) => state.subscription.isSubscriptionMode);
    const scrollItemClassNames = classNames({
        'scroll-item': true,
        'scroll-item--shrink-on-mobile': shrinkOnMobile,
    });
    return (React.createElement("div", { className: "scroll" }, products.map((product) => {
        return (React.createElement("div", { className: scrollItemClassNames, key: product.id },
            React.createElement(ProductTile, { product: product, shrinkOnMobile: shrinkOnMobile, isSubscriptionMode: isSubscriptionMode })));
    })));
}
export default ProductCarousel;
